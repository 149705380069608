import { Skeleton, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useGetEntitiesLinkedToStartupQuery } from 'apollo/generated/sdkShared';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { BaseCompanyDetails } from 'components/base/BaseCompanyDetails';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import {
  LinkedEntitiesType,
  LinkedToEntity,
} from 'components/dashboard/StartupInfoSidePanel/LinkedToEntity';
import StartupFunnelLabelChip from 'components/startups/StartupFunnelLabelChip';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { useMemo } from 'react';
import { DecoratedOrganizationStartup, RelatedList } from '.';
import { OpenStartupInTab } from 'components/shared/OpenStartupInTab';
import { formatDistance } from 'date-fns';

export const getDataGridColumns = () => {
  const COLUMNS: GridColDef<DecoratedOrganizationStartup>[] = [
    {
      field: 'name',
      headerName: 'Startup',
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 350,
      renderCell: function RenderName({ row: supplier }) {
        const { openStartupSidePanel } = useStartupSidePanel();

        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              openStartupSidePanel(supplier.id, {
                startupListId: undefined,
              })
            }
          >
            <BaseStartupAvatar
              startup={{
                logo_url: supplier.logo_url,
                name: supplier.name,
                domain: supplier.domain,
              }}
              size='medium'
            />
            <Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <TypographyWithEllipsis
                  tooltipTitle={
                    <Typography variant='body2'>{supplier.name}</Typography>
                  }
                  tooltipVariant='light'
                  variant='body2'
                  sx={({ palette, spacing }) => ({
                    color: palette.secondary.main,
                    cursor: 'pointer',
                    maxWidth: spacing(2),
                  })}
                >
                  {supplier.name}
                </TypographyWithEllipsis>
                {supplier?.funnel_label && (
                  <StartupFunnelLabelChip
                    label={supplier.funnel_label}
                    startupName={supplier.name as string}
                  />
                )}
                <OpenStartupInTab
                  name={supplier.name}
                  website={supplier.website}
                />
              </Stack>
              <BaseCompanyDetails
                company={{
                  employees_count: supplier.employees_count ?? '-',
                  founded_year: supplier.founded_year,
                  funding: supplier.funding,
                  hq: supplier.hq,
                  signal: supplier.signal_label,
                  name: supplier.name,
                }}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'short_description',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 300,
      renderCell: ({ value }) => {
        return (
          <ExpandedGridCell
            value={value || '-'}
            multiLineElipsis={true}
            numberOfLines={3}
          />
        );
      },
    },
    {
      field: 'linked_to',
      headerName: 'Linked to',
      flex: 1,
      minWidth: 300,
      getApplyQuickFilterFn: undefined,
      renderCell: params => (
        <RenderLinkedToCell params={{ value: params.row.id }} />
      ),
    },
    // Only visible for export purpose
    {
      field: 'listed_in',
      headerName: 'Listed in',
      flex: 1,
      minWidth: 300,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (_, row) => {
        const value = row.listed_in as RelatedList;
        return value ? value.map(({ title }) => title).join(', ') : null;
      },
    },
    {
      field: 'created_at',
      headerName: 'Listed since',
      flex: 1,
      minWidth: 200,
      valueFormatter: value => {
        const date = value as string;
        return value
          ? formatDistance(new Date(date), new Date(), {
              addSuffix: true,
            })
          : '-';
      },
    },
  ];
  return COLUMNS;
};

const RenderLinkedToCell = ({ params }: { params: { value: number } }) => {
  const cellValue = params.value;

  const { data: linkedEntitiesFetchResponse, loading } =
    useGetEntitiesLinkedToStartupQuery({
      variables: { startupId: cellValue! },
      fetchPolicy: 'cache-and-network',
    });

  const linkedEntitiesData = useMemo(
    () =>
      linkedEntitiesFetchResponse?.get_entities_linked_to_startup?.data
        ?.entities as LinkedEntitiesType | undefined,
    [
      linkedEntitiesFetchResponse?.get_entities_linked_to_startup?.data
        ?.entities,
    ],
  );

  if (loading) {
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <Skeleton variant='text' height={24} width={280} />
      </Stack>
    );
  }

  if (!linkedEntitiesData) return null;

  return (
    <Stack
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
      onClick={e => e.stopPropagation()}
    >
      <LinkedToEntity
        hideHeader
        showOnlyTitle
        linkedEntitiesData={linkedEntitiesData}
      />
    </Stack>
  );
};
