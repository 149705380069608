import { Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GetCachedContactsDocument,
  useCreateOrganizationStartupContactMutation,
  useFetchAndCacheContactsMutation,
  useGetCachedContactsQuery,
  useGetOrganizationStartupContactsQuery,
  useInitiateStartupOutreachMutation,
} from 'apollo/generated/sdkShared';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import useAuth from 'hooks/useAuth';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { formatApiDate } from 'utils/datetime';
import { OrganizationStartupContactType } from '../../../@types/organizationStartupContact';
import { OutreachStartupProps } from './types';

export const UserOutreachTab = ({
  startup,
  projectId,
  setActionButtonProps,
  setIsAddContactModalOpened,
}: {
  startup: OutreachStartupProps;
  projectId?: number;
  setActionButtonProps: Dispatch<
    SetStateAction<{
      label: string;
      disabled: boolean;
      onClick: () => void;
    }>
  >;
  setIsAddContactModalOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isStaff, user } = useAuth();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: contactsData } = useGetOrganizationStartupContactsQuery({
    variables: { startupId: startup.id },
  });

  const { data: cachedContactsData } = useGetCachedContactsQuery({
    variables: { startupId: startup.id },
  });

  const cachedContacts = useMemo(
    () =>
      cachedContactsData?.startup_contacts
        .filter(
          (
            cachedContact,
          ): cachedContact is typeof cachedContact & { email: string } =>
            !!cachedContact.email,
        )
        .map(cachedContact => {
          return {
            full_name: cachedContact.name,
            title: cachedContact.job_title,
            is_primary_contact: false,
            ...cachedContact,
            id: -1,
          };
        }) || [],
    [cachedContactsData?.startup_contacts],
  );

  const organizationStartupContacts =
    contactsData?.organization_startup_contacts || [];

  const [selectedContacts, setSelectedContacts] = useState<
    OrganizationStartupContactType[]
  >([]);

  const contactsToDisplay = uniqBy(
    [...organizationStartupContacts, ...cachedContacts],
    'email',
  );

  const [initiateStartupOutreach] = useInitiateStartupOutreachMutation();

  const [createOrganizationStartupContact] =
    useCreateOrganizationStartupContactMutation();

  const shouldUseGDTemplate = isStaff && Boolean(projectId);

  const outreachEmailSubject = 'Potential Pilot Project';
  const outreachEmailBody = shouldUseGDTemplate
    ? `Hi ${selectedContacts.map(contact => contact.full_name).join(',')},

I’m ${user.displayName} from GlassDollar, and I'm contacting you on behalf of my client, [INSERT ORGANIZATION], one of the leading [INSERT FIELD OF BUSINESS] companies globally. [INSERT ORGANIZATION] asked us to find promising startup solutions that help [INSERT POC TOPIC].

During an extensive review of 50+ companies, your solution stood out as a potentially excellent fit. Therefore, [INSERT ORGANIZATION] is keen to explore a proof-of-concept project with you, and I believe this could be a fantastic opportunity for ${startup.name} to win a new customer.

Would someone from your team be available for a high-level briefing call?

On a side note, GlassDollar does not take any share of the potential revenue you generate from this project. We are compensated solely by [INSERT ORGANIZATION] for our services. Our goal is to connect innovative startups like yours with incredible opportunities at leading corporations.

Looking forward to your response and am excited about the potential of working with you.

Many thanks and best regards,
${user.displayName}
`
    : '';

  useEffect(() => {
    const outreachEmailContacts = selectedContacts
      .map(contact => contact.email)
      .join(',');

    const outreachEmailString = `mailto:${outreachEmailContacts}?subject=${encodeURIComponent(outreachEmailSubject)}&body=${encodeURIComponent(outreachEmailBody)}`;

    return setActionButtonProps({
      label: 'Send (opens email client)',
      disabled: !Boolean(selectedContacts.length > 0),
      onClick: async () => {
        try {
          const updatedContacts = await Promise.all(
            selectedContacts.map(async contact => {
              if (contact.id === -1) {
                const addContactResponse =
                  await createOrganizationStartupContact({
                    variables: {
                      object: {
                        full_name: contact.full_name,
                        email: contact.email,
                        title: contact.title,
                        linkedin_url: contact.linkedin_url,
                        startup_id: startup.id,
                        connected_since: formatApiDate(Date.now()),
                      },
                    },
                    update: (cache, { data }) => {
                      if (!data?.insert_organization_startup_contacts_one?.id) {
                        return;
                      }
                      cache.evict({
                        id: cache.identify({
                          __typename: 'organization_startup_contacts',
                          id: data?.insert_organization_startup_contacts_one.id,
                        }),
                      });
                      cache.gc();
                    },
                  });

                const addedContact =
                  addContactResponse.data
                    ?.insert_organization_startup_contacts_one;

                if (addedContact) {
                  captureAnalyticsEvent(
                    'Organization Startup Contact selected from fetched contact.',
                    {
                      contactId: addedContact.id,
                    },
                  );

                  return {
                    ...contact,
                    id: addedContact.id,
                  };
                }
              } else {
                return contact;
              }
            }),
          );

          await initiateStartupOutreach({
            variables: {
              startup_id: startup.id,
              project_id: projectId,
              contacts: updatedContacts.map(contact => {
                return {
                  organization_startup_contact_id: contact?.id,
                };
              }),
            },
            update: (cache, { data }) => {
              cache.evict({
                id: cache.identify({
                  __typename: 'suppliers',
                  id: startup.id,
                }),
              });

              if (!data?.insert_startup_connections_one?.id) {
                return;
              }
              cache.evict({
                id: cache.identify({
                  __typename: 'startup_connections',
                  id: data.insert_startup_connections_one.id,
                }),
              });
              cache.gc();
            },
          }).then(() => {
            captureAnalyticsEvent('Outreach started by user.', {
              startupId: startup.id,
              startupName: startup.name,
            });

            window.location.href = outreachEmailString;

            navigate(PATH_ROOT.startups.requests);
            enqueueSnackbar('Connection request added successfully.', {
              variant: 'success',
            });
          });
        } catch (error) {
          captureException(error);
          enqueueSnackbar('Error saving connection. Please try again later.', {
            variant: 'error',
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setActionButtonProps,
    selectedContacts,
    initiateStartupOutreach,
    startup.id,
  ]);

  const handleToggleCheckbox =
    (value: OrganizationStartupContactType) => () => {
      const currentIndex = selectedContacts.indexOf(value);
      const newChecked = [...selectedContacts];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setSelectedContacts(newChecked);
    };

  const cacheHasContacts = cachedContacts.length > 0;

  const [fetchAndCacheContacts, { loading: fetchAndCacheContactsLoading }] =
    useFetchAndCacheContactsMutation();

  useEffect(() => {
    if (!cacheHasContacts && !fetchAndCacheContactsLoading) {
      (async () => {
        try {
          const { data: fetchedContactData } = await fetchAndCacheContacts({
            variables: {
              startupId: startup.id,
            },
            refetchQueries: [GetCachedContactsDocument],
          });

          const fetchedContacts =
            fetchedContactData?.save_contacts.data?.savedContacts || [];

          captureAnalyticsEvent('Fetched contacts to outreach.', {
            startupId: startup.id,
            cachedContactIds: fetchedContacts.map(contact => contact.id),
          });
        } catch (error) {
          captureException(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup.id]);

  return (
    <Grid container spacing={1} sx={{ marginY: 1 }}>
      <Grid item xs={4}>
        <Card
          sx={{
            width: '100%',
            paddingY: 3,
            marginY: 2,
          }}
        >
          <Stack alignItems='center' justifyContent='center'>
            <Typography marginBottom={1.5}>{startup.name}</Typography>
            <BaseStartupAvatar
              startup={{
                name: startup.name,
                domain: startup.domain,
                logo_url: startup.logo_url,
              }}
              size='medium'
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Stack spacing={2}>
          <List
            sx={{ width: '100%', paddingX: 4 }}
            subheader={
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                marginY={0.5}
              >
                <ListSubheader component='div'>Contacts</ListSubheader>
                <Button
                  onClick={() => setIsAddContactModalOpened(true)}
                  startIcon={<AddIcon />}
                  sx={{ marginY: 0.5, maxHeight: 32 }}
                >
                  Add
                </Button>
              </Stack>
            }
          >
            {fetchAndCacheContactsLoading ? (
              <Stack alignItems='center' padding={4}>
                <CircularProgress variant='indeterminate' />
              </Stack>
            ) : (
              <>
                {contactsToDisplay.length > 0 ? (
                  contactsToDisplay.map(contact => {
                    const labelId = `checkbox-list-label-${contact}`;
                    let contactText = contact.full_name;
                    contact.title
                      ? (contactText += ` (${contact.title})`)
                      : (contactText += ` (${contact.email})`);

                    return (
                      <ListItem
                        key={contact.id}
                        disablePadding
                        sx={{ marginX: 0.3 }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggleCheckbox(contact)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge='start'
                              checked={selectedContacts.indexOf(contact) !== -1}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          {contact.is_primary_contact && (
                            <Chip
                              sx={{ marginRight: 1, fontWeight: 'bold' }}
                              label='Primary'
                              size='small'
                              color='primary'
                            />
                          )}
                          <Tooltip title={contact.email} arrow>
                            <ListItemText
                              id={labelId}
                              primary={contactText}
                              primaryTypographyProps={{
                                variant: 'body1',
                              }}
                            />
                          </Tooltip>
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography align='center' variant='body2' marginTop={3.5}>
                    No contacts yet
                  </Typography>
                )}
              </>
            )}
          </List>
        </Stack>
      </Grid>
    </Grid>
  );
};
