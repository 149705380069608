import { ArrowBackIos } from '@mui/icons-material';
import {
  Box,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  TypographyTypeMap,
  useTheme,
} from '@mui/material';
import { useNavigateBack } from 'hooks/useNavigateBack';
import { useSnackbar } from 'notistack';
import { ProjectStatusField } from 'pages/dashboard/scoping/MainStatusFields';
import { FocusEvent, useRef, useState } from 'react';

type EditableProps = {
  readonly: false;
  updateTitle: (newTitle: string) => Promise<void>;
  title: string;
  tooltipTitle?: string;
  titleVariant?: TypographyTypeMap['props']['variant'];
  backToParentPath: string;
  sx?: SxProps;
  type?: 'project' | 'lead' | 'list';
  children?: React.ReactNode;
  hideBackButton?: boolean;
};

type ReadonlyProps = {
  readonly: true;
  updateTitle?: undefined;
  title: string;
  tooltipTitle?: string;
  titleVariant?: TypographyTypeMap['props']['variant'];
  backToParentPath: string;
  sx?: SxProps;
  type?: 'project' | 'lead' | 'list';
  children?: React.ReactNode;
  hideBackButton?: boolean;
};

export default function DetailsHeaderTitle({
  title,
  hideBackButton = false,
  tooltipTitle = 'Back',
  titleVariant = 'h4',
  backToParentPath,
  children,
  type,
  sx,
  ...editingProps
}: EditableProps | ReadonlyProps) {
  const { navigateBack } = useNavigateBack(backToParentPath);
  const { spacing } = useTheme();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const [initialTitle] = useState(title);

  const handleTitleUpdate = async (e: FocusEvent<HTMLDivElement, Element>) => {
    if (loading) return;
    if (editingProps.readonly) return;

    try {
      const newTitleRaw = e.currentTarget.textContent;

      if (initialTitle === newTitleRaw) return;
      const newTitle = newTitleRaw?.trim();

      if (newTitle === '' || !newTitle) {
        enqueueSnackbar('Title cannot be empty', { variant: 'error' });
        e.currentTarget.textContent = initialTitle;
        return;
      }

      setLoading(true);
      await editingProps.updateTitle(newTitle);

      enqueueSnackbar('Title updated', { variant: 'success' });
    } catch (_error) {
      enqueueSnackbar('Failed to update title', { variant: 'error' });
    } finally {
      setLoading(false);
      setIsEditing(false);
      restoreScrollPosition();
    }
  };

  const restoreScrollPosition = () => {
    if (titleRef.current) {
      titleRef.current.scrollLeft = 0;
    }
  };

  return (
    <Stack
      direction='row'
      justifyContent={'flex-start'}
      flexGrow={1}
      minWidth={0}
      alignItems='center'
      data-testid='details-title-heading-container'
      sx={sx}
    >
      {!hideBackButton && (
        <Tooltip title={tooltipTitle}>
          <ArrowBackIos
            onClick={navigateBack}
            fontSize='small'
            sx={{ cursor: 'pointer', width: spacing(2) }}
          />
        </Tooltip>
      )}
      {['project', 'lead'].includes(type ?? '') && (
        <Box marginRight={0.5}>
          <ProjectStatusField />
        </Box>
      )}
      <Tooltip title={title}>
        <Typography
          ref={titleRef}
          data-testid='details-title-heading'
          noWrap
          textOverflow={isEditing ? 'inherit' : 'ellipsis'}
          display='inline-block'
          variant={titleVariant}
          fontWeight={400}
          {...(!editingProps.readonly
            ? {
                sx: {
                  cursor: 'pointer',
                  '&:hover': {
                    color: !editingProps.readonly
                      ? 'secondary.main'
                      : 'inherit',
                  },
                },
              }
            : {})}
          contentEditable={!editingProps.readonly}
          suppressContentEditableWarning
          onFocus={() => setIsEditing(true)}
          onKeyDown={e => {
            if (editingProps.readonly) return;
            if (e.key === 'Enter' || e.key === 'Tab') {
              e.preventDefault();
              e.currentTarget.blur();
              window?.getSelection()?.removeAllRanges();
            }

            if (e.key === 'Escape') {
              e.preventDefault();
              e.currentTarget.textContent = initialTitle;
              e.currentTarget.blur();
              window?.getSelection()?.removeAllRanges();
            }
          }}
          onBlur={handleTitleUpdate}
        >
          {title}
        </Typography>
      </Tooltip>
      {children}
    </Stack>
  );
}
