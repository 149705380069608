import { useUpdateCategorizedStartupMutation } from 'apollo/generated/sdkShared';
import useAuth from 'hooks/useAuth';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useState } from 'react';
import { DecoratedStartup } from '../shared/StartupsTableContext';
import VotersInfoTooltip from './VotersInfoTooltip';
import { VotingButtonBase } from './shared/VotingButtonBase';

export const VotingButtonInternal = ({
  decoratedStartup,
}: {
  decoratedStartup: DecoratedStartup;
}) => {
  const { user: currentUser } = useAuth();
  const [votingInProgress, setVotingInProgress] = useState(false);
  const hasVoted = decoratedStartup.voters.includes(currentUser.email);
  const [updateCategorizedStartup] = useUpdateCategorizedStartupMutation();

  const toggleVote = async () => {
    if (!currentUser.email) return;

    setVotingInProgress(true);

    const toggledHasVoted = !hasVoted;
    const voters = toggledHasVoted
      ? [...decoratedStartup.voters, currentUser.email]
      : decoratedStartup.voters.filter(email => email !== currentUser.email);

    await updateCategorizedStartup({
      variables: {
        id: decoratedStartup.categorized_startup_id,
        payload: {
          voters,
        },
      },
    });

    captureAnalyticsEvent(
      toggledHasVoted ? 'Vote For Startup Added' : 'Vote For Startup Removed',
      {
        startupId: decoratedStartup.id,
        startupListId: decoratedStartup.startup_list_id,
      },
    );

    setVotingInProgress(false);
  };

  const votersCount = decoratedStartup.voters.length;
  const userVoted = decoratedStartup.voters.includes(currentUser.email);

  return (
    <VotersInfoTooltip
      decoratedStartup={decoratedStartup}
      title={hasVoted ? 'Click to remove your vote' : 'Click to vote'}
    >
      <span>
        <VotingButtonBase
          userVoted={userVoted}
          votesCount={votersCount}
          onClick={() => {
            toggleVote();
          }}
          disabled={votingInProgress}
        />
      </span>
    </VotersInfoTooltip>
  );
};
