import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Box,
} from '@mui/material';
import { OrganizationDepartments } from 'apollo/generated/sdkInnovationManager';
import {
  useAddPersonModalGetPeopleQuery,
  usePersonDepartmentAutocompleteGetDepartmentsQuery,
} from 'apollo/generated/sdkShared';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo, useRef, useState } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Person, PersonForInsert } from '../../../@types/stakeholder';
import { LoadingButton } from '@mui/lab';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';

import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import { AddDepartmentDialog } from 'components/settings/Departments';

type AddPersonModalProps = {
  open: boolean;
  onHide: () => void;
  onSetValues: (
    f: PersonForInsert,
  ) => Promise<{ stakeholderId: number | null }>;
  setPersonValue?: (value: Person) => void;
  setFieldValue?: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
};

export type DepartmentType = Pick<
  OrganizationDepartments,
  'id' | 'name' | 'description'
>;

export const AddPersonModal = (props: AddPersonModalProps) => {
  const [loading, setLoading] = useState(false);
  const { open, onHide, onSetValues, setPersonValue, setFieldValue } = props;
  const { data } = useAddPersonModalGetPeopleQuery();
  const people = useMemo(() => data?.people || [], [data]);
  const [nestedModalOpen, setNestedModalOpen] = useState(false);
  const inputValueRef = useRef('');

  const peopleForAutocomplete = useMemo(
    () =>
      people.map(s => ({
        id: s.id,
        full_name: s.full_name,
        email: s.email,
        organization_department: s.organization_department,
        job_title: s.job_title,
      })) || [],
    [people],
  );

  const STAKEHOLDER_VALIDATION_SCHEMA = useMemo(
    () =>
      z.object({
        full_name: z.string({
          required_error: 'Full name is required',
        }),
        department: z
          .string({
            required_error: 'Department is required',
          })
          .optional(),
        job_title: z
          .string({
            required_error: 'Job title is required',
          })
          .optional(),
        email: z
          .string({
            required_error: 'Company email is required',
          })
          .email()
          .refine(
            email =>
              !peopleForAutocomplete
                .map(s => s.email.toLowerCase())
                .includes(email.toLowerCase()),
            {
              message: 'A person with that email already exists',
            },
          ),
      }),
    [peopleForAutocomplete],
  );

  const formik = useFormik<PersonForInsert>({
    initialValues: {
      full_name: '',
      email: '',
      organization_department_id: null,
      job_title: '',
    },
    validationSchema: toFormikValidationSchema(STAKEHOLDER_VALIDATION_SCHEMA),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const normalizedValues = {
        ...values,
        email: values.email.toLowerCase(),
      };

      const { stakeholderId } = await onSetValues(normalizedValues);

      if (setPersonValue && setFieldValue) {
        setPersonValue({ ...normalizedValues, id: stakeholderId! });
        setFieldValue('stakeholder_id', stakeholderId);
      }

      captureAnalyticsEvent('Stakeholder added from stakeholders page', {
        stakeholderName: values.full_name,
        stakeholderEmail: values.email,
        stakeholderId: stakeholderId,
      });

      resetForm();
      onHide();
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const { id: organizationId } = useCurrentOrganizationFromContext();

  const { data: departmentData } =
    usePersonDepartmentAutocompleteGetDepartmentsQuery({
      variables: {
        organization_id: organizationId,
      },
    });

  const departments = useMemo(
    () =>
      departmentData?.organization_departments.map(d => ({
        id: d.id,
        name: d.name,
        description: d.description,
      })) || [],
    [departmentData],
  );

  const handleSetSelectedDepartment = async (id: number | undefined) => {
    formik.setFieldValue('organization_department_id', id);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      data-testid='add-person-modal'
      onClose={onHide}
    >
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add a BU stakeholder</DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <TextField
                {...getFieldProps('full_name')}
                error={Boolean(touched.full_name && errors.full_name)}
                helperText={touched.full_name && errors.full_name}
                fullWidth
                autoFocus
                label='Name *'
                data-testid='add-person-modal__name'
              />
              <TextField
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                label='Company email *'
                data-testid='add-person-modal__email'
              />
              <TextField
                {...getFieldProps('job_title')}
                error={Boolean(touched.job_title && errors.job_title)}
                helperText={touched.job_title && errors.job_title}
                fullWidth
                label='Job title'
                data-testid='add-person-modal__job-title'
              />
              <Autocomplete<DepartmentType, false, false>
                options={departments.map(d => ({
                  id: d.id,
                  name: d.name,
                  description: d.description,
                }))}
                data-testid='add-person-modal__department'
                getOptionLabel={option => option.name}
                renderOption={(props, option, state) => (
                  <Box key={`select-department-${option.id}`}>
                    {state.index === 0 && (
                      <BaseAddButtonForAutocomplete
                        onClick={e => {
                          e.preventDefault();
                          setNestedModalOpen(true);
                          captureAnalyticsEvent(
                            'Add Person Modal: New Department button clicked',
                          );
                        }}
                        text='New Department'
                      />
                    )}
                    <Box
                      component='li'
                      {...props}
                      key={option.id}
                      data-testid={`department-${option.id}`}
                    >
                      <Stack spacing={0}>
                        <Typography variant='body1'>{option.name}</Typography>
                        {option.description && (
                          <Typography
                            variant='body2'
                            color='text.secondary'
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '500px',
                            }}
                          >
                            {option.description}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                )}
                noOptionsText={
                  <BaseAddButtonForAutocomplete
                    text='New Department'
                    divider={false}
                    onClick={e => {
                      e.preventDefault();
                      setNestedModalOpen(true);
                      captureAnalyticsEvent(
                        'Add Person Modal: New Department button clicked',
                      );
                    }}
                  />
                }
                value={
                  departments.find(
                    d => d.id === formik.values.organization_department_id,
                  ) || null
                }
                onChange={(_, value) => {
                  handleSetSelectedDepartment(value?.id);
                }}
                renderInput={params => (
                  <TextField {...params} label='Department' />
                )}
                onInputChange={(_, newInputValue) => {
                  inputValueRef.current = newInputValue;
                }}
              />
              {nestedModalOpen && (
                <AddDepartmentDialog
                  open
                  setAddDepartmentDialogOpen={setNestedModalOpen}
                  defaultName={inputValueRef.current}
                  setSelectedDepartment={handleSetSelectedDepartment}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              loading={loading}
              variant='contained'
              data-testid='add-person-modal__save'
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
