import { Circle } from '@mui/icons-material';
import {
  Link,
  List,
  ListItem,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { capitalizeFirstLetter, parseLinksInText } from 'utils/general';
import { EditCustomColumnReferencePopup } from './EditCustomColumnReferencePopup';
import { CustomFeatureCellReadonly } from './fields';
import { DecoratedStartup } from './shared/StartupsTableContext';

export type ReferenceState = { value: null; open: boolean };
export type SetReference = Dispatch<SetStateAction<ReferenceState>>;
export type OnChangeCustomField = ({ value }: { value: string }) => void;

export const CustomColumnCell = ({
  columnName,
  type,
  value,
  isReadonly,
  categorizedStartup,
}: {
  isReadonly: boolean;
  value: string | null | undefined;
  type: 'text' | 'tribool' | 'line' | 'number';
  columnName: string;
  categorizedStartup: DecoratedStartup;
}) => {
  const [editReferenceModalOpen, setEditReferenceModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleReferenceModalClose = () => {
    setEditReferenceModalOpen(false);
    setAnchorEl(null);
  };
  const reference =
    categorizedStartup?.custom_dimensions_json_v1?.[columnName]?.reference ||
    '';
  const customValue = {
    value,
    reference,
  };

  // Case when moving a startup from shortlist to longlist
  if (!categorizedStartup?.custom_dimensions_json_v1) {
    return null;
  }

  if (isReadonly) {
    return (
      <CustomColumnCellContainer
        reference={customValue.reference}
        onClick={undefined}
      >
        <CustomFeatureCellReadonly
          value={customValue.value || ''}
          type={type}
        />
      </CustomColumnCellContainer>
    );
  }

  return (
    <>
      <CustomColumnCellContainer
        reference={customValue.reference}
        onClick={event => {
          setAnchorEl(event.currentTarget);
          setEditReferenceModalOpen(true);
        }}
      >
        <CustomFeatureCellReadonly
          value={customValue.value || ''}
          type={type}
        />

        {editReferenceModalOpen && (
          <EditCustomColumnReferencePopup
            anchorEl={anchorEl}
            open={editReferenceModalOpen}
            onClose={handleReferenceModalClose}
            columnName={columnName}
            categorizedStartup={categorizedStartup}
          />
        )}
      </CustomColumnCellContainer>
    </>
  );
};

const CustomColumnCellContainer = ({
  reference,
  onClick,
  children,
}: {
  reference: string | null | undefined;
  onClick: MouseEventHandler<HTMLElement> | undefined;
  children: ReactNode;
}) => {
  const isReadonly = !onClick;
  const showReference = !isReadonly || !!reference;

  return (
    <Stack
      direction='row'
      justifyContent='center'
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {showReference && (
        <ReferenceIndicator
          sx={{
            cursor: isReadonly ? 'default' : 'pointer',
          }}
          onClick={onClick}
          reference={reference}
        />
      )}
      {children}
    </Stack>
  );
};

const ReferenceIndicator = ({
  reference,
  sx,
  onClick,
}: {
  reference: string | null | undefined;
  sx: SxProps;
  onClick: MouseEventHandler<HTMLElement> | undefined;
}) => {
  const { palette, shadows } = useTheme();

  return (
    <Tooltip
      arrow
      placement='right'
      slotProps={{
        popper: {
          sx: {
            '& .MuiTooltip-arrow': {
              color: palette.grey[0],
            },
            '& .MuiTooltip-tooltip': {
              backgroundColor: palette.grey[0],
              padding: 0,
              minHeight: 50,
              display: 'flex',
              alignItems: 'center',
              boxShadow: shadows[10],
            },
          },
        },
      }}
      title={
        reference ? (
          <List>
            <Typography paddingX={1} fontSize={14} color={palette.grey[600]}>
              {parseLinksInText({
                textToParse: reference || '-',
                renderLink: (link, domain) => (
                  <ListItem key={`${link}-${domain}`}>
                    <Link
                      onClick={event => event.stopPropagation()}
                      href={link}
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: palette.secondary.main }}
                    >
                      {capitalizeFirstLetter(domain)}
                    </Link>
                  </ListItem>
                ),
              })}
            </Typography>
          </List>
        ) : null
      }
    >
      <Typography
        component='span'
        sx={{
          position: 'absolute',
          right: -5,
          top: -10,
          zIndex: 1,
          ...sx,
        }}
        onClick={onClick}
      >
        <Circle
          sx={({ palette }) => ({
            margin: '8px',
            color: reference ? palette.primary.main : palette.grey[400],
            fontSize: '10px',
          })}
        />
      </Typography>
    </Tooltip>
  );
};
