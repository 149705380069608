import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

/**
 * Returns the Access Token to be used in API requests before the Apollo Provider is available.
 * E.g. in the OrganizationPortalContext.
 * @returns The access token if the user is authenticated, otherwise null.
 */
export function useAccessToken() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        if (!isAuthenticated) return;
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error('Error getting token:', error);
        setToken(null);
      }
    };
    getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return token;
}
