import { Box, MenuItem, Popover, TextField, Typography } from '@mui/material';
import { GridEditCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import {
  CustomDimensionCellJSON,
  CustomFeatureHeader,
} from '../../@types/shared';
import { OnChangeCustomField } from './CustomColumnCell';

export const EditCustomColumnCell = ({
  type,
  params,
}: {
  type: CustomFeatureHeader['type'];
  params: GridEditCellProps<string | number>;
}) => {
  return type === 'tribool' ? (
    <CustomEditTriboolComponent {...params} />
  ) : (
    <CustomEditCell {...params} type={type} />
  );
};

const CustomEditTriboolComponent = (
  props: GridEditCellProps<string | number>,
) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();

  const rowIndex = apiRef.current
    .getAllRowIds()
    .findIndex(rowId => rowId === id);
  const nextRowId = apiRef.current.getAllRowIds()[rowIndex + 1];

  const handleValueChange = ({ value: newValue }: { value: string }) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field });
    if (nextRowId) apiRef.current.startCellEditMode({ id: nextRowId, field });
  };

  return (
    <CustomFeatureTriboolField
      value={value || ''}
      onChange={handleValueChange}
      focused={hasFocus as boolean}
    />
  );
};

const CustomFeatureTriboolField = ({
  value,
  onChange,
  focused,
}: {
  value: CustomDimensionCellJSON['value'];
  onChange: OnChangeCustomField;
  focused: boolean;
}) => {
  return (
    <TextField
      select
      fullWidth
      focused={focused}
      // Due to the automatic datagrid cell editing shortcuts, in order "Enter" and "Tab" to work, need to make sure
      // that always will be a "new" value to be selected from the dropdown, so clicking "Enter" to directly "save" + move
      // the user to the next cell.
      value=''
      onChange={e => {
        onChange({ value: e.target.value });
      }}
      sx={{
        '& fieldset': {
          border: 'none !important',
        },
      }}
      slotProps={{
        select: {
          displayEmpty: true,
          defaultOpen: true,
          renderValue: () => <Typography>{value || 'Unknown'}</Typography>,
        },
      }}
    >
      {['yes', 'no', 'unknown'].map(v => (
        <MenuItem value={v} key={v} sx={{ width: '100%' }}>
          {v}
        </MenuItem>
      ))}
    </TextField>
  );
};

const LegendItem = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => {
  return (
    <Typography
      variant='caption'
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Box component='span' sx={{ fontWeight: 'bold', mx: 0.5 }}>
        {label}:
      </Box>{' '}
      {description}
    </Typography>
  );
};

const CellTypeLegend = ({ type }: { type: CustomFeatureHeader['type'] }) => {
  return (
    <Typography
      variant='caption'
      color='text.secondary'
      sx={{ mb: 1, fontStyle: 'italic' }}
      whiteSpace='pre-wrap'
    >
      Input type:{' '}
      {type === 'line' &&
        'Line chart \nUse the following format: [2, 10, 5, 15, 10]'}
      {type === 'text' && 'Free text'}
      {type === 'number' && 'Number'}
    </Typography>
  );
};

const CustomEditCell = (
  props: GridEditCellProps<string | number> & {
    type: CustomFeatureHeader['type'];
  },
) => {
  const { id, value, field, type } = props;
  const [initialValue] = useState(value);
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [localValue, setLocalValue] = useState(value?.toString() || '');
  const isNumberType = type === 'number';

  useEffect(() => {
    const cell = document.querySelector('.MuiDataGrid-cell--editing');
    if (cell) {
      setAnchorEl(cell as HTMLElement);
    }
  }, [field, id]);

  const handleValueChange = (newValue: string) => {
    setLocalValue(newValue);

    if (isNumberType) {
      const numericValue = newValue !== '' ? Number(newValue) : null;
      apiRef.current.setEditCellValue({ id, field, value: numericValue });
    } else {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey || e.metaKey)) {
      e.stopPropagation();
      if (!isNumberType) {
        // @ts-expect-error - mix of react and dom events
        const cursorPosition = e.target.selectionStart;
        const newValue =
          localValue.slice(0, cursorPosition) +
          '\n' +
          localValue.slice(cursorPosition);

        handleValueChange(newValue);

        // set cursor to right after the new line
        const newCursorPosition = cursorPosition + 1;

        setTimeout(() => {
          // @ts-expect-error - mix of react and dom events
          e.target.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      apiRef.current.stopCellEditMode({ id, field });
    } else if (e.key === 'Escape') {
      handleValueChange(`${initialValue}`);
    }
  };

  const moveCursorToEnd = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const valueLength = e.target.value.length;
    e.target.setSelectionRange(valueLength, valueLength);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValue = e.target.value;

    if (isNumberType) {
      if (newValue === '' || newValue === '-' || !isNaN(Number(newValue))) {
        handleValueChange(newValue);
      }
    } else {
      handleValueChange(newValue);
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        apiRef.current.stopCellEditMode({ id, field });
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          minWidth: `${Math.max(300, anchorEl?.clientWidth || 0)}px`,
          maxWidth: '500px',
          minHeight: isNumberType ? '0px' : '120px',
          padding: 1,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CellTypeLegend type={type} />
        <TextField
          autoFocus
          multiline={!isNumberType}
          minRows={isNumberType ? 1 : 3}
          value={localValue}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={moveCursorToEnd}
          data-testid='custom-edit-cell'
          sx={{
            width: '100%',
            flex: 1,
            '& .MuiInputBase-input': { padding: 0 },
            '& .MuiInputBase-root': {
              padding: 1,
              fontSize: theme => theme.typography.body2.fontSize,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '& textarea': {
              minHeight: isNumberType ? '0px' : '100px',
            },
          }}
        />

        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            justifyContent: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
            pt: 1,
          }}
        >
          <LegendItem label='Enter' description='Save' />
          {type === 'text' && (
            <LegendItem label='Ctrl/Cmd + Enter' description='New line' />
          )}
          <LegendItem label='Esc' description='Cancel' />
        </Box>
      </Box>
    </Popover>
  );
};
