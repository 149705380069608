import { useValidateOrganizationEmailDomainMutation } from 'apollo/generated/sdkShared';
import { useTranslateForOrganization } from 'components/OrganizationPortal/shared/RequestVCUSection';
import { X_HASURA_ORGANIZATION_UUID } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { z } from 'zod';

export const useGetPersonInfoSchema = () => {
  const { translate } = useTranslateForOrganization();
  const organization = useCurrentOrganizationFromContext();
  const [validateEmail] = useValidateOrganizationEmailDomainMutation({
    context: {
      headers: {
        [X_HASURA_ORGANIZATION_UUID]: organization.uuid,
      },
    },
  });

  return z.object({
    requesterName: z.string().min(1, {
      message: translate('generalForm.nameEmpty'),
    }),
    requesterDepartment: z.string().min(1, {
      message: translate('generalForm.departmentEmpty'),
    }),
    requesterEmail: z
      .string({
        required_error: translate('generalForm.emailEmpty'),
      })
      .email({ message: translate('generalForm.emailInvalid') })
      .refine(
        async value => {
          if (value.includes('@')) {
            const isValidEmail = await validateEmail({
              variables: {
                email: value,
              },
              context: {
                headers: {
                  [X_HASURA_ORGANIZATION_UUID]: organization.uuid,
                },
              },
            });

            return isValidEmail.data?.validate_organization_email
              .is_valid_email;
          }
        },
        {
          message: translate(
            'requestForm.formValidation.emailNotInOrganization',
          ),
        },
      ),
  });
};
