import { Button, darken, styled } from '@mui/material';
import {
  DataGridPro,
  GridEventListener,
  GridRowParams,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { captureException } from '@sentry/react';
import { ProjectStartupFiles, Suppliers } from 'apollo/generated/sdkShared';
import EmptyContent from 'components/EmptyContent';
import LoadingTable from 'components/shared/table/LoadingTable';
import { ConfirmRequestFormLeave } from 'pages/request-form/confirmRequestFormLeave';
import React, { useState } from 'react';
import { z } from 'zod';
import useDocuments from './useDocuments';
import { useGetDocumentColumns } from './useGetDocumentColumns';
import { UploadModal } from './UploadModal';
import { AddOutlined } from '@mui/icons-material';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { useStartupIdFromSearchParams } from 'hooks/useStartupIdFromSearchParams';

export type Startup = Pick<Suppliers, 'id' | 'name' | 'logo_url'>;

export type StartupListDocument = Pick<
  ProjectStartupFiles,
  'id' | 'comment' | 'signed_at' | 'document_type'
> & {
  startup_name?: string;
  startup: Startup | null | undefined;
  __typename: 'project_startup_files';
  file_id?: number;
  attachment_url?: string;
  file_name: string;
  project?:
    | {
        id: number;
        title: string;
      }
    | null
    | undefined;
};

export const DOCUMENT_TYPE_OPTIONS = [
  'NDA',
  'Request for Proposal (RfP)',
  'Proposal Template (Offer Template)',
  'Supplier Creation',
  'Purchase Order (PO)',
  'Minimum Requirements for the Purchase of Software',
  'IT Security Check',
  'IT Data Privacy Check (DPA)',
  'Notes/Other',
] as const;

export type StartupForDocumentColumns = {
  id: number;
  name: string;
};

export const UploadSchema = z.object({
  document_type: z.string().min(1, 'Document type is required'),
  startup_id: z.number().nullable().optional(),
  project_id: z.number().nullable().optional(),
  comment: z.string().optional(),
  is_signed: z.boolean().optional(),
  signed_at: z.date().nullable().optional(),
});

export type UploadMetadata = z.infer<typeof UploadSchema>;

export type UploadProjectFileFunction = (
  file: File | null,
  metadata: UploadMetadata,
) => Promise<void>;

export default function DocumentsTab({
  projectId,
  startupId,
  startups,
}: {
  projectId?: number;
  startupId?: number;
  startups: StartupForDocumentColumns[];
}) {
  const apiRef = useGridApiRef();

  const { files, error, loading, updateFile, updating, uploadProjectFile } =
    useDocuments({ projectId, startupId, startups });

  const { openedStartupId: startupOpenedInPanel } =
    useStartupIdFromSearchParams();

  const { setIsDocumentModalOpen, isDocumentModalOpen } = useStartupSidePanel();

  const [isLocalAddNewModalOpen, setIsLocalAddNewModalOpen] =
    useState<boolean>(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);

  const handleRowEditStart = (
    _params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    _params,
    event,
  ) => {
    event.defaultMuiPrevented = true;
  };

  if (error) {
    captureException(error);
  }

  const { columns } = useGetDocumentColumns({
    startups,
    projectId,
    startupId,
  });

  if (loading) return <LoadingTable />;

  console.log({ isLocalAddNewModalOpen });

  return (
    <>
      <ConfirmRequestFormLeave always={updating} />
      <StyledDataGrid<StartupListDocument>
        apiRef={apiRef}
        sortModel={sortModel}
        onSortModelChange={sortModel => setSortModel(sortModel)}
        rows={files}
        columns={columns}
        editMode='row'
        processRowUpdate={updateFile}
        pagination
        pageSizeOptions={[20]}
        initialState={{
          columns: {
            columnVisibilityModel: { id: false },
          },
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        slots={{
          toolbar: () =>
            CustomToolbar(
              startupOpenedInPanel
                ? setIsDocumentModalOpen
                : setIsLocalAddNewModalOpen,
            ),
          noRowsOverlay: EmptyContent,
        }}
        sx={files.length === 0 ? { minHeight: 500 } : undefined}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        disableRowSelectionOnClick
        getRowId={row => `${row.__typename}:${row.id}`}
      />
      <UploadModal
        startups={startups}
        addNewModalOpen={
          startupOpenedInPanel
            ? isDocumentModalOpen ?? false
            : isLocalAddNewModalOpen ?? false
        }
        setAddNewModalOpen={
          startupOpenedInPanel
            ? setIsDocumentModalOpen
            : setIsLocalAddNewModalOpen
        }
        projectId={projectId}
        uploadProjectFile={uploadProjectFile}
        selectedStartupId={startupId}
      />
    </>
  );
}

const StyledButton = styled(Button)(({ theme: { palette } }) => ({
  whiteSpace: 'nowrap',
  marginLeft: 5,
  marginRight: '0 !important',
  color: `${palette.grey[0]} !important`,
  '&:hover': {
    backgroundColor: `${darken(palette.primary.main, 0.22)} !important`,
  },
}));

export const StyledDataGrid = styled(DataGridPro)(({ theme: { palette } }) => ({
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1A3E72',
    '& .MuiInputBase-root': {
      height: '90%',
      borderRadius: '5px',
      'input[type=text]': {
        height: '100%',
        borderRadius: '5px',
        border: '1px solid lightgrey',
        fontSize: '1rem',
        '&:hover': {
          border: `1px solid ${palette.grey[800]}`,
        },
        '&:focus': {
          border: `2px solid ${palette.primary.main}`,
          height: '95%',
        },
      },
    },
    '& .MuiTextField-root': {
      height: '100%',
      borderRadius: '5px',
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    cursor: 'pointer',
    '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus-visible':
      {
        borderRadius: 'unset',
      },
    '&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root ': {
      borderRadius: 'unset',
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
})) as typeof DataGridPro;

function CustomToolbar(
  setIsLocalAddNewModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <GridToolbarFilterButton />
      <StyledButton
        onClick={() => {
          setIsLocalAddNewModalOpen(true);
          console.log('New button clicked');
        }}
        variant='contained'
        startIcon={<AddOutlined />}
      >
        New
      </StyledButton>
    </GridToolbarContainer>
  );
}
