import { Add } from '@mui/icons-material';
import { Button, Divider, Stack, SxProps } from '@mui/material';
import { MouseEvent, useEffect } from 'react';

const BaseAddButtonForAutocomplete = ({
  onClick,
  disabled,
  text,
  sxProps = {},
  divider = true,
  href,
}: {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  text: string;
  sxProps?: SxProps;
  divider?: boolean;
  href?: string;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClick(event as unknown as MouseEvent<HTMLElement>);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClick]);

  return (
    <Stack>
      <Button
        disabled={disabled}
        sx={{
          width: '100%',
          borderRadius: 0,
          whiteSpace: 'nowrap',
          justifyContent: 'flex-start',
          paddingLeft: 2,
          ...sxProps,
        }}
        href={href}
        onClick={onClick}
        startIcon={<Add />}
      >
        {text}
      </Button>
      {divider && <Divider />}
    </Stack>
  );
};

export default BaseAddButtonForAutocomplete;
