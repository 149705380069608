import {
  Stack,
  Button,
  Avatar,
  Collapse,
  Typography,
  Divider,
} from '@mui/material';
import { useFindOrganizationConnectionsQuery } from 'apollo/generated/sdkShared';
import { X_HASURA_ORGANIZATION_UUID } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import { useSearchParams } from 'react-router';

export const ConnectionButtons = ({ returnTo }: { returnTo?: string }) => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const [searchParams, _setSearchParams] = useSearchParams();
  const showGDSSO = searchParams.get('show_gd_sso') === 'true';

  const { login } = useAuth();

  const loginWithConnection = async (connection: string) => {
    await login({
      returnTo,
      connection,
    });
  };

  const { data, loading } = useFindOrganizationConnectionsQuery({
    context: {
      headers: {
        [X_HASURA_ORGANIZATION_UUID]: currentOrganization.uuid,
      },
    },
  });

  const connections = data?.find_organization_connections?.data || [];

  const isProduction = import.meta.env.VITE_ENV === 'production';

  const filteredConnections = connections.filter(connection =>
    isProduction
      ? showGDSSO ||
        !['glassdollar-saml', 'glassdollar-oidc'].includes(connection.name)
      : true,
  );

  return (
    <Collapse
      in={!loading && connections && connections?.length > 0}
      mountOnEnter
    >
      <Stack gap={2} marginBottom={2} textAlign='center'>
        {filteredConnections.map(c => (
          <Button
            key={c.id}
            onClick={() => loginWithConnection(c.name)}
            variant='outlined'
            sx={{ marginBottom: 1, minHeight: 45 }}
            data-testid={`login-with-${c.name}`}
          >
            {c.logo_url && (
              <Avatar
                sx={({ spacing }) => ({
                  marginRight: 1,
                  '.MuiAvatar-img': {
                    objectFit: 'contain',
                  },
                  width: spacing(3),
                  height: spacing(3),
                })}
                src={c.logo_url}
              ></Avatar>
            )}
            Log in with {c.display_name}
          </Button>
        ))}
        {filteredConnections.length > 0 && (
          <Divider>
            <Typography variant='body1' fontWeight={300}>
              or
            </Typography>
          </Divider>
        )}
      </Stack>
    </Collapse>
  );
};
