import {
  Cancel as CancelIcon,
  PersonAdd as PersonAddIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  EnumTableNotificationTypeEnum,
  Suppliers,
  useSendNotificationMutation,
  useUpdateCategorizedStartupMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useSafeCallback } from 'components/base/useSafeCallback';
import { OutreachModal } from 'components/dashboard/startupConnections/OutreachModal';
import { AddContactModal } from 'components/organizationStartupContacts/AddContactModal';
import { ProjectDetailContext } from 'contexts/ProjectContext';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useContext, useState } from 'react';
import { AssessmentCategorizedSupplier } from './AssessmentTab';
import { useChangeProjectStage } from './hooks/useChangeProjectStage';

const type: EnumTableNotificationTypeEnum = 'STARTUP_SELECTED_FOR_POC';
type SelectedStartupProps = Pick<AssessmentCategorizedSupplier, 'id'> & {
  startup: Pick<Suppliers, 'id' | 'name' | 'logo_url'>;
};

export const useSelectStartupForPoc = () => {
  const [updateCategorizedStartup] = useUpdateCategorizedStartupMutation();
  const { project, shortlistedStartups } = useContext(ProjectDetailContext);
  const existingIsSelectedStartup = shortlistedStartups?.find(
    s => s.is_selected,
  );

  const [sendNotification] = useSendNotificationMutation();

  const handleSelectForPoC = useSafeCallback(
    async ({ selectedStartup }: { selectedStartup: SelectedStartupProps }) => {
      if (existingIsSelectedStartup) {
        await updateCategorizedStartup({
          variables: {
            id: existingIsSelectedStartup.id,
            updateData: { is_selected: false },
          },
        });
      }
      await updateCategorizedStartup({
        variables: {
          id: selectedStartup.id,
          updateData: { is_selected: true },
        },
      });

      await sendNotification({
        variables: {
          input: {
            type,
            payload: {
              startupId: selectedStartup.startup.id,
              startupName: selectedStartup.startup?.name,
              startupLogoUrl: selectedStartup.startup?.logo_url,
              listId: project.id,
              listTitle: project?.title,
            },
          },
        },
      });

      captureAnalyticsEvent('Startup Selected', {
        startupId: selectedStartup?.id,
      });
    },
    [
      updateCategorizedStartup,
      existingIsSelectedStartup,
      project.id,
      project?.title,
      sendNotification,
    ],
    { errorMessage: 'Error selecting startup for PoC' },
  );

  return { handleSelectForPoC };
};

const SelectedStartupActionButtons = ({
  selectedStartup,
}: {
  selectedStartup: AssessmentCategorizedSupplier | null;
}) => {
  const { handleSelectForPoC } = useSelectStartupForPoc();
  const { handleSubmitChangeStage } = useChangeProjectStage(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openRemoveStartupModal, setOpenRemoveStartupModal] =
    useState<boolean>(false);

  const [isOutreachModalOpened, setIsOutreachModalOpened] =
    useState<boolean>(false);
  const [isAddContactModalOpened, setIsAddContactModalOpened] =
    useState<boolean>(false);

  const { project, shortlistedStartups } = useContext(ProjectDetailContext);
  const [updateCategorizedStartup] = useUpdateCategorizedStartupMutation();

  const buttonText = selectedStartup?.is_selected
    ? 'Deselect for PoC'
    : 'Select for PoC';

  const selectedForPoCStartupExists = shortlistedStartups?.some(
    s => s.is_selected,
  );

  const onDeselectConfirmed = async () => {
    if (!selectedStartup) {
      enqueueSnackbar('Error deselecting startup for PoC', {
        variant: 'error',
      });
      return;
    }

    try {
      await handleSubmitChangeStage({
        fromStage: project.stage,
        toStage: 'assessment',
        projectId: project.id,
      });

      await updateCategorizedStartup({
        variables: {
          id: selectedStartup?.id,
          updateData: { is_selected: false },
        },
      });

      enqueueSnackbar('Startup deselected', {
        variant: 'success',
      });

      captureAnalyticsEvent('Startup Deselected', {
        startupId: selectedStartup?.id,
      });
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Error deselecting startup for PoC', {
        variant: 'error',
      });
    }
  };

  const onRemoveConfirm = async () => {
    if (!selectedStartup) {
      enqueueSnackbar('Error removing startup', {
        variant: 'error',
      });
      return;
    }

    await updateCategorizedStartup({
      variables: {
        id: selectedStartup.id,
        updateData: { is_selected: false, is_shortlisted: false },
      },
    });
    setOpenRemoveStartupModal(false);
    try {
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error removing startup', {
        variant: 'error',
      });
    }
  };

  const connectionRequest = selectedStartup?.startup.startup_connections.find(
    connection => connection?.project_id === project.id,
  );

  return (
    <Stack direction='row' gap={1}>
      {!selectedForPoCStartupExists || selectedStartup?.is_selected ? (
        <>
          {!connectionRequest && (
            <Button
              variant='outlined'
              onClick={() => setIsOutreachModalOpened(true)}
              startIcon={<PersonAddIcon fontSize='small' />}
            >
              Connect
            </Button>
          )}
          <Button
            data-testid='select-for-poc-button'
            variant='outlined'
            onClick={() => {
              if (!selectedStartup) {
                return;
              }

              !selectedStartup?.is_selected
                ? handleSelectForPoC({ selectedStartup })
                : onDeselectConfirmed();
            }}
          >
            {buttonText}
          </Button>
        </>
      ) : null}
      <Tooltip title='Remove startup from Assessment'>
        <IconButton onClick={() => setOpenRemoveStartupModal(true)}>
          <CancelIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      {openRemoveStartupModal && (
        <Dialog open fullWidth>
          <DialogContent sx={{ marginTop: 2, paddingBottom: 0 }}>
            <Typography sx={{ marginBottom: 0 }}>
              Are you sure you want to remove
              <Box component='span' fontWeight='bold'>
                {` ${selectedStartup?.startup?.name}?`}
              </Box>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant='text'
              color='inherit'
              onClick={() => setOpenRemoveStartupModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onRemoveConfirm()}
              variant='contained'
              color='error'
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {selectedStartup && (
        <>
          <OutreachModal
            open={isOutreachModalOpened}
            onHide={() => setIsOutreachModalOpened(false)}
            startup={{ ...selectedStartup.startup }}
            projectId={project.id}
            setIsAddContactModalOpened={setIsAddContactModalOpened}
            isAskGDEnabled={false}
          />
          <AddContactModal
            open={isAddContactModalOpened}
            onHide={() => setIsAddContactModalOpened(false)}
            startupId={selectedStartup.startup.id}
          />
        </>
      )}
    </Stack>
  );
};

export default SelectedStartupActionButtons;
