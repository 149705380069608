import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { varFadeIn } from 'components/animate';
import { autocompleteSx } from 'components/base/serverFilters/BaseServerFiltersContext';
import { motion } from 'framer-motion';

export type ServerFilterOption<T> = {
  label: string;
  value: T;
};

const BaseServerFilterAutocompleteWithCheckbox = <T,>({
  options,
  value,
  handleChange,
  loading,
  placeholder = 'Select',
  sxProps = {},
  dataTestId,
}: {
  options: ServerFilterOption<T>[];
  value: ServerFilterOption<T>[];
  handleChange: (newValue: ServerFilterOption<T>[]) => void;
  loading?: boolean;
  placeholder?: string;
  sxProps: SxProps;
  dataTestId?: string;
}) => {
  if (loading) {
    return (
      <motion.div {...varFadeIn}>
        <Skeleton
          variant='rectangular'
          sx={({ spacing }) => ({
            height: spacing(4),
            width: '90px',
            backgroundColor: 'grey.300',
          })}
        />
      </motion.div>
    );
  }

  const allSelected = value.length === options.length;
  const handleSelectAll = () => {
    if (allSelected) {
      handleChange([]);
    } else {
      handleChange(options);
    }
  };

  return (
    <motion.div {...varFadeIn}>
      <Autocomplete
        disableListWrap
        autoHighlight
        openOnFocus
        disableCloseOnSelect
        multiple
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={[
          ...options,
          { label: 'Select All', value: null as unknown as T },
        ]}
        getOptionLabel={option => option.label}
        value={value}
        noOptionsText={<Typography>No options</Typography>}
        onChange={(_event, newValue) => {
          if (
            newValue.some(option => option.value === (null as unknown as T))
          ) {
            handleSelectAll();
          } else {
            handleChange(newValue);
          }
        }}
        data-testid={dataTestId}
        sx={{ ...autocompleteSx, ...sxProps } as SxProps<Theme>}
        slotProps={{
          paper: {
            sx: {
              width: 'fit-content',
              whiteSpace: 'nowrap',
              '& .MuiAutocomplete-listbox': {
                paddingBottom: '0px',
              },
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
                paddingX: '8px',
              },
            },
          },
        }}
        renderOption={(props, option) => {
          if (option.value === (null as unknown as T)) {
            return (
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  background: 'white',
                  borderTop: ({ palette }) => '1px solid' + palette.grey['300'],
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                <Button onClick={handleSelectAll} variant='text' fullWidth>
                  {allSelected ? 'Deselect all' : 'Select all'}
                </Button>
              </Box>
            );
          }

          return (
            <li {...props} key={String(option.value)}>
              <Checkbox
                sx={{
                  padding: '4px',
                }}
                checked={value.some(v => v.value === option.value)}
              />
              {option.label}
            </li>
          );
        }}
        renderTags={(value, getTagProps) => (
          <>
            {value.length > 1 ? (
              <Chip
                {...getTagProps({ index: 0 })}
                label={`${value.length} selected`}
                size='small'
                sx={({ spacing }) => ({
                  margin: 0,
                  minWidth: spacing(5),
                })}
              />
            ) : (
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  size='small'
                  label={option.label}
                  sx={({ spacing }) => ({
                    margin: 0,
                    minWidth: spacing(5),
                  })}
                />
              ))
            )}
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            sx={{ width: '100%' }}
            variant='standard'
            placeholder={value.length === 0 ? placeholder : ''}
          />
        )}
      />
    </motion.div>
  );
};

export default BaseServerFilterAutocompleteWithCheckbox;
