import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Stack,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { GDLogo } from 'components/shared/GDLogo';
import { useState } from 'react';

const SearchCounter = () => {
  const [databaseInformationModalOpen, setDatabaseInformationModalOpen] =
    useState(false);

  return (
    <Stack sx={{ padding: 2 }}>
      {databaseInformationModalOpen && (
        <DatabaseInformationModal
          onClose={() => setDatabaseInformationModalOpen(false)}
        />
      )}
      <Stack direction='column' justifyContent='center' sx={{ paddingY: 2 }}>
        <Stack
          direction='row'
          justifyContent='center'
          sx={{ paddingTop: 3, paddingBottom: 4 }}
        >
          <GDLogo size={100} />
        </Stack>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Typography variant='h6'>
            <Box display='inline' fontWeight='fontWeightLight'>
              4+ million
            </Box>
            {` active startups`}
          </Typography>
          <IconButton
            aria-label='info'
            onClick={() => setDatabaseInformationModalOpen(true)}
            sx={{ marginLeft: 0.1 }}
          >
            <InfoOutlined />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

const DatabaseInformationModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogTitle sx={{ marginBottom: 2 }}>
        <Typography variant='h6'>📚 Database information</Typography>{' '}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={({ palette, spacing }) => ({
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography>
            <b>Wide coverage:</b> includes over 4+ million active startups.
            Combines industry-standard data-sources such as Crunchbase,
            Pitchbook, Clearbit and LinkedIn with proprietary sources.
          </Typography>
          <Typography>
            <b>Depth:</b> tens of thousands of featured startups provide us
            first-party data, updates and verifications to bring you the most
            accurate information.
          </Typography>
          <Typography>
            <b>Reliability:</b> the startup profiles are constantly updated and
            verified by combination of automated and human curation.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SearchCounter;
