import { Skeleton } from '@mui/material';
import { range } from 'lodash';

const LoadingTable = ({
  rows = 10,
  height = 60,
}: {
  rows?: number;
  height?: number;
}) => (
  <>
    {range(rows).map(i => (
      <Skeleton
        key={`skeleton-row-${i}`}
        variant='rectangular'
        sx={{
          marginX: '20px',
          marginY: '10px',
          height: '20px',
          borderRadius: '8px',
        }}
        height={height}
      />
    ))}
  </>
);

export default LoadingTable;
