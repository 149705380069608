import { HowToReg } from '@mui/icons-material';
import {
  Chip,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  StartupCorporateCustomers,
  useGetOldestPrimaryContactQuery,
} from 'apollo/generated/sdkShared';
import LightTooltip from 'components/LightTooltip';
import { RouterLink } from 'components/RouterLink';
import { BaseCompanyDetails } from 'components/base/BaseCompanyDetails';
import BaseEllipsisTypographyOneLine from 'components/base/BaseEllipsisTypographyOneline';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import { TooltipPreviewAndCopy } from 'components/base/TooltipPreviewAndCopy';
import { useEnrichmentProgressSpinner } from 'components/enrichmentProgress/useEnrichmentProgressSpinner';
import { getStartupSignalLabelByOrder } from 'components/startups/StartupSignalLabel';
import { useFormikContext } from 'formik';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { stripScheme } from 'utils/url';
import { useHealthCheckProgress } from '../startupHealthCheck/useHealthCheckProgress';
import { SocialLinkIcon } from './SocialLinkIcon';
import { StartupForSidePanel } from './StartupInfoSidePanel';
import StartupLabelChipSelector, {
  StartupFunnelLabel,
  getStartupFunnelLabelOrder,
} from './StartupLabelChipSelector';
import { CorporateCustomer, StartupFormState } from './useStartupDetailsFormik';

export const StartupHeader = ({
  currentStartup,
}: {
  currentStartup: StartupForSidePanel;
}) => {
  const { values } = useFormikContext<StartupFormState>();

  const { data: verifiedContactData } = useGetOldestPrimaryContactQuery({
    variables: {
      startupId: currentStartup.id,
    },
  });

  const verifiedContact =
    verifiedContactData?.organization_startup_contacts[0] || null;

  const theme = useTheme();

  const { healthCheckSpinner } = useHealthCheckProgress({
    startupId: currentStartup.id,
    signalLabels: currentStartup.signal_labels,
    healthCheckPresent: !!currentStartup?.health_check?.id,
  });

  const enrichingSpinner = useEnrichmentProgressSpinner({
    startupDomain: values.domain,
    defaultOpen: true,
    placement: 'right',
  });

  const onVerifiedContactClick = () => {
    if (!verifiedContact) return;
    window.open(
      `mailto:${verifiedContact?.email}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  const inLibrary = useMemo(
    () => currentStartup.funnel_labels.find(l => l.value === 'InLibrary'),
    [currentStartup.funnel_labels],
  );

  const label = useMemo(
    () =>
      getStartupFunnelLabelOrder(
        currentStartup.funnel_labels as { value: StartupFunnelLabel }[],
      ),
    [currentStartup.funnel_labels],
  );

  const signal = useMemo(
    () => getStartupSignalLabelByOrder(currentStartup.signal_labels),
    [currentStartup.signal_labels],
  );

  const caseStudies = compact(
    values.referenced_customers.filter(rc => rc.case_study_url),
  );

  const caseStudyCount = caseStudies.length;

  return (
    <Stack>
      <Stack direction='row' spacing={2}>
        <Stack justifyContent='center'>
          <BaseStartupAvatar
            startup={{
              name: values.name,
              domain: values.domain,
              logo_url: values.logo_url,
            }}
            size='large'
          />
        </Stack>
        <Stack justifyContent='center'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Link
              href={`https://${stripScheme(
                values.website || values.domain || '',
              )}`}
              color={'secondary'}
              target={'_blank'}
              maxWidth='280px'
            >
              <Typography variant='h6' textAlign='left'>
                {values.name}
              </Typography>
            </Link>

            {inLibrary && (
              <StartupLabelChipSelector
                label={label as StartupFunnelLabel}
                currentStartup={currentStartup}
              />
            )}

            {healthCheckSpinner ? (
              healthCheckSpinner
            ) : enrichingSpinner ? (
              enrichingSpinner
            ) : (
              <Stack direction='row' spacing={0.2}>
                <SocialLinkIcon
                  fieldName='website'
                  socialURL={values.website || `https://${values.domain}`}
                />
                {values.linkedin_url && (
                  <SocialLinkIcon
                    fieldName='linkedin'
                    socialURL={values.linkedin_url}
                  />
                )}
                {values.crunchbase_url && (
                  <SocialLinkIcon
                    fieldName='crunchbase'
                    socialURL={values.crunchbase_url}
                  />
                )}
                {currentStartup.organization_profile
                  ?.procurement_supplier_identifier && (
                  <Tooltip title='Supplier ID'>
                    <Chip
                      label={
                        currentStartup.organization_profile
                          .procurement_supplier_identifier
                      }
                      size='small'
                      sx={{
                        alignSelf: 'center',
                      }}
                    />
                  </Tooltip>
                )}
                {verifiedContact && (
                  <TooltipPreviewAndCopy
                    textToCopy={
                      verifiedContact.email || verifiedContact.full_name
                    }
                    previewContent={
                      <>
                        {verifiedContact.full_name}
                        {verifiedContact.email
                          ? `<${verifiedContact.email}>`
                          : ''}
                      </>
                    }
                  >
                    <Link
                      onClick={onVerifiedContactClick}
                      sx={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'flex',
                        color: theme.palette.grey[800],
                      }}
                    >
                      <HowToReg />
                    </Link>
                  </TooltipPreviewAndCopy>
                )}
              </Stack>
            )}
          </Stack>

          {/* Second row of the header */}
          <BaseCompanyDetails
            company={{
              employees_count: values.employees_count ?? '-',
              founded_year: values.founded_year,
              funding: values.funding,
              hq: values.hq,
              signal: signal,
              name: values.name,
            }}
          />

          {/* Third row of the header */}
          <Stack
            direction='row'
            marginTop={0.5}
            alignItems='center'
            spacing={0.5}
          >
            {caseStudyCount && caseStudyCount > 0 ? (
              <CaseStudiesSummary
                caseStudies={caseStudies}
                caseStudyCount={caseStudyCount}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

type CaseStudy = CorporateCustomer &
  Pick<StartupCorporateCustomers, 'type' | 'case_study_url'>;

const CaseStudiesSummary = (props: {
  caseStudies: CaseStudy[];
  caseStudyCount: number;
}) => {
  return (
    <LightTooltip
      placement='right'
      title={
        <Stack>
          {props.caseStudies
            .filter(study => study.case_study_url)
            .map(study => (
              <BaseEllipsisTypographyOneLine
                variant='caption'
                key={`case-study-${study.id}`}
              >
                <RouterLink
                  target='_blank'
                  to={
                    study.case_study_url?.startsWith('http')
                      ? study.case_study_url
                      : `https://${study.case_study_url}` || ''
                  }
                  rel='noreferrer noopener'
                >
                  {study.name}
                </RouterLink>
              </BaseEllipsisTypographyOneLine>
            ))}
        </Stack>
      }
    >
      <Stack direction='row' alignItems='center' gap={0.5}>
        <Typography variant='caption' color='textSecondary'>
          &
        </Typography>
        <Typography
          noWrap
          variant='caption'
          sx={{
            cursor: 'default',
          }}
        >
          {props.caseStudyCount} Case studies
        </Typography>
      </Stack>
    </LightTooltip>
  );
};
