import { Fullscreen as FullscreenIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useUpdateStartupListTitleMutation } from 'apollo/generated/sdkInnovationManager';
import DeliverSourcing from 'components/admin/DeliverSourcing';
import DetailsHeaderTitle from 'components/dashboard/DetailsHeaderTitle';
import AddStartupDialog from 'components/library/AddStartupDialog';
import useAuth from 'hooks/useAuth';
import { useContext, useState } from 'react';
import { SharedProjectCategory } from '../../../../@types/shared';
import { StartupListForDetail } from '../../../../@types/startupList';

import {} from '@mui/material';
import { captureException } from '@sentry/core';
import {
  useArchiveProjectMutation,
  useArchiveStartupListMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useCreateStartupListCollaboratorMutation } from 'apollo/generated/sdkShared';
import { FullscreenContext } from 'contexts/FullscreenContext';
import { format, isThisYear, parseISO } from 'date-fns';
import useBreakpoints from 'hooks/useBreakpoints';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from '../../../../routes/paths';
import { useUpdateStartupListActivities } from '../useUpdateStartupListActivities';
import { ListActionsMenu } from './ListActionsMenu';
import { ShowHideChartButton } from './ShowHideChartButton';
import { StartupListDetailBar } from './StartupListDetailBar';
import { getDateText } from './share/AvatarViews';
import { ShareStartupList } from './share/ShareStartupList';

import { useApolloClient } from '@apollo/client';

export type CategorizedStartupForHeader = {
  id: number;
  startup: {
    id: number;
    name: string;
    domain: string;
  };
};

export function formatDateCreated(dateString: string): string {
  const date = parseISO(dateString);
  if (isThisYear(date)) return format(date, 'MMM d');
  return format(date, 'MMM d, yyyy');
}

export default function Header({
  currentStartupList,
  categories,
  categorizedStartups,
  refetchMainQuery,
}: {
  currentStartupList: StartupListForDetail;
  categories: SharedProjectCategory[];
  categorizedStartups: CategorizedStartupForHeader[];
  refetchMainQuery: () => void;
}) {
  const { isBelowMd } = useBreakpoints();
  const { isStaff } = useAuth();
  const { palette, zIndex } = useTheme();
  const [addStartupInProgress, setAddStartupInProgress] = useState(false);
  const isFavourites = currentStartupList.source === 'favourites';
  const allowEdit = currentStartupList.source !== 'favourites';
  const isSourcingInProgress =
    !!currentStartupList.sourcing_order &&
    currentStartupList.sourcing_order.status === 'in_progress';

  const { toggleFullscreen } = useContext(FullscreenContext);

  return (
    <Box marginBottom={2} data-testid='header'>
      <Stack
        direction='row'
        sx={{
          marginBottom: 0.5,
          flexWrap: isBelowMd ? 'wrap' : 'nowrap',
          flexShrink: isBelowMd ? 'inherit' : 0,
          gap: 1,
        }}
        alignItems='center'
        justifyContent='space-between'
        zIndex={zIndex.drawer}
        bgcolor={palette.background.paper}
      >
        <Title currentStartupList={currentStartupList} />
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          marginLeft={isBelowMd ? 0 : 'auto'}
          flexWrap={isBelowMd ? 'wrap' : 'nowrap'}
          alignSelf='self-start'
        >
          <Tooltip
            title={
              <Stack>
                <Typography variant='caption'>
                  Created {getDateText(currentStartupList.created_at)}
                </Typography>
                <Typography variant='caption' color='grey.400'>
                  {formatDateCreated(currentStartupList.created_at)}
                </Typography>
              </Stack>
            }
          >
            <Typography
              variant='caption'
              fontWeight={500}
              color='grey.600'
              width='max-content'
            >
              Edited{' '}
              {getDateText(
                currentStartupList.latest_activity_created_at ??
                  currentStartupList.updated_at,
              )}
            </Typography>
          </Tooltip>
          <ShareStartupList
            currentStartupListId={currentStartupList.id}
            startupsCount={categorizedStartups.length}
          />
          {isStaff && isSourcingInProgress && (
            <DeliverSourcing
              currentStartupList={currentStartupList}
              hasResults={categories.some(
                category => category.categorized_suppliers.length > 0,
              )}
            />
          )}
          <Tooltip title={'Go full screen'}>
            <span>
              <IconButton
                onClick={() => {
                  toggleFullscreen();
                }}
              >
                <FullscreenIcon />
              </IconButton>
            </span>
          </Tooltip>
          {!isFavourites && (
            <ListActionsMenu
              currentStartupList={currentStartupList}
              categories={categories}
              categorizedStartups={categorizedStartups}
              refetchMainQuery={refetchMainQuery}
              scope='list'
            />
          )}
        </Stack>
        {addStartupInProgress && (
          <AddStartupDialog
            open={addStartupInProgress}
            handleClose={() => setAddStartupInProgress(false)}
            startupListId={currentStartupList.id}
            projectCategoryId={categories[0]?.id}
            navigateToLibrary={false}
            title={'Add startup to the current list'}
          />
        )}
      </Stack>
      {allowEdit && (
        <Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            marginBottom={1}
          >
            <ShowHideChartButton
              categorizedStartups={categorizedStartups}
              currentStartupList={{
                id: currentStartupList.id,
                is_startup_charts_data_shown:
                  currentStartupList.is_startup_charts_data_shown,
              }}
            />
          </Stack>
          <StartupListDetailBar
            startupList={currentStartupList}
            isSourcingInProgress={isSourcingInProgress}
          />
        </Stack>
      )}
    </Box>
  );
}

const Title = ({
  currentStartupList,
}: {
  currentStartupList: StartupListForDetail;
}) => {
  const [updateStartupListTitle] = useUpdateStartupListTitleMutation();
  const [upsertCollaborator] = useCreateStartupListCollaboratorMutation();
  const { logStartupListActivity } = useUpdateStartupListActivities();

  const updateListTitle = async (newTitle: string) => {
    await updateStartupListTitle({
      variables: {
        startupListId: currentStartupList.id,
        title: newTitle,
      },
    });

    const { data } = await upsertCollaborator({
      variables: {
        object: {
          role: 'editor',
          startup_list_id: currentStartupList.id,
        },
      },
    });

    const collaboratorId =
      data?.create_startup_list_collaborator?.startup_list_collaborator_id;

    await logStartupListActivity({
      logs: [
        {
          entityIds: currentStartupList.id,
          action: 'updated',
          entityType: 'startup_lists',
        },
        ...(collaboratorId
          ? [
              {
                entityIds: collaboratorId,
                action: 'created',
                entityType: 'startup_list_collaborators',
              },
            ]
          : []),
      ],
      startupListId: currentStartupList.id,
    });
  };

  const allowEdit = currentStartupList.source !== 'favourites';

  return (
    // Why the min-width? https://css-tricks.com/flexbox-truncated-text/#aa-the-solution-is-min-width-0-on-the-flex-child
    <Stack flexGrow={1} minWidth={0}>
      <DetailsHeaderTitle
        {...(allowEdit
          ? {
              readonly: false,
              updateTitle: updateListTitle,
            }
          : {
              readonly: true,
            })}
        backToParentPath={PATH_ROOT.lists.root}
        title={currentStartupList.title}
        sx={{
          maxWidth: `calc(100% - 5px)`,
        }}
      />
    </Stack>
  );
};

const LABEL_PER_ENTITY = {
  list: 'list',
  order: 'sourcing order',
  lead: 'lead',
  poc: 'PoC',
  implementation: 'implementation',
};

// TODO: Refactor and inject functionality and not conditionals
export const ConfirmArchiveListModal = ({
  archiveType,
  onHide,
  title,
  id,
  disableRedirect = false,
}: {
  id: number;
  title: string;
  onHide: () => void;
  archiveType: 'order' | 'list' | 'lead' | 'poc' | 'implementation';
  disableRedirect?: boolean;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [archiveStartupList] = useArchiveStartupListMutation();
  const [archiveProject] = useArchiveProjectMutation();
  const { cache: apolloCache } = useApolloClient();

  const handleArchiveList = async (projectId: number) => {
    try {
      if (['list', 'order'].includes(archiveType)) {
        await archiveStartupList({ variables: { startupListId: id } });

        apolloCache.evict({
          id: apolloCache.identify({ __typename: 'startup_lists', id }),
        });
      } else {
        await archiveProject({ variables: { projectId: id } });

        apolloCache.evict({
          id: apolloCache.identify({ __typename: 'projects', id }),
        });
      }

      apolloCache.gc();

      enqueueSnackbar(`The ${LABEL_PER_ENTITY[archiveType]} has been deleted`, {
        variant: 'success',
      });

      if (!disableRedirect) {
        if (archiveType === 'list') {
          navigate(PATH_ROOT.lists.root);
        }

        if (archiveType === 'order') {
          navigate(PATH_ROOT.sourcings.list);
        }

        if (archiveType === 'lead') {
          navigate(PATH_ROOT.projectLeads.list.active);
        }

        if (archiveType === 'poc') {
          navigate(PATH_ROOT.projects.pocs);
        }

        if (archiveType === 'implementation') {
          navigate(PATH_ROOT.projects.adoptions);
        }
      }

      closeSnackbar(`delete-startup-list-${projectId}`);
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Failed archiving ${title}`, {
        variant: 'error',
      });
    }
  };
  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography sx={{ marginTop: 2 }}>
          Are you sure you want to delete {title}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => handleArchiveList(id)}
          variant='contained'
          color='error'
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
