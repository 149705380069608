import { Button, styled } from '@mui/material';
import { useGetOrganizationPortalConfig } from 'components/OrganizationPortal/useGetOrganizationPortalConfig';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { usePersonContext } from 'contexts/PersonContext';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { PATH_ROOT } from '../../routes/paths';
import ActivityIcon from './assets/activity.svg?react';
import ChatSupportIcon from './assets/chat-support.svg?react';
import DiscoverIcon from './assets/discover.svg?react';
import InitiativesIcon from './assets/InitiativesIcon.svg?react';
import PainPointsIcon from './assets/PainPointsIcon.svg?react';
import BusinessUnitsIcon from './assets/BusinessUnitsIcon.svg?react';
import InnovationHubIcon from './assets/InnovationHubIcon.svg?react';
import SearchIcon from './assets/SearchIcon.svg?react';
import StartupListsIcon from './assets/StartupListsIcon.svg?react';
import StartupCRMIcon from './assets/StartupCRMIcon.svg?react';
import LeadsIcon from './assets/LeadsIcon.svg?react';
import ProofOfConceptsIcon from './assets/ProofOfConceptsIcon.svg?react';
import ImplementationsIcon from './assets/ImplementationsIcon.svg?react';

const BaseStyledLabel = styled(Button)(({ theme, size }) => ({
  color: `${theme.palette.primary.main} !important`,
  background: '#EDF6EF',
  position: 'initial',
  ...(size === 'small' ? { minWidth: 33, fontSize: 9.5, paddingY: 0 } : {}),
}));

export const StyledLabel = ({
  labelText,
  size = 'medium',
}: {
  labelText: string;
  size?: 'medium' | 'small';
}) => (
  <BaseStyledLabel size={size ? size : 'medium'} disabled>
    {labelText}
  </BaseStyledLabel>
);

// ----------------------------------------------------------------------

const useBuildSidebarConfig = () => {
  const { existingTeamPortalSlug } = useCheckIfUserTeamHasOrganizationPortal();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { isStaff } = useAuth();
  const { activity_enabled } = useCurrentOrganizationFromContext();
  const activityAndStakeholderVisibility = isStaff || activity_enabled;

  return {
    mainNavConfig: [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        items: [
          {
            id: 'tab-home',
            title: 'Discover',
            path: '/',
            icon: <DiscoverIcon />,
            matchEnd: false,
            alternativePath: '/discovery/:projectId',
            show: true,
          },
          {
            id: 'tab-activity',
            title: 'Activity',
            path: PATH_ROOT.activity.root,
            icon: <ActivityIcon />,
            matchEnd: false,
            show: activityAndStakeholderVisibility,
          },
        ],
      },
      {
        subheader: 'Opportunities',
        items: [
          {
            id: 'tab-organization-portal',
            title: 'Innovation Hub',
            path: PATH_ROOT.organizationPortalBuilder.home(
              existingTeamPortalSlug,
            ),
            alternativePath: PATH_ROOT.organizationPortalBuilder.root(
              existingTeamPortalSlug,
            ),
            icon: <InnovationHubIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-initiatives',
            title: 'Initiatives',
            path: PATH_ROOT.challenges.root,
            icon: <InitiativesIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-pain-points',
            title: 'Pain Points',
            path: PATH_ROOT.painPoints.root,
            icon: <PainPointsIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-business-units',
            title: 'Business Units',
            path: PATH_ROOT.stakeholders.root,
            icon: <BusinessUnitsIcon />,
            matchEnd: false,
            show: activityAndStakeholderVisibility,
          },
        ],
      },
      {
        subheader: 'Solutions',
        items: [
          {
            id: 'tab-startup-scouter',
            title: 'Search Startups',
            path: PATH_ROOT.search.classic,
            icon: <SearchIcon />,
            endIcon: <StyledLabel labelText='NEW' size='small' />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-your-library',
            title: 'Startup Lists',
            path: PATH_ROOT.lists.root,
            icon: <StartupListsIcon />,
            matchEnd: false,
            show: true,
            alternativePath: '/pain-points/:painPointId',
          },
          {
            id: 'tab-startups',
            title: 'Startup CRM',
            path: PATH_ROOT.startups.root,
            icon: <StartupCRMIcon />,
            matchEnd: false,
            show: true,
          },
        ],
      },
      {
        subheader: 'Projects',
        items: [
          {
            id: 'tab-project-leads',
            title: 'Leads',
            path: PATH_ROOT.projectLeads.list.index,
            icon: <LeadsIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-pocs',
            title: 'Projects',
            path: PATH_ROOT.projects.pocs,
            icon: <ProofOfConceptsIcon />,
            matchEnd: false,
            show: true,
          },
          {
            id: 'tab-adoptions',
            title: 'Implementations',
            // TODO: change to implementations
            path: PATH_ROOT.projects.adoptions,
            icon: <ImplementationsIcon />,
            matchEnd: false,
            show: true,
          },
        ],
      },
    ],
    bottomNavConfig: [
      {
        subheader: '',
        items: [
          {
            id: 'tab-chat-support',
            title: 'Chat Support',
            path: '',
            icon: <ChatSupportIcon />,
            matchEnd: false,
            show: true,
            onClick: () => {
              // @ts-expect-error: TODO: FIXME
              window.$chatwoot.toggle();
              setIsChatOpen(!isChatOpen);
            },
          },
        ],
      },
    ],
  };
};

export const useCheckIfUserTeamHasOrganizationPortal = () => {
  const { person } = usePersonContext();
  const possibleTeamSlug = person?.team?.name
    .replace(/\s+/g, '-')
    .toLowerCase();
  const { config: organizationPortalConfig } = useGetOrganizationPortalConfig({
    teamSlug: possibleTeamSlug ?? 'default',
  });

  return {
    existingTeamPortalSlug: organizationPortalConfig?.team_slug ?? 'default',
  };
};

export default useBuildSidebarConfig;
