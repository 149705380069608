import { Box, Divider, Grid, Skeleton, Stack, StackProps } from '@mui/material';
import useBreakpoints from 'hooks/useBreakpoints';
import { discoveryItemStyles } from '../dashboard/home/discovery/DiscoverySection';
import { CARD_HIGHT } from 'pages/dashboard/constants';

const useGetMultiplier = () => {
  const {
    isBelowXs,
    isBelowSm,
    isBelowMd,
    isBelowL,
    isBelowLg,
    isBelowXl,
    isBelowXxl,
  } = useBreakpoints();

  if (isBelowXs) {
    return 1;
  }

  if (isBelowSm || isBelowMd) {
    return 2;
  }

  if (isBelowL || isBelowLg || isBelowXl) {
    return 3;
  }

  if (isBelowXxl) {
    return 4;
  }

  return 6;
};

const BaseSkeletonSectionsWithGrid = ({
  rowsPerSection = 1,
  nbRows = 3,
  layoutProps,
  shouldRenderDivider = true,
}: {
  rowsPerSection?: number;
  nbRows?: number;
  layoutProps?: StackProps;
  shouldRenderDivider?: boolean;
}) => {
  const multiplier = useGetMultiplier();

  return (
    <Stack {...layoutProps}>
      {[...Array(nbRows)].map((_, i) => {
        return (
          <SectionWithGrid
            key={`section-${i}-skeleton`}
            itemsCount={rowsPerSection * multiplier}
            shouldRenderDivider={shouldRenderDivider}
          />
        );
      })}
    </Stack>
  );
};

const SectionWithGrid = ({
  itemsCount,
  shouldRenderDivider,
}: {
  itemsCount: number;
  shouldRenderDivider: boolean;
}) => {
  return (
    <Box>
      {shouldRenderDivider && (
        <>
          <Skeleton width={250} variant='text' />
          <Divider sx={{ marginBottom: 2 }} />
        </>
      )}
      <Grid container rowSpacing={2} columnSpacing={3}>
        {[...Array(itemsCount)].map((_, i) => (
          <Grid item key={i} {...discoveryItemStyles}>
            <Skeleton
              variant='rectangular'
              height={CARD_HIGHT}
              sx={{ borderRadius: 2 }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export { BaseSkeletonSectionsWithGrid };
