import { useState } from 'react';

// components
import { Button, Stack } from '@mui/material';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
// helpers
import { AddOutlined } from '@mui/icons-material';
import { useGetPainPointsCountQuery } from 'apollo/generated/sdkShared';
import Page500 from 'pages/Page500';
import { PainPointNewPageModal } from 'pages/request-form-v2/page/PainPointNewPageModal';
import PainPointsComponent from 'components/opportunities/PainPointsComponent';

export type LibraryTabType = 'lists' | 'sourcings';

export type GetStartupListsQueryAssignee =
  | {
      __typename?: 'users';
      uid: string;
      email: string;
      full_name?: string | null | undefined;
    }
  | null
  | undefined;

export default function PainPointsPage() {
  const [createNewPainPointOpen, setCreateNewPainPointOpen] = useState(false);

  const { data: painPointsData, error } = useGetPainPointsCountQuery({
    fetchPolicy: 'cache-and-network',
  });

  const allPainPointsCount =
    painPointsData?.problem_scopes_aggregate.aggregate?.count;

  if (error) return <Page500 />;

  return (
    <Page
      title='Pain Points | GlassDollar'
      trackingTitle='Pain Points'
      sx={{
        position: 'relative',
        height: '100%',
        paddingY: 0,
        marginBottom: 10,
      }}
    >
      <PageHeader
        heading={`Pain Points (${allPainPointsCount}) `}
        sx={{ marginBottom: 2 }}
        rightAction={
          <Stack direction='row' spacing={2}>
            <Button
              variant='contained'
              onClick={() => setCreateNewPainPointOpen(true)}
              startIcon={<AddOutlined />}
            >
              New pain point
            </Button>
          </Stack>
        }
      />
      {createNewPainPointOpen && (
        <PainPointNewPageModal
          open={createNewPainPointOpen}
          handleClose={() => setCreateNewPainPointOpen(false)}
        />
      )}
      <Stack
        justifyContent='space-between'
        width='100%'
        sx={{ marginBottom: 3 }}
      >
        <PainPointsComponent />
      </Stack>
    </Page>
  );
}
