import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';

import { FeaturesUpdateBanner } from 'components/SystemMessages/FeaturesUpdateBanner';
import {
  MaintenanceBanner,
  useIsMaintenanceBannerVisible,
} from 'components/SystemMessages/MaintenanceBanner';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import { FullscreenContext } from 'contexts/FullscreenContext';
import DashboardNavbar from './DashboardNavbar';
import MenuSidebar, { COLLAPSE_WIDTH, DRAWER_WIDTH } from './MenuSidebar';
import { TrialAccountBanner } from 'components/SystemMessages/TrialAccountBanner';

// ----------------------------------------------------------------------

const APP_BAR_DESKTOP = 64;
export const UPDATES_BANNER_HEIGHT = 50;

type UpdateReleasedProps = {
  isNewUpdateReleased?: boolean;
};

const RootStyle = styled('div')<UpdateReleasedProps>(
  ({ isNewUpdateReleased }) => ({
    display: 'flex',
    minHeight: '100%',
    width: '100vw',
    ...(isNewUpdateReleased && {
      height: `calc(100vh - ${UPDATES_BANNER_HEIGHT}px)`,
    }),
  }),
);

const MainStyle = styled('div')<
  UpdateReleasedProps & { isSidebarOpen: boolean }
>(({ theme, isNewUpdateReleased, isSidebarOpen }) => ({
  flexGrow: 1,
  minHeight: '100%',
  width: `calc(100vw - ${isSidebarOpen ? DRAWER_WIDTH : COLLAPSE_WIDTH}px)`,
  paddingTop: isNewUpdateReleased ? UPDATES_BANNER_HEIGHT : 0,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    paddingTop:
      APP_BAR_DESKTOP - 10 + (isNewUpdateReleased ? UPDATES_BANNER_HEIGHT : 0),
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(100vw - ${COLLAPSE_WIDTH}px)`,
  },
}));
// ----------------------------------------------------------------------

function DashboardLayout() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { isFeaturesUpdatesBannerVisible } = useFeaturesUpdatesBanner();
  const { isFullscreenView } = useContext(FullscreenContext);
  const { isVisible: isMaintenanceBannerVisible, setMaintenanceBannerVisible } =
    useIsMaintenanceBannerVisible();
  const isNewUpdateReleased =
    isFeaturesUpdatesBannerVisible || isMaintenanceBannerVisible;

  return (
    <RootStyle
      sx={{
        [theme.breakpoints.down('lg')]: {
          paddingLeft: '9vw',
        },
      }}
    >
      {!isMaintenanceBannerVisible && isNewUpdateReleased && (
        <FeaturesUpdateBanner />
      )}
      {isMaintenanceBannerVisible && (
        <MaintenanceBanner onDismiss={setMaintenanceBannerVisible} />
      )}
      <TrialAccountBanner />
      {!isFullscreenView && (
        <>
          <DashboardNavbar
            onOpenSidebar={() => setOpen(true)}
            isNewUpdateReleased={isNewUpdateReleased}
          />
          <MenuSidebar />
        </>
      )}
      <MainStyle
        isNewUpdateReleased={isNewUpdateReleased}
        isSidebarOpen={open}
        sx={{
          minHeight: `calc(100vh - ${64}px)`,
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

export default DashboardLayout;
