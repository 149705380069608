import { PlaylistRemove } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { useMemo } from 'react';
import { StyledDataGridForStartupComparison } from '../StyledDataGridForStartupComparison';
import { AddNewStartupField } from './AddNewStartupField';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
} from './StartupsTableContext';

const StyledStack = styled(Stack)({
  '& >:not(style)+:not(style)': {
    marginLeft: '0px !important',
  },
});

const useGetColumns = ({
  onRemoveStartupRow,
  categoryId,
  startupListId,
  shortlist,
}: {
  onRemoveStartupRow: () => void;
  categoryId: number;
  startupListId: number;
  shortlist: CategorizedStartupForMatrix[];
}) => {
  const { row, pinnedRightColumn, getActionsColumn } = useRightTableActions();
  const columns = useMemo(
    () => [
      {
        field: 'name',
        flex: 2,
        renderCell: (
          params: GridRenderCellParams<DecoratedStartup, string>,
        ) => {
          const { order, logo_url } = params.row;
          return (
            <StyledStack
              direction='row'
              gap={0}
              alignItems='center'
              width='100%'
              sx={{ cursor: 'pointer' }}
            >
              <Stack
                direction='row'
                alignItems='center'
                alignContent='center'
                spacing={2}
                sx={{
                  marginRight: 2,
                }}
              >
                <Typography
                  sx={{
                    margin: '5px',
                    color: ({ palette }) => palette.grey['50048'],
                  }}
                  variant='h5'
                >
                  {order}
                </Typography>
                <Avatar
                  sx={{ height: '30px', width: '30px' }}
                  src={logo_url || FALLBACK_SUPPLIER_LOGO_URL!}
                ></Avatar>
              </Stack>
              <AddNewStartupField
                projectCategoryId={categoryId}
                startupListId={startupListId}
                onRemoveStartupRow={onRemoveStartupRow}
                shortlist={shortlist}
              />
            </StyledStack>
          );
        },
        disableColumnMenu: true,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        pinnable: false,
      },
      getActionsColumn({
        minWidth: 140,
        renderCellNode: () => (
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            width='100%'
          >
            <Tooltip title='Remove row'>
              <IconButton size='small' onClick={() => onRemoveStartupRow()}>
                <PlaylistRemove fontSize='small' color='inherit' />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }) as GridColDef<DecoratedStartup, unknown, unknown>,
    ],
    [
      getActionsColumn,
      onRemoveStartupRow,
      categoryId,
      startupListId,
      shortlist,
    ],
  );
  return { row, columns, pinnedRightColumn };
};

export const NewStartupEmptyRowGrid = ({
  rows,
  onRemoveStartupRow,
  categoryId,
  startupListId,
  shortlist,
}: {
  rows: DecoratedStartup[];
  onRemoveStartupRow: () => void;
  categoryId: number;
  startupListId: number;
  shortlist: CategorizedStartupForMatrix[];
}) => {
  const { columns, row, pinnedRightColumn } = useGetColumns({
    onRemoveStartupRow,
    categoryId,
    startupListId,
    shortlist,
  });

  return (
    <StyledDataGridForStartupComparison
      initialState={{
        pinnedColumns: { right: pinnedRightColumn },
      }}
      rows={rows}
      className='new-startup-grid'
      sx={{
        border: 'none',
        borderBottom: '1px solid rgba(241, 243, 244, 1)',
        '& .MuiDataGrid-cell': {
          border: 'none',
        },
        '& .MuiDataGrid-row': {
          height: '0px !important',
          border: 'none !important',
        },
        '& .MuiDataGrid-columnHeaders': {
          display: 'none',
        },
        '& .MuiDataGrid-row .MuiDataGrid-rowReorderCell--draggable': {
          color: ({ palette }) => palette.grey['50048'],
        },
        '& .MuiDataGrid-cell:hover': {
          backgroundColor: 'transparent !important',
        },
      }}
      columns={columns}
      slotProps={{ row }}
      isRowSelectable={() => false}
      rowHeight={60}
      rowReordering
      columnHeaderHeight={0}
      isReadonly={false}
      hideFooter
      disableRowSelectionOnClick
    />
  );
};
