import { useTranslation } from 'react-i18next';
// material
// ----------------------------------------------------------------------

export const enCode = 'en';
export const deCode = 'de';

export const ALL_LANGS: Record<
  string,
  {
    label: string;
    value: string;
    systemValue: typeof enCode | typeof deCode;
    icon: string;
  }
> = {
  [enCode]: {
    label: 'English',
    value: 'en',
    systemValue: enCode,
    icon: '/static/icons/ic_flag_en.svg',
  },
  [deCode]: {
    label: 'German',
    value: 'de',
    systemValue: deCode,
    icon: '/static/icons/ic_flag_de.svg',
  },
};

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = i18n.language;
  const currentLang = langStorage
    ? ALL_LANGS[langStorage] || ALL_LANGS[enCode]
    : ALL_LANGS[enCode];

  const handleChangeLanguage = (newLang: string) => {
    i18n.changeLanguage(newLang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
  };
}
