import { ApolloCache } from '@apollo/client';
import { Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import {
  EnumTableProjectStakeholderRolesEnum,
  useGetAllPeopleQuery,
  useUpdateProjectStakeholdersMutation,
} from 'apollo/generated/sdkInnovationManager';
import {
  RemoveStakeholderFromProjectMutation,
  useRemoveStakeholderFromProjectMutation,
  useUpsertProjectOwnerMutation,
} from 'apollo/generated/sdkShared';
import { ProjectTagsSelect } from 'components/dashboard/projects/ProjectTagsSelect';
import {
  EditOwner,
  useOwnerButtonStyles,
} from 'components/dashboard/startupList/details/EditOwner';
import AssignTeamButton from 'components/teams/AssignTeamButton';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { ReactNode, useCallback } from 'react';
import { ProjectForDetail } from '../../../@types/startupList';
import { ProjectCategoryField } from './MainStatusFields';
import { getStakeholderRole } from 'utils/getStakeholderRole';
import { ProjectSourceTagsSelect } from 'components/dashboard/projects/ProjectSourceTagsSelect';

export const SelectItemField = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  const { typography } = useTheme();
  return (
    <Stack gap={0.5}>
      <Typography
        padding='0 0.25rem !important'
        variant='body2'
        color='grey.600'
        fontWeight={typography.fontWeightMedium}
      >
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

export const ProjectDetailBar = ({
  project,
  sx,
}: {
  project: ProjectForDetail;
  sx?: SxProps;
}) => {
  const { isSharedPage } = useSharedPagesContext();
  const [upsertProjectOwner] = useUpsertProjectOwnerMutation();
  const [upsertProjectPainPointOwner] = useUpdateProjectStakeholdersMutation();
  const { has_teams, subdomain } = useCurrentOrganizationFromContext();
  const { PROJECT_ROLES_MAP } = getStakeholderRole({ subdomain });
  const { typographyStyles } = useOwnerButtonStyles();

  const { data: allPeopleData } = useGetAllPeopleQuery({});

  const allStakeholders =
    allPeopleData?.people.map(person => ({
      id: person.id,
      full_name: person.full_name,
      email: person.email,
    })) || [];

  const { removeStakeholder } = useRemoveProjectStakeholder({
    projectId: project.id,
  });
  const projectOwner =
    project?.project_stakeholders
      ?.filter(ps => ps.role === 'project_owner')
      ?.map(mp => mp.stakeholder) || [];

  const painPointOwner =
    project?.project_stakeholders
      ?.filter(ps => ps.role === 'project_leader')
      ?.map(mp => mp.stakeholder) || [];

  const addOrUpdateOwner = useCallback(
    async (id: number) => {
      const owner = await upsertProjectOwner({
        variables: {
          object: {
            person_id: id,
            project_id: project.id,
          },
        },
      });

      captureAnalyticsEvent('Project Owner Added/Modified', {
        stakeholderId:
          owner.data?.upsert_project_owner?.project_owner_id ?? null,
      });
    },
    [project.id, upsertProjectOwner],
  );

  const addOrUpdatePainPointOwner = useCallback(
    async (id: number) => {
      await upsertProjectPainPointOwner({
        variables: {
          projectStakeholdersData: {
            stakeholder_id: id,
            project_id: project.id,
            role: 'project_leader',
          },
        },
      });
    },
    [project.id, upsertProjectPainPointOwner],
  );
  const { typography } = useTheme();

  return (
    <>
      <Stack
        direction='row'
        columnGap={theme => theme.spacing(5.5)}
        rowGap={theme => theme.spacing(1)}
        width='100%'
        flexWrap='wrap'
        sx={sx}
      >
        {isSharedPage ? (
          <>
            {projectOwner && (
              <Tooltip title={projectOwner?.map(po => po.email).join(', ')}>
                <Typography sx={{ ...typographyStyles, cursor: 'default' }}>
                  by {projectOwner[0]?.full_name}{' '}
                  {projectOwner?.length > 1 && `+ ${projectOwner?.length - 1}`}
                </Typography>
              </Tooltip>
            )}
          </>
        ) : (
          <SelectItemField label='Innovation manager'>
            <EditOwner
              owner={projectOwner}
              updateOwner={addOrUpdateOwner}
              removeOwner={stakeholderId =>
                removeStakeholder('project_owner')(stakeholderId)
              }
              options={allStakeholders}
              isMultiSelect
            />
          </SelectItemField>
        )}
        <SelectItemField label={PROJECT_ROLES_MAP.project_leader}>
          <EditOwner
            owner={painPointOwner}
            updateOwner={addOrUpdatePainPointOwner}
            removeOwner={stakeholderId =>
              removeStakeholder('project_leader')(stakeholderId)
            }
            options={allStakeholders}
            isMultiSelect
          />
        </SelectItemField>
        {(painPointOwner || [])?.length > 0 &&
          painPointOwner?.[0].organization_department?.name && (
            <SelectItemField label='Department'>
              <Typography
                variant='body2'
                color='#212b36'
                fontWeight={typography.fontWeightMedium}
                sx={{
                  lineHeight: '2.099',
                  padding: `0 0.25rem !important`,
                }}
              >
                {painPointOwner?.[0].organization_department?.name}
              </Typography>
            </SelectItemField>
          )}
        <SelectItemField label='Category'>
          <ProjectCategoryField />
        </SelectItemField>
        {has_teams && !isSharedPage && (
          <>
            <SelectItemField label='Team'>
              <AssignTeamButton
                name={project.title}
                id={project.id}
                type='project'
                team={project?.team}
                sxProps={{
                  paddingY: 0.3,
                  paddingX: 1,
                  fontWeight: typography.fontWeightMedium,
                  color: 'grey.700',
                  fontSize: '16px',
                }}
              />
            </SelectItemField>
          </>
        )}
        <SelectItemField label='Tags'>
          <ProjectTagsSelect />
        </SelectItemField>
        <SelectItemField label='Source'>
          <ProjectSourceTagsSelect />
        </SelectItemField>
      </Stack>
    </>
  );
};

export const useRemoveProjectStakeholder = ({
  projectId,
}: {
  projectId: number;
}) => {
  const [removeStakeholderFromProject] =
    useRemoveStakeholderFromProjectMutation();

  const removeStakeholder =
    (projectRole: EnumTableProjectStakeholderRolesEnum) =>
    async (stakeholderId: number) => {
      await removeStakeholderFromProject({
        variables: {
          stakeholderId: stakeholderId,
          projectId: projectId,
          role: projectRole,
        },
        update: (
          cache: ApolloCache<unknown>,
          { data }: { data?: RemoveStakeholderFromProjectMutation | null },
        ) => {
          if (!data?.delete_project_stakeholders?.returning?.[0].id) {
            return;
          }

          cache.evict({
            id: cache.identify({
              __typename: 'project_stakeholders',
              id: data.delete_project_stakeholders?.returning?.[0].id,
            }),
          });
          cache.gc();
        },
      });
    };

  return { removeStakeholder };
};
