import { orderBy } from 'lodash';

export const orderByMatrixScore = <
  CS extends {
    matrix_table_score: number;
    categorized_startup_id: number;
    is_in_matrix: boolean;
    shortlisted_date?: string | null;
  },
>(
  categorizedStartups: CS[],
) => {
  return orderBy(
    categorizedStartups,
    [
      'shortlisted_date',
      'is_in_matrix',
      'matrix_table_score',
      'categorized_startup_id',
    ],
    ['asc', 'desc', 'desc', 'asc'],
  ).map((orderedStartup, index) => {
    return { ...orderedStartup, order: index + 1 };
  });
};
