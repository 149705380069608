import BuUserGuard from 'guards/BuUserGuard';
import { lazy } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Loadable } from './Loadable';
import { PATH_NAME, PATH_PARAMS } from './paths';

const {
  teamSlug,
  startupListPublicUUID,
  shareSlug,
  projectPublicUUID,
  categoryId,
} = PATH_PARAMS;

const OrganizationPortalPage = Loadable(
  lazy(
    () => import('../pages/organizationPortal/shared/OrganizationPortalPage'),
  ),
);

const StartupListResultPage = Loadable(
  lazy(() => import('../pages/dashboard/StartupListResultPage')),
);
const SharedCategoryDetailsPage = Loadable(
  lazy(() => import('../pages/shared/SharedCategoryDetailsPage')),
);
const RequestSolutionsPage = Loadable(
  lazy(() => import('../pages/request-form-v2/page/RequestSolutionsPage')),
);

const BUProblemScopesForm = Loadable(
  lazy(
    () =>
      import('../pages/request-form-v2/page/RequestSolutionsMultistepsPage'),
  ),
);

const ScoutStartupsPage = Loadable(
  lazy(() => import('../pages/dashboard/ScoutStartupsPage')),
);

const SharedProjectDetailsPage = Loadable(
  lazy(() => import('../pages/shared/SharedProjectDetailsPage')),
);

const SharedChallengeDetailsPage = Loadable(
  lazy(() => import('../pages/shared/SharedChallengeDetailsPage')),
);

export const RedirectWithParams = ({ to }: { to: string }) => {
  const params = useParams<Record<string, string | undefined>>();
  const path = Object.keys(params).reduce((path, param) => {
    const value = params[param] ?? `:${param}`;
    return path.replace(`:${param}`, value);
  }, to);

  return <Navigate to={path} replace />;
};

const SHARED_ROUTES = [
  {
    path: `:${teamSlug}/:pageSlug`,
    element: (
      <BuUserGuard>
        <OrganizationPortalPage />
      </BuUserGuard>
    ),
  },
  {
    path: `:${teamSlug}/${PATH_NAME.scout}`,
    element: (
      <BuUserGuard>
        <ScoutStartupsPage />
      </BuUserGuard>
    ),
  },
  {
    path: `${PATH_NAME.lists}/:${startupListPublicUUID}/:${shareSlug}`,
    element: (
      <BuUserGuard>
        <StartupListResultPage />
      </BuUserGuard>
    ),
  },
  {
    path: `:${teamSlug}/${PATH_NAME.lists}/:${startupListPublicUUID}/:${shareSlug}`,
    element: (
      <BuUserGuard>
        <StartupListResultPage />
      </BuUserGuard>
    ),
  },
  {
    path: `${PATH_NAME.lists}/:${startupListPublicUUID}/:${shareSlug}/${PATH_NAME.category}/:${categoryId}`,
    element: (
      <BuUserGuard>
        <SharedCategoryDetailsPage />
      </BuUserGuard>
    ),
    index: true,
  },
  {
    path: `:${teamSlug}/${PATH_NAME.lists}/:${startupListPublicUUID}/:${shareSlug}/${PATH_NAME.category}/:${categoryId}`,
    element: (
      <BuUserGuard>
        <SharedCategoryDetailsPage />
      </BuUserGuard>
    ),
    index: true,
  },
  {
    path: `:${teamSlug}/${PATH_NAME.scope}/:${projectPublicUUID}`,
    element: (
      <BuUserGuard>
        <SharedProjectDetailsPage />
      </BuUserGuard>
    ),
    index: true,
  },
  {
    path: `${PATH_NAME.challenges}/:challengePublicUUID`,
    element: (
      <BuUserGuard>
        <SharedChallengeDetailsPage />
      </BuUserGuard>
    ),
  },
  {
    path: `:${teamSlug}/${PATH_NAME.briefUs}/fill-scope`,
    element: (
      <BuUserGuard>
        <BUProblemScopesForm />
      </BuUserGuard>
    ),
  },
  // Public
  {
    path: `:${teamSlug}/${PATH_NAME.briefUs}`,
    element: <RequestSolutionsPage />,
  },
  // Backwards compatibile routes
  {
    path: PATH_NAME.briefUs,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.briefUs}`}
        replace
      />
    ),
  },
  {
    path: PATH_NAME.portalAbout,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.portalAbout}`}
        replace
      />
    ),
  },
  {
    path: PATH_NAME.portalHome,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.portalHome}`}
        replace
      />
    ),
  },
  {
    path: PATH_NAME.scout,
    element: (
      <Navigate
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.scout}`}
        replace
      />
    ),
  },
  {
    path: `${PATH_NAME.scope}/:${projectPublicUUID}`,
    element: (
      <RedirectWithParams
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.scope}/:${projectPublicUUID}`}
      />
    ),
  },
  {
    path: `${PATH_NAME.listScope}/:${startupListPublicUUID}`,
    element: (
      <RedirectWithParams
        to={`/${PATH_NAME.portalRoot}/default/${PATH_NAME.listScope}/:${startupListPublicUUID}`}
      />
    ),
  },
];

export { SHARED_ROUTES };
