import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import { PATH_ROOT } from 'routes/paths';
import useIdleTimeout from 'hooks/useIdleTimeout';
// ----------------------------------------------------------------------

type AdminGuardProps = {
  children: ReactNode;
  disabled?: boolean;
};

export default function AdminGuard({ children, disabled }: AdminGuardProps) {
  const { user, isAuthenticated } = useAuth();
  const { IdleTimeoutSnackbar } = useIdleTimeout();

  if (disabled) {
    return <>{children}</>;
  }

  if (isAuthenticated && user.type === 'bu_member') {
    return <Navigate to={PATH_ROOT.organizationPortal.home('default')} />;
  }

  if (!isAuthenticated) {
    console.log('[DEBUG] AdminGuard: User is not authenticated');
    const returnTo = encodeURIComponent(
      window.location.pathname + window.location.search,
    );
    return <Navigate to={`${PATH_ROOT.auth.login}?returnTo=${returnTo}`} />;
  }

  return (
    <>
      {children}
      {IdleTimeoutSnackbar}
    </>
  );
}
