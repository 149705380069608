import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { captureException } from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { API_GRAPHQL_ENDPOINT } from 'config';
import { createClient } from 'graphql-ws';
import { useAuth0 } from '@auth0/auth0-react';
import { produce } from 'immer';
import { PATH_ROOT } from 'routes/paths';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';

const httpLink = new HttpLink({ uri: API_GRAPHQL_ENDPOINT });

const buildErrorLink = () =>
  onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (graphQLErrors[0].extensions?.code === 'invalid-jwt') {
        console.error('Invalid JWT token');
        captureException(new Error('Invalid JWT Access Token'), {
          extra: { errors: graphQLErrors },
        });
        window.location.href = PATH_ROOT.auth.login;
      }
      captureException(new Error(`${operation.operationName} - errors`), {
        extra: { errors: graphQLErrors },
      });
    }
    if (networkError) captureException(networkError);
  });

const filterStartupListLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (response?.data) {
      const newData = produce(response.data, draft => {
        Object.keys(draft).forEach(key => {
          const value = draft[key];
          if (Array.isArray(value)) {
            // Filter out objects that have a `startup_list` key with a value of `null`
            draft[key] = value.filter(item => item?.startup_list !== null);
          }
        });
      });

      return {
        ...response,
        data: newData,
      };
    }

    return response;
  });
});

const cache = new InMemoryCache({});

export const useApolloClientDoNotUse = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { logout } = useAuth();

  const createWsLink = useMemo(
    () =>
      new GraphQLWsLink(
        createClient({
          url: API_GRAPHQL_ENDPOINT.includes('localhost')
            ? API_GRAPHQL_ENDPOINT.replace('http', 'ws')
            : API_GRAPHQL_ENDPOINT.replace('https', 'wss'),
          connectionParams: async () => {
            try {
              const token = isAuthenticated
                ? await getAccessTokenSilently()
                : undefined;
              return token
                ? {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                : {};
            } catch (error) {
              console.error('WS connection error:', error);
              return {};
            }
          },
        }),
      ),
    [getAccessTokenSilently, isAuthenticated],
  );

  const splitLink = useMemo(() => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      createWsLink,
      httpLink,
    );
  }, [createWsLink]);

  const authLink = useMemo(() => {
    return setContext(async (_, { headers }) => {
      try {
        if (!isAuthenticated) {
          return {
            headers,
          };
        }

        const token = await getAccessTokenSilently();

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      } catch (error) {
        console.error('Error getting access token:', error);

        if ((error as Error).message === 'Unknown or invalid refresh token.') {
          console.log('Invalid Refresh token');
          captureException(error);
          logout(PATH_ROOT.auth.login);
        }
        captureException(error);
        return {
          headers,
        };
      }
    });
  }, [getAccessTokenSilently, isAuthenticated, logout]);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          buildErrorLink(),
          new SentryLink({
            attachBreadcrumbs: { includeError: true },
          }),
          authLink,
          filterStartupListLink,
          splitLink,
        ]),
        cache,
        connectToDevTools: true,
      }),
    [authLink, splitLink],
  );

  return apolloClient;
};
