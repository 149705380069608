import { ReactNode, useRef, useState } from 'react';
import { Menu, IconButton, SxProps, Box } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

// ----------------------------------------------------------------------
const MoreMenu = ({
  children,
  sxProps = {},
  icon = <MoreVert />,
}: {
  children: ReactNode;
  sxProps?: SxProps;
  icon?: ReactNode;
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box component='span'>
      <IconButton
        ref={ref}
        onClick={() => setIsOpen(true)}
        data-testid='more-menu'
      >
        {icon}
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        // // Make sure the menu closes when user click on one of the menu items
        // FIXME: Return with a proper solution
        // onClick={() => setIsOpen(false)}
        sx={sxProps}
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default MoreMenu;
