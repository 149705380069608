import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
// import { AddFeatureButton } from 'components/dashboard/sourcingOrder/SourcingOrderScopeContent';
import { Star, ThumbUpAlt } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { AddFeatureButton } from 'components/dashboard/sourcingOrder/SourcingOrderScopeContent';
import { StartupListFeatureBox } from 'components/dashboard/startupList/StartupListFeatureBox';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { PainPointsSaveFormDataType } from 'pages/request-form-v2/usePainPointActions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormLabelWithTooltip } from './ProblemField';

type FeatureItem = {
  name: string;
  mustHave: boolean;
};

type FeaturesFieldProps = {
  setFieldValue: (name: string, value: string | string[]) => void;
  must_have_features: string[];
  nice_to_have_features: string[];
  isEditable?: boolean;
  showTooltip?: boolean;
  onBlur?: (props: PainPointsSaveFormDataType) => void;
  triggerSaveFieldConfirmation?: (
    onSaveConfirmed: () => void,
    isValueChanged: boolean,
  ) => void;
};

export function FeaturesField(props: FeaturesFieldProps) {
  const {
    setFieldValue,
    must_have_features,
    nice_to_have_features,
    showTooltip,
    onBlur,
    isEditable,
    triggerSaveFieldConfirmation,
  } = props;

  const [editFeatures, setEditFeatures] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<FeatureItem | null>(
    null,
  );

  const [localMustHaveFeatures, setLocalMustHaveFeatures] =
    useState(must_have_features);
  const [localNiceToHaveFeatures, setLocalNiceToHaveFeatures] = useState(
    nice_to_have_features,
  );

  useEffect(() => {
    if (must_have_features !== localMustHaveFeatures) {
      setLocalMustHaveFeatures(must_have_features);
    }
    if (nice_to_have_features !== localNiceToHaveFeatures) {
      setLocalNiceToHaveFeatures(nice_to_have_features);
    }
  }, [
    must_have_features,
    nice_to_have_features,
    localMustHaveFeatures,
    localNiceToHaveFeatures,
  ]);

  // Combine must-have and nice-to-have features for display
  const combinedFeatures: FeatureItem[] = useMemo(() => {
    const must = localMustHaveFeatures.map(f => ({ name: f, mustHave: true }));
    const nice = localNiceToHaveFeatures.map(f => ({
      name: f,
      mustHave: false,
    }));
    return [...must, ...nice];
  }, [localMustHaveFeatures, localNiceToHaveFeatures]);

  const handleDelete = (feature: FeatureItem) => {
    if (feature.mustHave) {
      const newArr = localMustHaveFeatures.filter(f => f !== feature.name);
      setFieldValue('must_have_features', newArr);
      onBlur?.({ fieldName: 'must_have_features', fieldValue: newArr });
    } else {
      const newArr = localNiceToHaveFeatures.filter(f => f !== feature.name);
      setFieldValue('nice_to_have_features', newArr);
      onBlur?.({ fieldName: 'nice_to_have_features', fieldValue: newArr });
    }
  };

  const addFeature = useCallback(
    (feature: string, mustHave: boolean) => {
      if (mustHave) {
        const newArr = [...localMustHaveFeatures, feature];
        setFieldValue('must_have_features', newArr);
        onBlur?.({ fieldName: 'must_have_features', fieldValue: newArr });
      } else {
        const newArr = [...localNiceToHaveFeatures, feature];
        setFieldValue('nice_to_have_features', newArr);
        onBlur?.({ fieldName: 'nice_to_have_features', fieldValue: newArr });
      }
    },
    [localMustHaveFeatures, localNiceToHaveFeatures, setFieldValue, onBlur],
  );

  const handleSaveFeature = useCallback(
    (values: { name: string; mustHave: boolean }) => {
      if (isEditMode && selectedFeature) {
        // Editing an existing feature
        const { name: oldName, mustHave: oldMustHave } = selectedFeature;
        const { name: newName, mustHave: newMustHave } = values;

        // If mustHave changed
        if (oldMustHave !== newMustHave) {
          // Remove old
          if (oldMustHave) {
            const newArr = localMustHaveFeatures.filter(f => f !== oldName);
            setFieldValue('must_have_features', newArr);
            onBlur?.({ fieldName: 'must_have_features', fieldValue: newArr });
          } else {
            const newArr = localNiceToHaveFeatures.filter(f => f !== oldName);
            setFieldValue('nice_to_have_features', newArr);
            onBlur?.({
              fieldName: 'nice_to_have_features',
              fieldValue: newArr,
            });
          }

          // Add new
          if (newMustHave) {
            const newArr = [...localMustHaveFeatures, newName];
            setFieldValue('must_have_features', newArr);
            onBlur?.({ fieldName: 'must_have_features', fieldValue: newArr });
          } else {
            const newArr = [...localNiceToHaveFeatures, newName];
            setFieldValue('nice_to_have_features', newArr);
            onBlur?.({
              fieldName: 'nice_to_have_features',
              fieldValue: newArr,
            });
          }
        } else {
          // Must-have unchanged, just rename in place
          if (oldMustHave) {
            const newArr = localMustHaveFeatures.map(x =>
              x === oldName ? newName : x,
            );
            setFieldValue('must_have_features', newArr);
            onBlur?.({ fieldName: 'must_have_features', fieldValue: newArr });
          } else {
            const newArr = localNiceToHaveFeatures.map(x =>
              x === oldName ? newName : x,
            );
            setFieldValue('nice_to_have_features', newArr);
            onBlur?.({
              fieldName: 'nice_to_have_features',
              fieldValue: newArr,
            });
          }
        }
      } else {
        addFeature(values.name, values.mustHave);
      }

      setSelectedFeature(null);
      setIsEditMode(false);
    },
    [
      isEditMode,
      selectedFeature,
      localMustHaveFeatures,
      localNiceToHaveFeatures,
      setFieldValue,
      addFeature,
      onBlur,
    ],
  );

  return (
    <>
      {selectedFeature && (
        <FeaturesPopup
          open
          onHide={() => {
            setSelectedFeature(null);
            setIsEditMode(false);
          }}
          onSaveFeature={handleSaveFeature}
          selectedFeature={selectedFeature}
          isEditMode={isEditMode}
          triggerSaveFieldConfirmation={triggerSaveFieldConfirmation}
        />
      )}

      {isEditable ? (
        <Stack gap={1.25} width='100%'>
          <FormLabelWithTooltip
            label='Features'
            tooltipText={<TooltipInfoContent />}
            sx={{ marginBottom: 0 }}
            showTooltip={showTooltip}
          />

          <Stack
            direction='row'
            alignItems='center'
            flexWrap='wrap'
            gap={1}
            sx={{
              padding: '4px 2px',
              borderRadius: '8px',
              // boxShadow:
              //   editFeatures && combinedFeatures.length > 0
              //     ? ({ shadows }) => shadows[4]
              //     : 'none',
              '&:hover': {
                // background:
                //   !editFeatures && combinedFeatures.length > 0
                //     ? '#F8F8F8'
                //     : 'none',
                cursor: 'text',
              },
            }}
            onMouseEnter={() => {
              setEditFeatures(true);
            }}
            onMouseLeave={() => {
              setEditFeatures(false);
            }}
          >
            {combinedFeatures.map(feature => (
              <StartupListFeatureBox
                editModeEnabled={editFeatures}
                key={feature.name}
                featureText={feature.name}
                mustHave={feature.mustHave}
                onDelete={
                  editFeatures
                    ? () => {
                        triggerSaveFieldConfirmation
                          ? triggerSaveFieldConfirmation(() => {
                              handleDelete(feature);
                            }, true)
                          : handleDelete(feature);
                      }
                    : undefined
                }
                onClick={
                  editFeatures
                    ? () => {
                        setIsEditMode(true);
                        setSelectedFeature({
                          mustHave: feature.mustHave,
                          name: feature.name,
                        });
                      }
                    : undefined
                }
              />
            ))}

            {(editFeatures || combinedFeatures.length === 0) && (
              <AddFeatureButton
                onClick={() => {
                  setSelectedFeature({ mustHave: true, name: '' });
                  setIsEditMode(false);
                }}
              />
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack gap={4} width='100%'>
          <Stack>
            <FormLabelWithTooltip
              label='Features'
              tooltipText={<TooltipInfoContent />}
              sx={{ marginBottom: 1 }}
              showTooltip={showTooltip}
            />
            <Stack direction='row' alignItems='center' flexWrap='wrap' gap={1}>
              {combinedFeatures.map(feature => (
                <StartupListFeatureBox
                  key={feature.name}
                  featureText={feature.name}
                  mustHave={feature.mustHave}
                  editModeEnabled={false}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}

type FeaturesPopupProps = {
  open: boolean;
  onHide: () => void;
  onSaveFeature: (feature: { name: string; mustHave: boolean }) => void;
  selectedFeature: { mustHave: boolean; name: string };
  isEditMode: boolean;
  triggerSaveFieldConfirmation?: (
    onSaveConfirmed: () => void,
    isValueChanged: boolean,
  ) => void;
};

function FeaturesPopup(props: FeaturesPopupProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    open,
    onHide,
    onSaveFeature,
    selectedFeature,
    isEditMode,
    triggerSaveFieldConfirmation,
  } = props;

  const formik = useFormik<{
    name: string;
    mustHave: boolean;
  }>({
    enableReinitialize: true,
    initialValues: {
      name: selectedFeature.name || '',
      mustHave: selectedFeature.mustHave,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Feature is required'),
    }),
    onSubmit: async values => {
      try {
        triggerSaveFieldConfirmation
          ? triggerSaveFieldConfirmation(() => {
              onSaveFeature(values);
            }, true)
          : onSaveFeature(values);
      } catch (_e) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>
            {isEditMode ? 'Edit a feature' : 'Add a feature'}
          </DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <FormControl component='fieldset'>
              <RadioGroup
                {...getFieldProps('mustHave')}
                value={formik.values.mustHave}
                onChange={e =>
                  formik.setFieldValue('mustHave', e.target.value === 'true')
                }
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={
                    <Stack direction='row' alignItems='center' gap={0.5}>
                      <Star sx={{ fontSize: 24, color: 'grey.600' }} />
                      <Typography>Must have</Typography>
                    </Stack>
                  }
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={
                    <Stack direction='row' alignItems='center' gap={0.5}>
                      <ThumbUpAlt sx={{ fontSize: 24, color: 'grey.600' }} />
                      <Typography>Nice to have</Typography>
                    </Stack>
                  }
                />
              </RadioGroup>
            </FormControl>

            <TextField
              autoFocus
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
              label='Name your features, requirements, wanted technologies, startup locations, or maturity'
              sx={{ marginTop: 3 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant='text'
              color='inherit'
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </Button>
            <Button type='submit' variant='contained'>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

const TooltipInfoContent = () => (
  <Stack>
    <Stack direction='row' alignItems='center' gap={1}>
      <Typography variant='body2'>Must have feature :</Typography>
      <Star fontSize='small' sx={{ color: 'white' }} />
    </Stack>
    <Stack direction='row' alignItems='center' gap={1}>
      <Typography variant='body2'>Nice-to-have feature :</Typography>
      <ThumbUpAlt fontSize='small' sx={{ color: 'white' }} />
    </Stack>
  </Stack>
);
