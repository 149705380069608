import { Dialog } from '@mui/material';
import { RequestSolutionsStepsForm } from '../RequestSolutionsStepsForm';
import { useRequestsSolutionsMultiStepState } from '../state/requestSolutionsFormState';

export const PainPointNewPageModal = ({
  open,
  handleClose,
  postCreateAction,
}: {
  open: boolean;
  handleClose: () => void;
  postCreateAction?: (painPointId: number) => void;
}) => {
  // Reset step to 1 when closing the modal
  const { setCurrentStep } = useRequestsSolutionsMultiStepState();
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
        setCurrentStep(1);
      }}
      maxWidth='sm'
      sx={{
        zIndex: ({ zIndex }) => zIndex.modal,
      }}
    >
      <RequestSolutionsStepsForm
        isInnovationManager
        postCreateAction={postCreateAction}
      />
    </Dialog>
  );
};
