// material
import { Container, IconButton, Slide, Stack, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { NAV_TEXT_GREY, NAV_TEXT_HOVERED } from 'components/NavSection';
import { matchPath, useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import AccountPopover from './AccountPopover';
import { GoTo } from './GoTo/GoTo';
import NotificationsPopover from './NotificationsPopover';
import BarChartIcon from './assets/bar-chart.svg?react';
import HelpCenterIcon from './assets/help-center.svg?react';
import { useSettingsContext } from 'contexts/SettingsContext';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 3,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: `${theme.palette.background.default}`,
}));

// ----------------------------------------------------------------------

export default function NavbarContent() {
  const { palette, spacing } = useTheme();
  const navigate = useNavigate();
  const isAnalyticsActive = matchPath(PATH_ROOT.analytics, location.pathname);

  const {
    settings: { HELP_CENTER_LINK },
    loading: helpCenterLoading,
  } = useSettingsContext();

  return (
    <Container
      maxWidth='xl'
      sx={{
        display: 'flex',
        alignItems: 'center',
        spacing: { xs: 0.5, sm: 1.5 },
      }}
    >
      <Slide direction='down' in={true} mountOnEnter unmountOnExit timeout={0}>
        <SearchbarStyle>
          <GoTo />
          <Stack marginLeft='auto' direction='row' spacing={1} marginRight={2}>
            <Tooltip title='Help Center'>
              <IconButton
                sx={{
                  width: spacing(6),
                  height: spacing(6),
                  background: helpCenterLoading
                    ? palette.action.hover
                    : 'transparent',
                }}
                component='a'
                href={HELP_CENTER_LINK}
                target='_blank'
              >
                <HelpCenterIcon fill={NAV_TEXT_HOVERED} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Analytics'>
              <IconButton
                sx={{
                  width: spacing(6),
                  height: spacing(6),
                  background: isAnalyticsActive
                    ? palette.action.hover
                    : 'transparent',
                }}
                onClick={() => {
                  navigate(PATH_ROOT.analytics);
                }}
              >
                <BarChartIcon
                  fill={isAnalyticsActive ? NAV_TEXT_HOVERED : NAV_TEXT_GREY}
                />
              </IconButton>
            </Tooltip>
            <NotificationsPopover />
          </Stack>
          <AccountPopover />
        </SearchbarStyle>
      </Slide>
    </Container>
  );
}
