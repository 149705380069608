import { DropzoneOptions, useDropzone } from 'react-dropzone';
// material
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'components/Image';
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
  padding: '3% 0 !important',
}));

// ----------------------------------------------------------------------

export interface CustomFile extends File {
  path?: string;
  preview?: string;
}

interface UploadSingleFileProps extends DropzoneOptions {
  error?: boolean;
  file: CustomFile | null;
  sx?: SxProps<Theme>;
  imgHeight?: number | string;
}

export default function UploadSingleFile({
  error = false,
  file,
  sx,
  imgHeight,
  ...other
}: UploadSingleFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      multiple: false,
      ...other,
    });

  const previewUrl =
    file && file.type.startsWith('image/') ? file.preview : undefined;
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && { padding: '12% 0' }),
        }}
      >
        <input {...getInputProps()} />

        {file && (
          <Box
            sx={{
              borderRadius: 1,
            }}
          >
            {previewUrl ? (
              <Image src={previewUrl} imgHeight={imgHeight}></Image>
            ) : (
              file?.path
            )}
          </Box>
        )}

        {!file && (
          <Stack direction='row' sx={{ padding: 3 }}>
            <UploadIllustration sx={{ width: 100 }} />
            <Box sx={{ marginLeft: 2 }}>
              <Typography
                style={{ fontSize: '1rem' }}
                gutterBottom
                variant='h5'
              >
                Drop or Select file
              </Typography>

              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Drop files here or click&nbsp;
                <Typography
                  variant='body2'
                  component='span'
                  sx={{ color: 'primary.main', textDecoration: 'underline' }}
                >
                  browse
                </Typography>
                &nbsp;thorough your machine
              </Typography>
            </Box>
          </Stack>
        )}
      </DropZoneStyle>
    </Box>
  );
}
