import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { KnownStartupsField } from 'components/dashboard/startupList/shared/KnownStartupsField';
import { FeaturesField } from 'components/requestFormFields/FeaturesField';
import { useSnackbar } from 'notistack';
import {
  UploadedFile,
  UploadScopeFile,
} from 'pages/request-form/useScopeFiles';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import {
  ScopeSectionTwoState,
  useRequestsSolutionsMultiStepState,
  useValidateField,
} from './state/requestSolutionsFormState';
import { useRequestSolutionsManager } from './useRequestSolutionsManager';

type FileToShow = UploadedFile | File;

export const ScopeSectionTwo = ({
  isInnovationManager,
  postCreateAction,
}: {
  isInnovationManager?: boolean;
  postCreateAction?: (painPointId: number) => void;
}) => {
  const { teamSlug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { scopeSectionTwoData, updateScopeSectionTwo, clearState } =
    useRequestsSolutionsMultiStepState();
  const { handleFormSubmit } = useRequestSolutionsManager({
    isInnovationManager,
  });

  const { errors, validateField } = useValidateField('scopeSectionTwo');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    updateScopeSectionTwo({
      ...scopeSectionTwoData,
      [name]: value,
    });

    validateField(name, value);
  };

  const onNext = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const data = await handleFormSubmit();
      const publicUUID = data?.publicUUID ?? '';
      if (isInnovationManager) {
        if (postCreateAction) {
          postCreateAction(data?.painPointId);
        } else {
          navigate(PATH_ROOT.painPoints.details(data?.painPointId));
        }
      } else {
        navigate(PATH_ROOT.lists.sharedListScopeDetails(publicUUID, teamSlug), {
          state: {
            message:
              'Challenge registered successfully. Add more details here or return later using this link.',
            publicUUID,
            teamSlug,
          },
        });
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
      clearState();
    }
  };

  return (
    <form onSubmit={onNext} style={{ width: '100%' }}>
      <Stack direction='column' gap={4}>
        <Box>
          <FeaturesField
            {...{
              must_have_features: scopeSectionTwoData?.mustHaveFeatures ?? [],
              nice_to_have_features:
                scopeSectionTwoData?.niceToHaveFeatures ?? [],
              setFieldValue: (name: string, value: string | string[]) => {
                const fieldName =
                  name === 'must_have_features'
                    ? 'mustHaveFeatures'
                    : 'niceToHaveFeatures';

                updateScopeSectionTwo({
                  ...scopeSectionTwoData,
                  [fieldName]: value,
                });
                validateField(fieldName, value as string);
              },
              isEditable: true,
            }}
          />
        </Box>
        <Box>
          <KnownStartupsField
            {...{
              knownStartups: scopeSectionTwoData.knownSolutions,
              handleSave: ({ fieldValue }) => {
                updateScopeSectionTwo({
                  ...scopeSectionTwoData,
                  knownSolutions: fieldValue,
                });
              },
            }}
          />
        </Box>
        <Box>
          <Typography
            variant='body2'
            sx={{
              color: 'grey.700',
              fontWeight: 900,
              marginBottom: 1,
            }}
          >
            Additional Information
          </Typography>
          <TextField
            multiline
            minRows={4}
            name='additionalInformation'
            value={scopeSectionTwoData.additionalInformation}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'brief-additionalInformation',
            }}
            placeholder='Describe your preferred solution'
            error={Boolean(errors.desiredSolution)}
            helperText={errors.desiredSolution}
            fullWidth
          />
        </Box>
        <Box>
          <Typography
            variant='body2'
            sx={{
              color: 'grey.700',
              fontWeight: 900,
              marginBottom: 1,
            }}
          >
            Supporting documents
          </Typography>
          <UploadScopeFile
            fieldName='supportingDocuments'
            setFieldValue={(field: string, value: FileToShow[]) => {
              updateScopeSectionTwo({
                ...scopeSectionTwoData,
                [field]: [
                  ...Array.from(scopeSectionTwoData.supportingDocuments),
                  ...value,
                ],
              });
            }}
            problemScopeId={undefined}
          />
        </Box>
      </Stack>
      <Stack
        spacing={0}
        alignItems='start'
        sx={{ marginTop: 3, marginBottom: 1 }}
      >
        <LoadingButton
          sx={{ marginLeft: 'auto' }}
          type='submit'
          variant='contained'
          size='large'
          disabled={
            !isFormDirty(scopeSectionTwoData) ||
            Object.values(errors).some(Boolean) ||
            isSubmitting
          }
          loading={isSubmitting}
        >
          {/* {translate('requestForm.form.submitButton')}
           */}
          {isInnovationManager ? 'Save' : 'Submit'}
        </LoadingButton>
      </Stack>
    </form>
  );
};

const isFormDirty = (sectionOneStepTwo: ScopeSectionTwoState) => {
  return Object.values(sectionOneStepTwo).some(Boolean);
};
