import { Divider, Link, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Image from '../components/Image';

type MarkdownProps = Options & {
  themeVariant?: 'default' | 'large' | 'small';
  hideLists?: boolean;
};

const componentVariants: Record<
  'default' | 'large' | 'small',
  Record<
    string,
    (props: ComponentPropsWithoutRef<typeof Typography>) => JSX.Element
  >
> = {
  default: {
    h1: props => <Typography fontWeight='bold' fontSize='20px' {...props} />,
    h2: props => <Typography fontWeight='bold' fontSize='20px' {...props} />,
    h3: props => <Typography fontWeight='bold' fontSize='16px' {...props} />,
  },
  large: {
    h1: props => <Typography variant='h1' {...props} />,
    h2: props => <Typography variant='h2' {...props} />,
    h3: props => <Typography variant='h3' {...props} />,
    h4: props => <Typography variant='h4' {...props} />,
    h5: props => <Typography variant='h5' {...props} />,
    h6: props => <Typography variant='h6' {...props} />,
  },
  small: {
    h1: props => <Typography fontWeight='bold' fontSize='14px' {...props} />,
    h2: props => <Typography fontWeight='bold' fontSize='14px' {...props} />,
    h3: props => <Typography fontWeight='bold' fontSize='14px' {...props} />,
    h4: props => <Typography fontWeight='bold' fontSize='14px' {...props} />,
    h5: props => <Typography fontWeight='bold' fontSize='14px' {...props} />,
    h6: props => <Typography fontWeight='bold' fontSize='14px' {...props} />,
  },
};

const commonComponents: Record<
  string,
  (
    props:
      | ComponentPropsWithoutRef<typeof Link>
      | ComponentPropsWithoutRef<typeof Image>,
  ) => JSX.Element
> = {
  hr: props => (
    <Divider
      sx={{ my: 3 }}
      {...(props as ComponentPropsWithoutRef<typeof Divider>)}
    />
  ),
  img: props => (
    <Image
      alt={(props as ComponentPropsWithoutRef<typeof Image>).alt}
      ratio='16/9'
      sx={{ borderRadius: 2, my: 5 }}
      {...(props as ComponentPropsWithoutRef<typeof Image>)}
    />
  ),
  a: props => (
    <Link
      target='_blank'
      rel='noopener'
      {...(props as ComponentPropsWithoutRef<typeof Link>)}
    />
  ),
  p: ({ ...props }) => <Typography variant='body2' {...props} />,
};

const hiddenListComponents = {
  ul: () => <></>,
  ol: () => <></>,
};

const MarkdownStyle = styled('div')(({ theme }: { theme: Theme }) => ({
  '& .ql-align-center': { textAlign: 'center' },
  '& .ql-video.ql-align-center': {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    border: 'none',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  '& ul, & ol': {
    ...theme.typography.body2,
    paddingLeft: theme.spacing(4),
    lineHeight: '1.5rem',
  },
  '& blockquote': {
    lineHeight: 1.5,
    fontSize: '1.5em',
    margin: '40px auto',
    position: 'relative',
    fontFamily: 'Georgia, serif',
    padding: theme.spacing(3, 3, 3, 8),
    borderRadius: Number(theme.shape.borderRadius) * 2,
    backgroundColor: theme.palette.background.neutral,
    color: `${theme.palette.text.secondary} !important`,
    '&:before': {
      left: theme.spacing(2),
      top: `-${theme.spacing(1)}`,
      display: 'block',
      fontSize: '3em',
      content: '"\\201C"',
      position: 'absolute',
      color: theme.palette.text.disabled,
    },
  },
  '& pre, & pre > code': {
    fontSize: 16,
    overflowX: 'auto',
    whiteSpace: 'pre',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[900]
        : theme.palette.grey[500_16],
  },
  '& code': {
    fontSize: 14,
    borderRadius: 4,
    whiteSpace: 'pre',
    padding: theme.spacing(0.2, 0.5),
    color:
      theme.palette.warning[
        theme.palette.mode === 'light' ? 'darker' : 'lighter'
      ],
    backgroundColor:
      theme.palette.warning[
        theme.palette.mode === 'light' ? 'lighter' : 'darker'
      ],
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': { textDecoration: 'underline' },
  },
}));

const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(
  ({ themeVariant = 'default', hideLists, ...props }, ref) => (
    <MarkdownStyle ref={ref}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          ...commonComponents,
          ...componentVariants[themeVariant],
          ...(hideLists ? hiddenListComponents : {}),
        }}
        {...props}
      />
    </MarkdownStyle>
  ),
);

Markdown.displayName = 'Markdown';

export default Markdown;
