import { Stack, Typography, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InfoHover } from './InfoHover';

export const DataSourcesInfo = ({
  sx,
  iconSize = 16,
}: {
  sx?: SxProps;
  iconSize?: number;
}) => {
  const { palette } = useTheme();

  return (
    <Stack direction='row' spacing={0.4} alignItems='flex-start' sx={{ ...sx }}>
      <Typography
        fontSize={'inherit'}
        fontWeight={500}
        color={palette.grey[700]}
      >
        Publicly reported clients
      </Typography>
      <InfoHover
        iconSize={iconSize}
        additionalCSS={{ marginTop: '2px' }}
        iconColor={palette.grey[500]}
        tooltipText='Publicly reported relationships between startups and corporations. The data has been gathered from startup websites, LinkedIn profiles, innovation unit websites and various other publicly accessible sources.'
      />
    </Stack>
  );
};
