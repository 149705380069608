import { ChallengesPageContent } from 'components/engagement/challenges/ChallengesPageContent';
import Page from 'components/Page';

const ChallengesPage = () => {
  return (
    <Page
      title={`Initiatives | Glassdollar`}
      trackingTitle={`Initiatives`}
      sx={{ height: '100%' }}
    >
      <ChallengesPageContent />
    </Page>
  );
};

export default ChallengesPage;
