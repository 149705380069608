import { Box, Card, Stack, useTheme } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  NoRowsOverlayPropsOverrides,
  ToolbarPropsOverrides,
} from '@mui/x-data-grid-pro';
import { useGetPainPointsQuery } from 'apollo/generated/sdkShared';
import EmptyContent from 'components/EmptyContent';
import { StandardSearchBar } from 'components/StyledSearchInput';

import { captureException } from '@sentry/react';
import {
  InputMaybe,
  ProblemScopes,
  ProblemScopesBoolExp,
  ProblemScopesOrderBy,
} from 'apollo/generated/sdkInnovationManager';
import {
  LinkedEntitiesType,
  LinkedEntityTitleTypography,
  LinkedToEntity,
} from 'components/dashboard/StartupInfoSidePanel/LinkedToEntity';
import { ListItemIconStyle, NAV_TEXT_GREY } from 'components/NavSection';
import StyledDataGrid from 'components/StyledDataGrid';
import { SEARCH_PARAMS } from 'config';
import useLocalStorage from 'hooks/useLocalStorage';
import { LibraryTabType } from 'layouts/LibraryLayout';
import { CreatedByGridCell } from 'pages/engagement/SourcingOrdersPage';
import Page500 from 'pages/Page500';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { formatDate } from 'utils/datetime';
import BreakingNews from '../../layouts/dashboard/assets/breaking-news.svg?react';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    searchStr: string;
    setSearchStr: Dispatch<SetStateAction<string>>;
  }
  interface NoRowsOverlayPropsOverrides {
    searchStr: string;
  }
}

type DecoratedPainPoint = Pick<
  ProblemScopes,
  'id' | 'problem' | 'updated_at' | 'created_at'
> & {
  startup_lists: LinkedEntitiesType['startupLists'];
  projects: LinkedEntitiesType['projects'];
  created_by?: {
    person: {
      id: number;
      full_name: string;
      email: string;
    };
  } | null;
};

const PAGE_SIZE = 20;
const DEBOUNCED_TIME = 1000; // In milliseconds
const SORTABLE_COLUMNS = ['created_at'];

export default function PainPointsComponent() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchStr, setSearchStr] = useState('');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [debouncedSearchStr, setDebouncedSearchStr] = useState(searchStr);
  const [pageSize, setPageSize] = useLocalStorage(
    'library_startups_page_size',
    PAGE_SIZE,
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get(SEARCH_PARAMS.page) || 0),
    pageSize,
  });

  const [orderBy, setOrderBy] = useState<
    InputMaybe<Array<ProblemScopesOrderBy> | ProblemScopesOrderBy>
  >([{ created_at: 'desc' }]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sorting = sortModel
      .map(sm => {
        if (SORTABLE_COLUMNS.includes(sm.field)) {
          return {
            created_at: sm.sort,
          };
        } else {
          return null;
        }
      })
      .filter(sm => sm !== null) as Array<ProblemScopesOrderBy>;
    setOrderBy(sorting);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStr(searchStr);
    }, DEBOUNCED_TIME);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStr]);

  const where = useMemo((): InputMaybe<ProblemScopesBoolExp> => {
    const iLikeSearchStr = `%${debouncedSearchStr}%`;
    const searchStrWhereQuery: InputMaybe<ProblemScopesBoolExp> = {
      _or: [
        {
          title: {
            _ilike: iLikeSearchStr,
          },
        },
        {
          problem: {
            _ilike: iLikeSearchStr,
          },
        },
        {
          startup_lists: {
            title: {
              _ilike: iLikeSearchStr,
            },
          },
        },
        {
          projects: {
            title: {
              _ilike: iLikeSearchStr,
            },
          },
        },
      ],
    };

    return {
      ...(debouncedSearchStr ? searchStrWhereQuery : {}),
    };
  }, [debouncedSearchStr]);

  const {
    data: painPointsData,
    loading: painPointsLoading,
    previousData: previousPainPointsData,
    error: getAllPainPointsError,
  } = useGetPainPointsQuery({
    variables: {
      where,
      orderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const allPainPoints = useMemo(
    () =>
      painPointsData?.problem_scopes ||
      previousPainPointsData?.problem_scopes ||
      [],
    [painPointsData?.problem_scopes, previousPainPointsData?.problem_scopes, ,],
  );

  const error = getAllPainPointsError;

  if (error) {
    captureException(error);
    return <Page500 embedded />;
  }

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
    setPageSize(paginationModel.pageSize);
    setSearchParams({
      [SEARCH_PARAMS.page]: `${paginationModel.page}`,
      [SEARCH_PARAMS.tab]: 'pain-points' as LibraryTabType,
    });
  };

  return (
    <Card sx={{ marginTop: 2, marginBottom: 2 }}>
      <StyledDataGrid
        showCellVerticalBorder
        showColumnVerticalBorder
        getRowHeight={() => 'auto'}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[20, 50, 200]}
        isRowClickable={true}
        rows={allPainPoints}
        onSortModelChange={handleSortModelChange}
        columns={getDataGridColumns()}
        pagination
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: NoRowsOverlay,
        }}
        slotProps={{
          toolbar: { searchStr, setSearchStr },
          noRowsOverlay: { searchStr },
        }}
        autoHeight={allPainPoints.length > 0}
        sx={allPainPoints.length === 0 ? { height: 600 } : undefined}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={newSelectionModel =>
          setSelectionModel(newSelectionModel)
        }
        sortingMode='server'
        disableRowSelectionOnClick
        loading={painPointsLoading}
        keepNonExistentRowsSelected
        initialState={{
          columns: {
            columnVisibilityModel: { id: false },
          },
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        onRowClick={(data, event) => {
          if (event.metaKey || event.ctrlKey) {
            window.open(
              `${window.location.origin}${PATH_ROOT.painPoints.details(
                parseInt(data.id as string),
              )}`,
              '_blank',
            );
            return;
          }

          return navigate(
            PATH_ROOT.painPoints.details(parseInt(data.id as string)),
          );
        }}
      />
    </Card>
  );
}

const CustomToolbar = ({ searchStr, setSearchStr }: ToolbarPropsOverrides) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      sx={{
        padding: 1.5,
        width: '100%',
        backgroundColor: theme.palette.background.neutral,
      }}
      justifyContent='space-between'
    >
      <Box>
        <StandardSearchBar
          placeholder='Search by problem or pain point title'
          onQueryChange={setSearchStr}
          query={searchStr}
          sx={{
            maxWidth: 500,
            minWidth: 380,
          }}
        />
      </Box>
    </Stack>
  );
};

const NoRowsOverlay = ({ searchStr }: NoRowsOverlayPropsOverrides) => (
  <Box sx={{ flexGrow: 1, height: 320 }}>
    <EmptyContent
      title={
        searchStr
          ? `No results found matching "${searchStr}"`
          : 'There are no problem scopes yet'
      }
      img='/static/illustrations/illustration_empty_results.svg'
      sx={{ flexGrow: 1, height: 'auto' }}
    />
  </Box>
);

const getDataGridColumns = () => {
  const COLUMNS: GridColDef<DecoratedPainPoint>[] = [
    { field: 'id', filterable: false, sortable: false },
    {
      field: 'title',
      headerName: 'Title',
      filterable: false,
      sortable: false,
      minWidth: 400,
      flex: 1,
      renderCell: params => {
        return (
          <Stack
            direction='row'
            gap={0.5}
            alignItems='center'
            marginY={1.5}
            alignSelf='flex-start'
          >
            <ListItemIconStyle
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& svg, & path': { fill: NAV_TEXT_GREY },
                alignSelf: 'flex-start',
              }}
            >
              <BreakingNews />
            </ListItemIconStyle>
            <LinkedEntityTitleTypography title={params.value ?? ''} />
          </Stack>
        );
      },
    },
    {
      field: 'projects',
      headerName: 'Linked to',
      minWidth: 300,
      flex: 1,
      renderCell: params => {
        const projects = params.row.projects as DecoratedPainPoint['projects'];
        const startupLists = params.row
          .startup_lists as DecoratedPainPoint['startup_lists'];

        return (
          <Stack
            overflow={'hidden'}
            marginY={1}
            whiteSpace='initial'
            onClick={e => e.stopPropagation()}
          >
            <LinkedToEntity
              hideHeader
              showOnlyTitle
              linkedEntitiesData={{
                projects: projects || [],
                startupLists: startupLists || [],
                challenges: [],
              }}
            />
          </Stack>
        );
      },
      sortable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Last updated',
      minWidth: 200,
      flex: 1,
      valueFormatter: value => (value ? formatDate(value) : '-'),
    },
    {
      field: 'created_at',
      headerName: 'Created',
      minWidth: 200,
      flex: 1,
      valueFormatter: value => (value ? formatDate(value) : '-'),
    },
    {
      field: 'created_by',
      headerName: 'Created by',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => {
        return <CreatedByGridCell value={value} />;
      },
    },
  ];
  return COLUMNS;
};
