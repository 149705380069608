import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { captureException } from '@sentry/react';
import {
  GetLabeledProjectNotesDocument,
  Suppliers,
  useDeleteFileMutation,
} from 'apollo/generated/sdkShared';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';
import { LinkedToEntity } from '../StartupInfoSidePanel/LinkedToEntity';
import {
  DOCUMENT_TYPE_OPTIONS,
  StartupForDocumentColumns,
  StartupListDocument,
} from './DocumentsTab';

export const parseBooleanToString = (value: boolean) => (value ? 'Yes' : 'No');

type BaseProps = {
  startups: StartupForDocumentColumns[];
};

type Props = BaseProps & {
  projectId?: number;
  startupId?: number;
};

function useGetDocumentColumns({ startups, projectId, startupId }: Props) {
  const { palette } = useTheme();
  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'id' },
      {
        field: 'project',
        headerName: 'Project',
        flex: 1.5,
        minWidth: 300,
        renderCell: (params: GridCellParams) => {
          const project = params?.row?.project;
          const path = buildProjectDetailsPath({
            id: project?.id,
            stage: project?.stage,
          });

          if (!project?.id) return <>N/A</>;

          return (
            <Stack marginTop='0.75rem'>
              <Box
                component={RouterLink}
                to={path}
                onClick={e => {
                  e.stopPropagation();
                  return;
                }}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <LinkedToEntity
                  hideHeader
                  showOnlyTitle
                  suppressNavigation
                  linkedEntitiesData={{
                    projects: [
                      { ...project, lead_maturity: project?.lead_maturity },
                    ],
                    startupLists: [],
                    challenges: [],
                  }}
                />
              </Box>
            </Stack>
          );
        },
      },
      {
        field: 'document_type',
        headerName: 'Document Type',
        flex: 1.5,
        type: 'singleSelect',
        valueOptions: DOCUMENT_TYPE_OPTIONS as unknown as string[],
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              target='_blank'
              style={{ textDecoration: 'none', color: palette.secondary.main }}
              href={params.row.attachment_url}
            >
              {params.row.document_type}
            </Link>
          );
        },
        editable: true,
      },
      {
        field: 'startup_name',
        headerName: 'Startup',
        type: 'singleSelect',
        flex: 1,
        valueOptions: startups.map(option => option.name),
        renderCell: (params: GridCellParams) => {
          const startup = params.row.startup as Suppliers | null;
          if (!startup) return <></>;

          return (
            <Box
              component='span'
              sx={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <BaseStartupAvatar
                startup={{
                  logo_url: startup.logo_url,
                  name: startup.name,
                  domain: startup.domain,
                }}
                size='small'
                sx={{ marginRight: 1 }}
              />
              {startup.name}
            </Box>
          );
        },
        editable: true,
      },
      {
        field: 'comment',
        headerName: 'Comment',
        flex: 2,
        editable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (params: GridRowParams) => [
          <RenderActionsCell
            key={projectId || startupId}
            params={params}
            projectId={projectId}
          />,
        ],
      },
    ] satisfies GridColDef[];
  }, [palette.secondary.main, projectId, startups, startupId]);

  return { columns };
}

type RenderActionsCellProps = {
  params: GridRowParams<StartupListDocument>;
} & {
  projectId?: number;
};

function RenderActionsCell({ params, projectId }: RenderActionsCellProps) {
  const id = params.id; // generated by getRowId

  const apiRef = useGridApiContext();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const isInEditMode = apiRef.current.getRowMode(id) === 'edit';
  const { document_type } = apiRef.current.getRow(id);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteFile] = useDeleteFileMutation();

  const handleEditClick = (id: GridRowId) => () => {
    apiRef.current.startRowEditMode({ id });
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    apiRef.current.stopRowEditMode({ id });
  };

  const handleDeleteClick = () => async () => {
    try {
      await deleteFile({
        variables: {
          fileId: params.row.file_id!,
        },
        refetchQueries: [
          {
            query: GetLabeledProjectNotesDocument,
            variables: { projectId },
          },
        ],

        update: (cache, { data }) => {
          if (!data) return;

          cache.evict({
            id: cache.identify({
              __typename: 'project_startup_files',
              id: params.row.id,
            }),
          });

          cache.gc();

          cache.modify({
            fields: {
              [`project_startup_files_aggregate({"where":{"project_id":{"_eq":${projectId}}}})`](
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                existingAggregateRefs: any = {},
              ) {
                if (!existingAggregateRefs.aggregate) return;

                const newCount = existingAggregateRefs.aggregate.count - 1;

                return {
                  ...existingAggregateRefs,
                  aggregate: {
                    ...existingAggregateRefs.aggregate,
                    count: newCount,
                  },
                };
              },
            },
            id: `ROOT_QUERY`,
          });
        },
      });

      enqueueSnackbar('Document successfully deleted', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
      captureException(error);
      enqueueSnackbar('Error deleting document', {
        variant: 'error',
      });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    apiRef.current.stopRowEditMode({
      id,
      ignoreModifications: true,
    });
  };

  if (isInEditMode) {
    return [
      <GridActionsCellItem
        key={`${id}-save`}
        aria-placeholder={'Save'}
        icon={<SaveIcon />}
        label='Save'
        onClick={handleSaveClick(id)}
      />,

      <GridActionsCellItem
        key={`${id}-cancel`}
        aria-placeholder={'Cancel'}
        icon={<CancelIcon />}
        label='Cancel'
        className='textPrimary'
        onClick={handleCancelClick(id)}
        color='inherit'
      />,
    ];
  } else {
    return [
      <GridActionsCellItem
        key={`${id}-edit`}
        aria-placeholder='Edit'
        icon={<EditIcon />}
        label='Edit'
        className='textPrimary'
        onClick={handleEditClick(id)}
        color='inherit'
      />,
      <>
        <GridActionsCellItem
          aria-placeholder={'Delete'}
          icon={<DeleteIcon />}
          label='Delete'
          onClick={() => setOpenDeleteModal(true)}
          color='inherit'
        />
        {openDeleteModal && (
          <ConfirmDeleteDocumentModal
            title={document_type!}
            onConfirm={handleDeleteClick()}
            onHide={() => setOpenDeleteModal(false)}
          />
        )}
      </>,
    ];
  }
}

const ConfirmDeleteDocumentModal = ({
  title,
  onConfirm,
  onHide,
}: {
  title: string;
  onConfirm: () => void;
  onHide: () => void;
}) => {
  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography sx={{ mb: 1 }}>
          Are you sure you want to delete this document?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant='contained'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { useGetDocumentColumns };
