import { BaseActionButton } from 'components/base/BaseActionButton';
import { useTheme } from '@mui/material';
interface ShowMoreProps {
  showMore: boolean;
  setShowMore: (showMore: boolean) => void;
}

export const ShowMore = ({ showMore, setShowMore }: ShowMoreProps) => {
  const { palette } = useTheme();
  return (
    <BaseActionButton
      sx={{ marginTop: 1, color: palette.primary.main }}
      onClick={() => setShowMore(!showMore)}
      title={`Show ${showMore ? 'less' : 'more'}`}
    />
  );
};
