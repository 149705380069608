import { FiberNew, GridView, RocketLaunch } from '@mui/icons-material';
import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Projects,
  SourcingOrders,
  StartupLists,
} from 'apollo/generated/sdkInnovationManager';
import { FALLBACK_IMAGE } from 'components/AvatarGroup';
import EmptyContent from 'components/EmptyContent';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { varFadeIn } from 'components/animate';
import { useSettingsContext } from 'contexts/SettingsContext';
import { differenceInDays } from 'date-fns';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { SharedUser } from 'types/shared';
import { BaseSkeletonSectionsWithGrid } from '../../../base/BaseSkeletonSectionsWithGrid';
import { HomepageSubTitle } from '../HomepageSubTitle';
import { GetPersonDiscoveryItemsQuery } from 'apollo/generated/sdkShared';
import { Button } from '@mui/material';
import { CARD_COVER_HEIGHT } from 'pages/dashboard/constants';

interface Props extends BoxProps {
  title?: string;
  loading: boolean;
  showEmptyState: boolean;
  categorizedThemes: {
    title: string;
    items: Array<
      GetPersonDiscoveryItemsQuery['person_innovation_themes'][0]['startup_list_themes'][0]['startup_list']
    >;
  }[];
  setModalOpen: (open: boolean) => void;
}

export const discoveryGridItemConfig = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  customL: 4,
  xl: 3,
  xxl: 2,
};
export const discoveryItemStyles = {
  ...discoveryGridItemConfig,
  marginBottom: 1,
};

const badgeSize = 17;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    transition: 'all 0.3s ease-in-out',
    fontSize: 10,
    width: badgeSize,
    height: badgeSize,
    top: badgeSize,
    background: alpha(theme.palette.grey[300], 0.9),
    padding: '0 4px',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type DiscoveryItemWrapperProps = Pick<
  StartupLists,
  | 'id'
  | 'title'
  | 'discovery_cover_image_url'
  | 'public_uuid'
  | 'source'
  | 'result_type'
  | 'created_at'
> & {
  project?: Pick<Projects, 'id' | 'source' | 'stage' | 'title'> | null;
  sourcing_order?:
    | (Pick<SourcingOrders, 'id' | 'due_date' | 'delivered_date' | 'status'> & {
        created_by?: SharedUser | null;
      })
    | null;
  project_categories: {
    categorized_suppliers_aggregate: {
      aggregate?: {
        count: number;
      } | null;
    };
  }[];
};

export const DiscoveryItemWrapper = ({
  item,
  title,
}: {
  item: DiscoveryItemWrapperProps;
  title?: string;
}) => {
  return (
    <Grid
      item
      {...discoveryItemStyles}
      key={item.id}
      component={motion.div}
      {...varFadeIn}
    >
      <RouterLink
        key={item.id}
        to={PATH_ROOT.lists.details(item.id, true)}
        style={{ textDecoration: 'none' }}
        onClick={() =>
          captureAnalyticsEvent('Open Discovery Item', {
            title: title as string,
          })
        }
      >
        <DiscoveryItem key={item.id} item={item} />
      </RouterLink>
    </Grid>
  );
};

const DiscoverySection = ({
  loading,
  categorizedThemes: themes,
  showEmptyState,
  setModalOpen,
}: Props) => {
  if (showEmptyState) {
    return (
      <>
        <EmptyContent
          title='Select interests to get recommendations.'
          sx={{ paddingBottom: 0 }}
        />
        <Grid container justifyContent='center'>
          <Button
            variant='contained'
            onClick={() => {
              console.log('add logic here');
              setModalOpen(true);
            }}
          >
            Personalize
          </Button>
        </Grid>
      </>
    );
  }

  if (loading) {
    return (
      <AnimatePresence>
        <Box marginBottom={10} component={motion.div} {...varFadeIn}>
          <BaseSkeletonSectionsWithGrid
            rowsPerSection={2}
            layoutProps={{ spacing: 5 }}
          />
        </Box>
      </AnimatePresence>
    );
  }

  return (
    <Stack>
      <AnimatePresence>
        {themes.map(({ items: themeItems, title }) => {
          return (
            <Box key={title} component={motion.div} {...varFadeIn}>
              <HomepageSubTitle title={title} />
              <Divider sx={{ marginBottom: 2 }} />
              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                sx={{ marginBottom: 5 }}
              >
                {themeItems.map(i => (
                  <DiscoveryItemWrapper
                    key={`${i.id}-${i.title}`}
                    item={i}
                    title={title}
                  />
                ))}
              </Grid>
            </Box>
          );
        })}
      </AnimatePresence>
    </Stack>
  );
};

// ----------------------------------------------------------------------

type DiscoveryItemType = Pick<
  StartupLists,
  | 'title'
  | 'discovery_cover_image_url'
  | 'public_uuid'
  | 'id'
  | 'source'
  | 'result_type'
  | 'created_at'
> & {
  project_categories: {
    categorized_suppliers_aggregate: {
      aggregate?: {
        count: number;
      } | null;
    };
  }[];
};

type DiscoveryItemProps = {
  item: DiscoveryItemType;
};

export const DiscoveryItem = ({ item }: DiscoveryItemProps) => {
  const {
    loading,
    settings: { DISCOVERY_ITEM_NEW_LABEL_EXPIRY_DAYS },
  } = useSettingsContext();
  const { discovery_cover_image_url, title, project_categories, created_at } =
    item;
  const showNewLabel = useMemo(
    () =>
      !loading &&
      differenceInDays(new Date(), new Date(created_at)) <
        Number(DISCOVERY_ITEM_NEW_LABEL_EXPIRY_DAYS),
    [loading, created_at, DISCOVERY_ITEM_NEW_LABEL_EXPIRY_DAYS],
  );
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [imageLoaded, setImageLoaded] = useState(false);

  // Used only for benchmark discovery items
  const numberOfStartupToDiscovery = useMemo(() => {
    let numberOfStartups =
      project_categories[0]?.categorized_suppliers_aggregate?.aggregate
        ?.count || 0;
    if (project_categories.length < 1) {
      return numberOfStartups;
    }
    for (let i = 1; i < project_categories.length; i++) {
      numberOfStartups +=
        project_categories[i]?.categorized_suppliers_aggregate?.aggregate
          ?.count || 0;
    }
    return numberOfStartups;
  }, [project_categories]);

  const numberOfCategoriesToDiscovery = project_categories?.length;

  const theme = useTheme();

  return (
    <Card
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.spacing(1),
        height: 237,
        ':hover': {
          transform: 'scale(1.01) perspective(0px)',
          boxShadow: `0 7px 10px ${theme.palette.grey[400]}`,
          '& img': { scale: '1.10' },
          '& .MuiBadge-badge': {
            background: alpha(theme.palette.primary.main, 0.9),
            color: theme.palette.primary.contrastText,
          },
          '.MuiCardContent-root': { paddingTop: theme.spacing(3) },
        },
      }}
    >
      <Box height={CARD_COVER_HEIGHT} position='relative'>
        {isInView && (
          <CardMedia
            component='img'
            sx={{
              transition: 'all 0.3s ease-in-out',
              height: CARD_COVER_HEIGHT,
              opacity: 0,
            }}
            image={discovery_cover_image_url || FALLBACK_IMAGE}
            height='100%'
            onLoad={(e: SyntheticEvent<HTMLImageElement>) => {
              setImageLoaded(true);
              e.currentTarget.style.opacity = '1';
            }}
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = FALLBACK_IMAGE;
              e.currentTarget.style.opacity = '1';
            }}
          />
        )}
        {(!isInView || !imageLoaded) && (
          <Skeleton
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              transform: 'scale(1) perspective(0px)',
              borderRadius: 0,
            }}
            animation='wave'
          />
        )}
      </Box>
      <CardContent
        sx={{
          transition: 'all 0.3s ease-in-out',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: theme.spacing(2),
          background: theme.palette.grey[200],
        }}
      >
        <TypographyWithEllipsis
          tooltipTitle={<Typography variant='body2'>{title}</Typography>}
          numberOfLines={2}
          variant='subtitle1'
          fontWeight={400}
          sx={{
            verticalAlign: 'center',
            marginTop: 0,
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {title}
        </TypographyWithEllipsis>
      </CardContent>
      <Stack
        direction='row'
        spacing={1}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          paddingX: theme.spacing(1),
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          borderBottomRightRadius: theme.spacing(1),
        }}
      >
        <Tooltip title='Startups'>
          <IconButton size='small'>
            <StyledBadge
              badgeContent={numberOfStartupToDiscovery}
              color='secondary'
            >
              <RocketLaunch />
            </StyledBadge>
          </IconButton>
        </Tooltip>
        {numberOfCategoriesToDiscovery > 1 && (
          <Tooltip title='Categories'>
            <IconButton size='small'>
              <StyledBadge
                badgeContent={numberOfCategoriesToDiscovery}
                color='secondary'
              >
                <GridView />
              </StyledBadge>
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {showNewLabel && (
        <Stack
          direction='row'
          spacing={1}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            paddingX: theme.spacing(1),
            paddingY: theme.spacing(0.6),
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            borderBottomLeftRadius: theme.spacing(1),
          }}
        >
          <FiberNew color='primary' />
        </Stack>
      )}
    </Card>
  );
};

export default DiscoverySection;
