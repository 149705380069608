import { Add, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  DataGridPro,
  FooterPropsOverrides,
  GridColDef,
} from '@mui/x-data-grid-pro';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
  StartupsTableContext,
} from './StartupsTableContext';
import { useGetRows } from './useGetRows';

import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import { CustomersField } from 'components/startupsPage/CustomersField';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useGetMatrixSettingsQuery } from 'apollo/generated/sdkInnovationManager';
import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useMatch } from 'react-router';
import { MoveStartupActions } from '../MoveStartupActions';
import { MemoizedNameColumn } from './NameColumn';
import { NewStartupEmptyRowGrid } from './NewStartupEmptyRowGrid';
import { LonglistSettings } from './Settings';
import { StartupCorporateCustomer } from './useGetColumns';

export const useGetLongListColumns = ({
  isPublicPage,
  isDiscovery,
  hasCustomers,
  onRemoveStartup,
  onAddToListClick,
}: {
  isPublicPage: boolean;
  isDiscovery: boolean;
  hasCustomers: boolean;
  onRemoveStartup?: (startup: DecoratedStartup) => void;
  onAddToListClick: (startup: DecoratedStartup) => void;
}) => {
  const { row, getActionsColumn, pinnedRightColumn } = useRightTableActions();
  // TODO: refactor this to use dynamic project lead path
  const isExternalLeadScopingPage = !!useMatch(
    '/o/default/list-scope/:startupListPublicUUID',
  );

  const {
    currentStartupList: { id },
  } = useContext(StartupsTableContext)!;

  const { data } = useGetMatrixSettingsQuery({
    variables: { startupListId: id },
  });

  const isEditable = !isDiscovery && !isPublicPage;

  const columns = useMemo(() => {
    const localCols = [
      {
        field: 'name',
        headerName: 'Position, company',
        minWidth: 290,
        renderCell: (
          params: GridRenderCellParams<DecoratedStartup, string>,
        ) => (
          <MemoizedNameColumn
            {...params.row}
            isRankEnabled={
              data && data.list_matrix_settings.length > 0
                ? data.list_matrix_settings[0].rank_enabled
                : true
            }
            isLongList={false}
            isPublicPage={isPublicPage}
          />
        ),
        disableColumnMenu: true,
        sortable: false,
        hideable: false,
      },
      {
        field: 'short_description',
        headerName: 'Description',
        minWidth: 350,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<DecoratedStartup, string>,
        ) => {
          const value = params.value || '-';

          return (
            <ExpandedGridCell
              value={value}
              multiLineElipsis={true}
              numberOfLines={2}
            />
          );
        },
        disableColumnMenu: true,
        sortable: false,
      },
      isEditable
        ? getActionsColumn({
            minWidth: 170,
            renderCellNode: (params: GridCellParams<DecoratedStartup>) => (
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                width='100%'
              >
                {!isExternalLeadScopingPage && (
                  <BaseAddStartupToListMenu
                    selectedStartups={[params.row]}
                    onCreate={() => {
                      onAddToListClick(params.row);
                    }}
                    analyticsSource='STARTUP_LIST'
                  />
                )}
                <MoveStartupActions
                  id={params.id}
                  startup={params.row}
                  onRemoveStartup={onRemoveStartup}
                />
              </Stack>
            ),
          })
        : null,
    ] as GridColDef<DecoratedStartup>[];

    if (hasCustomers) {
      localCols.splice(1, 0, {
        field: 'startup_corporate_customers',
        headerName: 'Customers',
        minWidth: 180,
        align: 'center',
        renderCell: (
          params: GridRenderCellParams<
            DecoratedStartup,
            StartupCorporateCustomer[]
          >,
        ) => {
          if (!params.value?.length) return '-';

          return (
            <CustomersField
              referencedCustomers={params.value.map(
                pv => pv.corporate_customer,
              )}
              keyHelper={params.row.domain as string}
            />
          );
        },
        disableColumnMenu: true,
        sortable: false,
        hideable: true,
      });
    }

    return localCols.filter(Boolean);
  }, [
    data,
    getActionsColumn,
    hasCustomers,
    isEditable,
    onAddToListClick,
    onRemoveStartup,
    isExternalLeadScopingPage,
    isPublicPage,
  ]);

  return { columns, row, pinnedRightColumn };
};

export const NewStartupRowToggleButton = ({
  rowsForAddNew,
  setRowsForAddNew,
  shouldShowAddStartupRow,
  order,
  startupListId,
  categoryId,
  shortlist,
}: {
  rowsForAddNew: DecoratedStartup[];
  setRowsForAddNew: React.Dispatch<React.SetStateAction<DecoratedStartup[]>>;
  shouldShowAddStartupRow: boolean;
  order: number;
  startupListId: number;
  categoryId: number;
  shortlist: CategorizedStartupForMatrix[];
}) => {
  const [currentOrder, setCurrentOrder] = useState(order);

  useEffect(() => {
    setCurrentOrder(order);
    if (rowsForAddNew.length > 0) {
      setRowsForAddNew(prev => prev.map(row => ({ ...row, order })));
    }
  }, [order, rowsForAddNew.length, setRowsForAddNew]);

  const newStartupRow = [
    {
      id: -1,
      domain: '',
      name: '',
      startup_corporate_customers: [],
      categorized_startup_id: -1,
      voters: [],
      is_shortlisted: false,
      is_in_matrix: true,
      custom_dimensions_json_v1: {},
      is_recommended: false,
      all_startups_table_score: 0,
      matrix_table_score: 0,
      order: currentOrder,
      project_category_id: 0,
      startup_list_id: 0,
    },
  ];

  return (
    <>
      {(rowsForAddNew ?? []).length > 0 && (
        <Box
          {...(order !== 1 && {
            borderTop: '1px solid rgba(241, 243, 244, 1)',
          })}
        >
          <NewStartupEmptyRowGrid
            rows={rowsForAddNew}
            onRemoveStartupRow={() => setRowsForAddNew([])}
            startupListId={startupListId}
            categoryId={categoryId}
            shortlist={shortlist}
          />
        </Box>
      )}

      {shouldShowAddStartupRow && (
        <Stack
          alignItems='flex-start'
          {...(shortlist.length === 0 && {
            borderTop: '1px solid rgba(241, 243, 244, 1)',
          })}
        >
          <Button
            variant='text'
            onClick={() => setRowsForAddNew(newStartupRow)}
            disabled={(rowsForAddNew ?? []).length > 0}
            startIcon={<Add />}
            color='secondaryCTA'
            sx={{
              margin: 0,
              width: '100%',
              borderRadius: '0 !important',
              justifyContent: 'flex-start',
              padding: 2,
              paddingLeft: 2.75,
              borderTop: ({ palette }) => palette.grey[300] + `0.5px solid`,
              fontWeight: 'normal',
            }}
          >
            ADD NEW
          </Button>
        </Stack>
      )}
    </>
  );
};

const Footer = ({
  items,
  isDiscovery,
  isPublicPage,
  shortlist,
  onRemoveStartup,
  rowsForAddNew,
  setRowsForAddNew,
  startupListId,
  categoryId,
  shouldShowAddStartupRow,
}: FooterPropsOverrides) => {
  const [isExpanded, setIsExpanded] = useState(shortlist.length === 0);
  const { palette } = useTheme();
  const { isSharedPage } = useSharedPagesContext();

  const hasCustomers = useMemo(
    () => items.some(cs => cs.startup.startup_corporate_customers?.length),
    [items],
  );

  const { columns, row, pinnedRightColumn } = useGetLongListColumns({
    isPublicPage: !!isPublicPage,
    isDiscovery,
    hasCustomers,
    onRemoveStartup,
    onAddToListClick: () => {},
  });

  const rows = useGetRows({ categorizedStartups: items, filterMatrix: false });
  const shortlistRows = useGetRows({ categorizedStartups: shortlist });
  const nextOrder = useMemo(
    () => shortlistRows.length + 1,
    [shortlistRows.length],
  );

  return (
    <Stack>
      <NewStartupRowToggleButton
        rowsForAddNew={rowsForAddNew}
        setRowsForAddNew={setRowsForAddNew}
        shouldShowAddStartupRow={shouldShowAddStartupRow}
        order={nextOrder}
        startupListId={startupListId}
        categoryId={categoryId}
        shortlist={shortlist}
      />
      {rows.length > 0 && (
        <>
          <Accordion
            sx={{ padding: 0 }}
            expanded={isExpanded}
            onChange={(_, expanded) => setIsExpanded(expanded)}
            disableGutters
          >
            <AccordionSummary
              sx={{
                margin: '0 !important',
                padding: '0 !important',
                minHeight: 'auto !important',
                background: palette.grey[200],
                '&:hover': { background: palette.grey[300] },
                '& .MuiAccordionSummary-content': {
                  padding: '0 !important',
                  margin: '0 !important',
                },
              }}
              aria-controls='panel-content'
              id='panel'
            >
              <Stack
                direction='row'
                alignItems='center'
                padding={[1, 2]}
                gap={1}
              >
                <ExpandMore
                  sx={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
                <Typography>Longlist ({rows.length})</Typography>
              </Stack>
              {!isDiscovery && isExpanded && !isSharedPage && (
                <LonglistSettings currentStartupListId={startupListId} />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <DataGridPro
                initialState={{
                  pinnedColumns: {
                    left: ['name'],
                    right: pinnedRightColumn,
                  },
                }}
                rows={rows}
                columns={columns}
                hideFooterRowCount
                hideFooterPagination
                slots={{ footer: () => null }}
                slotProps={{ row }}
                isRowSelectable={() => false}
                rowHeight={50}
                columnHeaderHeight={0}
                sx={{
                  '& .MuiDataGrid-row': { height: '50px !important' },
                  '& .MuiDataGrid-columnHeaders': {
                    display: 'none',
                  },
                }}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Stack>
  );
};

export { Footer };
