import { useGetImStakeholdersQuery } from 'apollo/generated/sdkShared';
import BaseServerFilterAutocomplete, {
  ServerFilterOption,
} from 'components/base/serverFilters/BaseServerFilterAutocomplete';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { filter, includes, some, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';

export const OwnerFilter = () => {
  const { data: collaborators, loading } = useGetImStakeholdersQuery();
  const { setFilters, filters } = useServerFiltersContext('listTableFilters');
  const currentFilter = filters.owner;
  const options = useMemo(() => {
    if (!collaborators) return [];
    if (!collaborators.startup_list_collaborators) return [];

    const filteredCollaborators = (
      collaborators.startup_list_collaborators || []
    ).map(collaborator => collaborator?.user?.person);

    return (uniqBy(filteredCollaborators, 'full_name') || []).map(person => ({
      value: person?.full_name,
      label: person?.full_name,
    }));
  }, [collaborators]);

  const value = useMemo(() => {
    const namesToFilter = currentFilter?.collaborators?.user?._or?.map(
      condition =>
        condition.person?.full_name?._ilike?.replace(/%/g, '')?.toLowerCase(),
    );

    // Filter the options based on whether their full_name matches any of the namesToFilter, ignoring case
    return filter(options, option => {
      return some(namesToFilter, nameToFilter =>
        includes(option?.value?.toLowerCase(), nameToFilter),
      );
    });
  }, [currentFilter, options]);

  const defaultValue = options?.filter(t => value.includes(t));

  const handleChange = useCallback(
    (inputValue: ServerFilterOption<string>[]) => {
      if (!inputValue.length) {
        setFilters('owner', {});
        return;
      }

      setFilters('owner', {
        collaborators: {
          role: { _in: ['owner', 'editor'] },
          user: {
            _or: inputValue.map(stakeholder => ({
              person: { full_name: { _ilike: `%${stakeholder.value}%` } },
            })),
          },
        },
      });
    },
    [setFilters],
  );

  return (
    <BaseServerFilterAutocomplete
      value={value}
      loading={loading}
      defaultValue={defaultValue}
      options={options}
      handleChange={values => handleChange(values)}
      placeholder='Owner'
      sxProps={{ minWidth: 130 }}
    />
  );
};
