export const USERCENTRICS_SETTINGS_ID = '6YAIyl_cYZQj88';
export const USERCENTRICS_EVENT_NAME = 'ucEvent';

// Define service IDs for your Usercentrics services
export enum ServiceId {
  Usercentrics = 'H1Vl5NidjWX',
  Sentry = 'rH1vNPCFR',
  PostHot = 'uRoG9JxhEUtI4V',
  Chatwoot = 'GUFjIB9gOaQ3IB',
}
