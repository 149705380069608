import { showSecondLayer, useIsInitialized } from '@s-group/react-usercentrics';

import { useTheme } from '@mui/material';
import { useHasUserInteracted } from '@s-group/react-usercentrics';
import { useEffect } from 'react';
import useAuth from './useAuth';

export const useCMP = () => {
  const hasUserInteracted = useHasUserInteracted();
  const isInitialized = useIsInitialized();
  const { isStaff } = useAuth();

  useEffect(() => {
    if (isStaff || hasUserInteracted || !isInitialized) {
      return;
    }

    showSecondLayer();
  }, [isInitialized, hasUserInteracted, isStaff]);

  return {
    isInitialized,
    hasUserInteracted,
    showSecondLayer,
  };
};

// used to inject styles to the usercentrics shadow root
const injectUsercentricsStyles = ({
  isInitialized,
  color,
}: {
  isInitialized: boolean;
  color: string;
}) => {
  if (!isInitialized) return;

  const interval = setInterval(() => {
    const shadowRoot = document.querySelector('#usercentrics-root')?.shadowRoot;

    if (shadowRoot) {
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        #uc-tab-list button[aria-selected="true"] {
          border-color: ${color} !important;
          color: ${color} !important;
      `;
      shadowRoot.appendChild(styleElement);
      clearInterval(interval); // Stop checking once injected
    }
  }, 500);
};

export const InjectUsercentricsStyles = () => {
  const isInitialized = useIsInitialized();
  const {
    palette: {
      primary: { light },
    },
  } = useTheme();

  useEffect(() => {
    injectUsercentricsStyles({ isInitialized, color: light });
  }, [isInitialized, light]);

  return null;
};
