import { Box, SxProps } from '@mui/material';

type Props = {
  variant?: 'dark' | 'light';
  size?: number;
  sxProps?: SxProps;
};

const GDLogo = ({ variant = 'dark', size, sxProps }: Props) => {
  const logoSRC =
    variant === 'dark'
      ? '/assets/logos/glassdollar-brandmark-dark.png'
      : '/assets/logos/glassdollar-brandmark-light.png';
  return (
    <Box
      component='img'
      sx={{ ...sxProps, marginX: 0.8 }}
      width={size}
      height={size}
      alt='GlassDollar Logo'
      src={logoSRC}
    />
  );
};

export { GDLogo };
