import { Clear } from '@mui/icons-material';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { BannerStyle } from './StyledBanner';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { captureAnalyticsEvent } from 'plugins/Analytics';

type BannerStatusType = 'yellow' | 'red' | null;

export const useTrialAccountBannerStatus = () => {
  const { trial_end_date, account_type } = useCurrentOrganizationFromContext();
  const [isDismissed, setIsDismissed] = useState(false);

  const { bannerStatus, remainingDays } = useMemo(() => {
    const bannerNotApplicable =
      account_type !== 'trial' || !trial_end_date || isDismissed;

    if (bannerNotApplicable) return { bannerStatus: null, remainingDays: null };

    const trialEnd = new Date(trial_end_date);
    const now = new Date();
    const diffDays = Math.ceil(
      (trialEnd.getTime() - now.getTime()) / (1000 * 60 * 60 * 24),
    );

    if (diffDays <= 0) return { bannerStatus: 'red', remainingDays: 0 };
    if (diffDays <= 10)
      return { bannerStatus: 'yellow', remainingDays: diffDays };

    return { bannerStatus: null, remainingDays: null };
  }, [account_type, trial_end_date, isDismissed]);

  return {
    bannerStatus,
    remainingDays,
    dismissBanner: () => setIsDismissed(true),
  };
};

export const TrialAccountBanner = () => {
  const { bannerStatus, remainingDays, dismissBanner } =
    useTrialAccountBannerStatus();
  const { palette, zIndex } = useTheme();

  const onConfirm = () => {
    // @ts-expect-error: TODO: FIXME
    window.$chatwoot.toggle();
    captureAnalyticsEvent('Trial Banner CTA Clicked', {
      bannerType: bannerStatus as BannerStatusType,
    });
  };

  if (!bannerStatus) return null;

  const backgroundColor =
    bannerStatus === 'yellow' ? palette.warning.main : palette.error.main;
  const message =
    bannerStatus === 'yellow'
      ? `Your trial expires in ${remainingDays} day${remainingDays !== 1 ? 's' : ''}. Contact us to explore your upgrade options!`
      : 'Action required: Your trial ended. Please contact us to avoid any service disruptions.';

  return (
    <BannerStyle
      sx={{
        background: backgroundColor,
        top: 0,
        left: 0,
        zIndex: zIndex.modal,
      }}
    >
      <Stack direction='row' gap={2} alignItems='center' flexGrow={1}>
        <Typography
          variant='body2'
          fontWeight='800'
          color={palette.common.white}
        >
          {message}
        </Typography>
      </Stack>
      <Stack direction='row' gap={2} alignItems='center'>
        <Button
          variant='outlined'
          sx={{
            borderColor: palette.common.white,
            color: palette.common.white,
          }}
          onClick={onConfirm}
        >
          Contact us
        </Button>
        <Button
          sx={{ color: palette.common.white, minWidth: '0 !important' }}
          onClick={dismissBanner}
        >
          <Clear fontSize='medium' />
        </Button>
      </Stack>
    </BannerStyle>
  );
};
