import { flatMap, omitBy } from 'lodash';
import ExcelJS from 'exceljs';
import { downloadFile } from 'utils/downloadFile';

import { CustomDimensionCellJSON, CustomFeatureHeader } from '../@types/shared';
import {
  CategorizedSuppliers,
  ProjectCategories,
  Suppliers,
} from 'apollo/generated/sdkShared';
import { sortAndFilterProjectCategories } from 'utils/projects';
import { orderByMatrixScore } from 'components/startupComparisonTable/utils/orderByMatrixScore';

type StartupForExport = Pick<
  Suppliers,
  | 'id'
  | 'name'
  | 'domain'
  | 'website'
  | 'linkedin_url'
  | 'crunchbase_url'
  | 'short_description'
  | 'long_description'
  | 'hq'
  | 'founded_year'
  | 'employees_count'
  | 'funding'
  | 'investors'
  | 'tags'
> & {
  startup_corporate_customers: {
    corporate_customer: Pick<Suppliers, 'domain'>;
  }[];
};

export type CategoryForCSVExport = Pick<
  ProjectCategories,
  'id' | 'title' | 'short_description' | 'is_hidden' | 'rank'
> & {
  custom_dimensions_schema: CustomFeatureHeader[];
  categorized_suppliers: (Pick<
    CategorizedSuppliers,
    | 'id'
    | 'is_archived'
    | 'is_in_matrix'
    | 'is_shortlisted'
    | 'is_selected'
    | 'matrix_table_score'
  > & {
    custom_dimensions_json_v1: Record<string, CustomDimensionCellJSON>;
    startup: StartupForExport;
  })[];
};

export const exportListToExcel = ({
  categories,
  listTitle,
}: {
  categories: CategoryForCSVExport[];
  listTitle: string;
}) => {
  let rows: object[];
  if (categories.length === 1) {
    rows = buildTableForBenchmark({
      categoryToExport: categories[0],
    });
  } else {
    rows = buildTableForLandscape({
      categoriesToExport: categories,
    });
  }

  exportAsExcel(rows, listTitle, categories.length ? 'Landscape' : 'Benchmark');
};

const exportAsExcel = (
  rows: object[],
  listTitle: string,
  sheetTitle: string,
) => {
  const workbook = new ExcelJS.Workbook();
  // sheet names are limited to 31 characters and cannot contain the following characters: * : / \ ? [ ]
  const worksheet = workbook.addWorksheet(sheetTitle);

  // add column headers

  const headers = Object.keys(rows[0]).map(header => {
    return {
      header,
      key: header,
      width: ['Description', 'Long Description'].includes(header) ? 60 : 30,
    };
  });

  worksheet.columns = headers;
  rows.forEach(row => {
    worksheet.addRow(row);
  });

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    downloadFile({
      content: blob,
      // Needed to replace dots with dashes to avoid issues with file extension
      title: listTitle.replace(/\./g, '-'),
      format: 'xlsx',
    });
  });
};

const buildTableForBenchmark = ({
  categoryToExport,
}: {
  categoryToExport: CategoryForCSVExport;
}) => {
  const categorizedStartups = categoryToExport.categorized_suppliers || [];

  const exportPayload = orderByMatrixScore(
    categorizedStartups.map(cs => ({
      categorized_startup_id: cs.id,
      ...cs,
    })),
  ).map(categorizedStartup => {
    return {
      'Startup Name': categorizedStartup.startup.name,
      ...extractCoreStartupColumns(categorizedStartup.startup!),
      ...categoryToExport.custom_dimensions_schema.reduce(
        (customFeaturesByName, categorySchema) => {
          const customFeatureCell = categorizedStartup
            .custom_dimensions_json_v1[categorySchema.name] || {
            value: null,
            reference: null,
          };

          return {
            ...customFeaturesByName,
            [categorySchema.name]: normalizeCustomFeatureValue(
              customFeatureCell.value,
              categorySchema.type,
            ),
            // We don't export the reference if it is empty for all rows.
            ...(customFeatureCell.reference && {
              [`${categorySchema.name} - References`]:
                customFeatureCell.reference,
            }),
          };
        },
        {},
      ),
      'Added to Comparison': categorizedStartup.is_in_matrix ? 'Yes' : 'No',
      Shortlisted: categorizedStartup.is_shortlisted ? 'Yes' : 'No',
      'Selected for PoC': categorizedStartup.is_selected ? 'Yes' : 'No',
    };
  });

  return exportPayload;
};

const buildTableForLandscape = ({
  categoriesToExport,
}: {
  categoriesToExport: CategoryForCSVExport[];
}) => {
  const categorizedStartupAndCategoryPairs = flatMap(
    sortAndFilterProjectCategories({
      categories: categoriesToExport,
      filterHidden: true,
    }),
    category =>
      orderByMatrixScore(
        category.categorized_suppliers.map(cs => ({
          categorized_startup_id: cs.id,
          ...cs,
        })),
      ).map(categorizedStartup => [categorizedStartup, category] as const),
  );

  const exportPayload = categorizedStartupAndCategoryPairs.map(
    ([categorizedStartup, category]) => {
      if (!categorizedStartup.startup)
        throw Error(
          `Missing organization startup for categorized startup ID:${categorizedStartup.id}`,
        );

      return {
        'Startup Name': categorizedStartup.startup.name,
        'Category Title': category.title,
        'Category Description': category.short_description?.replace(
          /<[^>]*>/g,
          '',
        ),
        ...extractCoreStartupColumns(categorizedStartup.startup),
        Shortlisted: categorizedStartup.is_shortlisted ? 'Yes' : 'No',
        'Selected for PoC': categorizedStartup.is_selected ? 'Yes' : 'No',
      };
    },
  );

  return exportPayload;
};

const normalizeCustomFeatureValue = (
  value: string | number | null,
  valueType: 'text' | 'tribool' | 'line' | 'number',
) => {
  if (valueType === 'text' || valueType === 'number') {
    return value;
  } else if (valueType === 'tribool') {
    return value === 'yes' ? 'Yes' : value === 'no' ? 'No' : 'N/A';
  } else if (valueType === 'line') {
    return value ? JSON.parse(value as string) : [];
  } else {
    throw Error(`Unknown value type: ${valueType}`);
  }
};

const extractCoreStartupColumns = (startup: StartupForExport) => {
  // We omit null values so then we can exclude all columns with null values
  return omitBy(
    {
      Domain: startup.domain,
      'Website URL': startup.website,
      'Linkedin URL': startup.linkedin_url,
      'Crunchbase URL': startup.crunchbase_url,
      Description: startup.short_description,
      'Long Description': startup.long_description,
      Location: startup.hq,
      'Founded Year': startup.founded_year,
      'Team Size': startup.employees_count,
      'Total Funding ($)': startup.funding,
      Customers:
        startup.startup_corporate_customers
          ?.map(scc => scc.corporate_customer?.domain)
          .join(',') || null,
      Investors: startup.investors?.join(',') || null,
      Tags: startup.tags?.join(',') || null,
    },
    value => value === null,
  );
};
