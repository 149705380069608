import { z } from 'zod';

export const scopeSectionTwoSchema = () => {
  return z.object({
    mustHaveFeatures: z
      .array(z.string().nonempty('Feature cannot be empty'))
      .nonempty('Must have at least one feature'),
    niceToHaveFeatures: z.array(z.string()),
    knownSolutions: z.array(z.string()),
    additionalInformation: z.string(),
    supportingDocuments: z.array(z.string()),
  });
};
