import {
  Add,
  AutoAwesome,
  Link as LinkIcon,
  LowPriority,
  OpenInNew,
  PersonAdd as PersonAddIcon,
  ThumbDown,
  ThumbUp,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Link,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { captureMessage } from '@sentry/react';
import {
  GetDataForSidePanelDocument,
  GetDataForSidePanelQuery,
  Projects,
  StartupContacts,
  StartupCorporateCustomers,
  StartupLists,
  Suppliers,
  useGetDataForSidePanelQuery,
  useGetEntitiesLinkedToStartupQuery,
  useGetOrganizationStartupContactCountQuery,
  useGetStartupDocumentsCountQuery,
  useGetStartupNotesQuery,
} from 'apollo/generated/sdkShared';
import MoreMenu from 'components/MoreMenu';
import BaseCopyToClipboard from 'components/base/BaseCopyToClipboard';
import { useEnrichmentProgressPool } from 'components/enrichmentProgress/StartupEnrichmentProgressContext';
import { FullscreenContext } from 'contexts/FullscreenContext';
import {
  StartupListForStartupProfile,
  useStartupSidePanel,
} from 'contexts/StartupSidePanelContext';
import { FormikProvider } from 'formik';
import useCaptureViewStartupEvent from 'hooks/analytics/useCaptureViewStartupEvent';
import useAuth from 'hooks/useAuth';
import useBreakpoints from 'hooks/useBreakpoints';
import useSearchSimilarActions from 'hooks/useSearchSimilarActions';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { Dictionary, keyBy } from 'lodash';
import Page404 from 'pages/Page404';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  buildAdminPlatformDomain,
  buildOrganizationPortalStartupProfileUrl,
  isOrganizationPortalURL,
} from 'utils/url';
import { CachedWebsiteImage, StartupConnection } from '../../../@types/shared';
import { IamInterestedButton } from '../startupList/result/IamInterestedButton';
import CopyClaimProfileLinkMenuItem from './CopyStartupPitchFormLinkMenuItem';
import { StartupHeader } from './StartupHeader';
import {
  CategorizedStartupForInfoNavigator,
  StartupInfoNavigator,
} from './StartupInfoNavigator';
import { StartupInfoSidePanelContacts } from './StartupInfoSidePanelContacts';
import { StartupInfoSidePanelGeneral } from './StartupInfoSidePanelGeneral';
import { StartupInfoSidePanelNotes } from './StartupInfoSidePanelNotes';
import { StartupPitchDetailsTab } from './pitch/StartupPitchDetailsTab';

import {
  ProjectStartupFiles,
  useEnrichStartupAsyncMutation,
  useGetCategorizedStartupsForProfileNavigationQuery,
  useUpdateCategorizedStartupRecommendedStatusMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useOrganizationPortalContext } from 'components/OrganizationPortal/contexts/OrganizationPortalContext';
import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { AddContactModal } from 'components/organizationStartupContacts/AddContactModal';
import { CreateLeadButton } from 'components/projectLeads/CreateLeadButton';
import { LIMITED_ENRICHMENT } from 'config';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { Navigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import DocumentsTab from '../projects/DocumentsTab';
import { OutreachModal } from '../startupConnections/OutreachModal';
import { useToggleInactiveLabel } from '../startupHealthCheck/useToggleInactiveLabel';
import EditSupplierIdMenuItem from './EditSupplierIdMenuItem';
import { LinkedEntitiesType, LinkedToEntity } from './LinkedToEntity';
import { ExportStartupProfilePDFMenuItem } from './export/ExportStartupProfilePDFMenuItem';
import {
  CorporateCustomer,
  getFirstPartyDataFromOrganizationStartup,
  getInitialValuesFromOrganizationStartups,
  useStartupDetailsFormik,
} from './useStartupDetailsFormik';

export type ProjectFilesType = Pick<
  ProjectStartupFiles,
  'id' | 'comment' | 'document_type' | 'file_id' | 'project' | 'file'
>;

export type StartupContact = Pick<
  StartupContacts,
  | 'id'
  | 'email'
  | 'name'
  | 'linkedin_url'
  | 'job_title'
  | 'source'
  | 'is_primary_contact'
>;

export type ListedInStartupLists = Pick<
  StartupLists,
  'id' | 'title' | 'source'
> & {
  project?: Pick<Projects, 'id' | 'stage' | 'title'> | null;
};

// TODO: Stop redefining types, all the components used in the SidePanel depend on this query
// let's then just use types derived from this query. For this:
// - The query should not be used in other places
// - If a component needs to be use in other places like a base component it needs to defined its own types
export type StartupForSidePanel = Pick<
  Suppliers,
  | 'id'
  | 'domain'
  | 'name'
  | 'website'
  | 'linkedin_url'
  | 'crunchbase_url'
  | 'logo_url'
  | 'short_description'
  | 'long_description'
  | 'hq'
  | 'founded_year'
  | 'employees_count'
  | 'funding'
  | 'investors'
  | 'tags'
  | 'pricing'
  | 'business_model'
  | 'product_kpi'
  | 'usp'
  | 'company_traction'
  | 'documents_json'
  | 'enriched_at'
  | 'is_proven'
> & {
  organization_profile?: NonNullable<
    GetDataForSidePanelQuery['startup']
  >['organization_profile'];
  contacts: StartupContact[];
  label?: string;
  connection?: StartupConnection | null;
  listed_in_startup_lists: ListedInStartupLists[];
  shortlisted_in_startup_lists: ListedInStartupLists[];
  selected_in_startup_lists: ListedInStartupLists[];
  cached_website_images: CachedWebsiteImage[];
  health_check?: {
    id: number;
  } | null;
  similar_startups: Array<{
    similar_startup: Pick<
      Suppliers,
      | 'id'
      | 'domain'
      | 'name'
      | 'logo_url'
      | 'website'
      | 'employees_count'
      | 'founded_year'
      | 'funding'
      | 'hq'
      | 'short_description'
    >;
  }>;
  startup_corporate_customers: Array<
    Pick<StartupCorporateCustomers, 'type' | 'case_study_url'> & {
      corporate_customer: CorporateCustomer;
    }
  >;
  organization_startup_pitches: Array<{
    id: number;
    title: string;
  }>;
} & Pick<
    NonNullable<GetDataForSidePanelQuery['startup']>,
    'funnel_labels' | 'signal_labels'
  >;

const StartupInfoSidePanel = () => {
  const {
    startupId,
    startupListId,
    categoryId,
    openStartupSidePanel,
    setIsNotesIconClicked,
    isNotesIconClicked,
  } = useStartupSidePanel();

  const open = !!startupId;
  const onClose = () => {
    openStartupSidePanel(null);
    setIsNotesIconClicked(false);
  };
  const { isSharedPage } = useSharedPagesContext();
  const { isFullscreenView } = useContext(FullscreenContext);
  const { isAuthenticated, user } = useAuth();

  const { data, loading } = useGetDataForSidePanelQuery({
    // Always present because of `skip: !startupId`
    variables: { startupId: startupId!, startupListId: startupListId || -1 },
    fetchPolicy: 'cache-and-network',
    skip: !startupId || !isAuthenticated,
  });

  const { data: getCategorizedStartupsResponse } =
    useGetCategorizedStartupsForProfileNavigationQuery({
      variables: { project_category_id: categoryId! },
      skip: !categoryId || !isAuthenticated,
    });

  const categorizedStartupsById = useMemo(() => {
    const categorizedStartups =
      getCategorizedStartupsResponse?.categorized_suppliers || [];

    return keyBy(
      categorizedStartups!.filter(x => x.is_in_matrix),
      'id',
    );
  }, [getCategorizedStartupsResponse]);

  const currentStartupList = data?.startup_list;

  /**
   * TODO: Figure out what is going on here
   * If we don't add !gqlContextExists. RefetchQueries does not work...
   */
  const { data: notesData } = useGetStartupNotesQuery({
    variables: { startupId: startupId! },
    skip: !startupId || !isAuthenticated,
  });

  const notesCount = useMemo(
    () =>
      notesData?.organization_startup_notes.filter(({ created_by }) =>
        user.type === 'bu_member'
          ? created_by?.person?.email === user?.email
          : true,
      ).length,
    [notesData?.organization_startup_notes, user.type, user?.email],
  );

  const { data: contactsCountData } =
    useGetOrganizationStartupContactCountQuery({
      variables: { startupId: startupId! },
      skip: !startupId || !isAuthenticated,
    });

  const { data: startupsDocumentCountData } = useGetStartupDocumentsCountQuery({
    variables: { startupId: startupId! },
    skip: !startupId || !isAuthenticated,
  });

  const contacsCount =
    contactsCountData?.organization_startup_contacts_aggregate.aggregate?.count;

  const startupDocumentsCount =
    startupsDocumentCountData?.project_startup_files_aggregate.aggregate?.count;

  const { data: linkedEntitiesFetchResponse } =
    useGetEntitiesLinkedToStartupQuery({
      variables: { startupId: startupId! },
      skip: !startupId,
      fetchPolicy: 'cache-and-network',
    });

  const linkedEntitiesData = useMemo(
    () =>
      linkedEntitiesFetchResponse?.get_entities_linked_to_startup?.data
        ?.entities as LinkedEntitiesType | undefined,
    [
      linkedEntitiesFetchResponse?.get_entities_linked_to_startup?.data
        ?.entities,
    ],
  );

  const currentStartup = useMemo(
    () =>
      data?.startup
        ? {
            ...data.startup,
            listed_in_startup_lists: data.startup.listed_in_startup_lists || [],
            shortlisted_in_startup_lists:
              data.startup.shortlisted_in_startup_lists || [],
            selected_in_startup_lists:
              data.startup.selected_in_startup_lists || [],
          }
        : undefined,
    [data?.startup],
  );

  if (startupId && !isAuthenticated) {
    const returnTo = encodeURIComponent(
      window.location.pathname + window.location.search,
    );
    return <Navigate to={`${PATH_ROOT.auth.login}?returnTo=${returnTo}`} />;
  }

  if (!startupId) return null;

  if (!data && loading) return;

  if (startupId && !currentStartup && isAuthenticated) {
    captureMessage(`404, Couldn't get startup with ID: ${startupId}`);
    return (
      <SwipeableDrawer
        onOpen={() => {}}
        open={open}
        anchor='right'
        onClose={onClose}
        data-testid='startup-drawer'
      >
        <Page404 embedded />
      </SwipeableDrawer>
    );
  }

  if (!currentStartup) {
    throw new Error('StartupInfoSidePanel: currentStartup is undefined');
  }

  return (
    // TODO: Make sure the fade works
    <Fade in={!!startupId}>
      <Box>
        <StartupInfoSidePanelLoaded
          categorizedStartupsById={categorizedStartupsById!}
          currentStartup={currentStartup!}
          currentStartupList={currentStartupList!}
          currentCategoryId={categoryId}
          open={open}
          onClose={onClose}
          isSharedPage={isSharedPage || isFullscreenView}
          isNotesIconClicked={isNotesIconClicked}
          notesCount={notesCount}
          contactsCount={contacsCount}
          linkedEntitiesData={linkedEntitiesData}
          startupDocumentsCount={startupDocumentsCount}
          /**
           * TODO: Find a way to avoid passing those props to reduce the amount of props
           * There was a BUG where the SuccessBanner was not showing because
           * RequestStartupConnectButton was refetching useGetOrganizationStartupQuery
           * and because of the loading check above it was removing the React component
           * from the tree loosing the state altogether and therefore preventing the
           * Banner from showing
           * I tried to move 'loading' to 'StartupInfoSidePanelLoaded' but it just generated
           * more side-effects
           */
        />
      </Box>
    </Fade>
  );
};

const StartupInfoSidePanelLoaded = ({
  categorizedStartupsById,
  currentStartup,
  currentStartupList,
  currentCategoryId,
  open,
  onClose,
  isSharedPage,
  isNotesIconClicked,
  notesCount,
  contactsCount,
  linkedEntitiesData,
  startupDocumentsCount,
}: {
  // TODO: Create shared type
  categorizedStartupsById: Dictionary<CategorizedStartupForInfoNavigator>;
  currentStartup: StartupForSidePanel;
  currentStartupList?: StartupListForStartupProfile;
  currentCategoryId?: number;
  open: boolean;
  onClose: () => void;
  isSharedPage?: boolean;
  isNotesIconClicked?: boolean;
  notesCount?: number | null;
  contactsCount?: number | null;
  linkedEntitiesData?: LinkedEntitiesType;
  startupDocumentsCount?: number | null;
}) => {
  const { palette, spacing } = useTheme();
  const staffMenuItemColor = `${palette.secondary.main} !important`;

  const { isBelowMd, isBelowSm } = useBreakpoints();

  const { addProgressUUIDToPool, progressPool } = useEnrichmentProgressPool();
  const [enrichStartupAsync] = useEnrichStartupAsyncMutation();

  const { getPortalConfigField } = useOrganizationPortalContext();
  const teamSlug = getPortalConfigField('team_slug');

  useCaptureViewStartupEvent({
    startupId: currentStartup.id,
    startupDomain: currentStartup.domain,
    listId: currentStartupList?.id,
    categoryId: currentCategoryId,
  });

  const { ToggleInactiveLabelMenu } = useToggleInactiveLabel({
    startupId: currentStartup.id,
    color: staffMenuItemColor,
    signalLabels: currentStartup.signal_labels,
  });

  const firstPartyData =
    getFirstPartyDataFromOrganizationStartup(currentStartup);

  const initialValues = getInitialValuesFromOrganizationStartups(
    firstPartyData,
    currentStartup,
  );

  const { user } = useAuth();

  const {
    formik,
    isApprovedAndNotOverridden,
    hasFirstPartyData,
    dateApproved,
  } = useStartupDetailsFormik({
    isPanelOpened: open,
    initialValues, // TODO: Make this a generic interface
  });

  type StartupProfileTabsType =
    | 'general'
    | 'notes'
    | 'contacts'
    | 'pitches'
    | 'documents';

  const { pitchId, projectId, isDocumentModalOpen } = useStartupSidePanel();

  const defaultTabValue = useMemo(() => {
    if (pitchId) {
      return 'pitches';
    }

    if (isDocumentModalOpen && projectId) {
      return 'documents';
    }

    return isNotesIconClicked ? 'notes' : 'general';
  }, [isNotesIconClicked, pitchId, projectId, isDocumentModalOpen]);

  const [currentTabValue, setCurrentTabValue] =
    useState<StartupProfileTabsType>(defaultTabValue);

  const pitchIds = currentStartup.organization_startup_pitches.map(
    pitch => pitch.id,
  );
  const pitches = currentStartup.organization_startup_pitches;
  const pitchCount = pitches.length;

  let pitchTabLabel;

  switch (pitchCount) {
    case 0:
      pitchTabLabel = 'Pitches';
      break;
    case 1:
      pitchTabLabel = `Pitches (1)`;
      break;
    default:
      pitchTabLabel = `Pitches (${pitchId && currentTabValue === 'pitches' ? `${pitches.map(pitch => pitch.id).indexOf(Number(pitchId)) + 1}/${pitchCount}` : pitchCount})`;
      break;
  }

  const { onSearchSimilarClickFromStartupProfile } = useSearchSimilarActions();

  const [updateCategorizedSupplierRecommendedStatusMutation] =
    useUpdateCategorizedStartupRecommendedStatusMutation();

  const handleEnrich = useCallback(async () => {
    const { data: enrichStartupAsyncData } = await enrichStartupAsync({
      variables: {
        domain: currentStartup.domain,
        overwriteExistingData: LIMITED_ENRICHMENT ? ['name'] : ['all'],
      },
    });

    addProgressUUIDToPool({
      // eslint-disable-next-line
      progressUUID: enrichStartupAsyncData?.enrich_startup!,
      domain: currentStartup.domain,
      refetchQuery: GetDataForSidePanelDocument,
    });
  }, [currentStartup.domain, enrichStartupAsync, addProgressUUIDToPool]);

  useEffect(() => {
    if (open && !currentStartup.enriched_at) {
      handleEnrich();
    }
  }, [open, handleEnrich, currentStartup.enriched_at]);

  const displayNavigation = useMemo(() => {
    return (
      currentStartupList?.id &&
      categorizedStartupsById &&
      Boolean(Object.keys(categorizedStartupsById).length >= 1)
    );
  }, [categorizedStartupsById, currentStartupList?.id]);

  const categorizedStartupId = useMemo(() => {
    for (const categorizedStartupId in categorizedStartupsById) {
      if (
        categorizedStartupsById[categorizedStartupId]?.startup?.id ===
        // eslint-disable-next-line
        currentStartup?.id!
      ) {
        return categorizedStartupId;
      }
    }
    return null;
  }, [currentStartup?.id, categorizedStartupsById]);

  const categorizedStartup = useMemo(() => {
    if (categorizedStartupsById)
      return categorizedStartupsById[Number(categorizedStartupId)];
  }, [categorizedStartupId, categorizedStartupsById]);

  const isValidOrganizationPortalURL = isOrganizationPortalURL();

  const isOrganizationPortal =
    isValidOrganizationPortalURL || user.type === 'bu_member';

  const [isAddContactModalOpened, setIsAddContactModalOpened] = useState(false);

  const [isOutreachModalOpened, setIsOutreachModalOpened] = useState(false);

  const hasAcceptedConnection =
    currentStartup.connection?.status === 'accepted';

  return (
    <SwipeableDrawer
      onOpen={() => {}}
      open={open}
      anchor='right'
      onClose={onClose}
      data-testid='startup-drawer'
    >
      {/* Not removed with Editing - Breaks useFormikContext for child components when removed */}
      <FormikProvider value={formik}>
        <Stack sx={{ width: isBelowMd ? 'inherit' : 900, padding: 3 }}>
          {displayNavigation && (
            <StartupInfoNavigator
              isMobile={isBelowMd}
              // eslint-disable-next-line
              currentStartupId={currentStartup?.id!}
              categorizedStartupsById={categorizedStartupsById}
              currentStartupList={currentStartupList!}
              currentCategoryId={currentCategoryId}
            />
          )}
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ gap: 2, marginBottom: 2 }}
            flexWrap='wrap'
          >
            <StartupHeader currentStartup={currentStartup} />
            <Stack direction='row' alignItems='start'>
              <Stack direction='row' spacing={1} alignItems='center'>
                {!isOrganizationPortal && (
                  <BaseCopyToClipboard
                    text={buildOrganizationPortalStartupProfileUrl(
                      currentStartup.id,
                      teamSlug,
                    )}
                    successMessage='Link copied'
                  >
                    <Button
                      onClick={() =>
                        captureAnalyticsEvent('Startup profile url copied', {
                          domain: currentStartup.domain,
                        })
                      }
                      startIcon={<LinkIcon />}
                    >
                      Copy link
                    </Button>
                  </BaseCopyToClipboard>
                )}
                {isOrganizationPortal ? (
                  <Button
                    variant='text'
                    startIcon={<LowPriority />}
                    onClick={() =>
                      onSearchSimilarClickFromStartupProfile(
                        currentStartup.domain,
                        currentStartup.id,
                      )
                    }
                  >
                    Search similar
                  </Button>
                ) : (
                  <Tooltip title='Search similar'>
                    <IconButton
                      sx={{ width: spacing(5), height: spacing(5) }}
                      color='primary'
                      onClick={() =>
                        onSearchSimilarClickFromStartupProfile(
                          currentStartup.domain,
                          currentStartup.id,
                        )
                      }
                    >
                      <LowPriority fontSize='small' />
                    </IconButton>
                  </Tooltip>
                )}
                {!isSharedPage && (
                  <CreateLeadButton
                    source='Startup profile'
                    selectedStartup={{
                      name: currentStartup.name,
                      domain: currentStartup.domain,
                      logo_url: currentStartup.logo_url || '',
                    }}
                  />
                )}
                {!isSharedPage && (
                  <BaseAddStartupToListMenu
                    buttonSx={{ width: spacing(5), height: spacing(5) }}
                    selectedStartups={[currentStartup]}
                    analyticsSource='STARTUP_PROFILE'
                  />
                )}
                {isSharedPage && (
                  <IamInterestedButton
                    variant='text'
                    title={currentStartup.name}
                  />
                )}
                {!isSharedPage && (
                  <MoreMenu>
                    <MenuItem
                      onClick={() => {
                        setIsOutreachModalOpened(true);
                        captureAnalyticsEvent('Outreach modal opened.', {
                          startupId: currentStartup.id,
                          startupName: currentStartup.name,
                        });
                      }}
                      sx={{ color: 'text.secondary' }}
                    >
                      <PersonAddIcon fontSize='small' sx={{ marginRight: 1 }} />
                      <Typography variant='body2'>
                        {!hasAcceptedConnection ? 'Connect' : 'Reach out'}
                      </Typography>
                    </MenuItem>
                    <EditSupplierIdMenuItem currentStartup={currentStartup} />
                    <CopyClaimProfileLinkMenuItem
                      startupDomain={currentStartup.domain}
                    />
                    <ExportStartupProfilePDFMenuItem
                      currentStartup={currentStartup}
                    />
                    {categorizedStartupId && (
                      <MenuItem
                        sx={{
                          color: 'text.secondary',
                        }}
                        disabled={!categorizedStartupId}
                        onClick={() => {
                          updateCategorizedSupplierRecommendedStatusMutation({
                            variables: {
                              id: Number(categorizedStartupId),
                              is_recommended:
                                !categorizedStartup?.is_recommended,
                            },
                          });
                        }}
                        key='toggle_recommended'
                      >
                        {!categorizedStartup?.is_recommended ? (
                          <>
                            <ThumbUp fontSize='small' sx={{ marginRight: 1 }} />
                            <Typography variant='body2'>Recommend</Typography>
                          </>
                        ) : (
                          <>
                            <ThumbDown
                              fontSize='small'
                              sx={{ marginRight: 1 }}
                            />
                            <Typography variant='body2'>
                              {/* eslint-disable-next-line */}
                              Don't recommend
                            </Typography>
                          </>
                        )}
                      </MenuItem>
                    )}
                    {user.isContentEditor && (
                      <Box>
                        <MenuItem
                          onClick={handleEnrich}
                          sx={{
                            color: staffMenuItemColor,
                          }}
                          disabled={Boolean(
                            progressPool.find(
                              el => el.domain === currentStartup.domain,
                            ),
                          )}
                        >
                          <AutoAwesome />
                          <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
                            Enrich
                          </Typography>
                        </MenuItem>
                        <ToggleInactiveLabelMenu />
                      </Box>
                    )}
                    {user.isContentEditor && (
                      <MenuItem
                        sx={{
                          color: staffMenuItemColor,
                        }}
                        component={Link}
                        href={`${buildAdminPlatformDomain()}/startups/${
                          currentStartup.id
                        }`}
                        target='_blank'
                        key='edit_in_admin'
                      >
                        <OpenInNew fontSize='small' sx={{ marginRight: 0.5 }} />
                        <Typography variant='body2'>Edit in Admin</Typography>
                      </MenuItem>
                    )}
                  </MoreMenu>
                )}
              </Stack>
              {!isSharedPage && (
                <OutreachModal
                  open={isOutreachModalOpened}
                  onHide={() => setIsOutreachModalOpened(false)}
                  startup={{ ...currentStartup }}
                  setIsAddContactModalOpened={setIsAddContactModalOpened}
                  isAskGDEnabled
                />
              )}
            </Stack>
          </Stack>
          {linkedEntitiesData && !isSharedPage && (
            <LinkedToEntity
              linkedEntitiesData={linkedEntitiesData}
              sxProps={{
                marginY: 0.75,
              }}
            />
          )}
          <Stack direction='row' justifyContent='space-between'>
            <Tabs
              sx={({ breakpoints }) => ({
                [breakpoints.down('sm')]: {
                  '& .MuiTab-root': { marginRight: '0 !important' },
                },
              })}
              value={currentTabValue}
              onChange={(_e, value) => setCurrentTabValue(value)}
            >
              <Tab key='general' value='general' label='Overview' />

              <Tab
                key='notes'
                value='notes'
                label={`Notes${notesCount ? ` (${notesCount})` : ''}`}
              />
              <Tab key='pitches' value='pitches' label={pitchTabLabel} />
              <Tab
                key='contacts'
                value='contacts'
                label={`Contacts${contactsCount ? ` (${contactsCount})` : ''}`}
              />
              <Tab
                key='documents'
                value='documents'
                label={`Documents${startupDocumentsCount ? ` (${startupDocumentsCount})` : ''}`}
              />
            </Tabs>
            {currentTabValue === 'contacts' &&
              (isBelowSm ? (
                <IconButton
                  color='primary'
                  sx={{ marginRight: 2 }}
                  onClick={() => setIsAddContactModalOpened(true)}
                >
                  <Add />
                </IconButton>
              ) : (
                <Button
                  variant='contained'
                  onClick={() => setIsAddContactModalOpened(true)}
                  sx={{ marginY: 0.5, maxHeight: 32 }}
                >
                  + Add
                </Button>
              ))}
          </Stack>
          {currentTabValue === 'general' && (
            <StartupInfoSidePanelGeneral
              formik={formik}
              isApprovedAndNotOverridden={isApprovedAndNotOverridden}
              hasFirstPartyData={hasFirstPartyData}
              dateApproved={dateApproved}
              currentStartup={currentStartup}
            />
          )}
          {currentTabValue === 'notes' && currentStartup && (
            <StartupInfoSidePanelNotes
              startupId={currentStartup.id}
              startupName={currentStartup.name}
              startupLogoUrl={currentStartup.logo_url}
              // eslint-disable-next-line
              listId={currentStartupList?.id!}
              // eslint-disable-next-line
              listTitle={currentStartupList?.title!}
            />
          )}
          {currentTabValue === 'pitches' && (
            <StartupPitchDetailsTab
              pitchIds={pitchIds}
              startupId={currentStartup.id}
            />
          )}
          {currentTabValue === 'contacts' && (
            <StartupInfoSidePanelContacts startupId={currentStartup.id} />
          )}
          {currentTabValue === 'documents' && (
            <Stack marginY={2}>
              <DocumentsTab
                startups={[
                  {
                    id: currentStartup.id,
                    name: currentStartup.name,
                  },
                ]}
                projectId={projectId}
                startupId={currentStartup.id}
              />
            </Stack>
          )}
        </Stack>
      </FormikProvider>
      <AddContactModal
        open={isAddContactModalOpened}
        onHide={() => setIsAddContactModalOpened(false)}
        startupId={currentStartup.id}
      />
    </SwipeableDrawer>
  );
};

export default StartupInfoSidePanel;
