// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function LogoShort({ width = 45, height = 45, ...rest }) {
  return (
    <svg
      {...rest}
      id='svg'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 190 190'
      fill='none'
      // viewBox='0, 0, 400,406.425702811245'
    >
      <g id='svgg'>
        <path
          opacity='0.4'
          d='M182.74 58.0536H159.576L133.908 153.544C132.692 157.723 128.957 160.69 124.594 160.932H52.426L46.5741 182.726C45.4868 186.705 47.8904 190 51.9538 190H147.271C151.635 189.786 155.384 186.848 156.628 182.669L188.119 65.3419C189.207 61.3626 186.803 58.0678 182.74 58.0678'
          fill='#001D0F'
        />
        <path
          opacity='0.4'
          d='M60.2462 131.943H29.7996L23.9621 153.665C22.8747 157.645 25.2784 160.939 29.3417 160.939H52.4485L60.2319 131.943M160.085 29.0072H136.935L129.123 58.0608H159.598L165.45 36.267C166.537 32.2877 164.134 28.9929 160.07 28.9929'
          fill='#001D0F'
        />
        <path
          opacity='0.7'
          d='M159.619 58.0536H129.144L111.26 124.604C110.015 128.769 106.295 131.693 101.945 131.936H60.2531L52.4697 160.932H124.637C129.001 160.69 132.735 157.737 133.952 153.544L159.619 58.0536Z'
          fill='#001D0F'
        />
        <path
          opacity='0.4'
          d='M137.37 0H42.1239C37.7887 0.213944 34.0545 3.12358 32.7954 7.25983L1.2614 124.672C0.17402 128.652 2.5777 131.932 6.64106 131.932H29.7765L55.473 36.2564C56.732 32.1201 60.452 29.2105 64.7872 28.9823H136.926L142.764 7.25983C143.865 3.28047 141.433 0 137.37 0Z'
          fill='#001D0F'
        />
        <path
          opacity='0.7'
          d='M136.904 28.9929H64.7655C60.4303 29.2211 56.7103 32.1165 55.4512 36.267L29.769 131.943H60.2156L78.1145 65.3348C79.3735 61.1986 83.1078 58.289 87.4287 58.0608H129.107L136.919 29.0072'
          fill='#001D0F'
        />
        <path
          d='M129.093 58.0536H87.415C83.0798 58.2818 79.3598 61.1914 78.1007 65.3277L60.2019 131.936H101.894C106.244 131.707 109.964 128.769 111.209 124.604L129.093 58.0536Z'
          fill='#001D0F'
        />
      </g>
    </svg>
  );
}
