import { captureException } from '@sentry/react';
import {
  useCreateProjectServiceMutation,
  useCreateStartupListServiceMutation,
  useSendMailMutation,
  useUpdateProjectStakeholdersMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useCreateProblemScopeMutation } from 'apollo/generated/sdkShared';
import {
  GLASSDOLLAR_DEFAULT_FROM_EMAIL,
  GLASSDOLLAR_DEFAULT_FROM_NAME,
  REGISTERED_SCOPE_TEMPLATE_ID,
  SEARCH_PARAMS,
  X_HASURA_ORGANIZATION_UUID,
} from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { usePersonContext } from 'contexts/PersonContext';
import useAddStartupToList from 'hooks/useAddStartupToList';
import { useInsertProjectSourceTag } from 'hooks/useInsertProjectSourceTag';
import { useUploadProblemScopeFile } from 'pages/request-form/useScopeFiles';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { buildListScopeUrl } from 'utils/url';
import { KnownStartup } from '../../@types/startupList';
import { useRequestsSolutionsMultiStepState } from './state/requestSolutionsFormState';

const keysToPick: (keyof KnownStartup)[] = [
  'domain',
  'name',
  'product',
  'logo',
  'excludeWhenBenchmarking',
];

export const useRequestSolutionsManager = ({
  isInnovationManager,
}: {
  isInnovationManager?: boolean;
}) => {
  const params = new URLSearchParams(window.location.search);
  const portalTitle = params.get(SEARCH_PARAMS.portalTitle);
  const currentOrganization = useCurrentOrganizationFromContext();
  const [createStartupList] = useCreateStartupListServiceMutation();
  const [createProject] = useCreateProjectServiceMutation();
  const [createProblemScope] = useCreateProblemScopeMutation();
  const [addPainPointOwner] = useUpdateProjectStakeholdersMutation();
  const [sendMail] = useSendMailMutation();
  const { teamSlug } = useParams();
  const { personInfoData, scopeSectionOneData, scopeSectionTwoData } =
    useRequestsSolutionsMultiStepState();
  const { addStartupToList } = useAddStartupToList();
  const uploadProblemScopeFile = useUploadProblemScopeFile();
  const { person } = usePersonContext();
  const { handleInsertTag } = useInsertProjectSourceTag();

  const formData = {
    ...personInfoData,
    ...scopeSectionOneData,
    ...scopeSectionTwoData,
  };

  const context = useMemo(
    () => ({
      headers: {
        [X_HASURA_ORGANIZATION_UUID]: currentOrganization.uuid,
      },
    }),
    [currentOrganization.uuid],
  );

  const handleFormSubmit = async () => {
    window.addEventListener('beforeunload', function (event) {
      event.stopImmediatePropagation();
    });
    // const challengeTitle = `Challenge by ${personInfoData.requesterName}`;
    const scopeKnownStartups: KnownStartup[] = (
      formData?.knownSolutions ?? []
    ).map((startup: KnownStartup) =>
      Object.fromEntries(
        keysToPick
          .map(key => [key, startup[key]])
          .filter(([, value]) => value !== undefined),
      ),
    );

    const problemScopeData = await createProblemScope({
      variables: {
        object: {
          additional_info: formData.additionalInformation,
          desired_solution: formData.desiredSolution,
          problem: formData.needPainPoint,
          nice_to_have_features: formData.niceToHaveFeatures,
          must_have_features: formData.mustHaveFeatures,
          known_startups: scopeKnownStartups,
          title: formData.title,
        },
      },
      context,
    });

    const problemScopeId = problemScopeData.data?.insert_problem_scopes_one?.id;
    if (!problemScopeId) {
      throw new Error('Problem scope creation failed');
    }

    if (isInnovationManager) {
      if ((formData?.supportingDocuments ?? []).length > 0) {
        await Promise.all(
          Array.from(formData.supportingDocuments).map(file =>
            uploadProblemScopeFile(file!, problemScopeId),
          ),
        ).catch(e => {
          captureException(e);
        });
      }
      return { painPointId: problemScopeId, publicUUID: '' };
    }

    const { data } = await createStartupList({
      variables: {
        object: {
          title: formData.title,
          status: 'pending_request',
          source: portalTitle ? 'portal_item' : 'inbox',
          requested_by: {
            full_name: personInfoData.requesterName,
            email: personInfoData.requesterEmail,
            department: personInfoData.requesterDepartment,
          },
          scope_id: problemScopeId,
        },
      },
      context,
    });

    if (!data?.create_startup_list?.startup_list) {
      throw new Error('Startup list creation failed');
    }

    const startupListId = data?.create_startup_list?.startup_list.id;
    const { public_uuid: publicUUID } = data.create_startup_list.startup_list;

    if ((formData?.knownSolutions ?? []).length > 0) {
      for (const startup of formData.knownSolutions) {
        await addStartupToList({
          selectedStartup: {
            ...startup,
          },
          listId: startupListId,
          categoryId: undefined,
        });
      }
    }

    if ((formData?.supportingDocuments ?? []).length > 0) {
      await Promise.all(
        Array.from(formData.supportingDocuments).map(file =>
          uploadProblemScopeFile(file!, problemScopeId),
        ),
      ).catch(e => {
        captureException(e);
      });

      captureAnalyticsEvent('File added to scope', {
        listId: startupListId,
      });
    }

    const sourceTagId = await handleInsertTag('Request Form');

    const { data: projectData } = await createProject({
      variables: {
        object: {
          title: formData.title,
          stage: 'scoping',
          source: 'self_created',
          type_of_solution: 'unknown',
          lead_maturity: 'pending_request',
          linked_startup_list_id: data?.create_startup_list?.startup_list?.id,
          scope_id: problemScopeId,
          source_tag_ids: sourceTagId ? [sourceTagId] : undefined,
        },
      },
      context,
    });
    const projectId = projectData?.create_project?.project?.id;

    if (projectId) {
      captureAnalyticsEvent('Project Lead Created', {
        projectId: projectId,
        source: 'New Challenge',
      });
    }

    await addPainPointOwner({
      variables: {
        projectStakeholdersData: [
          {
            role: 'project_leader',
            stakeholder_id: person?.id,
            project_id: projectId,
          },
        ],
      },
    });

    await sendMail({
      variables: {
        emailPayload: {
          templateId: REGISTERED_SCOPE_TEMPLATE_ID,
          to: [personInfoData.requesterEmail],
          from: {
            name:
              currentOrganization?.from_name || GLASSDOLLAR_DEFAULT_FROM_NAME,
            email:
              currentOrganization?.from_email || GLASSDOLLAR_DEFAULT_FROM_EMAIL,
          },

          reply_to:
            currentOrganization?.from_email || GLASSDOLLAR_DEFAULT_FROM_EMAIL,
          templateData: {
            organization_name: currentOrganization?.name,
            stakeholder_name: personInfoData.requesterName,
            link_to_form: buildListScopeUrl({
              startupListPublicUUID: publicUUID,
              teamSlug,
            }),
            logo_url: currentOrganization?.logo_url,
          },
        },
      },
      context,
    });

    return { publicUUID, painPointId: problemScopeId };
  };

  return {
    context,
    handleFormSubmit,
  };
};
