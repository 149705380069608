import { AddShoppingCart } from '@mui/icons-material';
import { Button, SxProps } from '@mui/material';
import useDraftSourcingModal from 'components/dashboard/sourcingOrder/useDraftSourcingModal';
import { SourcingOrderRedirectOrigin } from 'pages/request-form/ProblemScopeFormLayout';

const OrderSourcingButton = ({
  buttonSx,
  startupListId,
  withIcon,
  variant,
  disabled = false,
  title,
  orderOrigin,
}: {
  buttonSx?: SxProps;
  startupListId?: number;
  problemScopeId?: number;
  withIcon?: boolean;
  variant?: 'contained' | 'text' | 'outlined';
  disabled?: boolean;
  title?: string;
  orderOrigin: SourcingOrderRedirectOrigin;
}) => {
  const [openModal, createSourcingOrderModal] = useDraftSourcingModal({
    invalidateCache: null,
    initialTitle: title,
    startupListId,
    orderOrigin,
  });

  return (
    <>
      <Button
        startIcon={withIcon ? <AddShoppingCart /> : null}
        variant={variant || (withIcon ? 'contained' : 'text')}
        disabled={disabled}
        sx={{
          whiteSpace: 'nowrap',
          paddingX: 0.5,
          ...buttonSx,
        }}
        onClick={openModal}
      >
        Order sourcing
      </Button>
      {createSourcingOrderModal}
    </>
  );
};

export { OrderSourcingButton };
