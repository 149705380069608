// material
import { Box, BoxProps } from '@mui/material';
// ----------------------------------------------------------------------

export default function LogoLong(props: { sx?: BoxProps; png?: boolean }) {
  const { sx } = props;
  return (
    <Box sx={{ width: 150, height: 20, ...sx }}>
      <svg
        viewBox='0 0 1035 190'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M531.158 116.17C531.158 116.117 531.14 116.065 531.107 116.024C528.68 112.974 525.38 110.576 521.207 108.773C516.95 106.958 512.085 105.363 506.557 104.043C504.511 103.493 502.19 102.833 499.481 102.008C496.827 101.238 494.505 100.138 492.625 98.8185C490.691 97.4985 489.751 95.7936 489.751 93.6486C489.751 90.6787 490.912 88.6988 493.234 87.6538C495.556 86.6088 498.264 86.0588 501.194 86.0588C504.954 86.0588 508.05 86.9388 510.593 88.6988C512.586 90.1091 513.87 92.0138 514.5 94.4695C514.778 95.5518 515.696 96.3986 516.817 96.3986H531.852C533.123 96.3986 534.138 95.3267 533.978 94.0695C533.017 86.5298 529.773 80.7568 524.248 76.7091C518.167 72.2542 510.703 69.9993 501.858 69.9993C496.219 69.9993 490.967 70.9343 486.102 72.8042C481.237 74.6742 477.367 77.3691 474.382 80.999C471.452 84.6289 469.959 88.9738 469.959 94.0886C469.959 99.2035 471.286 103.438 473.829 106.573C476.372 109.708 479.855 112.128 484.222 113.888C488.59 115.648 493.455 117.133 498.817 118.398C500.973 118.948 503.295 119.553 505.838 120.323C508.437 121.093 510.648 122.083 512.583 123.348C514.463 124.668 515.458 126.373 515.458 128.628C515.458 131.488 514.131 133.578 511.533 134.897C508.934 136.217 506.115 136.877 503.129 136.877C499.647 136.877 496.44 135.942 493.621 134.018C491.33 132.454 489.806 130.418 489.018 127.911C488.696 126.885 487.798 126.098 486.719 126.098H470.421C469.152 126.098 468.136 127.168 468.321 128.42C468.978 132.857 470.729 136.812 473.608 140.287C476.98 144.302 481.292 147.382 486.489 149.582C491.741 151.727 497.325 152.827 503.295 152.827C508.934 152.827 514.186 151.892 518.996 150.077C523.75 148.207 527.675 145.512 530.605 141.882C533.535 138.307 535.028 133.853 535.028 128.683C534.918 123.494 533.664 119.391 531.21 116.318C531.177 116.276 531.158 116.224 531.158 116.17Z'
          fill='#001D0F'
        />
        <path
          d='M607.106 116.172C607.087 116.116 607.059 116.065 607.022 116.019C604.54 112.972 601.241 110.575 597.071 108.773C592.814 106.958 588.005 105.363 582.421 104.043C580.376 103.493 578.054 102.833 575.4 102.008C572.691 101.238 570.424 100.138 568.545 98.8185C566.61 97.4985 565.67 95.7936 565.67 93.6486C565.67 90.6787 566.831 88.6988 569.153 87.6538C571.475 86.6088 574.184 86.0588 577.169 86.0588C580.873 86.0588 584.024 86.9388 586.512 88.6988C588.496 90.1025 589.812 91.9961 590.432 94.4354C590.711 95.5314 591.634 96.3986 592.768 96.3986H607.786C609.052 96.3986 610.065 95.3355 609.912 94.083C608.986 86.5367 605.701 80.7592 600.222 76.7091C594.141 72.2542 586.678 69.9993 577.833 69.9993C572.138 69.9993 566.886 70.9343 562.021 72.8042C557.156 74.6742 553.287 77.3691 550.356 80.999C547.371 84.6289 545.934 88.9738 545.934 94.0886C545.934 99.2035 547.205 103.438 549.748 106.573C552.347 109.708 555.83 112.128 560.197 113.888C564.564 115.648 569.429 117.133 574.737 118.398C576.893 118.948 579.215 119.553 581.813 120.323C584.356 121.093 586.567 122.083 588.502 123.348C590.437 124.668 591.377 126.373 591.377 128.628C591.377 131.488 590.05 133.578 587.452 134.897C584.854 136.217 582.034 136.877 579.049 136.877C575.511 136.877 572.359 135.942 569.54 134.018C567.25 132.454 565.725 130.418 564.938 127.911C564.615 126.885 563.717 126.098 562.639 126.098H546.396C545.127 126.098 544.11 127.168 544.296 128.42C544.952 132.857 546.703 136.812 549.583 140.287C552.9 144.302 557.212 147.382 562.464 149.582C567.716 151.727 573.299 152.827 579.27 152.827C585.241 152.827 590.161 151.892 594.97 150.077C599.78 148.207 603.65 145.512 606.58 141.882C609.51 138.307 610.947 133.853 610.947 128.683C610.838 123.496 609.584 119.393 607.186 116.32C607.151 116.276 607.124 116.226 607.106 116.172Z'
          fill='#001D0F'
        />
        <path
          d='M715.562 65.9294C711.582 57.5696 705.335 51.0248 696.876 46.3499C688.418 41.6751 677.472 39.3101 664.148 39.3101H628.095C626.878 39.3101 625.892 40.2932 625.892 41.5059V148.541C625.892 149.754 626.878 150.737 628.095 150.737H664.148C676.532 150.737 686.925 148.592 695.494 144.302C704.008 140.012 710.476 133.688 714.954 125.328C719.377 117.023 721.588 106.903 721.588 95.0236C721.588 84.0239 719.598 74.2892 715.562 65.9294ZM697.153 115.428C694.886 121.148 691.016 125.548 685.543 128.628C680.07 131.708 672.662 133.248 663.153 133.248H648.55C647.333 133.248 646.347 132.264 646.347 131.052V58.8854C646.347 57.6727 647.333 56.6896 648.55 56.6896H663.153C675.868 56.6896 685.267 59.7696 691.403 65.9294C697.54 72.0892 700.58 81.824 700.58 95.0236C700.58 102.888 699.419 109.653 697.153 115.428Z'
          fill='#001D0F'
        />
        <path
          d='M811.242 89.4758C807.538 83.371 802.618 78.5311 796.26 75.0662C789.958 71.6563 782.826 69.8964 774.81 69.8964C766.794 69.8964 760.104 71.5463 753.747 74.8462C747.445 78.1461 742.358 82.876 738.599 89.0908C734.84 95.3057 732.905 102.675 732.905 111.31C732.905 119.45 734.674 126.655 738.212 132.925C741.75 139.194 746.615 144.034 752.862 147.499C759.109 150.964 766.186 152.669 774.202 152.669C782.605 152.669 790.013 150.909 796.371 147.334C802.673 143.759 807.704 138.864 811.353 132.65C814.946 126.38 816.77 119.285 816.77 111.31C816.77 102.95 814.946 95.6357 811.242 89.4758ZM793.109 123.025C791.34 126.71 788.852 129.57 785.701 131.77C782.55 133.97 778.846 135.07 774.589 135.07C770.719 135.07 767.236 134.08 764.03 132.155C760.823 130.175 758.28 127.425 756.456 123.795C754.576 120.22 753.636 116.04 753.636 111.42C753.636 106.8 754.521 102.62 756.29 99.0456C758.059 95.4707 760.547 92.7207 763.643 90.6858C766.794 88.6508 770.443 87.6609 774.589 87.6609C778.459 87.6609 781.942 88.5408 785.148 90.3558C788.355 92.1157 790.898 94.8107 792.943 98.2756C794.878 101.795 795.873 106.03 795.873 110.98C795.873 115.38 794.933 119.34 793.109 123.025Z'
          fill='#001D0F'
        />
        <path
          d='M831.085 39.3616C829.868 39.3616 828.882 40.3447 828.882 41.5574V148.593C828.882 149.805 829.868 150.789 831.085 150.789H847.19C848.406 150.789 849.392 149.805 849.392 148.593V41.5574C849.392 40.3447 848.406 39.3616 847.19 39.3616H831.085Z'
          fill='#001D0F'
        />
        <path
          d='M866.761 39.3616C865.545 39.3616 864.559 40.3447 864.559 41.5574V148.593C864.559 149.805 865.545 150.789 866.761 150.789H882.867C884.083 150.789 885.069 149.805 885.069 148.593V41.5574C885.069 40.3447 884.083 39.3616 882.867 39.3616H866.761Z'
          fill='#001D0F'
        />
        <path
          d='M970.488 134.913C970.493 134.907 970.496 134.9 970.496 134.893V103.61C970.496 96.7356 969.059 90.7408 966.35 85.7359C963.586 80.7311 959.605 76.8262 954.409 74.0213C949.267 71.2713 942.91 69.8964 935.446 69.8964C929.365 69.8964 923.671 71.0513 918.308 73.2513C913.001 75.4512 908.634 78.6411 905.206 82.821C902.262 86.3638 900.379 90.5151 899.591 95.2401C899.379 96.5102 900.401 97.6156 901.693 97.6156H917.889C918.962 97.6156 919.859 96.8382 920.162 95.8126C921.021 92.9091 922.659 90.7999 925.108 89.4208C928.038 87.7709 931.355 86.9459 935.115 86.9459C938.321 86.9459 940.975 87.6059 943.186 88.9258C945.342 90.2458 947.001 92.1157 948.162 94.5907C949.033 96.5422 949.562 98.8357 949.746 101.471C949.831 102.681 948.834 103.665 947.617 103.665H930.913C924.611 103.665 918.972 104.6 913.83 106.36C908.8 108.175 904.819 110.925 901.944 114.665C899.07 118.405 897.632 123.135 897.632 129.02C897.632 133.915 898.904 138.094 901.391 141.669C903.879 145.244 907.196 147.939 911.287 149.864C915.323 151.789 919.69 152.724 924.39 152.724H940.506C941.089 152.724 941.649 152.493 942.062 152.083L951.139 143.052C951.999 142.197 953.391 142.197 954.251 143.052L961.393 150.158C961.806 150.568 962.365 150.799 962.949 150.799H976.697C977.913 150.799 978.899 149.816 978.899 148.603V137.155C978.899 135.943 977.913 134.96 976.697 134.96H970.508C970.483 134.96 970.471 134.93 970.488 134.913ZM949.903 117.36C949.888 117.36 949.875 117.372 949.875 117.388C949.871 121.116 948.931 124.407 947.166 127.26C945.342 130.12 942.965 132.375 940.035 133.915C937.105 135.51 933.898 136.335 930.36 136.335C927.043 136.335 924.279 135.565 921.957 134.025C919.635 132.54 918.53 130.065 918.53 126.82C918.53 124.4 919.138 122.42 920.464 120.99C921.736 119.56 923.394 118.515 925.606 117.8C927.762 117.14 929.973 116.755 932.295 116.755H949.627C949.795 116.755 949.931 116.89 949.931 117.058V117.333C949.931 117.348 949.918 117.36 949.903 117.36Z'
          fill='#001D0F'
        />
        <path
          d='M452.563 133.752C452.568 133.742 452.57 133.732 452.57 133.722V103.653C452.57 96.7785 451.133 90.7837 448.424 85.7788C445.66 80.774 441.624 76.8691 436.483 74.0641C431.341 71.3142 424.984 69.9393 417.52 69.9393C411.439 69.9393 405.745 71.0942 400.383 73.2942C395.075 75.4941 390.708 78.684 387.28 82.8639C384.336 86.4067 382.453 90.558 381.665 95.283C381.453 96.5531 382.475 97.6585 383.767 97.6585H399.963C401.036 97.6585 401.933 96.881 402.236 95.8555C403.095 92.952 404.733 90.8428 407.182 89.4637C410.112 87.8138 413.429 86.9888 417.189 86.9888C420.395 86.9888 423.049 87.6488 425.26 88.9687C427.472 90.2887 429.13 92.1586 430.236 94.6336C431.108 96.5851 431.636 98.8786 431.82 101.514C431.905 102.724 430.908 103.708 429.692 103.708H412.987C406.685 103.708 401.046 104.643 395.905 106.403C390.874 108.218 386.838 110.968 384.019 114.708C381.144 118.448 379.706 123.178 379.706 129.063C379.706 133.957 380.923 138.137 383.466 141.712C386.009 145.287 389.271 147.982 393.306 149.907C397.342 151.832 401.709 152.767 406.464 152.767H422.58C423.163 152.767 423.723 152.536 424.136 152.125L433.213 143.095C434.073 142.24 435.465 142.24 436.325 143.095L443.467 150.2C443.88 150.611 444.44 150.842 445.023 150.842H458.771C459.987 150.842 460.973 149.859 460.973 148.646V136.043C460.973 134.831 459.987 133.847 458.771 133.847H452.623C452.573 133.847 452.541 133.796 452.563 133.752ZM431.894 117.348C431.894 121.088 430.954 124.388 429.13 127.248C427.361 130.108 424.984 132.363 421.998 133.902C419.068 135.497 415.862 136.322 412.324 136.322C409.007 136.322 406.187 135.552 403.921 134.012C401.599 132.528 400.493 130.053 400.493 126.808C400.493 124.388 401.101 122.408 402.428 120.978C403.7 119.548 405.358 118.503 407.514 117.788C409.67 117.128 411.937 116.743 414.259 116.743H431.287C431.623 116.743 431.894 117.014 431.894 117.348Z'
          fill='#001D0F'
        />
        <path
          d='M1034.6 69.8964C1034.57 69.8964 1034.55 69.921 1034.55 69.9514V89.04C1034.55 90.2527 1033.56 91.2358 1032.35 91.2358H1029.13C1022.61 91.2358 1017.8 92.8307 1014.59 95.8556C1011.38 98.9356 1009.78 104.105 1009.78 111.31V148.548C1009.78 149.761 1008.79 150.744 1007.58 150.744H991.639C990.423 150.744 989.437 149.761 989.437 148.548V74.0171C989.437 72.8044 990.423 71.8213 991.639 71.8213H1007.58C1008.79 71.8213 1009.78 72.8044 1009.78 74.0171V78.2976C1009.78 80.2527 1012.15 81.2329 1013.54 79.8518L1022.9 70.5379C1023.31 70.1271 1023.87 69.8964 1024.46 69.8964H1034.6Z'
          fill='#001D0F'
        />
        <path
          d='M364.874 39.3616H348.769C347.552 39.3616 346.566 40.3447 346.566 41.5574V148.593C346.566 149.805 347.552 150.789 348.769 150.789H364.874C366.09 150.789 367.076 149.805 367.076 148.593V41.5574C367.076 40.3447 366.09 39.3616 364.874 39.3616Z'
          fill='#001D0F'
        />
        <path
          d='M328.973 90.6238V148.596C328.973 149.809 327.986 150.792 326.77 150.792H320.654C320.069 150.792 319.508 150.56 319.095 150.147L310.074 141.128C309.214 140.269 307.819 140.267 306.958 141.124L295.95 152.076C295.537 152.486 294.977 152.717 294.394 152.717H274.131C263.517 152.717 254.34 150.297 246.434 145.567C238.528 140.782 232.392 134.128 228.025 125.493C223.657 116.858 221.446 106.958 221.446 95.7387C221.446 84.519 223.602 74.9493 227.859 66.1495C232.116 57.3498 238.473 50.3649 246.821 45.1951C255.224 40.0252 265.452 37.4403 277.448 37.4403C290.606 37.4403 301.441 40.5752 310.066 46.845C317.983 52.6008 323.384 60.3496 326.184 70.1339C326.572 71.4906 325.522 72.8043 324.107 72.8043H305.598C304.637 72.8043 303.797 72.1797 303.45 71.287C301.7 66.7857 298.787 63.2265 294.752 60.6497C290.274 57.7347 284.525 56.2498 277.448 56.2498C269.985 56.2498 263.683 57.8447 258.431 61.0347C253.179 64.2246 249.198 68.7344 246.489 74.6193C243.78 80.5591 242.398 87.5989 242.398 95.8487C242.398 104.098 243.78 111.028 246.489 116.803C249.198 122.578 253.068 126.978 258.209 130.003C263.24 133.028 269.211 134.568 276.011 134.568C285.63 134.568 292.983 132.093 298.069 127.253C302.654 122.888 305.744 117.255 307.339 110.392C307.647 109.066 306.607 107.838 305.241 107.838H279.651C278.434 107.838 277.448 106.853 277.448 105.64C277.448 100.278 277.448 97.7554 277.448 92.8773C277.448 91.6647 278.434 90.6788 279.651 90.6788H328.917C328.948 90.6788 328.973 90.6542 328.973 90.6238Z'
          fill='#001D0F'
        />
        <path
          opacity='0.4'
          d='M181.9 58.0541H158.736L133.068 153.544C131.852 157.723 128.118 160.69 123.754 160.933H51.5864L45.7345 182.726C44.6472 186.706 47.0508 190 51.1142 190H146.432C150.795 189.786 154.544 186.848 155.789 182.669L187.28 65.3424C188.367 61.3631 185.964 58.0683 181.9 58.0683'
          fill='#001D0F'
        />
        <path
          opacity='0.4'
          d='M59.4051 131.942H28.9585L23.121 153.665C22.0336 157.644 24.4373 160.939 28.5007 160.939H51.6075L59.3908 131.942M159.244 29.0067H136.094L128.282 58.0603H158.757L164.609 36.2665C165.696 32.2872 163.293 28.9924 159.229 28.9924'
          fill='#001D0F'
        />
        <path
          opacity='0.7'
          d='M158.777 58.0541H128.302L110.417 124.605C109.173 128.77 105.453 131.694 101.103 131.936H59.4108L51.6274 160.933H123.795C128.159 160.69 131.893 157.738 133.109 153.544L158.777 58.0541Z'
          fill='#001D0F'
        />
        <path
          opacity='0.4'
          d='M136.527 0H41.2814C36.9462 0.213944 33.2119 3.12358 31.9529 7.25983L0.418869 124.672C-0.66851 128.652 1.73517 131.932 5.79853 131.932H28.934L54.6304 36.2564C55.8895 32.1201 59.6095 29.2105 63.9447 28.9823H136.084L141.921 7.25983C143.023 3.28047 140.591 0 136.527 0Z'
          fill='#001D0F'
        />
        <path
          opacity='0.7'
          d='M136.061 28.9924H63.9217C59.5865 29.2206 55.8665 32.116 54.6075 36.2665L28.9253 131.942H59.3719L77.2707 65.3344C78.5298 61.1981 82.2641 58.2885 86.585 58.0603H128.263L136.075 29.0067'
          fill='#001D0F'
        />
        <path
          d='M128.252 58.0541H86.5734C82.2382 58.2823 78.5183 61.1919 77.2592 65.3282L59.3604 131.936H101.053C105.402 131.708 109.122 128.77 110.367 124.605L128.252 58.0541Z'
          fill='#001D0F'
        />
      </svg>
    </Box>
  );
}
