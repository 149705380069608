import { useCallback } from 'react';
import { CustomDimensionCellJSON } from '../../@types/shared';

import { useUpdateCategorizedStartupCustomDimensionsMutation } from 'apollo/generated/sdkInnovationManager';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { useSnackbar } from 'notistack';
import { DecoratedStartup } from './shared/StartupsTableContext';

export const useHandleCustomCellUpdate = () => {
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [updateCustomFeatures, { loading }] =
    useUpdateCategorizedStartupCustomDimensionsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleCustomCellUpdate = useCallback(
    async ({
      columnName,
      categorizedStartup,
      cellState,
    }: {
      columnName: string;
      categorizedStartup: DecoratedStartup;
      cellState: CustomDimensionCellJSON;
    }) => {
      try {
        const updated = {
          [columnName]: {
            value:
              typeof cellState.value === 'string'
                ? cellState.value?.trim() || ''
                : cellState?.value,
            reference: cellState.reference?.trim() || '',
          },
        };

        await updateCustomFeatures({
          variables: {
            id: categorizedStartup.categorized_startup_id,
            upsertPayload: {
              ...updated,
            },
          },
        });

        logStartupListActivity({
          logs: [
            {
              action: 'updated',
              entityIds: categorizedStartup.id,
              entityType: 'categorized_suppliers',
            },
          ],
          startupListId: categorizedStartup.startup_list_id,
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Error updating cell', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, updateCustomFeatures, logStartupListActivity],
  );

  return {
    handleCustomCellUpdate,
    loading,
  };
};
