import { Link } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  useGetAllPeopleQuery,
  useUpsertPersonActionMutation,
} from 'apollo/generated/sdkInnovationManager';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { AddPersonModal } from 'components/engagement/people/AddPersonModal';
import LoadingScreen from 'components/LoadingScreen';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { usePersonContext } from 'contexts/PersonContext';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { buildPublicChallengeUrl } from 'utils/url';
import { PersonForInsert } from '../../../../../@types/stakeholder';
import { Person } from '../../../../../@types/startupList';
import { EmailField } from '../../../../shared/EmailField';
import { ChallengeType } from '../../types';
import { useAddChallengeStakeholdersMutation } from 'apollo/generated/sdkShared';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useGetChallengeAndConnections } from 'hooks/useGetChallengeAndConnections';
import BaseAddButtonForAutocomplete from 'components/base/BaseAddButtonForAutocomplete';
import { useBaseStakeholderFilterOptions } from 'components/base/stakeholdersSelect/useBaseStakeholderFilterOptions';

const ShareChallengeModal = ({
  challenge,
  isOpen,
  onClose,
}: {
  challenge: ChallengeType;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data, loading: loadingStakeholders } = useGetAllPeopleQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { spacing } = useTheme();
  const [selectedStakeholders, setSelectedStakeholders] = useState<Person[]>(
    [],
  );
  const [upsertPersonAction] = useUpsertPersonActionMutation();
  const [addChallengeStakeholders] = useAddChallengeStakeholdersMutation();
  const { person } = usePersonContext();

  const { enqueueSnackbar } = useSnackbar();

  const { refetch } = useGetChallengeAndConnections({
    challengeID: challenge.id,
  });

  const url = useMemo(() => {
    if (!challenge?.public_uuid) return '';

    return buildPublicChallengeUrl({
      challengePublicUUID: challenge.public_uuid,
    });
  }, [challenge?.public_uuid]);
  const [addNewModalOpened, showAddNewModalOpened] = useState(false);

  const filterOptions = useBaseStakeholderFilterOptions();

  if (loadingStakeholders)
    return (
      <Box sx={{ height: '13rem', justifyContent: 'center' }}>
        <LoadingScreen />
      </Box>
    );

  const challengeStakeholders = challenge.challenge_stakeholders || [];

  if (!data?.people) return null;

  const notInvited = data.people
    .filter(p => !challengeStakeholders.find(st => st.stakeholder?.id === p.id))
    .filter(p => p.id !== person?.id);

  const people: typeof data.people = [...notInvited]
    .sort((a, b) => a.full_name.localeCompare(b.full_name))
    .map(p => ({
      ...p,
      department: p.organization_department?.name,
    }));

  const uniquePeople = uniqBy(people, (person: Person) => person.id);

  const handleInvite = async () => {
    await addChallengeStakeholders({
      variables: {
        challengeStakeholdersData: selectedStakeholders.map(stakeholder => ({
          challenge_id: challenge.id,
          person_id: stakeholder.id,
          role: 'other',
        })),
      },
    });

    setSelectedStakeholders([]);
    captureAnalyticsEvent('Challenge stakeholders invited via share modal', {
      challengeId: challenge.id,
    });
    enqueueSnackbar('Stakeholders invited', {
      variant: 'success',
    });
  };

  const handleAddPerson = async (object: PersonForInsert) => {
    try {
      const personCreated = await upsertPersonAction({
        variables: { object },
      });

      const person = personCreated.data?.upsert_person?.person;

      if (!person) {
        enqueueSnackbar('There was an error while adding the stakeholder', {
          variant: 'error',
        });
        return { stakeholderId: null };
      }

      setSelectedStakeholders(stakeholders => [...stakeholders, person]);
    } catch (error) {
      captureException(error);
      enqueueSnackbar('There was an error while adding the stakeholder', {
        variant: 'error',
      });
    }

    return { stakeholderId: null };
  };

  const handleClose = () => {
    refetch();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={handleClose}>
      <Stack>
        <TypographyWithEllipsis
          variant='h4'
          sx={{
            paddingX: 2,
            paddingY: 2,
            fontSize: `${spacing(3)}px !important`,
          }}
        >
          Share &quot;{challenge.title}&quot;
        </TypographyWithEllipsis>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Autocomplete
              multiple
              fullWidth
              filterOptions={filterOptions}
              filterSelectedOptions
              slotProps={{
                paper: {
                  sx: {
                    '& .MuiAutocomplete-noOptions': { padding: '0 !important' },
                  },
                },
              }}
              ListboxProps={{
                sx: {
                  '&.MuiAutocomplete-listbox': { padding: '0 !important' },
                },
              }}
              options={uniquePeople}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              disableCloseOnSelect
              value={selectedStakeholders}
              onChange={async (_e, newValue: Person[]) => {
                setSelectedStakeholders(newValue);
              }}
              noOptionsText={
                <BaseAddButtonForAutocomplete
                  text='New BU stakeholder'
                  onClick={() => showAddNewModalOpened(true)}
                  divider={false}
                />
              }
              getOptionLabel={option => option.full_name}
              renderOption={(props, option, state) => {
                const text = option.department
                  ? `${option.full_name} (${option.department})`
                  : option.full_name;

                return (
                  <>
                    {state.index === 0 && (
                      <BaseAddButtonForAutocomplete
                        onClick={() => showAddNewModalOpened(true)}
                        text='New BU stakeholder'
                      />
                    )}
                    <Box component='li' {...props}>
                      <BaseInitialsAvatar full_name={option.full_name} />
                      <Stack
                        sx={{
                          minWidth: 0,
                          flex: 1,
                          paddingY: 0.5,
                        }}
                      >
                        <TypographyWithEllipsis>{text}</TypographyWithEllipsis>
                        {option.full_name !== option.email && (
                          <EmailField ignoreTooltip value={option.email} />
                        )}
                      </Stack>
                    </Box>
                  </>
                );
              }}
              renderInput={({ inputProps, ...rest }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    sx={{ backgroundColor: 'white' }}
                    inputProps={{
                      ...inputProps,
                    }}
                    className='input-padding'
                    {...rest}
                    variant='outlined'
                    placeholder='Invite Initiative stakeholders'
                    InputProps={{
                      ...rest.InputProps,
                      endAdornment: (
                        <Stack>
                          {rest.InputProps.endAdornment}
                          {selectedStakeholders.length > 0 && (
                            <Button onClick={handleInvite}>Invite</Button>
                          )}
                        </Stack>
                      ),
                    }}
                  />
                </div>
              )}
            />
          </Box>
          <Divider sx={{ marginTop: 2 }} />
          <Stack direction='row' gap={1} marginTop={2}>
            <Stack direction='row' sx={{ marginLeft: 'auto' }} gap={1}>
              <CopyToClipboard
                text={url}
                onCopy={() => {
                  captureAnalyticsEvent('Challenge share link copied', {
                    challengeId: challenge.id,
                  });
                  enqueueSnackbar('Link copied', { variant: 'success' });
                }}
              >
                <Button
                  disabled={!url}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 120,
                    marginLeft: 'auto',
                  }}
                  startIcon={<Link />}
                >
                  Copy link
                </Button>
              </CopyToClipboard>
              <Button variant='contained' onClick={handleClose}>
                Done
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
      <AddPersonModal
        open={addNewModalOpened}
        onHide={() => showAddNewModalOpened(false)}
        onSetValues={handleAddPerson}
      />
    </Dialog>
  );
};

export default ShareChallengeModal;
