import { z } from 'zod';

export const scopeSectionOneSchema = () => {
  return z.object({
    title: z.string().min(1, {
      message: 'Title is required',
    }),
    needPainPoint: z.string().min(1, {
      message: 'Need/Pain point is required',
    }),
    desiredSolution: z.string().min(1, {
      message: 'Desired solution is required',
    }),
  });
};
