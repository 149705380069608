import { Radio, Stack } from '@mui/material';
import { useSelectStartupForPoc } from 'pages/dashboard/scoping/SelectedStartupActionButtons';
import React from 'react';

interface SelectForPocCellProps {
  categorizedStartup: {
    startup_id: number;
    categorized_startup_id: number;
    is_selected?: boolean | null;
    name: string;
    logo_url?: string | null;
  };
}

const SelectForPocCell: React.FC<SelectForPocCellProps> = ({
  categorizedStartup,
}) => {
  const { handleSelectForPoC } = useSelectStartupForPoc();
  return (
    <Stack
      width='100%'
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={{ cursor: 'pointer' }}
    >
      <Radio
        checked={categorizedStartup.is_selected ?? false}
        onChange={() =>
          handleSelectForPoC({
            selectedStartup: {
              id: categorizedStartup.categorized_startup_id,
              startup: {
                id: categorizedStartup.startup_id,
                name: categorizedStartup.name,
                logo_url: categorizedStartup.logo_url,
              },
            },
          })
        }
        color='primary'
        size='small'
        sx={{ stroke: ({ palette }) => palette.common.white, strokeWidth: 0.5 }}
      />
    </Stack>
  );
};

export { SelectForPocCell };
