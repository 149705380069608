import { captureException } from '@sentry/react';
import {
  useFilesWithSignedUrlsQuery,
  useGetDocumentsByProjectIdQuery,
  useGetDocumentsByStartupIdQuery,
  useInsertProjectStartupFileByPkMutation,
  useUpdateProjectFilesByPkMutation,
} from 'apollo/generated/sdkShared';
import { FileError } from 'errors';
import useFiles from 'hooks/useFiles';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  StartupForDocumentColumns,
  StartupListDocument,
  UploadProjectFileFunction,
} from './DocumentsTab';

const useDocuments = ({
  projectId,
  startupId,
  startups,
  skipFilesFetch = false,
}: {
  projectId?: number;
  startupId?: number;
  startups: StartupForDocumentColumns[];
  skipFilesFetch?: boolean; // TODO: Review after PR if it is still needed
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: documentsData,
    loading: loadingDocuments,
    error,
    refetch,
  } = useGetDocumentsByProjectIdQuery({
    variables: {
      projectId: projectId!,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipFilesFetch || !projectId,
  });

  const {
    data: startupFilesData,
    loading: loadingStartupFiles,
    error: startupFilesError,
    refetch: refetchStartupFiles,
  } = useGetDocumentsByStartupIdQuery({
    variables: {
      startupId: startupId!,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipFilesFetch || !startupId,
  });

  const refetchFiles = startupId ? refetchStartupFiles : refetch;

  const { fileIds, fileData } = useMemo(() => {
    return {
      fileIds: startupId
        ? startupFilesData?.project_startup_files.map(pf => pf.file_id)
        : documentsData?.projects_by_pk?.project_files.map(pf => pf.file_id),
      fileData: startupId
        ? startupFilesData?.project_startup_files
        : documentsData?.projects_by_pk?.project_files,
    };
  }, [
    startupId,
    documentsData?.projects_by_pk?.project_files,
    startupFilesData?.project_startup_files,
  ]);

  const skipFetchingSignedUrls = !fileIds || skipFilesFetch;

  const { data: filesWithSignedUrlsData, loading: loadingFilesWithSignedUrls } =
    useFilesWithSignedUrlsQuery({
      variables: {
        files_ids: fileIds!,
      },
      skip: skipFetchingSignedUrls,
    });

  const files: StartupListDocument[] = useMemo(() => {
    const filesWithSignedUrls =
      filesWithSignedUrlsData?.files_with_signed_urls?.data;

    // If there a files (new architecture) wait until the signed urls are fetched
    if (fileData?.length === 0 || !Array.isArray(filesWithSignedUrls))
      return [];

    const signedUrlByFileId = filesWithSignedUrls.reduce<
      Record<number, string>
    >((acc, file) => {
      acc[file.id] = file.signed_url;
      return acc;
    }, {});

    const projectFiles = (fileData ?? []).map(
      ({
        __typename,
        id,
        document_type,
        file_id,
        startup,
        comment,
        file,
        project,
      }) => ({
        __typename: __typename!,
        id,
        document_type,
        attachment_url: signedUrlByFileId[file_id],
        startup,
        comment,
        startup_name: startup?.name,
        file_id,
        file_name: file.name,
        project,
      }),
    );

    return [...projectFiles];
  }, [filesWithSignedUrlsData?.files_with_signed_urls?.data, fileData]);

  const [updateProjectFileByPk, { loading: updateFileLoading }] =
    useUpdateProjectFilesByPkMutation();

  const updating = updateFileLoading;

  const updateFile = async (
    newRow: StartupListDocument,
  ): Promise<StartupListDocument> => {
    let updatedRow = { ...newRow };

    if (updatedRow.startup_name) {
      const newStartup = startups.find(
        startup => startup!.name === updatedRow.startup_name,
      );

      if (!newStartup) return newRow;

      updatedRow = {
        ...updatedRow,
        startup: {
          ...newStartup,
        },
      };
    }

    try {
      console.log('[DEBUG]: Updating project file', updatedRow);
      await updateProjectFileByPk({
        variables: {
          projectFileId: updatedRow.id,
          updateData: {
            comment: updatedRow.comment,
            document_type: updatedRow.document_type,
            startup_id: updatedRow.startup?.id,
          },
        },
      });
      enqueueSnackbar('Document successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error saving changes', {
        variant: 'error',
      });
    }

    return updatedRow;
  };

  const uploadProjectFile = useUploadProjectFile(refetchFiles);

  return {
    files,
    loading:
      loadingDocuments || loadingFilesWithSignedUrls || loadingStartupFiles,
    updating,
    error: error || startupFilesError,
    updateFile,
    uploadProjectFile,
    refetch: refetchFiles,
  };
};

export const useUploadProjectFile = (refetch?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();

  const { uploadFile } = useFiles({ filePrefix: 'project_documents' });

  const [insertProjectFileByPk] = useInsertProjectStartupFileByPkMutation();

  const uploadProjectFile: UploadProjectFileFunction = async (
    file,
    metadata,
  ) => {
    if (!file) return;

    try {
      const insertedFile = await uploadFile(file, 2000);

      if (!insertedFile) return;

      await insertProjectFileByPk({
        variables: {
          object: {
            startup_id: Boolean(metadata.startup_id)
              ? metadata.startup_id!
              : null,
            project_id: Boolean(metadata.project_id)
              ? metadata.project_id!
              : null,
            comment: metadata.comment,
            document_type: metadata.document_type,
            file_id: insertedFile.id,
          },
        },
        update(cache, { data }) {
          const aggregateField = metadata.startup_id
            ? `project_startup_files_aggregate({"where":{"startup_id":{"_eq":${metadata.startup_id}}}})`
            : `project_startup_files_aggregate({"where":{"project_id":{"_eq":${metadata.project_id}}}})`;

          if (!data) return;
          cache.modify({
            fields: {
              [aggregateField](
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                existingAggregateRefs: any = {},
              ) {
                if (!existingAggregateRefs.aggregate) return;

                const newCount = existingAggregateRefs.aggregate.count + 1;

                return {
                  ...existingAggregateRefs,
                  aggregate: {
                    ...existingAggregateRefs.aggregate,
                    count: newCount,
                  },
                };
              },
            },
            id: `ROOT_QUERY`,
          });
        },
      });

      refetch && refetch();
    } catch (error) {
      // FileError comes from uploadFile and includes the validation error
      // and possible request errors
      if (error instanceof FileError) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      } else {
        // Here it would be an error cause by insertProjectFileByPk
        captureException(error);
        console.error(error);
        enqueueSnackbar('Error uploading document', {
          variant: 'error',
        });
      }
    }
  };

  return uploadProjectFile;
};

export default useDocuments;
