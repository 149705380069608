import { forwardRef } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

const BaseEllipsisTypographyOneLine = forwardRef<
  HTMLSpanElement,
  TypographyProps
>(({ children, ...rest }, ref) => {
  return (
    <Typography
      ref={ref}
      {...rest}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...rest.sx,
      }}
    >
      {children}
    </Typography>
  );
});

BaseEllipsisTypographyOneLine.displayName = 'BaseEllipsisTypographyOneLine';

export default BaseEllipsisTypographyOneLine;
