import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import PersonDetailsDrawer from 'components/engagement/people/details/DetailsDrawer';
import ScoutStartupsErrorBoundary from 'components/search/ScoutStartupsErrorBoundryComponent';
import { Props as ProjectsPageProps } from 'contexts/ProjectContext';
import { CategoryDetailsPageProps } from 'pages/CategoryDetailsPage';
import { ProjectStageChangeDialog } from 'pages/dashboard/scoping/ProjectStageChangeDialog';
import { WithProjectFiltersFromUrlProps } from 'pages/dashboard/scoping/withProjectFiltersFromUrl';
import OutboundRequestsPage from 'pages/startupCRM/OutboundRequestsPage';
import PitchInboxPage from 'pages/startupCRM/PitchInboxPage';
import StartupsLayout from 'pages/startupCRM/StartupsLayout';
import StartupsPage from 'pages/startupCRM/StartupsPage';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { Loadable } from './Loadable';
import { PATH_NAME, PATH_PARAMS, PATH_ROOT, pathForRoutes } from './paths';
import ChallengesPage from 'pages/engagement/ChallengesPage';
import ChallengeDetailsPage from 'pages/dashboard/ChallengeDetailsPage';
import SearchAssistantThreadPage from 'pages/search/SearchAssistantThreadPage';
import SearchTraditionalPage from 'pages/search/SearchTraditionalPage';
import SearchAssistantPage from 'pages/search/SearchAssistantPage';
import PainPointsPage from 'pages/opportunities/PainPointsPage';

const DeprecatedRoute = ({ newPath }: { newPath: string }) => {
  captureAnalyticsEvent('Deprecated Path Visited', {
    path: window.location.pathname,
  });

  return <Navigate to={newPath} />;
};

const Home = Loadable(lazy(() => import('../pages/HomePage')));

const LibraryLayout = Loadable(lazy(() => import('../layouts/LibraryLayout')));

const ListDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/ListDetailsPage')),
);
const CategoryDetailsPage = Loadable<CategoryDetailsPageProps>(
  lazy(() => import('../pages/CategoryDetailsPage')),
);
const ScoutStartupsPage = Loadable(
  lazy(() => import('../pages/dashboard/ScoutStartupsPage')),
);

// Engagement
// TODO: Create brand new request form page + new sourcing order management

const SourcingOrderPage = Loadable(
  lazy(() => import('../pages/request-form-v2/page/SourcingOrderPage')),
);

const LeadsListLayoutPage = Loadable(
  lazy(() => import('../pages/implementations/LeadsListLayoutPage')),
);

const LeadsKanbanBoardPage = Loadable(
  lazy(() => import('../pages/implementations/LeadsKanbanBoardPage')),
);

const LeadsAllPage = Loadable(
  lazy(() => import('../pages/implementations/LeadsAllPage')),
);

const LeadsRequestsPage = Loadable(
  lazy(() => import('../pages/implementations/LeadsRequestsPage')),
);

const LeadsSuggestionsPage = Loadable(
  lazy(() => import('../pages/implementations/LeadsSuggestionsPage')),
);

const PeopleBusinessUnitPage = Loadable(
  lazy(() => import('../pages/engagement/PeopleBusinessUnitPage')),
);

const OrganizationPortalBuilderPage = Loadable(
  lazy(
    () => import('../pages/organizationPortal/OrganizationPortalBuilderPage'),
  ),
);

const ActivitiesPage = Loadable(
  lazy(() => import('../pages/engagement/ActivitiesPage')),
);

// Projects

const ProjectDetailsPage = Loadable<ProjectsPageProps>(
  lazy(() => import('../pages/dashboard/ProjectDetailsPage')),
);
const ProjectLeadDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/ProjectLeadDetailsPage')),
);

const ProjectsContentPage = Loadable<WithProjectFiltersFromUrlProps>(
  lazy(() => import('../pages/dashboard/ProjectsContentPage')),
);

const AnalyticsPage = Loadable(
  lazy(() => import('../pages/analytics/AnalyticsPage')),
);

// Settings
const OrganizationSettingsPage = Loadable(
  lazy(() => import('../pages/settings/OrganizationSettingsPage')),
);

const UsersPage = Loadable(lazy(() => import('../pages/settings/UsersPage')));

const DepartmentsPage = Loadable(
  lazy(() => import('../pages/settings/DepartmentsPage')),
);

const TeamsPage = Loadable(lazy(() => import('../pages/settings/TeamsPage')));

const SettingsLayoutPage = Loadable(
  lazy(() => import('../pages/settings/SettingsLayoutPage')),
);

// Pain points
const PainPointDetailsPage = Loadable(
  lazy(() => import('../pages/request-form-v2/page/PainPointDetailsPage')),
);

const SearchPageLayout = Loadable(
  lazy(() => import('../pages/search/SearchPageLayout')),
);

const INTERNAL_ROUTES: RouteObject[] = [
  { path: '/', element: <Home /> },
  {
    path: PATH_NAME.projectLeads,
    element: <LeadsListLayoutPage />,
    children: [
      {
        index: true,
        element: <Navigate to={PATH_ROOT.projectLeads.list.active} />,
      },
      {
        path: 'active',
        element: <LeadsKanbanBoardPage />,
      },
      {
        path: 'all',
        element: <LeadsAllPage />,
      },
      {
        path: 'requests',
        element: <LeadsRequestsPage />,
      },
      {
        path: 'suggestions',
        element: <LeadsSuggestionsPage />,
      },
    ],
  },
  // This has to be here in cases where user is not logged in.
  // If this is not here, the router will go to 404 page
  {
    path: '/people',
    element: <DeprecatedRoute newPath={PATH_ROOT.stakeholders.root} />,
  },
  {
    path: `${PATH_NAME.personProfile}/:${PATH_PARAMS.personId}`,
    element: <PersonDetailsDrawer />,
  },
  {
    path: PATH_ROOT.changeProjectStage.dialog(
      `:${PATH_PARAMS.projectId}`,
      `:${PATH_PARAMS.currentStage}` as unknown as EnumTableProjectStagesEnum,
      `:${PATH_PARAMS.nextStage}` as unknown as EnumTableProjectStagesEnum,
    ),
    element: <ProjectStageChangeDialog />,
  },
  {
    path: pathForRoutes(PATH_ROOT.stakeholders.root),
    element: <PeopleBusinessUnitPage />,
  },
  {
    path: PATH_ROOT.activity.root,
    element: <ActivitiesPage />,
  },
  { path: PATH_ROOT.challenges.root, element: <ChallengesPage /> },
  {
    path: `/portal-builder/:${PATH_PARAMS.teamSlug}/:${PATH_PARAMS.pageSlug}`,
    element: <OrganizationPortalBuilderPage />,
  },
  {
    path: `/${PATH_NAME.challenges}/:${PATH_PARAMS.challengeId}`,
    element: <ChallengeDetailsPage />,
  },
  {
    path: PATH_NAME.sourcings,
    element: <DeprecatedRoute newPath={PATH_ROOT.sourcings.list} />,
  },
  {
    path: pathForRoutes(
      PATH_ROOT.projectLeads._details(`:${PATH_PARAMS.projectId}`),
    ),
    element: <ProjectLeadDetailsPage />,
  },
  { path: PATH_ROOT.root, element: <Home /> },

  {
    path: PATH_NAME.scout,
    element: (
      <ScoutStartupsErrorBoundary>
        <ScoutStartupsPage />
      </ScoutStartupsErrorBoundary>
    ),
  },
  {
    path: PATH_NAME.search,
    element: <SearchPageLayout />,
    children: [
      {
        path: PATH_NAME.classic,
        element: <SearchTraditionalPage />,
      },
      {
        path: PATH_NAME.assistant,
        element: <SearchAssistantPage />,
      },
      {
        path: `${PATH_NAME.assistant}/:${PATH_PARAMS.threadId}`,
        element: <SearchAssistantThreadPage />,
      },
    ],
  },
  {
    path: PATH_NAME.lists,
    element: <LibraryLayout />,
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.root),
    element: (
      <StartupsLayout currentTab='all'>
        <StartupsPage />
      </StartupsLayout>
    ),
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.all),
    element: (
      <StartupsLayout currentTab='all'>
        <StartupsPage />
      </StartupsLayout>
    ),
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.inbox),
    element: (
      <StartupsLayout currentTab='inbox'>
        <PitchInboxPage />
      </StartupsLayout>
    ),
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.requests),
    element: (
      <StartupsLayout currentTab='requests'>
        <OutboundRequestsPage />
      </StartupsLayout>
    ),
  },
  {
    path: `/${PATH_NAME.lists}/:${PATH_PARAMS.startupListId}`,
    element: <ListDetailsPage />,
  },
  {
    path: `/${PATH_NAME.lists}/:${PATH_PARAMS.startupListId}/${PATH_NAME.category}/:${PATH_PARAMS.categoryId}`,
    element: <CategoryDetailsPage isDiscoveryPage={false} />,
  },
  {
    path: `/${PATH_NAME.discovery}/:${PATH_PARAMS.startupListId}/${PATH_NAME.category}/:${PATH_PARAMS.categoryId}`,
    element: <CategoryDetailsPage isDiscoveryPage />,
  },
  {
    path: `/${PATH_NAME.discovery}/:${PATH_PARAMS.startupListId}`,
    element: <ListDetailsPage />,
  },
  {
    path: `/${PATH_NAME.lists}/:${PATH_PARAMS.startupListId}/${PATH_NAME.orderSourcing}`,
    element: <SourcingOrderPage />,
  },
  {
    path: `${PATH_NAME.pocs}/:${PATH_PARAMS.projectId}`,
    element: <ProjectDetailsPage scope='pocs' />,
  },
  {
    path: `${PATH_NAME.adoptions}/:${PATH_PARAMS.projectId}`,
    element: <ProjectDetailsPage scope='adoptions' />,
  },
  {
    path: PATH_NAME.orderSourcing,
    element: <SourcingOrderPage />,
  },
  {
    path: PATH_NAME.painPoints,
    element: <PainPointsPage />,
  },
  {
    path: `${PATH_NAME.painPoints}/:painPointId`,
    element: <PainPointDetailsPage />,
  },
  {
    path: PATH_NAME.pocs,
    element: <ProjectsContentPage isPoC scope='projectPoCFilters' />,
  },
  {
    path: PATH_NAME.adoptions,
    element: (
      <ProjectsContentPage isPoC={false} scope='projectAdoptionsFilters' />
    ),
  },
  {
    path: PATH_NAME.analytics,
    element: <AnalyticsPage />,
  },
  {
    path: PATH_NAME.settings,
    element: <SettingsLayoutPage />,
    children: [
      {
        path: PATH_NAME.organization,
        element: <OrganizationSettingsPage />,
      },
      {
        path: PATH_NAME.users,
        element: <UsersPage />,
      },
      {
        path: PATH_NAME.teams,
        element: <TeamsPage />,
      },
      {
        path: PATH_NAME.departments,
        element: <DepartmentsPage />,
      },
    ],
  },
];

export { INTERNAL_ROUTES };
