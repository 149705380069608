import { captureException, captureMessage } from '@sentry/react';
import {
  EnumTableLeadMaturityEnum,
  useCreateProjectServiceMutation,
} from 'apollo/generated/sdkInnovationManager';
import useAuth from 'hooks/useAuth';
import { useInsertProjectSourceTag } from 'hooks/useInsertProjectSourceTag';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';

export type CreateLeadSource =
  | 'Startup profile'
  | 'Search'
  | 'Manual Creation'
  | 'Link List to Lead Button'
  | 'New Challenge'
  | 'Leads assistant'
  | 'Leads Kanban List'
  | 'Leads Kanban Card';

export const DEFAULT_LEAD_TITLE_PREFIX = 'New Lead by';
export const useCreateLead = (skipNavigate: boolean = false) => {
  const [createProject, { loading }] = useCreateProjectServiceMutation();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { handleInsertTag } = useInsertProjectSourceTag();

  const handleCreateLead = async ({
    title,
    leadMaturityLevel,
    painPointOwnerId,
    source = 'Manual Creation',
    sourceTagIds,
    newTag,
  }: {
    title?: string;
    leadMaturityLevel?: EnumTableLeadMaturityEnum | null;
    painPointOwnerId?: number;
    source: CreateLeadSource;
    sourceTagIds?: number[];
    newTag?: string;
  }) => {
    try {
      let finalTagIds = [...(sourceTagIds ?? [])];

      // Add new custom tag if provided
      if (newTag?.trim()) {
        const tagId = await handleInsertTag(newTag.trim());
        if (tagId) {
          finalTagIds.push(tagId);
        } else {
          enqueueSnackbar('Failed to create custom source tag', {
            variant: 'error',
          });
          return;
        }
      }

      if (finalTagIds.length === 0) {
        const tagId = await handleInsertTag(source.trim());
        if (tagId) {
          finalTagIds = [tagId];
        }
      }

      const { data } = await createProject({
        variables: {
          object: {
            title:
              title ||
              `${DEFAULT_LEAD_TITLE_PREFIX} ${currentUser.displayName}`,
            stage: 'scoping',
            source: 'self_created',
            type_of_solution: 'unknown',
            lead_maturity: leadMaturityLevel,
            pain_point_owner_id: painPointOwnerId,
            source_tag_ids: finalTagIds,
          },
        },
      });

      const project = data?.create_project?.project;
      if (!project?.id) {
        captureMessage('Failed to create new lead');
        enqueueSnackbar('Failed to create new lead', { variant: 'error' });
        return;
      }

      captureAnalyticsEvent('Project Lead Created', {
        projectId: project.id,
        source,
      });

      if (skipNavigate) {
        return { project };
      }

      navigate(buildProjectDetailsPath({ id: project.id, stage: 'scoping' }));
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Failed to create new lead', { variant: 'error' });
    }
  };

  return {
    handleCreateLead,
    loading,
  };
};
