import { Button, Popover, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { CustomDimensionCellJSON } from '../../@types/shared';

import TextField from '@mui/material/TextField';
import { DecoratedStartup } from './shared/StartupsTableContext';
import { useHandleCustomCellUpdate } from './useHandleCustomCellUpdate';

export const EditCustomColumnReferencePopup = ({
  categorizedStartup,
  open,
  anchorEl,
  columnName,
  onClose,
}: {
  categorizedStartup: DecoratedStartup;
  open: boolean;
  anchorEl: HTMLElement | null;
  columnName: string;
  onClose: () => void;
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 0,
      }}
    >
      <EditCustomFeatureCellDialogContent
        columnName={columnName}
        categorizedStartup={categorizedStartup}
        onClose={onClose}
      />
    </Popover>
  );
};

const EditCustomFeatureCellDialogContent = ({
  columnName,
  categorizedStartup,
  onClose,
}: {
  columnName: string;
  categorizedStartup: DecoratedStartup;
  onClose: () => void;
}) => {
  const [initialReference] = useState(
    categorizedStartup.custom_dimensions_json_v1?.[columnName]?.reference || '',
  );
  const [cellState, setCellState] = useState<CustomDimensionCellJSON>(
    categorizedStartup.custom_dimensions_json_v1?.[columnName] || {
      reference: '',
      value: '',
    },
  );

  const { handleCustomCellUpdate, loading } = useHandleCustomCellUpdate();
  const handleSubmit = useCallback(() => {
    handleCustomCellUpdate({
      columnName,
      categorizedStartup,
      cellState,
    });
    onClose();
  }, [
    handleCustomCellUpdate,
    cellState,
    columnName,
    categorizedStartup,
    onClose,
  ]);

  return (
    <Stack justifyContent='center' spacing={2} padding={1}>
      <Typography
        component='div'
        display='flex'
        position='relative'
        width='100%'
        height='100%'
        sx={{ cursor: 'pointer' }}
      >
        <Stack direction='row' gap={2}>
          <Stack flex={1}>
            <TextField
              hiddenLabel
              autoFocus
              fullWidth
              multiline
              variant='outlined'
              value={cellState.reference}
              onKeyDown={event => {
                event.stopPropagation();
                if (
                  event.key === 'Enter' &&
                  !event.shiftKey &&
                  !event.ctrlKey &&
                  !event.metaKey
                ) {
                  handleSubmit();
                }
              }}
              onChange={event => {
                const refValue = event.target.value;
                setCellState(prev => ({
                  ...prev,
                  reference: refValue,
                }));
              }}
            />
          </Stack>
        </Stack>
      </Typography>
      <Button disabled={loading} onClickCapture={handleSubmit}>
        {initialReference ? 'Update' : 'Add'} reference
      </Button>
    </Stack>
  );
};
