import { Box, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AddNoteButtonShared } from 'components/startupComparisonTable/shared/AddNoteButtonShared';
import {
  DecoratedStartup,
  StartupsContextType,
} from 'components/startupComparisonTable/shared/StartupsTableContext';
import { VotingButtonInternal } from 'components/startupComparisonTable/voting/VotingButtonInternal';
import { VotingButtonShared } from 'components/startupComparisonTable/voting/shared/VotingButtonShared';

const ReactionsCell = (
  params: GridRenderCellParams<DecoratedStartup>['row'] & {
    isDiscovery?: boolean;
    isPublicPage: boolean;
    id: number;
    votingNotificationSent: boolean;
    setState: StartupsContextType['setState'];
  },
) => {
  const {
    isDiscovery,
    isPublicPage,
    setState,
    votingNotificationSent,
    ...votingParams
  } = params;

  return (
    <Stack
      width='100%'
      direction='row'
      justifyContent='center'
      spacing={0.5}
      data-testid='reactions-column-cell'
    >
      {!isDiscovery && (
        <Box data-testid='voting-container'>
          {isPublicPage ? (
            <VotingButtonShared
              decoratedStartup={votingParams}
              setState={setState}
              votingNotificationSent={votingNotificationSent}
            />
          ) : (
            <VotingButtonInternal decoratedStartup={votingParams} />
          )}
        </Box>
      )}

      <AddNoteButtonShared
        startupId={params.id}
        startupListId={votingParams.startup_list_id}
      />
    </Stack>
  );
};

export { ReactionsCell };
