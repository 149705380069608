import { Box, BoxProps } from '@mui/material';
import { useTrackPage } from 'plugins/PosthogAnalytics';
import { ReactNode, createContext, forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
  trackingTitle: string;
  noStyles?: boolean;
}

export const PageTrackingContext = createContext<{ title?: string }>({});

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', trackingTitle, sx, noStyles, ...other }, ref) => {
    useTrackPage({ title: trackingTitle });

    return (
      <Box
        ref={ref}
        {...other}
        {...(!noStyles && {
          sx: {
            marginX: 3,
            paddingLeft: ({ spacing }) => spacing(2),
            paddingRight: ({ spacing }) => spacing(2),
            ...sx,
          },
        })}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <PageTrackingContext.Provider value={{ title: trackingTitle }}>
          {children}
        </PageTrackingContext.Provider>
      </Box>
    );
  },
);

Page.displayName = 'Page';

export default Page;
