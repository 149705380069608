import { Clear } from '@mui/icons-material';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useGetFeatureFlag } from 'contexts/FeatureFlagsContext';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { BannerStyle } from './StyledBanner';

const UPDATE_TYPE = 'Apr-2025-Nav-Rework';
const SHOW_CTA = false;

export const FeaturesUpdateBanner = () => {
  const navigate = useNavigate();
  const { content: bannerContent, updateReleaseTimestamp } =
    useGetUpdatesReleaseBanner();

  const { isFeaturesUpdatesBannerVisible, setIsFeaturesUpdatesBannerVisible } =
    useFeaturesUpdatesBanner();

  if (
    !isFeaturesUpdatesBannerVisible ||
    window.localStorage.getItem(updateReleaseTimestamp)
  ) {
    return null;
  }

  const handleDismiss = () => {
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
    setIsFeaturesUpdatesBannerVisible(false);
  };

  const handleTakeALook = () => {
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
    setIsFeaturesUpdatesBannerVisible(false);
    navigate(PATH_ROOT.painPoints.root);
  };

  return (
    <BannerStyle sx={({ palette }) => ({ background: palette.primary.main })}>
      {bannerContent}
      <Stack direction='row' gap={1} alignItems='center'>
        {SHOW_CTA && (
          <Button
            variant='outlined'
            sx={{
              paddingY: 0.5,
              fontSize: '0.75rem',
              color: 'white',
              border: '1px solid white',
              '&:hover': {
                border: '1px solid white',
              },
            }}
            onClick={handleTakeALook}
          >
            Explore
          </Button>
        )}
        <Button
          sx={{
            color: 'white',
            minWidth: '0 !important',
          }}
          onClick={handleDismiss}
        >
          <Clear fontSize='medium' />
        </Button>
      </Stack>
    </BannerStyle>
  );
};

export const useGetUpdatesReleaseBanner = () => {
  const { isEnabled } = useGetFeatureFlag('ff_new_features_released');

  const content = useMemo(
    () => (
      <Stack direction='row' gap={1}>
        <Chip
          label='NEW'
          size='small'
          sx={{
            backgroundColor: 'white',
            color: 'primary.main',
            fontWeight: 800,
            borderRadius: '0.25rem',
          }}
        />
        <Typography variant='body2' fontWeight='800' color='white'>
          Navigation just got an update!{' '}
          <span style={{ fontWeight: 400 }}>
            More focus on the opportunities to innovate inside your organization
            - so pain-points get their own tab and Challenges become
            Initiatives.
          </span>
        </Typography>
      </Stack>
    ),
    [],
  );

  const isUpdateReleased =
    isEnabled && !window.localStorage.getItem(UPDATE_TYPE);

  return {
    content,
    updateReleaseTimestamp: UPDATE_TYPE,
    isNewUpdateReleased: !!isUpdateReleased,
  };
};
