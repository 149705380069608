import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/react';
import { useEnrichSupplierMutation } from 'apollo/generated/sdkShared';
import { FALLBACK_SUPPLIER_LOGO_URL, LogoAvatar } from 'components/AvatarGroup';
import { AddFeatureButton } from 'components/dashboard/sourcingOrder/SourcingOrderScopeContent';
import { NAV_TEXT_GREY } from 'components/NavSection';
import { FormLabelWithTooltip } from 'components/requestFormFields/ProblemField';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
// import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { KnownStartup } from '../../../../@types/startupList';

type SourcingOrderState = {
  known_startups: KnownStartup[];
};

const SUPPLIER_SCHEMA: Yup.Schema<KnownStartup> = Yup.object({
  domain: Yup.string().required('Domain is required'),
  name: Yup.string().required('Name is required'),
  logo: Yup.string().nullable(),
  product: Yup.string(),
  excludeWhenBenchmarking: Yup.boolean().required(),
});

export const SupplierIcon = styled('div')({
  height: '52px',
  width: '52px',
  borderRadius: '50%',
  border: '1px solid #919EAB3D',
});

type AddStartupsProps = {
  knownStartups: KnownStartup[];
  handleSave: ({
    fieldName,
    fieldValue,
  }: {
    fieldName: 'known_startups';
    fieldValue: KnownStartup[];
  }) => void;
  editModeEnabled: boolean;
  triggerSaveFieldConfirmation?: (
    onSaveConfirmed: () => void,
    isValueUnchanged: boolean,
  ) => void;
};

type KnownStartupsProps = {
  knownStartups: KnownStartup[];
  state?: SourcingOrderState;
  cardClassName?: string;
  titleSx?: SxProps;
  handleSave: ({
    fieldName,
    fieldValue,
  }: {
    fieldName: 'known_startups';
    fieldValue: KnownStartup[];
  }) => void;
  showTooltip?: boolean;
  triggerSaveFieldConfirmation?: (
    onSaveConfirmed: () => void,
    isValueUnchanged: boolean,
  ) => void;
};

function AddStartups({
  knownStartups = [],
  handleSave,
  editModeEnabled,
  triggerSaveFieldConfirmation,
}: AddStartupsProps) {
  const [isSuppliersPopup, setSuppliersPopup] = useState(false);
  const showSuppliersPopup = useCallback(() => setSuppliersPopup(true), []);
  const hideSuppliersPopup = useCallback(() => setSuppliersPopup(false), []);

  const addSupplier = (supplier: KnownStartup) =>
    handleSave({
      fieldName: 'known_startups',
      fieldValue: [...(knownStartups || []), supplier],
    });

  return (
    <Stack direction='row' gap={1}>
      {knownStartups.length > 0 && (
        <StartupChips
          knownStartups={knownStartups}
          onChange={(startups: KnownStartup[]) => {
            handleSave({ fieldName: 'known_startups', fieldValue: startups });
          }}
          editModeEnabled={editModeEnabled}
          triggerSaveFieldConfirmation={triggerSaveFieldConfirmation}
        />
      )}
      {(editModeEnabled || knownStartups.length === 0) && (
        <AddFeatureButton onClick={showSuppliersPopup} />
      )}
      {isSuppliersPopup && (
        <StartupsPopup
          onHide={hideSuppliersPopup}
          onSetValues={addSupplier}
          triggerSaveFieldConfirmation={triggerSaveFieldConfirmation}
        />
      )}
    </Stack>
  );
}

const StartupChips = ({
  knownStartups,
  onChange,
  editModeEnabled,
  triggerSaveFieldConfirmation,
}: {
  knownStartups: KnownStartup[];
  onChange: (startups: KnownStartup[]) => void;
  editModeEnabled: boolean;
  triggerSaveFieldConfirmation?: (
    onSaveConfirmed: () => void,
    isValueUnchanged: boolean,
  ) => void;
}) => {
  return (
    <Stack flexWrap='wrap' direction='row' gap={1}>
      {knownStartups.map((option, index) => (
        <Chip
          sx={({ spacing }) => ({
            borderRadius: 1,
            height: spacing(3.5),
            paddingX: 0,
            cursor: editModeEnabled ? 'pointer' : 'default',
          })}
          key={option.domain}
          data-tag-index={index}
          deleteIcon={
            editModeEnabled ? (
              <CloseOutlined
                fontSize='small'
                sx={({ spacing }) => ({
                  width: spacing(2),
                  height: spacing(2),
                  fill: NAV_TEXT_GREY,
                  marginRight: 1,
                })}
              />
            ) : undefined
          }
          onDelete={
            editModeEnabled
              ? e => {
                  e.stopPropagation();
                  triggerSaveFieldConfirmation
                    ? triggerSaveFieldConfirmation(
                        () =>
                          onChange(
                            knownStartups.filter(
                              startup => startup.domain !== option.domain,
                            ),
                          ),
                        true,
                      )
                    : onChange(
                        knownStartups.filter(
                          startup => startup.domain !== option.domain,
                        ),
                      );
                }
              : undefined
          }
          label={
            <Stack alignItems='center' direction='row' gap={0.5}>
              <LogoAvatar
                src={option.logo_url || FALLBACK_SUPPLIER_LOGO_URL}
                size={20}
              />
              <Typography variant='caption'>{option.name}</Typography>
            </Stack>
          }
        />
      ))}
    </Stack>
  );
};

type SuppliersPopupProps = {
  onHide: () => void;
  onSetValues: (supplier: KnownStartup) => void;
  triggerSaveFieldConfirmation?: (
    onSaveConfirmed: () => void,
    isValueUnchanged: boolean,
  ) => void;
};

const ENRICHMENT_FAILED_MESSAGE = 'Failed to enrich supplier';

function StartupsPopup(props: SuppliersPopupProps) {
  const {
    onSetValues,
    onHide: originalOnHide,
    triggerSaveFieldConfirmation,
  } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [enrichSupplier, { loading: enriching, called: enrichCalled }] =
    useEnrichSupplierMutation();

  const formik = useFormik<KnownStartup>({
    initialValues: {
      domain: '',
      name: '',
      logo: '',
      employees_count: null,
      hq: '',
      short_description: '',
      long_description: '',
      linkedin_url: '',
      founded: null,
      website: '',
      crunchbase_url: '',
      funding: null,
      excludeWhenBenchmarking: false,
    },
    validationSchema: SUPPLIER_SCHEMA,
    onSubmit: async values => {
      if (triggerSaveFieldConfirmation) {
        triggerSaveFieldConfirmation(() => onSetValues(values), true);
      } else {
        onSetValues(values);
      }
      originalOnHide();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  // Cannot pass inline function here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEnrichment = useCallback(
    debounce((domain: string) => {
      let sanatizedDomain = domain;
      try {
        sanatizedDomain = new URL(sanatizedDomain).hostname.replace('www.', '');
      } catch (_e) {
        sanatizedDomain = sanatizedDomain.replace(/(www\.|https?:\/\/)/, '');
        sanatizedDomain = sanatizedDomain.split('/')[0];
      }
      if (sanatizedDomain !== domain) {
        setFieldValue('domain', sanatizedDomain);
      } else {
        const snackbarKey = enqueueSnackbar(
          `Enrichment started for: ${domain}`,
          {
            variant: 'info',
          },
        );

        enrichSupplier({ variables: { domain } })
          .then(({ data: responseData }) => {
            const { DEPRECATED_enrich_supplier } = responseData!;
            closeSnackbar(snackbarKey);

            if (!DEPRECATED_enrich_supplier.success) {
              enqueueSnackbar(ENRICHMENT_FAILED_MESSAGE, { variant: 'error' });
              return;
            }

            const fieldsToUpdate = [
              'logo_url',
              'name',
              'employees_count',
              'hq',
              'short_description',
              'long_description',
              'linkedin_url',
              'founded',
              'website',
              'crunchbase_url',
              'funding',
            ];

            fieldsToUpdate.forEach(field => {
              setFieldValue(field, DEPRECATED_enrich_supplier.data[field]);
            });
            enqueueSnackbar('Enrichment successful', { variant: 'success' });
          })
          .catch(e => {
            captureException(e);
            enqueueSnackbar(ENRICHMENT_FAILED_MESSAGE, { variant: 'error' });
          });
      }
    }, 1500),
    [enqueueSnackbar, closeSnackbar, setFieldValue, enrichSupplier],
  );

  useEffect(() => {
    if (values.domain.trim() === '' || errors.domain) {
      return;
    }

    handleEnrichment(values.domain);
  }, [values.domain, errors.domain, handleEnrichment]);

  return (
    <Dialog open={true} fullWidth onClose={originalOnHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add known solution(s)</DialogTitle>
          <DialogContent sx={{ marginTop: '25px', overflowY: 'hidden' }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: '5px' }}>
              <TextField
                autoFocus
                {...getFieldProps('domain')}
                error={Boolean(touched.domain && errors.domain)}
                helperText={touched.domain && errors.domain}
                fullWidth
                label='Please paste the website to ensure we review the right solution.'
              />
              {enrichCalled && !enriching && (
                <>
                  <Stack direction='row' spacing={1}>
                    <Box>
                      <SupplierIcon
                        style={{
                          background: `center / contain no-repeat url("${
                            values.logo as string
                          }")`,
                        }}
                      />
                    </Box>
                    <TextField
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      disabled={enriching}
                      fullWidth
                      label='Enter the name of the solution you know'
                    />
                  </Stack>
                  <FormControlLabel
                    label='Exclude this provider when benchmarking solutions'
                    disabled={enriching}
                    control={
                      <Checkbox {...getFieldProps('excludeWhenBenchmarking')} />
                    }
                  />
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={originalOnHide}>
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enriching}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

export function KnownStartupsField({
  knownStartups,
  handleSave,
  showTooltip,
  triggerSaveFieldConfirmation,
}: KnownStartupsProps) {
  const { isSharedPage } = useSharedPagesContext();
  const [editKnownStartups, setEditKnownStartups] = useState(false);

  const tooltipText = isSharedPage
    ? `Are you already using a provider in this area from which you'd like
    to switch, or are you simply aware of some providers that offer a
    suitable solution? Please add the solutions that you know of.`
    : `Are your stakeholders already using a provider in this area from
    which they'd like to switch, or are they simply aware of some providers
    that offer a suitable solution? Please add the solutions that you know
    of.`;

  return (
    <>
      <FormLabelWithTooltip
        label='Known solutions'
        tooltipText={tooltipText}
        sx={{ marginTop: 0 }}
        showTooltip={showTooltip}
      />
      <Stack
        direction='row'
        alignItems='center'
        flexWrap='wrap'
        gap={1}
        sx={{
          padding: '4px 2px',
          borderRadius: '8px',
          '&:hover': {
            // background:
            //   !editKnownStartups && knownStartups.length > 0
            //     ? '#F8F8F8'
            //     : 'none',
            cursor: 'text',
          },
        }}
        onMouseEnter={() => {
          setEditKnownStartups(true);
        }}
        onMouseLeave={() => {
          setEditKnownStartups(false);
        }}
      >
        <AddStartups
          knownStartups={knownStartups}
          handleSave={handleSave}
          editModeEnabled={editKnownStartups}
          triggerSaveFieldConfirmation={triggerSaveFieldConfirmation}
        />
      </Stack>
    </>
  );
}
