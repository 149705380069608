import { useState } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { BaseInlineSelectOption } from 'components/base/BaseInlineSelectOption';
import { MAX_BODY_WIDTH, SolutionCategory } from './ConversationMessage';

export const SolutionCategoriesSelect = ({
  solutionCategories,
  submitMessage,
  newMessageLoading,
}: {
  solutionCategories: SolutionCategory[];
  submitMessage: (message: string) => void;
  newMessageLoading: boolean;
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const selectOptionsDisabled = selectedIds.length >= 3;

  const selectedCategoriesAsText = selectedIds
    .map(id => {
      const category = solutionCategories.find(option => option.id === id);
      return category?.name;
    })
    .join(', ');

  return (
    <Box maxWidth={MAX_BODY_WIDTH}>
      <Stack>
        {solutionCategories.map(option => (
          <Tooltip
            key={option.id}
            title={
              selectOptionsDisabled && !selectedIds.includes(option.id)
                ? 'Please select up to 3 topics. Remove a topic to select another.'
                : ''
            }
            placement='right'
          >
            <span>
              <BaseInlineSelectOption
                key={option.id}
                disabled={
                  selectOptionsDisabled && !selectedIds.includes(option.id)
                }
                categoryOption={option}
                isChecked={selectedIds.includes(option.id)}
                handleSelect={(categoryOption, checked) => {
                  setSelectedIds(
                    checked
                      ? [...selectedIds, categoryOption.id]
                      : selectedIds.filter(id => id !== categoryOption.id),
                  );
                }}
              />
            </span>
          </Tooltip>
        ))}
      </Stack>
      <Stack
        direction='row'
        marginTop={1}
        paddingTop={1}
        spacing={2}
        position={'sticky'}
        bottom={0}
        sx={{ zIndex: 10, backgroundColor: 'white' }}
      >
        <Tooltip
          title={
            !selectedCategoriesAsText
              ? 'Select topic to proceed'
              : newMessageLoading
                ? 'Assistant is responding...'
                : ''
          }
        >
          <span>
            <Button
              variant='contained'
              color='primary'
              disabled={!selectedCategoriesAsText || newMessageLoading}
              onClick={() => {
                submitMessage(
                  `Suggest solutions for: ${selectedCategoriesAsText}`,
                );
              }}
            >
              Search solutions
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={newMessageLoading ? 'Assistant is responding...' : ''}>
          <span>
            <Button
              variant='text'
              color={'primary'}
              disabled={newMessageLoading}
              onClick={() => {
                submitMessage(
                  selectedCategoriesAsText
                    ? `Explore sub-topics for: ${selectedCategoriesAsText}`
                    : 'Recommend me more topics.',
                );
              }}
            >
              {selectedCategoriesAsText
                ? 'Explore Sub-Topics'
                : 'Recommend more'}
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Box>
  );
};
