import { captureMessage } from '@sentry/react';
import { useSendMailMutation } from 'apollo/generated/sdkInnovationManager';
import { useGetStartupListForNotifyListOwnerForVoteQuery } from 'apollo/generated/sdkShared';

import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useMemo } from 'react';
import { buildListDetailsUrl } from 'utils/url';

const useNotifyInnovationManagerForVote = ({
  startupListId,
}: {
  startupListId: number;
}) => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const [sendMail] = useSendMailMutation();
  const { data: startupListData } =
    useGetStartupListForNotifyListOwnerForVoteQuery({
      variables: { startupListId },
    });

  const { listOwner, title } = useMemo(() => {
    const listOwner =
      startupListData?.startup_lists[0].sourcing_order?.created_by?.person;
    return { listOwner, title: startupListData?.startup_lists[0].title };
  }, [startupListData]);

  const sendEmailNotification = async ({
    stakeholderFullName,
  }: {
    stakeholderFullName: string;
  }) => {
    if (!listOwner) {
      captureMessage(
        `No email was sent because nobody was assigned to list ${startupListId}`,
      );
    } else {
      await sendMail({
        variables: {
          emailPayload: {
            // Edit here:
            // https://mc.sendgrid.com/dynamic-templates/d-9fb07424f84c4a0fb6c3b682e2556093/edit
            templateId: 'd-9fb07424f84c4a0fb6c3b682e2556093',
            from: {
              name: currentOrganization.from_name,
              email: currentOrganization.from_email,
            },
            to: [listOwner.email],
            reply_to: currentOrganization.reply_to_email,
            templateData: {
              innovation_manager_name: listOwner.full_name,
              stakeholder_full_name: stakeholderFullName,
              startup_list_title: title,
              resource_url: buildListDetailsUrl(startupListId),
            },
          },
        },
      });
    }
  };

  return [sendEmailNotification];
};

export default useNotifyInnovationManagerForVote;
