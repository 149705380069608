import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useGetProjectsAndLeadsMinimalQuery } from 'apollo/generated/sdkShared';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import UploadSingleFile, {
  CustomFile,
} from 'components/upload/UploadSingleFile';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { DOCUMENT_TIMELINE_STEPS_TYPE } from 'pages/dashboard/scoping/documents/PocDocumentsField';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import React, { useCallback, useMemo, useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  DOCUMENT_TYPE_OPTIONS,
  UploadMetadata,
  UploadProjectFileFunction,
  UploadSchema,
} from './DocumentsTab';

export type UploadModalStartup = {
  id: number;
  name: string;
};

type DocumentTypeOptionsMapType = {
  [K in keyof DOCUMENT_TIMELINE_STEPS_TYPE]?:
    | 'NDA'
    | 'Request for Proposal (RfP)'
    | 'Proposal Template (Offer Template)'
    | 'Purchase Order (PO)'
    | undefined;
};

export const DOCUMENT_TYPE_OPTIONS_MAP: DocumentTypeOptionsMapType = {
  NDA: 'NDA',
  RfP: 'Request for Proposal (RfP)',
  'Offer Proposal': 'Proposal Template (Offer Template)',
  'Purchase Order': 'Purchase Order (PO)',
} as const;

export const UploadModal = ({
  addNewModalOpen,
  setAddNewModalOpen,
  startups,
  projectId,
  uploadProjectFile,
  defaultDocument,
  selectedStartupId,
}: {
  setAddNewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addNewModalOpen: boolean;
  startups: UploadModalStartup[];
  projectId?: number;
  uploadProjectFile: UploadProjectFileFunction;
  defaultDocument?: keyof DocumentTypeOptionsMapType;
  selectedStartupId?: number;
}) => {
  const [file, setFile] = useState<CustomFile | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { normalizedDefaultFilter: projectsFilter } =
    useServerFiltersContext('projectPoCFilters');

  const {
    data: projectLeadsData,
    loading: projectLeadsLoading,
    error,
  } = useGetProjectsAndLeadsMinimalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: { _or: [projectsFilter] },
    },
  });

  const projectOptions = useMemo(
    () =>
      projectLeadsData?.projects
        .slice()
        .sort(a => (a.id === projectId ? -1 : 1)) || [],
    [projectLeadsData?.projects, projectId],
  );

  const closeModal = () => {
    setAddNewModalOpen(false);
    setIsUploading(false);
    resetForm();
    setFile(null);
  };

  const handleDropSingleFile = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setFile(file);
      }
    },
    [],
  );

  const formik = useFormik<UploadMetadata>({
    initialValues: {
      document_type:
        DOCUMENT_TYPE_OPTIONS_MAP[
          defaultDocument as keyof DOCUMENT_TIMELINE_STEPS_TYPE
        ] ?? '',
      ...(selectedStartupId && { startup_id: selectedStartupId }),
      ...(projectId && { project_id: projectId }),
      is_signed: false,
      signed_at: null,
      comment: '',
    },
    validationSchema: toFormikValidationSchema(UploadSchema),
    onSubmit: async (values, { resetForm }) => {
      setIsUploading(true);

      try {
        await uploadProjectFile(file, values);
        enqueueSnackbar('Upload successful', { variant: 'success' });
        projectId &&
          captureAnalyticsEvent('Startup List Document Added', {
            type: values.document_type,
            projectId,
          });
        setAddNewModalOpen(false);
        setFile(null);
        resetForm();
      } catch (error) {
        captureException(error);

        console.error(error);
      } finally {
        setIsUploading(false);
      }
    },
  });

  const { handleSubmit, getFieldProps, resetForm } = formik;

  if (projectLeadsLoading) return <Skeleton sx={{ minWidth: '170px' }} />;
  if (error) return <Typography color='error'>Error</Typography>;

  return (
    <Dialog
      style={{ minWidth: '700px' }}
      fullWidth
      open={addNewModalOpen}
      onClose={closeModal}
    >
      <DialogTitle sx={{ marginBottom: 3 }}>Upload a document</DialogTitle>
      <DialogContent sx={{ color: 'grey.600' }}>
        <Card sx={({ shadows }) => ({ boxShadow: shadows[10] })}>
          <UploadSingleFile file={file} onDrop={handleDropSingleFile} />
        </Card>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <TextField
              select
              fullWidth
              label='Document Type *'
              {...getFieldProps('document_type')}
              helperText='Please select document type'
              sx={{ mt: 3 }}
            >
              {DOCUMENT_TYPE_OPTIONS.map((value, index) => (
                <MenuItem key={`${value} + ${index}`} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id='upload-file-startup-id-label'>Startup</InputLabel>
              <Select
                {...getFieldProps('startup_id')}
                fullWidth
                displayEmpty
                label='Startup'
                labelId='upload-file-startup-id-label'
                renderValue={selected => {
                  if (selected === 0) {
                    return '';
                  }
                  return startups.find(s => s.id === selected)?.name || '';
                }}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                {startups.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Please select startup</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id='upload-file-project-id-label'>Project</InputLabel>
              <Select
                {...getFieldProps('project_id')}
                fullWidth
                displayEmpty
                label='Project'
                labelId='upload-file-project-id-label'
                renderValue={selected => {
                  if (selected === 0) {
                    return '';
                  }
                  return (
                    projectOptions.find(proj => proj.id === selected)?.title ||
                    ''
                  );
                }}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                {projectOptions.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Please select project</FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              multiline
              minRows={3}
              maxRows={5}
              label='Comment'
              {...getFieldProps('comment')}
              sx={{ mt: 3 }}
              {...(defaultDocument && file ? { focused: true } : {})}
            />

            <DialogActions
              sx={{ paddingX: '0 !important', paddingBottom: '0 !important' }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Button
                type='button'
                variant='outlined'
                color='inherit'
                onClick={closeModal}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isUploading}
                loadingIndicator='Uploading..'
                disabled={
                  defaultDocument && file
                    ? false
                    : !(formik.isValid && formik.dirty && file)
                }
              >
                Upload
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
