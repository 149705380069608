import { Add, ClearOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  autocompleteClasses,
  filledInputClasses,
  inputLabelClasses,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  BaseMultiselectProps,
  CustomNoOptions,
  CustomOption,
  Tag,
  ValueTag,
} from 'components/base/BaseMultiselect';
import { differenceBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';

interface BaseTagsSelectProps extends BaseMultiselectProps {
  loading?: boolean;
  inputPlaceholder?: string;
}

type AutocompleteInputProps = TextFieldProps & {
  inputTextValue: string;
  setInputTextValue: React.Dispatch<React.SetStateAction<string>>;
  params: AutocompleteRenderInputParams;
  inputPlaceholder?: string;
};

type SkeletonLoaderProps = {
  spacing: string;
};

type TagsContainerProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

type TagChipProps = {
  label: string;
  onDelete?: () => void;
  theme: Theme;
  isRemoveable?: boolean;
};

type EditModeProps = {
  filteredOptions: Tag[];
  inputTextValue: string;
  setInputTextValue: React.Dispatch<React.SetStateAction<string>>;
  handleOnKeyDown: (e: React.KeyboardEvent) => Promise<void>;
  handleCreateTag: () => Promise<void>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  buttonRef: React.RefObject<HTMLDivElement>;
  editMode: boolean;
  value: Tag[];
  onAdd: (value: string) => Promise<void>;
  onInsert?: (input: string) => Promise<void>;
  inputPlaceholder?: string;
  newOptionText?: string;
};

type AddTagButtonProps = {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  theme: Theme;
  value: Tag[];
  inputPlaceholder?: string;
};

export const BaseTagsSelect = ({
  options,
  value,
  onAdd,
  onInsert,
  onRemove,
  loading,
  sx,
  addedTagIds,
  inputPlaceholder = 'Add tag',
  newOptionText,
}: BaseTagsSelectProps) => {
  const [inputTextValue, setInputTextValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [firstTag, ...remainingTags] = value;

  const tooltipStyle = {
    tooltip: {
      sx: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        boxShadow: theme.shadows[2],
        maxWidth: 'none',
        padding: '0.5rem',
      },
    },
  };

  const filteredOptions = useMemo(
    () =>
      differenceBy(
        options.filter(option =>
          option.description
            .toLowerCase()
            .includes(inputTextValue.toLowerCase()),
        ),
        value,
        'description',
      ),
    [inputTextValue, options, value],
  );

  const handleCreateTag = useCallback(async () => {
    if (!inputTextValue || !onInsert) return;

    try {
      await onInsert(inputTextValue);
      setInputTextValue('');
    } catch (e) {
      console.log(e);
      captureException(e);
      enqueueSnackbar('Error creating tag', { variant: 'error' });
    }
  }, [enqueueSnackbar, onInsert, inputTextValue]);

  const handleOnKeyDown = useCallback(
    async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const matchingOption = options.find(
          option =>
            option.description.toLowerCase() ===
            inputTextValue.trim().toLowerCase(),
        );

        setEditMode(false);
        if (matchingOption) {
          await onAdd(matchingOption.value);
          setInputTextValue('');
        } else if (inputTextValue.trim() !== '') {
          await handleCreateTag();
        }
      }
    },
    [inputTextValue, options, onAdd, handleCreateTag],
  );

  if (loading) {
    return <SkeletonLoader spacing={theme.spacing(2)} />;
  }

  return (
    <TagsContainer sx={sx}>
      {firstTag && (
        <span>
          <TagChip
            label={firstTag.description}
            onDelete={() => onRemove(firstTag)}
            theme={theme}
            isRemoveable={addedTagIds?.includes(firstTag.id)}
          />
        </span>
      )}

      {remainingTags.length > 0 && (
        <Tooltip
          slotProps={tooltipStyle}
          title={
            <Stack direction='row' gap={1}>
              {remainingTags.map(tag => (
                <TagChip
                  key={tag.id}
                  label={tag.description}
                  onDelete={() => onRemove(tag)}
                  isRemoveable={addedTagIds.includes(tag.id)}
                  theme={theme}
                />
              ))}
            </Stack>
          }
        >
          <span>
            <TagChip label={`+${remainingTags.length}`} theme={theme} />
          </span>
        </Tooltip>
      )}
      <EditMode
        filteredOptions={filteredOptions}
        inputTextValue={inputTextValue}
        setInputTextValue={setInputTextValue}
        handleOnKeyDown={handleOnKeyDown}
        handleCreateTag={handleCreateTag}
        setEditMode={setEditMode}
        value={value}
        onAdd={onAdd}
        onInsert={onInsert}
        editMode={editMode}
        buttonRef={buttonRef}
        inputPlaceholder={inputPlaceholder}
        newOptionText={newOptionText}
      />
      <AddTagButton
        setEditMode={setEditMode}
        theme={theme}
        value={value}
        ref={buttonRef}
        inputPlaceholder={inputPlaceholder}
      />
    </TagsContainer>
  );
};

const SkeletonLoader = ({ spacing }: SkeletonLoaderProps) => (
  <Stack direction='row' alignItems='center' spacing={1}>
    <Skeleton variant='rectangular' height={spacing} width={64} />
    <Skeleton variant='rectangular' height={spacing} width={64} />
    <Skeleton variant='rectangular' height={spacing} width={64} />
  </Stack>
);

const TagsContainer = ({ children, sx }: TagsContainerProps) => (
  <Stack
    direction='row'
    gap={1}
    flexWrap='wrap'
    sx={{
      width: '100%',
      // overflow: 'auto',
      marginTop: '0.1rem',
      alignItems: 'flex-end',
      ...sx,
    }}
  >
    {children}
  </Stack>
);

const TagChip = ({ label, onDelete, theme, isRemoveable }: TagChipProps) => (
  <Chip
    size='small'
    label={label}
    deleteIcon={
      <ClearOutlined
        sx={{
          height: '12px',
          width: '12px',
          stroke: theme.palette.grey[600],
          strokeWidth: 1.5,
        }}
      />
    }
    {...(isRemoveable && { onDelete: onDelete })}
    sx={{
      fontWeight: theme.typography.fontWeightMedium,
      color: !isRemoveable
        ? theme.palette.grey[600]
        : theme.palette.common.black,
      borderRadius: '0.4rem',
      fontSize: '0.8rem',
    }}
  />
);

const EditMode = ({
  filteredOptions,
  inputTextValue,
  setInputTextValue,
  handleOnKeyDown,
  handleCreateTag,
  setEditMode,
  buttonRef,
  editMode,
  value,
  onAdd,
  onInsert,
  inputPlaceholder,
  newOptionText,
}: EditModeProps) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const target = event.target as Node;
      if (buttonRef.current?.contains(target)) {
        return;
      }
      setEditMode(false);
    },
    [buttonRef, setEditMode],
  );

  const handleOnChange = async (_e: unknown, newValue: ValueTag) => {
    await onAdd(newValue.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={editMode}
        anchorEl={buttonRef.current}
        sx={{ zIndex: theme => theme.zIndex.drawer }}
      >
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <Autocomplete<ValueTag, false, true, false>
            open={open}
            sx={{
              [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${autocompleteClasses.inputRoot}`]:
                {
                  paddingRight: '0px',
                },
              [`&.${autocompleteClasses.hasPopupIcon} .${autocompleteClasses.inputRoot}, &.${autocompleteClasses.hasClearIcon} .${autocompleteClasses.inputRoot}`]:
                {
                  backgroundColor: 'grey.300',
                },
              '& .MuiInputBase-root': {
                backgroundColor: 'grey.300',
              },
              '& .MuiInputLabel-root': {},
            }}
            options={filteredOptions}
            onInputChange={(_event, newInputValue) => {
              setInputTextValue(newInputValue);
            }}
            onChange={handleOnChange}
            onOpen={() => setOpen(true)}
            onKeyDown={handleOnKeyDown}
            inputValue={inputTextValue}
            getOptionLabel={option => option.description || option.value || ''}
            renderInput={params => (
              <AutocompleteInput
                params={params}
                inputTextValue={inputTextValue}
                setInputTextValue={setInputTextValue}
                inputPlaceholder={inputPlaceholder}
              />
            )}
            slotProps={{
              listbox: { sx: { padding: 0 } },
            }}
            slots={{
              paper: CustomPaper,
              popper: CustomPopper,
            }}
            renderOption={(props, option) => (
              <CustomOption
                sx={{
                  margin: '2px 0px',
                  fontSize: '14px',
                  ':hover': {
                    borderRadius: '0px !important',
                  },
                  borderRadius: '0px !important',
                }}
                props={props}
                option={option}
                onAddNew={onAdd}
                key={`custom-option-${option.id}`}
              />
            )}
            noOptionsText={
              <CustomNoOptions
                sx={{
                  fontSize: '12px',
                }}
                value={value}
                inputTextValue={inputTextValue}
                handleCreateTag={handleCreateTag}
                onInsert={onInsert}
                newOptionText={newOptionText}
              />
            }
          />
        </Stack>
      </Popper>
    </ClickAwayListener>
  );
};

const CustomPaper = (props: PaperProps) => (
  <Paper
    {...props}
    sx={{
      width: '200px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    }}
  />
);

const CustomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    popperOptions={{
      placement: 'bottom',
    }}
  />
);

const AutocompleteInput = ({
  inputTextValue,
  setInputTextValue,
  params,
  inputPlaceholder = 'Add tag',
}: AutocompleteInputProps) => {
  const endAdornment =
    params.InputProps.endAdornment &&
    React.isValidElement(params.InputProps.endAdornment) &&
    React.Children.toArray(params.InputProps.endAdornment.props.children).find(
      child => {
        return (
          React.isValidElement(child) &&
          child.props.className.includes(autocompleteClasses.clearIndicator)
        );
      },
    );

  return (
    <TextField
      {...params}
      variant='filled'
      autoFocus
      InputProps={{
        ...params.InputProps,
        endAdornment: endAdornment && inputTextValue && (
          <InputAdornment position='end' onClick={() => {}}>
            {endAdornment}
          </InputAdornment>
        ),
      }}
      label={inputPlaceholder}
      size='medium'
      onChange={e => setInputTextValue(e.target.value)}
      sx={{
        fontSize: '12px',
        width: '200px',
        zIndex: theme => theme.zIndex.drawer,
        [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
          color: 'text.secondary',
          lineHeight: '1.1em',
        },
        [`.${filledInputClasses.root}`]: {
          paddingTop: '0px !important',
        },
        [`.${filledInputClasses.root}:hover`]: {
          backgroundColor: 'grey.300',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          backgroundColor: 'grey.300',
        },
        '& .MuiAutocomplete-inputFocused': {
          backgroundColor: 'grey.300',
        },
        '& input': { fontSize: '14px' },
      }}
      InputLabelProps={{
        shrink: false,
        style: {
          visibility: inputTextValue ? 'hidden' : 'visible',
          fontSize: '12px',
          lineHeight: '1.1em',
        },
      }}
    />
  );
};

const AddTagButton = React.forwardRef<HTMLDivElement, AddTagButtonProps>(
  ({ setEditMode, inputPlaceholder }, ref) => (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      ref={ref}
    >
      <Tooltip title={inputPlaceholder ?? 'Add tag'}>
        <span>
          <IconButton
            sx={{
              padding: '2px',
              stroke: ({ palette }) => palette.grey[600],
              strokeWidth: 0,
            }}
            onClick={() => setEditMode(true)}
          >
            <Add fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  ),
);

AddTagButton.displayName = 'AddTagButton';
