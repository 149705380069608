import { People } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import {
  EnumTableProjectSourcesEnum,
  useGetListUniqueViewsAggregateQuery,
  useGetProjectCategoriesForListQuery,
} from 'apollo/generated/sdkInnovationManager';
import StyledDataGrid from 'components/StyledDataGrid';
import { GDLogo } from 'components/shared/GDLogo';
import { TeamFilter } from 'components/teams/TeamFilter';
import { SEARCH_PARAMS } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { formatDate } from 'utils/datetime';
import { getListOwnerDetails } from 'utils/projects';
import { DecoratedStartupListForListPage } from '../../../../@types/startupList';
import { OwnerFilter } from './tableFilters/OwnerFilter';
import { SourcingsOnlyFilter } from './tableFilters/SourcingsOnlyFilter';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  CollaboratorFragment,
  useFindStartupListTagsQuery,
} from 'apollo/generated/sdkShared';
import { ListItemIconStyle, NAV_TEXT_GREY } from 'components/NavSection';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { BaseStartupListStats } from 'components/base/BaseStartupListStats';
import { BaseServerFilterMenu } from 'components/base/serverFilters/BaseServerFilterMenu';
import {
  dotDivider,
  smallTextStyles,
} from 'components/base/typography/constants';
import { LinkedToEntity } from 'components/dashboard/StartupInfoSidePanel/LinkedToEntity';
import useAlternativeNavigate from 'hooks/useAlternativeNavigate';
import { LibraryTabType } from 'layouts/LibraryLayout';
import { SharedUser } from 'types/shared';
import ListsGrid from '../../../../layouts/dashboard/assets/list-grid.svg?react';
import Lists from '../../../../layouts/dashboard/assets/lists.svg?react';
import { UserWithPerson } from '../details/share/ShareStartupList';
import { TagsFilter } from './tableFilters/TagsFilters';

const PAGE_SIZE = 20;
export const ABSENT_ASSIGNEE_LABEL = 'Not assigned';

export type StartupListTag = { id: number; name: string };

export const LIST_TABLE_SCOUTINGS_ONlY_FILTER_SELECTED =
  'list_table_scoutings_only_filter';

export default function ListTable({
  filteredStartupLists,
  loading,
}: {
  filteredStartupLists: DecoratedStartupListForListPage[];
  loading: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useLocalStorage(
    'library_lists_page_size',
    PAGE_SIZE,
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get(SEARCH_PARAMS.page) || 0),
    pageSize,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'created_at', sort: 'desc' },
  ]);

  const { data: tagsData } = useFindStartupListTagsQuery();
  const tagsValueOptions = (tagsData?.startup_list_tags || []).map(t => t.name);
  const columns = useGetColumns(tagsValueOptions);

  const columnsVisibilityModel = {
    // init all columns as not visible as by default they're visible and that shows some irrelevant ones
    ...columns.reduce((acum, col) => ({ ...acum, [col.field]: false }), {}),
    ...columns
      .filter(c => c.hideable !== false && c.headerName)
      .map(c => ({ label: c.headerName!, visible: true, field: c.field }))
      .reduce(
        (allCols, col) => ({
          ...allCols,
          [columns.find(c => c.headerName === col.label)!.field]: col.visible,
        }),
        {},
      ),
  };

  const onListClicked = (id: number) => {
    return navigate(PATH_ROOT.lists.details(id as number));
  };

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
    setPageSize(paginationModel.pageSize);
    setSearchParams({
      [SEARCH_PARAMS.page]: `${paginationModel.page}`,
      [SEARCH_PARAMS.tab]: 'lists' as LibraryTabType,
    });
  };

  return (
    <Card sx={{ marginTop: 2, marginBottom: 2 }}>
      <StyledDataGrid
        showCellVerticalBorder
        showColumnVerticalBorder
        getRowHeight={() => 'auto'}
        loading={loading}
        isRowClickable
        sortModel={sortModel}
        onSortModelChange={sortModel => setSortModel(sortModel)}
        rows={filteredStartupLists.map(fs => ({
          ...fs,
          startup_list_tags: fs.startup_list_tags.map(t => ({
            id: t.id,
            name: t?.tag?.name,
          })),
        }))}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[20, 50, 200]}
        columnVisibilityModel={columnsVisibilityModel}
        disableRowSelectionOnClick
        disableColumnSelector
        onRowClick={(data, event) => {
          if (event.metaKey || event.ctrlKey) {
            window.open(
              `${window.location.origin}${PATH_ROOT.lists.details(
                parseInt(data.id as string),
              )}`,
              '_blank',
            );
            return;
          }

          onListClicked(parseInt(data.id as string));
        }}
        slots={{ toolbar: CustomToolbar }}
      />
    </Card>
  );
}

export const ConnectedProjectCell = (params: GridCellParams) => {
  const data = params.row as DecoratedStartupListForListPage;

  const project = data.project;

  return project ? (
    <Stack
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <LinkedToEntity
        hideHeader
        showOnlyTitle
        linkedEntitiesData={{
          projects: [project],
          challenges: [],
          startupLists: [],
        }}
      />
    </Stack>
  ) : null;
};

const ViewsIndicator = ({ startupListId }: { startupListId: number }) => {
  const { data, loading } = useGetListUniqueViewsAggregateQuery({
    variables: { startupListId },
  });

  if (loading) {
    return (
      <Skeleton
        sx={({ spacing }) => ({
          height: spacing(2),
          width: spacing(4),
          marginLeft: 1,
        })}
        variant='text'
      />
    );
  }

  const visits =
    data?.startup_lists_visitor_records_aggregate?.aggregate?.count;

  if (visits && visits > 0) {
    return (
      <Tooltip
        title={`
        ${visits} ${visits === 1 ? 'person' : 'people'}
        visited this list
      `}
      >
        <People
          sx={({ palette }) => ({
            fontSize: '1rem',
            color: palette.grey[700],
            marginLeft: 1,
          })}
        />
      </Tooltip>
    );
  }

  return null;
};

export const TitleCell = (params: GridCellParams) => {
  const row = params.row as DecoratedStartupListForListPage;
  return (
    <StartupListCardContent
      row={row}
      detailsPagePath={PATH_ROOT.lists.details(row.id)}
    />
  );
};

export const StartupListCardContent = ({
  row,
  detailsPagePath,
}: {
  row: {
    id: number;
    title: string;
    source: EnumTableProjectSourcesEnum;
    sourcing_order?: {
      created_by?: UserWithPerson | null;
    } | null;
  };
  detailsPagePath: string | null;
}) => {
  const { data: projectCategories, loading } =
    useGetProjectCategoriesForListQuery({
      variables: { startup_list_id: row.id },
    });

  const totalStartups = projectCategories?.project_categories.reduce(
    (acc, curr) => acc + curr.categorized_suppliers.length,
    0,
  );
  const totalCategories = projectCategories?.project_categories.length;
  const isLandscape = totalCategories && totalCategories > 1;

  return (
    <Box
      onClick={e => {
        e.stopPropagation();
      }}
      component={Link}
      // The below is a hack to get the "open in new tab on right click" to work
      // Here we are also using the react-dom's Link component as opposed to the 'a' tag to keep it
      // client-side and prevent a full page reload
      to={detailsPagePath || ''}
      marginY={0.75}
      paddingX={0.75}
      gap={0.5}
      rel='noopener noreferrer'
      sx={{
        display: 'flex',
        direction: 'row',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: 'grey.50012',
          borderRadius: '8px',
        },
      }}
    >
      <ListItemIconStyle
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'flex-start',
          ...(!isLandscape && {
            '& svg, & path': { fill: NAV_TEXT_GREY },
          }),
        }}
      >
        {isLandscape ? (
          <ListsGrid fontSize='small' />
        ) : (
          <Lists fontSize='small' />
        )}
      </ListItemIconStyle>
      <Stack spacing={0}>
        <Stack
          direction='row'
          alignItems='center'
          sx={{
            minWidth: 0,
          }}
        >
          {detailsPagePath ? (
            <Typography
              sx={{
                cursor: 'pointer',
                color: '#212B36',
                textDecoration: 'underline',
                textDecorationColor: '#E5E8EB',
                textDecorationThickness: '1.25px',
                textUnderlineOffset: '3px',
                flexShrink: 1,
                minWidth: 0,
                display: 'block',
                paddingRight: '10px',
                lineHeight: '1.75',
                '&:hover': {
                  textDecorationColor: '#E5E8EB',
                  textDecorationThickness: '1.25px',
                },
              }}
              variant='body2'
              fontWeight={500}
            >
              {row.title}
            </Typography>
          ) : (
            <Typography data-testid='list-title' variant='subtitle2'>
              {row.title}
            </Typography>
          )}
          <ViewsIndicator startupListId={row.id} />
        </Stack>
        <Typography
          sx={({ palette }) => ({
            display: loading ? 'flex' : 'inline',
            alignItems: 'center',
            color: palette.grey[500],
            ...smallTextStyles,
          })}
          component={'div'}
        >
          {row.source === 'discovery_section' && (
            <Tooltip title='This list is maintained by GlassDollar and you are subscribed to updates'>
              <Typography
                marginRight={0.3}
                sx={smallTextStyles}
                component='span'
              >
                <GDLogo
                  size={20}
                  sxProps={{
                    display: 'inline-flex',
                    marginBottom: '-7px',
                  }}
                />
                Discovery {dotDivider}
              </Typography>
            </Tooltip>
          )}
          {loading ? (
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
              justifyContent='center'
            >
              <Skeleton
                sx={({ spacing }) => ({
                  height: spacing(2),
                  width: spacing(6),
                })}
                variant='text'
              />
              {dotDivider}
              <Skeleton
                sx={({ spacing }) => ({
                  height: spacing(2),
                  width: spacing(6),
                })}
                variant='text'
              />
            </Stack>
          ) : (
            <BaseStartupListStats
              totalStartups={totalStartups || 0}
              totalCategories={totalCategories || 0}
            />
          )}
        </Typography>
      </Stack>
    </Box>
  );
};

const CreatedByCell = ({
  row,
}: {
  row: {
    owner?: SharedUser | null;
    collaborators: CollaboratorFragment[];
  };
}) => {
  const { isOwnedByGD, ownerName } = getListOwnerDetails({
    collaborators: row.collaborators || [],
  });
  const { navigateTo } = useAlternativeNavigate();
  const person = row.owner?.person;
  return (
    <Typography
      sx={({ palette }) => ({
        display: 'inline',
        alignItems: 'center',
        color: palette.grey[500],
        ...smallTextStyles,
      })}
      component={'div'}
    >
      {ownerName && person?.id && (
        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
          {isOwnedByGD && (
            <GDLogo
              size={20}
              sxProps={{
                display: 'inline-flex',
                marginTop: 0.1,
              }}
            />
          )}
          <BaseInitialsAvatar
            full_name={ownerName || ''}
            sx={{
              height: '22px',
              width: '22px',
              fontSize: '10px',
            }}
          />

          <Typography
            onClick={e => {
              e.stopPropagation();
              navigateTo(PATH_ROOT.personProfile.details(person?.id));
            }}
            sx={{
              flexShrink: 1,
              minWidth: 0,
              display: 'block',
              paddingRight: '10px',
              lineHeight: '1.75',
              cursor: 'pointer',
              color: '#212B36',
              '&:hover': {
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
                textDecorationColor: '#E5E8EB',
                textDecorationThickness: '1.25px',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant='body2'
            fontWeight={500}
          >
            {ownerName ?? ''}
          </Typography>
        </Stack>
      )}
    </Typography>
  );
};

// TODO: Enforce better types
const useGetColumns = (tagsValueOptions: string[]) => {
  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'id', filterable: false },
      {
        field: 'title',
        headerName: 'Name',
        minWidth: 400,
        renderCell: TitleCell,
        filterable: false,
      },
      {
        field: 'projects',
        headerName: 'Linked to',
        minWidth: 300,
        flex: 0.5,
        filterable: false,
        renderCell: ConnectedProjectCell,
      },
      {
        field: 'created_by',
        headerName: 'Created by',
        minWidth: 250,
        filterable: false,
        renderCell: CreatedByCell,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'dateTime',
        width: 160,
        filterable: false,
        renderCell: params => {
          return (
            <>
              <Typography variant='body2'>
                {formatDate(params.value as string)}
              </Typography>
            </>
          );
        },
      },
      {
        field: 'startup_list_tags',
        headerName: 'Tags',
        minWidth: 200,
        getApplyQuickFilterFn: undefined,
        type: 'singleSelect',
        valueOptions: tagsValueOptions,
        renderCell(
          params: GridRenderCellParams<
            DecoratedStartupListForListPage,
            StartupListTag[]
          >,
        ) {
          return (
            <Stack
              sx={{ width: '100%', overflow: 'hidden' }}
              direction={'row'}
              spacing={1}
            >
              {params.value?.map(v => (
                <Chip
                  key={`startup-list-tag-${v.id}`}
                  sx={theme => ({
                    backgroundColor: theme.palette.grey[300],
                  })}
                  size='small'
                  label={
                    <Stack
                      direction={'row'}
                      spacing={0.5}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ position: 'relative' }}
                    >
                      <Typography variant='inherit'>{v.name}</Typography>
                    </Stack>
                  }
                />
              ))}
            </Stack>
          );
        },
      },
    ],
    [tagsValueOptions],
  );

  return columns;
};

function CustomToolbar() {
  const { has_teams } = useCurrentOrganizationFromContext();
  const theme = useTheme();
  const staticStyles = {
    toolbarContainer: {
      width: '100%',
      justifyContent: 'flex-end',
      '&.MuiDataGrid-toolbarContainer .MuiButton-root': {
        marginRight: '0px',
      },
    },
    divider: { height: theme.spacing(2), marginY: 'auto' },
  };

  return (
    <GridToolbarContainer sx={staticStyles.toolbarContainer}>
      <BaseServerFilterMenu scope='listTableFilters'>
        <OwnerFilter />
        {has_teams && <TeamFilter scope='listTableFilters' />}
        <TagsFilter />
        <SourcingsOnlyFilter />
      </BaseServerFilterMenu>
    </GridToolbarContainer>
  );
}
