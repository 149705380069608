import { ScopeSectionOne } from './ScopeSectionOne';
import { ScopeSectionTwo } from './ScopeSectionTwo';
import { useRequestsSolutionsMultiStepState } from './state/requestSolutionsFormState';
import { StepsWrapper } from './StepsWrapper';

export const RequestSolutionsStepsForm = ({
  isInnovationManager,
  postCreateAction,
}: {
  isInnovationManager?: boolean;
  postCreateAction?: (painPointId: number) => void;
}) => {
  const { currentStep } = useRequestsSolutionsMultiStepState();
  switch (currentStep) {
    case 1:
      return (
        <StepsWrapper isInnovationManager={isInnovationManager}>
          <ScopeSectionOne />
        </StepsWrapper>
      );
    case 2:
      return (
        <StepsWrapper isInnovationManager={isInnovationManager}>
          <ScopeSectionTwo
            isInnovationManager={isInnovationManager}
            postCreateAction={postCreateAction}
          />
        </StepsWrapper>
      );
    default:
      return null;
  }
};
