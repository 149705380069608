import { ThumbUpOutlined } from '@mui/icons-material';
import { Stack, IconButton, Typography } from '@mui/material';

export const VotingButtonBase = ({
  userVoted,
  votesCount,
  onClick,
  disabled,
}: {
  userVoted: boolean;
  votesCount: number;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <Stack direction='row' alignItems='center'>
      <IconButton size='small' onClick={onClick} disabled={disabled}>
        {userVoted ? (
          <ThumbUpOutlined
            fontSize='small'
            color='primary'
            sx={{ stroke: '#FFFFFF', strokeWidth: 0.5 }}
          />
        ) : (
          <ThumbUpOutlined
            fontSize='small'
            sx={{ stroke: '#FFFFFF', strokeWidth: 0.5 }}
          />
        )}
      </IconButton>
      <Typography variant='body2' color={!userVoted ? 'default' : 'primary'}>
        {votesCount}
      </Typography>
    </Stack>
  );
};
