import { styled, Theme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import EditorToolbar, {
  formats,
  redoChange,
  undoChange,
} from './QuillEditorToolbar';
import { useEffect, useRef } from 'react';

interface HeaderSize {
  h1: string;
  h2: string;
  h3: string;
  h4?: string;
  h5?: string;
  h6?: string;
}

const headerSizes = {
  default: {
    h1: '20px !important',
    h2: '20px !important',
    h3: '16px !important',
  },
  large: {
    h1: '4rem !important',
    h2: '3rem !important',
    h3: '2.5rem !important',
    h4: '2rem !important',
    h5: '1.5rem !important',
    h6: '1.25rem !important',
  },
  small: {
    h1: '1rem !important',
    h2: '1rem !important',
    h3: '0.875rem !important',
    h4: '0.75rem !important',
  },
} as const;

const getHeaderStyles = (theme: Theme, size: HeaderSize) => ({
  '& h1': { ...theme.typography.h1, fontSize: size.h1 },
  '& h2': { ...theme.typography.h2, fontSize: size.h2 },
  '& h3': { ...theme.typography.h3, fontSize: size.h3 },
  ...(size.h4 ? { '& h4': { ...theme.typography.h4, fontSize: size.h4 } } : {}),
  ...(size.h5 ? { '& h5': { ...theme.typography.h5, fontSize: size.h5 } } : {}),
  ...(size.h6 ? { '& h6': { ...theme.typography.h6, fontSize: size.h6 } } : {}),
});

const RootStyle = styled(Box, {
  shouldForwardProp: prop => prop !== 'themeVariant',
})<{ themeVariant: keyof typeof headerSizes }>(({ theme, themeVariant }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body2,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    padding: theme.spacing(2),
    paddingBottom: 0,
    minHeight: 100,
    '& .ql-align-center': {
      textAlign: 'center',
    },
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& ul, & ol': {
      ...theme.typography.body2,
      paddingLeft: theme.spacing(1),
      lineHeight: '1.5rem',
    },
    ...getHeaderStyles(theme, headerSizes[themeVariant]),
  },
}));

// ----------------------------------------------------------------------

interface QuillEditorProps extends ReactQuillProps {
  id?: string;
  error?: boolean;
  simple?: boolean;
  autoFocus?: boolean;
  sx?: BoxProps;
  themeVariant?: keyof typeof headerSizes;
}

export default function QuillEditor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  simple = false,
  autoFocus = false,
  themeVariant = 'default',
  sx,
  ...other
}: QuillEditorProps) {
  const editorRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (autoFocus && editorRef.current) {
      setTimeout(() => {
        const editor = editorRef.current?.getEditor();
        if (editor) editor.focus();
      }, 0);
    }
  }, [autoFocus]);

  const modules = {
    toolbar: {
      container: `#${id}`,
      handlers: { undo: undoChange, redo: redoChange },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <RootStyle
      themeVariant={themeVariant}
      sx={{
        ...(error && {
          border: theme => `solid 1px ${theme.palette.error.main}`,
        }),
        ...sx,
      }}
    >
      <EditorToolbar
        id={id}
        isSimple={simple}
        allowBiggerHeaders={themeVariant === 'large'}
      />
      <ReactQuill
        ref={editorRef}
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder='Write something awesome...'
        {...other}
      />
    </RootStyle>
  );
}
