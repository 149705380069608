import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useGetMatrixSettingsQuery } from 'apollo/generated/sdkInnovationManager';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import { SelectForAssessmentCell } from 'components/assessmentsComparisonTable/utils/SelectForAssessmentCell';
import { SelectForPocCell } from 'components/assessmentsComparisonTable/utils/SelectForPocCell';
import { ReferenceCustomersColumn } from 'components/dashboard/CategoryReferenceCustomers';
import { ReactionsCell } from 'components/startupComparisonTable/shared/ReactionsCell';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { useCallback, useMemo } from 'react';
import getReferencedCustomersAndSuppliers from 'utils/getReferencedCustomersAndSuppliers';
import {
  REACTIONS_HEADER_WIDTH,
  ReactionsColumnHeader,
} from '../ReactionsColumnHeader';
import { MainColumnHeader } from './MainColumnHeader';
import { MemoizedNameColumn } from './NameColumn';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
  StartupsContextType,
} from './StartupsTableContext';
import { StartupCorporateCustomer } from './useGetColumns';

export type StartupButtonActionHelpers = {
  favouriteStartups: {
    domain: string;
    categorizedSupplierId: number;
  }[];
  addToList: ({ startup }: { startup: unknown }) => void;
  loadingAddStartupToList: boolean;
};

export type GetMainColumnsType = {
  isPublicPage: boolean;
  isDiscovery?: boolean;
  categorizedStartups: CategorizedStartupForMatrix[];
  hasCustomers: boolean;
  belongsInAssessment?: 'select_for_assessment' | 'select_for_poc';
  projectId?: number;
  setState: StartupsContextType['setState'];
  votingNotificationSent: boolean;
};

export const CUSTOM_DIMENSION_HEADER_FIELD_PREFIX = 'c';
export const CUSTOMERS_COLUMN_MIN_WIDTH = 168;

export const useGetMainColumns = ({
  isPublicPage,
  isDiscovery,
  categorizedStartups,
  hasCustomers,
  belongsInAssessment,
  projectId,
  setState,
  votingNotificationSent,
}: GetMainColumnsType) => {
  const { startup_list_id: startupListId } = categorizedStartups[0] || {};
  const { data } = useGetMatrixSettingsQuery({
    variables: { startupListId },
    skip: !startupListId,
  });

  const matrixSettings = useMemo(
    () =>
      data?.list_matrix_settings[0] || {
        customers_column_enabled: true,
        description_column_enabled: true,
        reactions_column_enabled: true,
        rank_enabled: true,
      },
    [data?.list_matrix_settings],
  );
  const { openStartupSidePanel } = useStartupSidePanel();

  const handleOpenStartupSidePanel = useCallback(
    ({
      id,
      startup_list_id,
      project_category_id,
    }: {
      id: number;
      startup_list_id: number;
      project_category_id: number;
    }) => {
      openStartupSidePanel(id, {
        startupListId: startup_list_id,
        categoryId: project_category_id,
        projectId,
      });
    },
    [openStartupSidePanel, projectId],
  );

  const mainColumns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Position, company',
          minWidth: 320,
          flex: 1,
          cellClassName: 'company-details',
          renderCell: (
            params: GridRenderCellParams<DecoratedStartup, string>,
          ) => {
            return (
              <div
                onClick={() =>
                  handleOpenStartupSidePanel({
                    id: params.row.id,
                    startup_list_id: params.row.startup_list_id,
                    project_category_id: params.row.project_category_id,
                  })
                }
              >
                <MemoizedNameColumn
                  {...params.row}
                  isLongList={false}
                  isRankEnabled={
                    matrixSettings.rank_enabled &&
                    belongsInAssessment !== 'select_for_poc'
                  }
                  projectId={projectId}
                  isPublicPage={isPublicPage}
                />
              </div>
            );
          },
          disableColumnMenu: true,
          sortable: false,
          hideable: false,
          disableReorder: true,
        },
        belongsInAssessment == 'select_for_assessment'
          ? {
              field: 'select_for_assessment',
              headerName: 'Select for assessment',
              sortable: false,
              hideable: false,
              headerClassName: 'select-for-assessment-header',
              disableColumnMenu: true,
              renderCell: params => {
                return (
                  <SelectForAssessmentCell
                    categorizedStartup={{
                      is_shortlisted: params.row.is_shortlisted,
                      categorized_startup_id: params.row.categorized_startup_id,
                    }}
                  />
                );
              },
            }
          : belongsInAssessment == 'select_for_poc'
            ? {
                field: 'select_for_poc',
                headerName: 'Select for PoC',
                sortable: false,
                hideable: false,
                headerClassName: 'select-for-assessment-header',
                disableColumnMenu: true,
                renderCell: params => {
                  return (
                    <SelectForPocCell
                      categorizedStartup={{
                        is_selected: params.row.is_selected,
                        categorized_startup_id:
                          params.row.categorized_startup_id,
                        startup_id: params.row.id,
                        name: params.row.name,
                        logo_url: params.row.logo_url,
                      }}
                    />
                  );
                },
              }
            : null,
        matrixSettings.reactions_column_enabled
          ? {
              field: 'voters',
              headerName: 'Reactions',
              flex: 1,
              minWidth: REACTIONS_HEADER_WIDTH,
              renderHeader: () => {
                return (
                  <ReactionsColumnHeader
                    isReadonly={isPublicPage ?? isDiscovery ?? false}
                    startupListId={startupListId}
                  />
                );
              },
              renderCell: (
                params: GridRenderCellParams<DecoratedStartup, string>,
              ) => (
                <ReactionsCell
                  {...params.row}
                  id={+params.id}
                  isDiscovery={isDiscovery}
                  isPublicPage={isPublicPage}
                  votingNotificationSent={votingNotificationSent}
                  setState={setState}
                />
              ),
              disableColumnMenu: true,
              cellClassName: 'voting-commenting',
              sortable: false,
              hideable: false,
            }
          : null,
        hasCustomers && matrixSettings.customers_column_enabled
          ? {
              field: 'startup_corporate_customers',
              headerName: 'Customers',
              minWidth: CUSTOMERS_COLUMN_MIN_WIDTH,
              align: 'center',
              flex: 1,
              renderHeader: () => (
                <MainColumnHeader
                  field='startup_corporate_customers'
                  label='Customers'
                  isReadonly={isPublicPage ?? isDiscovery ?? false}
                  startupListId={startupListId}
                />
              ),
              renderCell: (
                params: GridRenderCellParams<
                  DecoratedStartup,
                  StartupCorporateCustomer[]
                >,
              ) => {
                const { referenceCustomersWithSuppliers } =
                  getReferencedCustomersAndSuppliers({
                    startups: categorizedStartups.map(cs => cs.startup),
                    columnProvidedReferencedCustomers: params.value?.map(
                      c => c.corporate_customer,
                    ),
                  });

                if (!params.value?.length) return '-';

                return (
                  <>
                    <ReferenceCustomersColumn
                      referenceCustomerList={referenceCustomersWithSuppliers}
                      allowHoverDropDown={false}
                    />
                  </>
                );
              },
              disableColumnMenu: true,
              sortable: false,
              hideable: true,
            }
          : null,
        matrixSettings.description_column_enabled
          ? {
              field: 'short_description',
              headerName: 'Description',
              minWidth: 350,
              flex: 2,
              renderHeader: () => (
                <MainColumnHeader
                  field='short_description'
                  label='Description'
                  isReadonly={isPublicPage ?? isDiscovery ?? false}
                  startupListId={startupListId}
                />
              ),
              renderCell: (
                params: GridRenderCellParams<DecoratedStartup, string>,
              ) => {
                const value = params.value || '-';

                return (
                  <ExpandedGridCell
                    value={value}
                    multiLineElipsis={true}
                    numberOfLines={3}
                  />
                );
              },
              disableColumnMenu: true,
              sortable: false,
              disableReorder: true,
              hideable: true,
            }
          : null,
      ] as GridColDef<DecoratedStartup>[],
    [
      hasCustomers,
      isDiscovery,
      isPublicPage,
      matrixSettings,
      categorizedStartups,
      handleOpenStartupSidePanel,
      belongsInAssessment,
      projectId,
      startupListId,
      setState,
      votingNotificationSent,
    ],
  );

  return {
    mainColumns: mainColumns.filter(Boolean),
  };
};
