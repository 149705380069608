import { makeVar, useReactiveVar } from '@apollo/client';
import { SEARCH_PARAMS } from 'config';
import { useState } from 'react';
import { z } from 'zod';
import { KnownStartup } from '../../../@types/startupList';
import { useGetPersonInfoSchema } from '../stepsSchema/personInfoStepSchema';
import { scopeSectionOneSchema } from '../stepsSchema/scopeSectionOneSchema';
import { scopeSectionTwoSchema } from '../stepsSchema/scopeSectionTwoSchema';

type AllStepKeys =
  | keyof PersonInfoStepState
  | keyof ScopeSectionOneState
  | keyof ScopeSectionTwoState;

export type PersonInfoStepState = {
  requesterName: string;
  requesterDepartment: string;
  requesterEmail: string;
};

export type ScopeSectionOneState = {
  title: string;
  needPainPoint: string;
  desiredSolution: string;
};

export type ScopeSectionTwoState = {
  mustHaveFeatures: string[];
  niceToHaveFeatures: string[];
  knownSolutions: KnownStartup[];
  additionalInformation: string;
  supportingDocuments: ArrayLike<File> | null[];
};

export type RequestSolutionsFormState = {
  personInfo: PersonInfoStepState;
  step1: ScopeSectionOneState;
  step2: ScopeSectionTwoState;
  currentStep: number;
};

const defaultRequestSolutionsFormState = {
  personInfo: {
    requesterName: '',
    requesterDepartment: '',
    requesterEmail: '',
  },
  step1: {
    title: '',
    needPainPoint: '',
    desiredSolution: '',
  },
  step2: {
    mustHaveFeatures: [],
    niceToHaveFeatures: [],
    knownSolutions: [],
    additionalInformation: '',
    supportingDocuments: [],
  },
  currentStep: 1,
};

const loadState = (): RequestSolutionsFormState => {
  const savedState = localStorage.getItem('requestSolutionsFormState');
  const initialState = savedState
    ? JSON.parse(savedState)
    : defaultRequestSolutionsFormState;

  const params = new URLSearchParams(window.location.search);
  const portalTitle = params.get(SEARCH_PARAMS.portalTitle);

  return {
    ...initialState,
    step1: {
      ...initialState.step1,
      title: portalTitle
        ? `Interested in "${portalTitle}" \n \n`
        : initialState.step1.title,
    },
  };
};

const saveState = (state: RequestSolutionsFormState) => {
  localStorage.setItem('requestSolutionsFormState', JSON.stringify(state));
};

export const requestSolutionsFormStateVar =
  makeVar<RequestSolutionsFormState>(loadState());

export const useRequestsSolutionsMultiStepState = () => {
  const formDataSteps = useReactiveVar(requestSolutionsFormStateVar);

  const updatePersonInfoStep = (personInfo: PersonInfoStepState) => {
    const newState = {
      ...formDataSteps,
      personInfo,
    };
    requestSolutionsFormStateVar(newState);
    saveState({
      ...defaultRequestSolutionsFormState,
      personInfo: {
        ...personInfo,
      },
    });
  };

  const updateScopeSectionOne = (step1: ScopeSectionOneState) => {
    requestSolutionsFormStateVar({
      ...formDataSteps,
      step1,
    });
  };

  const updateScopeSectionTwo = (step2: ScopeSectionTwoState) => {
    requestSolutionsFormStateVar({
      ...formDataSteps,
      step2,
    });
  };

  const setCurrentStep = (currentStep: number) => {
    const newState = {
      ...formDataSteps,
      currentStep,
    };
    requestSolutionsFormStateVar(newState);
  };

  const clearState = () => {
    requestSolutionsFormStateVar({
      ...defaultRequestSolutionsFormState,
      personInfo: formDataSteps.personInfo,
    });
  };

  return {
    currentStep: formDataSteps.currentStep,
    personInfoData: formDataSteps.personInfo,
    scopeSectionOneData: formDataSteps.step1,
    scopeSectionTwoData: formDataSteps.step2,
    allFormData: {
      ...formDataSteps.personInfo,
      ...formDataSteps.step1,
      ...formDataSteps.step2,
    },
    updatePersonInfoStep,
    updateScopeSectionOne,
    updateScopeSectionTwo,
    setCurrentStep,
    clearState,
  };
};

export const useValidateField = (
  currentForm: 'personInfo' | 'scopeSectionOne' | 'scopeSectionTwo',
) => {
  const [errors, setErrors] = useState<Partial<Record<AllStepKeys, string>>>(
    {},
  );
  const personInfoSchema = useGetPersonInfoSchema();

  const validateField = async (field: string, value: string) => {
    try {
      switch (currentForm) {
        case 'personInfo':
          await personInfoSchema
            .pick({ [field]: true })
            .parseAsync({ [field]: value });
          break;
        case 'scopeSectionOne':
          await scopeSectionOneSchema()
            .pick({ [field]: true })
            .parseAsync({ [field]: value });
          break;
        case 'scopeSectionTwo':
          await scopeSectionTwoSchema()
            .pick({ [field]: true })
            .parseAsync({ [field]: value });
          break;
        default:
          return;
      }

      setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    } catch (validationError) {
      if (validationError instanceof z.ZodError) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field]: validationError.errors[0].message,
        }));
      }
    }
  };
  return { validateField, errors };
};
