import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, FocusEvent } from 'react';
import {
  ScopeSectionOneState,
  useRequestsSolutionsMultiStepState,
  useValidateField,
} from './state/requestSolutionsFormState';

export const ScopeSectionOne = () => {
  const { setCurrentStep, scopeSectionOneData, updateScopeSectionOne } =
    useRequestsSolutionsMultiStepState();

  const { errors, validateField } = useValidateField('scopeSectionOne');

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    updateScopeSectionOne({
      ...scopeSectionOneData,
      [name]: value,
    });
    validateField(name, value);
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  return (
    <form onSubmit={() => setCurrentStep(2)} style={{ width: '100%' }}>
      <Stack direction='column' gap={4}>
        <Box>
          <Typography
            variant='body2'
            sx={{ color: 'grey.700', fontWeight: 900, marginBottom: 1 }}
          >
            Title*
          </Typography>
          <TextField
            fullWidth
            name='title'
            value={scopeSectionOneData.title}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'brief-title',
            }}
            placeholder='Title'
            error={Boolean(errors.title)}
            helperText={errors.title}
            onBlur={handleBlur}
            autoFocus
          />
        </Box>
        <Box>
          <Typography
            variant='body2'
            sx={{ color: 'grey.700', fontWeight: 900, marginBottom: 1 }}
          >
            Need*
          </Typography>
          <TextField
            multiline
            minRows={4}
            fullWidth
            name='needPainPoint'
            value={scopeSectionOneData.needPainPoint}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'brief-needPainPoint',
            }}
            placeholder='Describe the specifics'
            error={Boolean(errors.needPainPoint)}
            helperText={errors.needPainPoint}
            onBlur={handleBlur}
          />
        </Box>
        <Box>
          <Typography
            variant='body2'
            sx={{ color: 'grey.700', fontWeight: 900, marginBottom: 1 }}
          >
            Desired solution*
          </Typography>
          <TextField
            multiline
            minRows={4}
            fullWidth
            name='desiredSolution'
            value={scopeSectionOneData.desiredSolution}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'brief-desiredSolution',
            }}
            placeholder='Describe your preferred solution'
            error={Boolean(errors.desiredSolution)}
            helperText={errors.desiredSolution}
            onBlur={handleBlur}
          />
        </Box>
      </Stack>
      <Stack
        spacing={0}
        alignItems='start'
        sx={{ marginTop: 4, marginBottom: 1 }}
      >
        <LoadingButton
          sx={{ marginLeft: 'auto' }}
          type='submit'
          variant='contained'
          size='large'
          disabled={
            !isFormDirty(scopeSectionOneData) ||
            Object.values(errors).some(Boolean)
          }
        >
          {/* {translate('requestForm.form.submitButton')}
           */}
          Next
        </LoadingButton>
      </Stack>
    </form>
  );
};

const isFormDirty = (sectionOneStepOne: ScopeSectionOneState) => {
  return Object.values(sectionOneStepOne).every(value => value.trim() !== '');
};
