import { useInsertProjectSourceTagMutation } from 'apollo/generated/sdkShared';
import { CreateLeadSource } from 'components/projectLeads/useCreateLead';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useCallback } from 'react';

type SourceTag = CreateLeadSource | 'Request Form' | 'Linked list' | string;

export const useInsertProjectSourceTag = () => {
  const { id: organizationId } = useCurrentOrganizationFromContext();
  const [insertTag] = useInsertProjectSourceTagMutation();

  const handleInsertTag = useCallback(
    async (source: SourceTag) => {
      const tag = await insertTag({
        variables: { name: source, organizationId },
      });

      if (tag.data?.insert_project_source_tags_one?.id) {
        return tag.data.insert_project_source_tags_one.id;
      }

      return null;
    },
    [insertTag, organizationId],
  );

  return { handleInsertTag };
};
