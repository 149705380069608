import { Dictionary } from 'lodash';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react';
import {
  CustomDimensionCellJSON,
  SharedProjectCategory,
  StartupConnection,
} from '../../../@types/shared';

import { Box } from '@mui/material';
import { CategorizedSuppliers, Suppliers } from 'apollo/generated/sdkShared';
import { CorporateCustomer } from 'components/dashboard/StartupInfoSidePanel/useStartupDetailsFormik';
import { EmptyListDataGrid } from 'components/dashboard/startupList/list/EmptyListDataGrid';
import { keyBy } from 'lodash';
import { StartupListForDetail } from '../../../@types/startupList';
import { StartupLists } from 'apollo/generated/sdkInnovationManager';
import { SharedUser } from 'types/shared';

type StartupForMatrix = Pick<
  Suppliers,
  | 'id'
  | 'domain'
  | 'name'
  | 'website'
  | 'employees_count'
  | 'logo_url'
  | 'founded_year'
  | 'short_description'
  | 'funding'
  | 'hq'
> & {
  connection?: StartupConnection;
  startup_corporate_customers: {
    case_study_url?: string | null | undefined;
    corporate_customer: CorporateCustomer;
  }[];
};

// A type that's union between startup and categorized startup
export type DecoratedStartup = StartupForMatrix & {
  categorized_startup_id: number;
  // Important to have the project_category_id & startup_list_id here because it's used in the matrix table
  // Also to decouple from the startups table context
  project_category_id: number;
  startup_list_id: number;
  // ------------------------------------------------------------------------------------------------------------
  voters: string[];
  order: number;
  is_shortlisted: boolean;
  is_recommended?: boolean | null;
  is_in_matrix: boolean;
  is_selected?: boolean;
  all_startups_table_score?: number;
  matrix_table_score?: number;
  custom_dimensions_json_v1: Record<string, CustomDimensionCellJSON>;
};

export type StartupListForMatrix = Pick<
  StartupLists,
  | 'id'
  | 'title'
  | 'source'
  | 'is_longlist_displayed'
  | 'is_readonly'
  | 'is_subscribed'
  | 'is_readonly'
> & {
  project_categories: {
    id: number;
  }[];
  sourcing_order?: {
    id: number;
    created_by?: SharedUser | null;
  } | null;
};

export type PersonUser = {
  id?: number;
  email: string;
  fullName: string;
};

type StartupListState = {
  votingNotificationSent: boolean;
  editComparisonColumnModalSettings: {
    isActive: boolean;
    customDimensionColumnToEdit: {
      columnName: string;
      categoryId: number;
      startupListId: number;
    };
  };
  isLoadingAfterColumnChange: boolean;
};

export const DEFAULT_CUSTOM_COLUMN_SETTINGS = {
  isActive: false,
  customDimensionColumnToEdit: {
    columnName: '',
    categoryId: 0,
    startupListId: 0,
  },
};

export type CategorizedStartupForMatrix = Pick<
  CategorizedSuppliers,
  | 'id'
  | 'matrix_table_score'
  | 'all_startups_table_score'
  | 'is_shortlisted'
  | 'shortlisted_date'
  | 'is_selected'
  | 'is_contacted'
  | 'is_recommended'
  | 'is_in_matrix'
  | 'is_archived'
> & {
  startup: StartupForMatrix;
  custom_dimensions_json_v1: Record<string, CustomDimensionCellJSON>;
  voters: string[];
  startup_list_id: number;
  project_category_id: number;
};

export type StartupsContextType = StartupListState & {
  category: SharedProjectCategory;
  categorizedStartupsById: Dictionary<CategorizedStartupForMatrix>;
  categorizedStartups: CategorizedStartupForMatrix[];
  isPublicPage: boolean;
  currentStartupList: StartupListForDetail;
  setState: Dispatch<
    SetStateAction<{
      isLoadingAfterColumnChange: boolean;
      votingNotificationSent: boolean;
      editComparisonColumnModalSettings: {
        isActive: boolean;
        customDimensionColumnToEdit: {
          columnName: string;
          categoryId: number;
          startupListId: number;
        };
      };
    }>
  >;
};

const StartupsTableContext = createContext<StartupsContextType | null>(null);

const StartupsTableProvider = ({
  category,
  children,
  isPublicPage,
  currentStartupList,
}: {
  category: SharedProjectCategory;
  children: ReactNode;
  isPublicPage: boolean;
  currentStartupList: StartupListForDetail;
}) => {
  const [state, setState] = useState({
    // Adding this to the state to force rerender of the table after column change
    // because of a bug in mui x-data-grid-pro when adding new columns the header is misaligned
    // TODO: Try to update from v5.X to v6.X and test if the bug is fixed
    isLoadingAfterColumnChange: false,
    votingNotificationSent: false,
    editComparisonColumnModalSettings: DEFAULT_CUSTOM_COLUMN_SETTINGS,
  });

  const categorizedStartups = useMemo(
    () =>
      (
        currentStartupList.project_categories.find(
          cs_pj => cs_pj.id === category.id,
        )?.categorized_suppliers || []
      ).map(cs => ({
        ...cs,
        project_category_id: category.id,
        startup_list_id: currentStartupList.id,
      })) || [],

    [category, currentStartupList],
  );

  const categorizedStartupsById: Record<number, CategorizedStartupForMatrix> =
    useMemo(
      () =>
        keyBy(
          categorizedStartups.filter(x => x.is_in_matrix),
          'id',
        ),
      [categorizedStartups],
    );

  // FIXME: Remove the `!` when the query is fixed
  // eslint-disable-next-line

  const value = useMemo(
    () => ({
      isPublicPage,
      // FIXME: Remove the `!` when the query is fixed
      category: category!,
      categorizedStartupsById,
      categorizedStartups,
      currentStartupList,
      ...state,
      setState,
    }),
    [
      isPublicPage,
      category,
      categorizedStartupsById,
      categorizedStartups,
      currentStartupList,
      state,
    ],
  );

  if (state.isLoadingAfterColumnChange || !currentStartupList.id) {
    return (
      <Box>
        <StartupsTableContext.Provider value={value}>
          <EmptyListDataGrid withSkeleton />
        </StartupsTableContext.Provider>
      </Box>
    );
  }

  if (!category) {
    return <EmptyListDataGrid showNewRow={false} />;
  }

  return (
    <StartupsTableContext.Provider value={value}>
      {children}
    </StartupsTableContext.Provider>
  );
};

export { StartupsTableContext, StartupsTableProvider };
