/**
 * To be honest I got no idea of where to put this component. Adrian
 */

import { Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { GDLogo } from 'components/shared/GDLogo';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useMemo } from 'react';

const StartupSignalOrder = [
  'Inactive',
  'GDProvenSolution',
  'GDConnected',
  'GDScouted',
  'Acquired',
] as const;
export type StartupSignal = (typeof StartupSignalOrder)[number];

export function getStartupSignalLabelByOrder(
  signals: { value?: string | null; selected?: boolean | null }[] | null = [],
): StartupSignal | undefined {
  if (!signals) return undefined;
  return StartupSignalOrder.find(
    slo => signals?.findIndex(sl => sl.value === slo) !== -1,
  );
}

const StartupSignalName = ({ signal }: { signal: StartupSignal }) => {
  switch (signal) {
    case 'Inactive':
      return <>Inactive</>;
    case 'GDScouted':
      return (
        <>
          <GDLogo size={17} sxProps={{ marginLeft: -0.5 }} /> Sourced
        </>
      );
    case 'GDConnected':
      return (
        <>
          <GDLogo size={17} sxProps={{ marginLeft: -0.5 }} /> Connected
        </>
      );
    case 'GDProvenSolution':
      return (
        <>
          <GDLogo size={17} sxProps={{ marginLeft: -0.5 }} /> Proven Solution
        </>
      );
    case 'Acquired':
      return <>Acquired</>;
    default:
      return null;
  }
};

const Label = ({ signal }: { signal: StartupSignal }) => (
  <Stack alignItems='center' direction='row'>
    <StartupSignalName signal={signal} />
  </Stack>
);

export default function StartupSignalChip({
  signal,
  startupName,
  sx,
}: {
  signal?: StartupSignal | null;
  startupName: string;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  const organization = useCurrentOrganizationFromContext();

  const signalStyleMap: Record<
    StartupSignal,
    {
      color: string;
    }
  > = {
    Inactive: {
      color: theme.palette.error.main,
    },
    GDScouted: {
      color: theme.palette.text.primary,
    },
    GDConnected: {
      color: theme.palette.text.primary,
    },
    GDProvenSolution: {
      color: theme.palette.text.primary,
    },
    Acquired: {
      color: theme.palette.text.primary,
    },
  };

  const startupSignalTooltip: Record<StartupSignal, string> = useMemo(
    () => ({
      Inactive: `${startupName} is no longer operating`,
      GDScouted: `${startupName} was previously sourced for a partner by our venture experts`,
      GDConnected: `GlassDollar has a verified contact at ${startupName}`,
      GDProvenSolution: `${startupName} has already run a Proof of Concept with GlassDollar`,
      Acquired: `${startupName} has been acquired`,
    }),
    [startupName],
  );

  if (!signal) return null;

  if (!organization.signal_labels_enabled) return null;

  return (
    <Tooltip title={startupSignalTooltip[signal]}>
      <Typography
        variant='caption'
        data-testid='startup-signal-chip'
        style={{
          color: signalStyleMap[signal].color,
          width: 'fit-content',
        }}
        sx={sx}
      >
        <Label signal={signal} />
      </Typography>
    </Tooltip>
  );
}
