import { Box, Card, SxProps } from '@mui/material';

export const BaseEmbeddedIframe = ({
  src,
  title,
  allow = 'encrypted-media; fullscreen;',
  component = Card,
  containerSx = {},
  iframeSx = {},
}: {
  src: string;
  title: string;
  allow?: string;
  containerSx?: SxProps;
  iframeSx?: SxProps;
  component?: React.ElementType;
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
        ...containerSx,
      }}
      component={component}
    >
      <Box
        component='iframe'
        src={src}
        loading='lazy'
        title={title}
        allow={allow}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 'none',
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          ...iframeSx,
        }}
      ></Box>
    </Box>
  );
};
