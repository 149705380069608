import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  Collapse,
  Box,
} from '@mui/material';
import { SearchSubtitle } from './shared/SearchSubtitle';
import {
  BaseStartupCard,
  StartupForCard,
} from 'components/base/BaseStartupCard';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import {
  NameSearchDocument,
  NameSearchQuery,
  useNameSearchLazyQuery,
  useWebSearchMutation,
  WebSearchMutation,
} from 'apollo/generated/sdkShared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentNode } from '@apollo/client';
import {
  getStartupFunnelLabelOrder,
  StartupFunnelLabel,
} from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import { getStartupSignalLabelByOrder } from 'components/startups/StartupSignalLabel';
import { ShowMore } from './shared/ShowMore';
export type NameSearchResultType = {
  supplier: StartupForCard;
};

const transformResultLabels = (
  result: NonNullable<NameSearchQuery['name_search'][number]>,
) => {
  return {
    ...result,
    supplier: {
      ...result.supplier,
      funnel_label: getStartupFunnelLabelOrder(
        result.supplier?.funnel_labels as {
          value: StartupFunnelLabel;
        }[],
      ),
      signal_label: getStartupSignalLabelByOrder(
        result.supplier?.signal_labels
          ? result.supplier.signal_labels.map(label => ({
              value: label.id,
            }))
          : [],
      ),
    },
  };
};

export const SearchNameResults = ({ query }: { query: string }) => {
  const { handleNameSearch, nameSearchData, nameSearchLoading, searchCalled } =
    useNameSearch();

  const { handleWebSearch, webSearchData, webSearchLoading } = useWebSearch();

  useEffect(() => {
    handleNameSearch(query);
    handleWebSearch(query);
  }, [query, handleNameSearch, handleWebSearch]);

  const [showMore, setShowMore] = useState(false);
  const MAX_RESULTS_VISIBLE = 4;

  const processedResults = useMemo(() => {
    const nameResults = (nameSearchData?.name_search || [])
      .filter(result => result !== null)
      .map(result => ({
        ...transformResultLabels(result!),
        source: 'name',
      })) as Array<NameSearchResultType & { source: 'name' }>;
    const webResults = (webSearchData?.web_search || [])
      .map(result => transformResultLabels(result))
      .filter((result): result is NameSearchResultType => result !== null)
      .filter(
        result =>
          !nameResults.some(
            nr => nr.supplier.domain === result.supplier.domain,
          ),
      )
      .map(result => ({ ...result, source: 'web' })) as Array<
      NameSearchResultType & { source: 'web' }
    >;
    return [...nameResults, ...webResults];
  }, [nameSearchData, webSearchData]);

  return (
    <Box marginBottom={3}>
      <NameSearchTitle
        nameSearchLoading={nameSearchLoading}
        webSearchLoading={webSearchLoading}
        nameSearchData={nameSearchData}
        webSearchData={webSearchData}
        searchCalled={searchCalled}
      />
      <Grid container rowSpacing={2} columnSpacing={2} sx={{ marginTop: 0.5 }}>
        {processedResults.slice(0, MAX_RESULTS_VISIBLE).map((result, index) => (
          <NameSearchResult
            key={`${result.source}_${result.supplier.id}`}
            enrichment={
              result.source === 'name'
                ? {
                    autoEnrich: true,
                    queryDocument: NameSearchDocument,
                    enrichmentKey: `name_search_${result.supplier.id}`,
                  }
                : undefined
            }
            supplier={result.supplier}
            trackFn={() =>
              captureAnalyticsEvent(
                result.source === 'name'
                  ? 'Search name result clicked'
                  : 'Web search result clicked',
                {
                  searchQuery: query,
                  clickedResultDomain: result.supplier.domain,
                  clickedResultIndex: index,
                  totalNumberOfResults: processedResults.length,
                },
              )
            }
          />
        ))}
      </Grid>
      {processedResults.length > MAX_RESULTS_VISIBLE && (
        <>
          <Collapse in={showMore} timeout='auto' unmountOnExit>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              sx={{ marginTop: 1 }}
            >
              {processedResults
                .slice(MAX_RESULTS_VISIBLE)
                .map((result, index) => (
                  <NameSearchResult
                    key={`${result.source}_${result.supplier.id}_extra`}
                    enrichment={
                      result.source === 'name'
                        ? {
                            autoEnrich: true,
                            queryDocument: NameSearchDocument,
                            enrichmentKey: `name_search_${result.supplier.id}`,
                          }
                        : undefined
                    }
                    supplier={result.supplier}
                    trackFn={() =>
                      captureAnalyticsEvent(
                        result.source === 'name'
                          ? 'Search name result clicked'
                          : 'Web search result clicked',
                        {
                          searchQuery: query,
                          clickedResultDomain: result.supplier.domain,
                          clickedResultIndex: index + MAX_RESULTS_VISIBLE,
                          totalNumberOfResults: processedResults.length,
                        },
                      )
                    }
                  />
                ))}
            </Grid>
          </Collapse>
          <ShowMore showMore={showMore} setShowMore={setShowMore} />
        </>
      )}
    </Box>
  );
};

const NameSearchResult = ({
  supplier,
  trackFn,
  enrichment,
}: {
  supplier: StartupForCard;
  trackFn: () => void;
  enrichment?: {
    autoEnrich: boolean;
    queryDocument: DocumentNode;
    enrichmentKey?: string;
  };
}) => {
  return (
    <BaseStartupCard
      enrichment={enrichment}
      trackFn={trackFn}
      startup={supplier}
      avatarSize='medium'
      gridProps={{ xs: 12, sm: 6, md: 4, lg: 3 }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .MuiCard-root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    />
  );
};

const NameSearchTitle = ({
  nameSearchLoading,
  webSearchLoading,
  nameSearchData,
  webSearchData,
  searchCalled,
}: {
  nameSearchLoading: boolean;
  webSearchLoading: boolean;
  nameSearchData: NameSearchQuery | undefined;
  webSearchData: WebSearchMutation | undefined | null;
  searchCalled: boolean;
}) => {
  const isLoading = nameSearchLoading || webSearchLoading;
  const isEmpty =
    searchCalled &&
    !nameSearchData?.name_search.length &&
    !webSearchData?.web_search.length;

  return (
    <Stack direction='row' alignItems='center' gap={2}>
      <SearchSubtitle>Startups by name</SearchSubtitle>
      {isLoading ? (
        <CircularProgress size={20} sx={{ mt: 1 }} />
      ) : (
        isEmpty && (
          <Typography variant='body2' sx={{ mt: 1.3, color: 'text.secondary' }}>
            No results found
          </Typography>
        )
      )}
    </Stack>
  );
};

const useNameSearch = () => {
  const [nameSearch, { data, loading, called }] = useNameSearchLazyQuery();
  const handleNameSearch = useCallback(
    async (query: string) => {
      if (!query) return;

      nameSearch({ variables: { query } });
    },
    [nameSearch],
  );

  return {
    searchCalled: called,
    nameSearchData: data,
    handleNameSearch,
    nameSearchLoading: loading,
  };
};

const useWebSearch = () => {
  const [webSearch, { data, loading }] = useWebSearchMutation();

  const handleWebSearch = useCallback(
    async (query: string) => {
      if (!query) return;

      webSearch({ variables: { query } });
    },
    [webSearch],
  );

  return {
    webSearchData: data,
    handleWebSearch,
    webSearchLoading: loading,
  };
};
