import { styled } from '@mui/material/styles';
import { unstable_debounce as debounce } from '@mui/utils';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import {
  gridQuickFilterValuesSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { GridFilterModel } from '@mui/x-data-grid/models';
import { DataGridProcessedProps } from '@mui/x-data-grid/models/props/DataGridProps';
import { isDeepEqual } from '@mui/x-data-grid/utils/utils';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

type OwnerState = DataGridProcessedProps;

const GridToolbarQuickFilterRoot = styled(TextField, {
  name: 'MuiDataGrid',
  slot: 'ToolbarQuickFilter',
  overridesResolver: (_props, styles) => styles.toolbarQuickFilter,
})<{ ownerState: OwnerState }>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    maxWidth: theme.spacing(30),
    background: theme.palette.common.white,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
  },
  '& .Mui-focused': { boxShadow: theme.customShadows.z8 },
  //
  paddingBottom: theme.spacing(0.5),
  '& input': {
    marginLeft: theme.spacing(0.5),
  },

  [`& input[type=search]::-ms-clear,
  & input[type=search]::-ms-reveal`]: {
    /* clears the 'X' icon from IE */
    display: 'none',
    width: 0,
    height: 0,
  },
  [`& input[type="search"]::-webkit-search-decoration,
    & input[type="search"]::-webkit-search-cancel-button,
    & input[type="search"]::-webkit-search-results-button,
    & input[type="search"]::-webkit-search-results-decoration`]: {
    /* clears the 'X' icon from Chrome */
    display: 'none',
  },
}));

const defaultSearchValueParser = (searchText: string) =>
  searchText.split(' ').filter(word => word !== '');

const defaultSearchValueFormatter = (values: string[]) => values.join(' ');

export type GridToolbarQuickFilterProps = TextFieldProps & {
  /**
   * Function responsible for parsing text input in an array of independent values for quick filtering.
   * @param {string} input The value entered by the user
   * @returns {any[]} The array of value on which quick filter is applied
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  quickFilterParser?: (input: string) => any[];
  /**
   * Function responsible for formatting values of quick filter in a string when the model is modified
   * @param {any[]} values The new values passed to the quick filter model
   * @returns {string} The string to display in the text field
   */
  quickFilterFormatter?: (
    values: GridFilterModel['quickFilterValues'],
  ) => string;
  /**
   * The debounce time in milliseconds.
   * @default 500
   */
  debounceMs?: number;
};

function StakeholdersGridToolbarQuickFilter(
  props: GridToolbarQuickFilterProps,
) {
  const {
    quickFilterParser = defaultSearchValueParser,
    quickFilterFormatter = defaultSearchValueFormatter,
    debounceMs = 500,
    ...other
  } = props;

  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const quickFilterValues = useGridSelector(
    apiRef,
    gridQuickFilterValuesSelector,
  );

  const [searchValue, setSearchValue] = useState(() =>
    quickFilterFormatter(quickFilterValues ?? []),
  );

  const [prevQuickFilterValues, setPrevQuickFilterValues] =
    useState(quickFilterValues);

  useEffect(() => {
    if (!isDeepEqual(prevQuickFilterValues, quickFilterValues)) {
      // The model of quick filter value has been updated
      setPrevQuickFilterValues(quickFilterValues);

      // Update the input value if needed to match the new model
      setSearchValue(prevSearchValue =>
        isDeepEqual(quickFilterParser(prevSearchValue), quickFilterValues)
          ? prevSearchValue
          : quickFilterFormatter(quickFilterValues ?? []),
      );
    }
  }, [
    prevQuickFilterValues,
    quickFilterValues,
    quickFilterFormatter,
    quickFilterParser,
  ]);

  const updateSearchValue = useCallback(
    (newSearchValue: string) => {
      apiRef.current.setQuickFilterValues(quickFilterParser(newSearchValue));
    },
    [apiRef, quickFilterParser],
  );

  const debouncedUpdateSearchValue = useMemo(
    () => debounce(updateSearchValue, debounceMs),
    [updateSearchValue, debounceMs],
  );

  const handleSearchValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = event.target.value;
      setSearchValue(newSearchValue);
      debouncedUpdateSearchValue(newSearchValue);
    },
    [debouncedUpdateSearchValue],
  );

  const handleSearchReset = useCallback(() => {
    setSearchValue('');
    updateSearchValue('');
  }, [updateSearchValue]);

  return (
    <GridToolbarQuickFilterRoot
      data-testid='search-filter-container'
      as={rootProps.slots.baseTextField}
      ownerState={rootProps}
      variant='outlined'
      size='small'
      value={searchValue}
      onChange={handleSearchValueChange}
      placeholder={apiRef.current.getLocaleText(
        'toolbarQuickFilterPlaceholder',
      )}
      sx={{ minWidth: 200 }}
      aria-label={apiRef.current.getLocaleText('toolbarQuickFilterLabel')}
      type='search'
      InputProps={{
        startAdornment: (
          <Box
            component='span'
            sx={{
              color: 'text.disabled',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <rootProps.slots.quickFilterIcon fontSize='small' />
          </Box>
        ),
        endAdornment: (
          <IconButton
            aria-label={apiRef.current.getLocaleText(
              'toolbarQuickFilterDeleteIconLabel',
            )}
            size='small'
            sx={{ visibility: searchValue ? 'visible' : 'hidden' }}
            onClick={handleSearchReset}
          >
            <rootProps.slots.quickFilterClearIcon fontSize='small' />
          </IconButton>
        ),
      }}
      {...other}
      {...rootProps.slotProps?.baseTextField}
    />
  );
}

export { StakeholdersGridToolbarQuickFilter };
