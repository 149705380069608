import { captureException } from '@sentry/react';
import {
  useAddFeatureColumnsToCategoryMutation,
  useEnrichStartupAsyncMutation,
} from 'apollo/generated/sdkInnovationManager';
import {
  GetDataForSidePanelDocument,
  GetProjectCategoriesForScopeDocument,
  useCheckIfStartupInLibraryLazyQuery,
  useCreateFirstCategoryToListMutation,
  useGetStartupListSuppliersCountLazyQuery,
  useInsertStartupsIfNotExistMutation,
  useUpsertCategorizedSupplierToListMutation,
} from 'apollo/generated/sdkShared';
import { HIDE_FROM_LIBRARY_TAG } from 'components/dashboard/startupList/constants';
import { useRemoveListTag } from 'components/dashboard/startupList/details/StartupListTagsSelect';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { useEnrichmentProgressPool } from 'components/enrichmentProgress/StartupEnrichmentProgressContext';
import { LIMITED_ENRICHMENT } from 'config';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { sanitizeDomain } from 'utils/general';

export type AddStartupToListArgs = {
  selectedStartup: {
    name?: string | null;
    domain?: string | null;
    employees_count?: string | number | null;
    hq?: string | null;
    short_description?: string | null;
    long_description?: string | null;
    logo_url?: string | null;
    linkedin_url?: string | null;
    founded?: number | null;
    website?: string | null;
    crunchbase_url?: string | null;
    funding?: number | null;
  };
  listId: number | undefined;
  // TODO: Make it required and always specify the category
  categoryId?: number;
  logActivity?: boolean;
};

const useAddStartupToList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [checkIfStartupInLibrary, { loading: query1Loading }] =
    useCheckIfStartupInLibraryLazyQuery();
  const [insertStartupsIfNotExist, { loading: query2Loading }] =
    useInsertStartupsIfNotExistMutation();
  const [createFirstCategoryToList, { loading: query3Loading }] =
    useCreateFirstCategoryToListMutation();
  const [enrichStartupAsync, { loading: query4Loading }] =
    useEnrichStartupAsyncMutation();
  const [upsertCategorizedStartup, { loading: query5Loading }] =
    useUpsertCategorizedSupplierToListMutation({
      refetchQueries: [GetProjectCategoriesForScopeDocument],
    });
  const [addFeatureColumns] = useAddFeatureColumnsToCategoryMutation();
  const [getStartupListSuppliersCount, { loading: query6Loading }] =
    useGetStartupListSuppliersCountLazyQuery();
  const removeListTag = useRemoveListTag();

  const loading =
    query1Loading ||
    query2Loading ||
    query3Loading ||
    query4Loading ||
    query5Loading ||
    query6Loading;

  const { addProgressUUIDToPool } = useEnrichmentProgressPool();
  const { logStartupListActivity } = useUpdateStartupListActivities();

  const addStartupToList = useCallback(
    async ({
      selectedStartup,
      listId,
      categoryId,
      logActivity,
    }: AddStartupToListArgs) => {
      if (!listId) {
        enqueueSnackbar('Error adding startup to list', { variant: 'error' });
        captureException(new Error('List ID is required'));
        return;
      }

      let hasSuppliers = false;
      const suppliersCountResult = await getStartupListSuppliersCount({
        variables: { startupListId: listId },
        fetchPolicy: 'no-cache',
      });

      if (
        suppliersCountResult.data?.categorized_suppliers &&
        suppliersCountResult.data?.categorized_suppliers.length >= 1
      ) {
        hasSuppliers = true;
      }

      let supplierId: number;
      const sanitizedDomain = sanitizeDomain(selectedStartup?.domain || '');

      let employeesCount = null;
      try {
        // this could still be a range ir 1-10 rather than a number. In such case we will just ignore it
        employeesCount =
          typeof selectedStartup.employees_count === 'number'
            ? selectedStartup.employees_count
            : parseInt(selectedStartup.employees_count!);
      } catch {}

      const { data: newStartupData } = await insertStartupsIfNotExist({
        variables: {
          objects: [
            {
              domain: sanitizedDomain,
              name: selectedStartup.name || selectedStartup.domain,
              hq: selectedStartup?.hq || null,
              short_description:
                selectedStartup?.short_description ||
                selectedStartup?.long_description ||
                null,
              long_description: selectedStartup?.long_description || null,
              logo_url: selectedStartup?.logo_url || null,
              linkedin_url: selectedStartup?.linkedin_url || null,
              founded_year: selectedStartup?.founded || null,
              website: selectedStartup?.website || null,
              crunchbase_url: selectedStartup?.crunchbase_url || null,
              funding: selectedStartup?.funding || null,
              employees_count: employeesCount,
            },
          ],
        },
      });

      const { data: startupInLibraryData } = await checkIfStartupInLibrary({
        variables: {
          // eslint-disable-next-line
          startupId: newStartupData?.insert_suppliers?.returning?.[0]?.id!,
        },
      });

      if (!startupInLibraryData?.suppliers?.[0]?.organization_startup) {
        const { data: enrichStartupAsyncData } = await enrichStartupAsync({
          variables: {
            domain: sanitizedDomain,
            overwriteExistingData: LIMITED_ENRICHMENT ? ['name'] : ['all'],
          },
        });
        addProgressUUIDToPool({
          // eslint-disable-next-line
          progressUUID: enrichStartupAsyncData?.enrich_startup!,
          domain: sanitizedDomain,
          refetchQuery: GetDataForSidePanelDocument,
        });
      }

      const startup = newStartupData?.insert_suppliers?.returning?.[0];
      // eslint-disable-next-line
      supplierId = startup?.id!;

      let listCategoryId = categoryId;

      if (!listCategoryId) {
        const insertedCategory = await createFirstCategoryToList({
          variables: {
            startupListId: listId,
          },
        });
        listCategoryId =
          // eslint-disable-next-line
          insertedCategory?.data?.create_first_category_to_list.startup_list
            ?.project_categories[0].id!;
      }

      await addFeatureColumns({
        variables: {
          projectCategoryId: listCategoryId,
        },
      });

      await upsertCategorizedStartup({
        variables: {
          categoryId: listCategoryId,
          startupId: supplierId,
        },
      });

      const newStartupId = newStartupData?.insert_suppliers?.returning?.[0].id;

      if (logActivity && newStartupId) {
        const activitiesLog = [
          {
            action: 'created',
            entityIds: supplierId ? supplierId : newStartupId,
            entityType: 'suppliers',
          },
        ];
        await logStartupListActivity({
          logs: activitiesLog,
          startupListId: listId,
        });
      }

      if (hasSuppliers) {
        try {
          await removeListTag({
            startupListId: listId,
            name: HIDE_FROM_LIBRARY_TAG,
          });
        } catch (error) {
          console.error('Failed to remove tag from list', error);
          captureException(new Error('Failed to remove tag from list'));
        }
      }

      return startup;
    },
    [
      getStartupListSuppliersCount,
      insertStartupsIfNotExist,
      checkIfStartupInLibrary,
      addFeatureColumns,
      upsertCategorizedStartup,
      enqueueSnackbar,
      enrichStartupAsync,
      addProgressUUIDToPool,
      createFirstCategoryToList,
      logStartupListActivity,
      removeListTag,
    ],
  );

  return {
    addStartupToList,
    loading,
  };
};

export default useAddStartupToList;
