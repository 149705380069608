import { useCallback, useMemo } from 'react';

import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { useSnackbar } from 'notistack';
import { useUpdateStartupListCategoriesCustomDimensionsSchemaMutation } from 'apollo/generated/sdkInnovationManager';

type DIRECTION = 'left' | 'right';

export const useReorderCustomHeaders = (
  category:
    | {
        id: number;
        custom_dimensions_schema: { name: string }[];
        startup_list_id: number;
      }
    | undefined,
) => {
  const [updateSchema] =
    useUpdateStartupListCategoriesCustomDimensionsSchemaMutation();
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const { enqueueSnackbar } = useSnackbar();

  const categoryId = category?.id;
  const startupListId = category?.startup_list_id;
  const customDimensionsSchema = useMemo(
    () => category?.custom_dimensions_schema || [],
    [category?.custom_dimensions_schema],
  );
  const reorderColumnPlan = useCallback(
    (currentColumn: string, direction: DIRECTION) => {
      const currentColumnIndex = customDimensionsSchema
        .map(x => x.name)
        .indexOf(currentColumn);
      const indexToReplaceWith =
        direction === 'right' ? currentColumnIndex + 1 : currentColumnIndex - 1;

      return {
        currentColumnIndex,
        indexToReplaceWith,
        reorderAllowed:
          indexToReplaceWith >= 0 &&
          indexToReplaceWith < customDimensionsSchema.length,
      };
    },
    [customDimensionsSchema],
  );

  const reorderColumn = useCallback(
    async (currentColumn: string, direction: DIRECTION) => {
      if (!categoryId) {
        throw new Error('Category ID is required');
      }
      const { currentColumnIndex, indexToReplaceWith, reorderAllowed } =
        reorderColumnPlan(currentColumn, direction);
      if (!reorderAllowed) {
        throw new Error('Cannot move column outside of bounds');
      }

      const orderedCustomDimensionsSchema = customDimensionsSchema.map(
        (x, i) => {
          if (i === currentColumnIndex) {
            return customDimensionsSchema[indexToReplaceWith];
          } else if (i === indexToReplaceWith) {
            return customDimensionsSchema[currentColumnIndex];
          } else {
            return x;
          }
        },
      );

      if (
        orderedCustomDimensionsSchema.length !== customDimensionsSchema?.length
      ) {
        throw new Error(
          'DataGrid column names does not include all custom dimensions fields',
        );
      }

      await updateSchema({
        variables: {
          project_category_ids: [categoryId],
          custom_dimensions_schema: orderedCustomDimensionsSchema,
        },
        optimisticResponse: {
          update_project_categories: {
            affected_rows: 1,
            returning: [
              {
                id: categoryId!,
                custom_dimensions_schema: orderedCustomDimensionsSchema,
              },
            ],
          },
        },
      });

      enqueueSnackbar('Column order changed', { variant: 'success' });
      startupListId &&
        (await logStartupListActivity({
          logs: [
            {
              action: 'updated',
              entityIds: [categoryId ? categoryId : 0],
              entityType: 'project_categories',
            },
          ],
          startupListId,
        }));
    },
    [
      updateSchema,
      categoryId,
      customDimensionsSchema,
      enqueueSnackbar,
      reorderColumnPlan,
      logStartupListActivity,
      startupListId,
    ],
  );

  return {
    reorderColumn,
    reorderColumnPlan,
  };
};
