import {
  Autocomplete,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FindProjectSourceTagsQuery,
  useDeleteProjectSourceTagMutation,
  useDeleteProjectsProjectSourceTagMutation,
  useFindProjectSourceTagByNameLazyQuery,
  useFindProjectSourceTagsQuery,
  useInsertProjectSourceTagMutation,
  useInsertProjectsProjectSourceTagMutation,
  useUpdateProjectSourceTagMutation,
} from 'apollo/generated/sdkShared';
import { Tag, ValueTag } from 'components/base/BaseMultiselect';
import { BaseTagsSelect } from 'components/base/BaseTagsSelect';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { ProjectDetailContext } from 'contexts/ProjectContext';
import { Dispatch, SetStateAction, useContext, useState } from 'react';

export const useInsertProjectSourceTag = ({
  onInsertComplete,
}: {
  onInsertComplete?: (tagId: number) => void;
}) => {
  const { id: organizationId } = useCurrentOrganizationFromContext();
  const [insertTag] = useInsertProjectSourceTagMutation();
  const [insertProjectSourceTags] = useInsertProjectsProjectSourceTagMutation();
  const [getTagByName] = useFindProjectSourceTagByNameLazyQuery();

  const onInsert = async (name: string, projectId: number) => {
    const tag = await insertTag({
      variables: { name, organizationId },
    });

    let newTagId = tag.data?.insert_project_source_tags_one?.id;

    if (!newTagId) {
      const res = await getTagByName({ variables: { name, organizationId } });
      newTagId = res.data?.project_source_tags?.[0].id;
    }

    if (newTagId) {
      const res = await insertProjectSourceTags({
        variables: {
          project_id: projectId,
          tag_id: newTagId,
        },
      });

      const projectSourceTag =
        res.data?.insert_projects_project_source_tags_one;
      projectSourceTag?.id &&
        onInsertComplete &&
        onInsertComplete(projectSourceTag.id);
    }
  };

  return onInsert;
};

export const ProjectSourceTagsSelect = ({ sx }: { sx?: SxProps }) => {
  const { id: organizationId } = useCurrentOrganizationFromContext();
  const { data, loading } = useFindProjectSourceTagsQuery({
    variables: { organizationId },
  });
  const { project } = useContext(ProjectDetailContext);
  const [insertProjectSourceTags] = useInsertProjectsProjectSourceTagMutation();
  const [deleteProjectSourceTags] = useDeleteProjectsProjectSourceTagMutation();
  const [deleteTag] = useDeleteProjectSourceTagMutation();
  const [updateTag] = useUpdateProjectSourceTagMutation();
  const onInsert = useInsertProjectSourceTag({
    onInsertComplete: tagId => setAddedTagIds(prev => [...prev, tagId]),
  });

  const projectSourceTags =
    project.project_source_tags
      ?.map(t => ({
        id: t.id,
        name: t.tag.name,
      }))
      .sort((a, b) => a.id - b.id) || [];

  const [addedTagIds, setAddedTagIds] = useState<number[]>(
    projectSourceTags?.map(pt => pt.id),
  );

  const options: Tag[] = (data?.project_source_tags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
  }));

  const value: ValueTag[] = (projectSourceTags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
    option_id: options.find(o => o.value === t.name)?.id,
  }));

  const onAdd = async (tag_name: string) => {
    const option = options.find(o => o.value === tag_name);
    if (!option) return;

    const res = await insertProjectSourceTags({
      variables: {
        project_id: project.id,
        tag_id: option.id as number,
      },
    });

    const projectSourceTag = res.data?.insert_projects_project_source_tags_one;
    projectSourceTag?.id &&
      setAddedTagIds(prev => [...prev, projectSourceTag.id]);
  };

  const onRemove = async (tag: Tag) => {
    await deleteProjectSourceTags({
      variables: {
        id: tag.id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_projects_project_source_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'projects_project_source_tags',
            id: data.delete_projects_project_source_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onDelete = async (tag_id: number) => {
    await deleteTag({
      variables: {
        id: tag_id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_project_source_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'project_source_tags',
            id: data.delete_project_source_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onUpdate = async (tag_id: number, name: string) => {
    await updateTag({
      variables: {
        id: tag_id,
        name: name,
      },
    });
  };

  return (
    <BaseTagsSelect
      loading={loading}
      options={options}
      value={value}
      onAdd={onAdd}
      onRemove={onRemove}
      onInsert={name => onInsert(name, project.id)}
      onDelete={onDelete}
      onUpdate={onUpdate}
      inputPlaceholder='Add source'
      sx={sx}
      addedTagIds={addedTagIds}
      newOptionText='+ Create new source'
    />
  );
};

type ProjectSourceTag =
  FindProjectSourceTagsQuery['project_source_tags'][number];
type ProjectSourceTagsAutocompleteProps = {
  selectedTagIds: number[];
  onTagsChange: (tagIds: number[]) => void;
  onNewTagAdd: Dispatch<SetStateAction<string>>;
  isLead?: boolean;
};

export const ProjectSourceTagsAutocomplete = ({
  selectedTagIds,
  onTagsChange,
  onNewTagAdd,
  isLead = false,
}: ProjectSourceTagsAutocompleteProps) => {
  const { id: organizationId } = useCurrentOrganizationFromContext();
  const { data, loading } = useFindProjectSourceTagsQuery({
    variables: { organizationId },
    fetchPolicy: 'cache-and-network',
  });
  const [inputTextValue, setInputTextValue] = useState('');
  const { zIndex } = useTheme();
  const options = data?.project_source_tags || [];
  const selectedTagValues = selectedTagIds
    .map(id => options.find(tag => tag.id === id))
    .filter(Boolean) as ProjectSourceTag[];

  return (
    <Autocomplete
      multiple
      value={selectedTagValues}
      loading={loading}
      options={options}
      inputValue={inputTextValue}
      getOptionLabel={option => (option as ProjectSourceTag).name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      slotProps={{
        popper: {
          sx: {
            zIndex: zIndex.drawer + zIndex.tooltip,
            '& .MuiAutocomplete-noOptions': { padding: '0 !important' },
          },
        },
        paper: {
          sx: {
            '& .MuiAutocomplete-option': {
              textAlign: 'left !important',
              alignItems: 'flex-start !important',
            },
          },
        },
      }}
      noOptionsText={<></>}
      onChange={(_, value) => {
        onTagsChange(value.map(v => v.id));
        setInputTextValue('');
        onNewTagAdd('');
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={isLead ? 'Lead Source' : 'Project Source'}
          placeholder='Type new or select existing'
          variant='outlined'
          onChange={e => {
            const input = e.target.value;
            setInputTextValue(input);
            const exists = options.some(tag =>
              tag.name.toLowerCase().includes(input.toLowerCase()),
            );

            if (!exists && input.trim() !== '') {
              onNewTagAdd(input);
            }
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Stack component='li' {...props}>
            <Typography variant='body2' fontWeight={600}>
              {option.name}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};
