import { Box, Tooltip } from '@mui/material';
import InfoIcon from '../analytics/assets/ic_info.svg?react';
import { CSSProperties } from 'react';

interface InfoHoverProps {
  tooltipText: string;
  iconColor?: string;
  iconSize?: number;
  tooltipPlacement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  additionalCSS?: CSSProperties;
}

export function InfoHover({
  tooltipText,
  tooltipPlacement = 'right',
  iconColor = '#667380',
  iconSize = 16,
  additionalCSS,
}: InfoHoverProps) {
  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <Box sx={{ cursor: 'help', display: 'flex', alignItems: 'center' }}>
        <InfoIcon
          style={{
            fill: iconColor,
            width: iconSize,
            height: iconSize,
            ...additionalCSS,
          }}
        />
      </Box>
    </Tooltip>
  );
}
