import {
  useScoutStartupsV2LazyQuery,
  useSearchListsMutation,
} from 'apollo/generated/sdkShared';
import {
  StartupFunnelLabel,
  getStartupFunnelLabelOrder,
} from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import { getStartupSignalLabelByOrder } from 'components/startups/StartupSignalLabel';
import { SEARCH_PARAMS } from 'config';
import { usePreviousTruthy } from 'hooks/usePrevious';
import { compact } from 'lodash';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { getUrlHashValue, updateUrlHash } from 'utils/url';
import { Searchbox } from './Searchbox';
import { SearchV2Filter } from './SearchFilters';
import { SearchNameResults } from './SearchNameResults';
import { SearchStartupListResultContainer } from './SearchStartupListResults';
import { StartupResults } from './StartupResults';
import { DecoratedScoutedStartup } from './types';

export type SearchVersion = 'hybrid-2024' | 'stable-2023';

const CURRENT_SEARCH_VERSION = 'hybrid-2024';

export const SearchV2 = ({
  embeddedInCurrentList,
}: {
  embeddedInCurrentList: { listId: number; categoryId?: number | null } | null;
}) => {
  const [query, setQuery] = useState('');

  const {
    searchWithFilters,
    handleFilterChange,
    submittedQuery,
    listResults,
    loadingLists,
    decoratedStartupResults,
    startupsLoading,
    startupsInitialLoading,
    filter,
  } = useSearchWithFilters({ setQuery });

  const location = useLocation();

  useEffect(() => {
    const query = getUrlHashValue(SEARCH_PARAMS.searchQuery);
    if (query && query !== submittedQuery) {
      searchWithFilters(query || '', filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  // Someone is typing a new query => There is a query and no submitted query
  // Someone is updating the query => Query != submitted query and there is a submitted query.
  //    Also check that query !== '' otherwise the button will blink for a second when clicking the X
  const showSearchButton = useMemo(
    () =>
      (Boolean(query) && !Boolean(submittedQuery)) ||
      (query !== submittedQuery && Boolean(submittedQuery) && Boolean(query)),
    [query, submittedQuery],
  );

  return (
    <>
      <Searchbox
        query={query}
        setQuery={setQuery}
        loading={startupsLoading}
        handleSubmit={() => searchWithFilters(query, filter)}
        showSearchButton={showSearchButton}
      />
      {submittedQuery && <SearchNameResults query={submittedQuery} />}
      {submittedQuery && (
        <SearchStartupListResultContainer
          listResults={listResults}
          query={submittedQuery}
          loading={loadingLists}
        />
      )}
      <StartupResults
        decoratedStartupResults={decoratedStartupResults}
        submittedQuery={submittedQuery}
        initialLoading={startupsInitialLoading}
        loading={startupsLoading}
        filter={filter}
        handleFilterChange={handleFilterChange}
        search={query => searchWithFilters(query, filter)}
        embeddedInCurrentList={embeddedInCurrentList}
      />
    </>
  );
};

const useSearchWithFilters = ({
  setQuery,
}: {
  setQuery: Dispatch<SetStateAction<string>>;
}) => {
  const [submittedQuery, setSubmittedQuery] = useState('');
  const [filter, setFilter] = useState<SearchV2Filter>({
    employeesCount: '',
    funding: '',
    founded: '',
    countries: [],
  });

  const {
    loading,
    initialLoading,
    decoratedStartupResults,
    handleSearchStartups,
  } = useStartupsSubmit();

  const {
    loading: loadingLists,
    listResults,
    handleSearchLists,
  } = useSearchLists();
  const searchWithFilters = useCallback(
    (
      query: string,
      newFilter: SearchV2Filter,
      skipListSearch: boolean = false,
    ) => {
      setSubmittedQuery(query);
      setQuery(query);
      setFilter(newFilter);
      handleSearchStartups(query, newFilter);
      if (!skipListSearch) handleSearchLists(query);
    },
    [handleSearchStartups, handleSearchLists, setSubmittedQuery, setQuery],
  );

  const handleFilterChange = useCallback(
    (key: keyof SearchV2Filter, value: string | string[]) => {
      const newFilter = { ...filter, [key]: value };
      searchWithFilters(submittedQuery, newFilter, true);
    },
    [filter, submittedQuery, searchWithFilters],
  );

  return {
    searchWithFilters,
    handleFilterChange,
    submittedQuery,
    listResults,
    loadingLists,
    decoratedStartupResults,
    startupsLoading: loading,
    startupsInitialLoading: initialLoading,
    filter,
  };
};

const useSearchLists = () => {
  const [searchLists, { data: listData, loading }] = useSearchListsMutation();

  const handleSearchLists = useCallback(
    async (query: string) => {
      if (!query) return;

      return searchLists({
        variables: {
          listQuery: query,
        },
      });
    },
    [searchLists],
  );

  const listResults = useMemo(
    () => compact(listData?.search_lists?.map(sl => sl?.startup_list)),
    [listData],
  );

  return { listResults, handleSearchLists, loading };
};

const useStartupsSubmit = () => {
  const [searchStartups, { data: searchData, loading }] =
    useScoutStartupsV2LazyQuery();

  const handleSearchStartups = useCallback(
    async (query: string, filter: SearchV2Filter) => {
      updateUrlHash(SEARCH_PARAMS.searchQuery, query);

      if (!query) return;

      searchStartups({
        variables: {
          searchCriteria: {
            query,
            enrichQuery: true,
            limit: 100,
            fundingRange: filter.funding,
            foundedRange: filter.founded,
            employeeCountRange: filter.employeesCount,
            hqCountries: filter.countries?.length
              ? filter.countries
              : undefined,
          },
        },
      }).then(result => {
        const results = result.data?.startups_search;

        if (results) {
          captureAnalyticsEvent('Startups search submitted', {
            searchQuery: query,
            searchResultsCount: results.length,
            searchVersion: CURRENT_SEARCH_VERSION,
          });
        }
        return results;
      });
    },
    [searchStartups],
  );

  const prevSearchData = usePreviousTruthy(searchData);

  const startupResults = useMemo(
    () => searchData?.startups_search || prevSearchData?.startups_search,
    [searchData?.startups_search, prevSearchData?.startups_search],
  );

  const decoratedStartupResults = useMemo(
    () =>
      startupResults?.map(startup => {
        const cityPrefix = startup.city ? `${startup.city}, ` : '';
        const dataGridId = startup.domain;

        return {
          ...startup,
          hq: `${cityPrefix}${startup.country || ''}`,
          description: getBestDescription(startup),
          signal_label: startup.signal_labels
            ? getStartupSignalLabelByOrder(startup.signal_labels)
            : null,
          funnel_label: startup.funnel_labels
            ? getStartupFunnelLabelOrder(
                startup.funnel_labels as { value: StartupFunnelLabel }[],
              )
            : null,
          highlight: { description: [getBestDescription(startup)] },
          dataGridId,
          employees_count: startup.employees_count ?? '-',
          total_funding: startup.funding,
          founded_year: startup.founded,
        } as DecoratedScoutedStartup;
      }),
    [startupResults],
  );

  return {
    handleSearchStartups,
    loading,
    startupResults: searchData?.startups_search || null,
    decoratedStartupResults: decoratedStartupResults || null,
    initialLoading: !decoratedStartupResults && loading,
  };
};

const getBestDescription = (startup: {
  description?: string | null;
  pb_description?: string | null;
  long_description?: string | null;
  short_description?: string | null;
  linkedin_description?: string | null;
}) => {
  return (
    // startup.description is the supplier.long_description
    startup.description ||
    startup.pb_description ||
    startup.long_description ||
    startup.short_description ||
    startup.linkedin_description ||
    ''
  );
};
