import LoadingScreen from 'components/LoadingScreen';
import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import useAuth from '../hooks/useAuth';
import useIdleTimeout from 'hooks/useIdleTimeout';

type BuUserGuardProps = {
  children: ReactNode;
  externalUserEmail?: string;
};

export default function BuUserGuard({ children }: BuUserGuardProps) {
  const { isAuthenticated } = useAuth();
  const { IdleTimeoutSnackbar } = useIdleTimeout();

  if (!isAuthenticated) {
    const returnTo = encodeURIComponent(
      window.location.pathname + window.location.search,
    );
    return <Navigate to={`${PATH_ROOT.auth.login}?returnTo=${returnTo}`} />;
  }

  if (isAuthenticated) {
    return (
      <>
        {children}
        {IdleTimeoutSnackbar}
      </>
    );
  } else {
    return <LoadingScreen />;
  }
}
