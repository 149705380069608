import { Checkbox, Stack } from '@mui/material';
import { useUpdateCategorizedStartupMutation } from 'apollo/generated/sdkInnovationManager';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { StartupsTableContext } from 'components/startupComparisonTable/shared/StartupsTableContext';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';

interface SelectForAssessmentCellProps {
  categorizedStartup: {
    categorized_startup_id: number;
    is_shortlisted: boolean;
  };
}

const SelectForAssessmentCell: React.FC<SelectForAssessmentCellProps> = ({
  categorizedStartup,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentStartupList } = useContext(StartupsTableContext)!;
  const [updateMatrixState] = useUpdateCategorizedStartupMutation();
  const { logStartupListActivity } = useUpdateStartupListActivities();

  const handleCheckboxChange = async () => {
    const newShortlistedState = !categorizedStartup.is_shortlisted;

    const updateData = {
      is_shortlisted: newShortlistedState,
      shortlisted_date: newShortlistedState ? new Date().toISOString() : null,
    };

    try {
      await updateMatrixState({
        variables: {
          id: categorizedStartup.categorized_startup_id,
          updateData,
        },
      });

      await logStartupListActivity({
        logs: [
          {
            action: 'updated',
            entityIds: categorizedStartup.categorized_startup_id,
            entityType: 'categorized_suppliers',
          },
        ],
        startupListId: currentStartupList.id,
      });
    } catch {
      enqueueSnackbar(
        `Error ${newShortlistedState ? 'selecting' : 'unselecting'}`,
        { variant: 'error' },
      );
    }
  };

  return (
    <Stack
      width='100%'
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={{ cursor: 'pointer' }}
    >
      <Checkbox
        checked={categorizedStartup.is_shortlisted}
        onChange={handleCheckboxChange}
        color='primary'
        size='small'
        sx={{ stroke: ({ palette }) => palette.common.white, strokeWidth: 0.5 }}
      />
    </Stack>
  );
};

export { SelectForAssessmentCell };
