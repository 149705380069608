import { CollaboratorFragment } from 'apollo/generated/sdkShared';
import { GLASSDOLLAR_EMAIL_DOMAIN } from 'config';
import { sortBy } from 'lodash';

// All our calculations in the market intelligence landscapes should be based on the date of the last update of
// - funding rounds
// - companies inserted in the landscape
// In next iterations this should be dynamic and not hardcoded
export const MARKET_INTELLIGENCE_LAST_UPDATED = new Date('2023-03-01');

export const sortAndFilterProjectCategories = <
  T extends {
    rank: number;
    is_hidden: boolean;
  },
>({
  categories = [],
  filterHidden = false,
}: {
  categories: T[];
  filterHidden?: boolean;
}) => {
  const sorted = sortBy(categories, category => category.rank);

  if (filterHidden) {
    return sorted.filter(({ is_hidden }) => !is_hidden);
  }

  return sorted;
};

const finiteValue = (value: number) =>
  value === Infinity || Number.isNaN(+value) ? 0 : +value.toFixed(2);

export const calculateStartupsFoundedPercentageGrowth = (
  startups: {
    founded_year?: number | null;
  }[],
) => {
  const lastUpdatedYear = MARKET_INTELLIGENCE_LAST_UPDATED.getFullYear();
  const startupsInTotal = startups.length;
  let foundedInLast2Years = 0;

  startups.forEach(startup => {
    if (
      startup?.founded_year &&
      // 2023 - 2021 = 2
      lastUpdatedYear - startup.founded_year <= 2
    ) {
      foundedInLast2Years++;
    }
  });

  // 1(startups founded in the last 2 years) / (5(total startups) - 1) * 100 = 25%
  return finiteValue(
    (foundedInLast2Years / (startupsInTotal - foundedInLast2Years)) * 100,
  );
};

export const calculateFundingTotal = (
  startups: Array<{
    funding?: number | null;
  }>,
) => startups?.reduce((acc, f) => (f?.funding ? acc + f.funding : acc), 0);

export const getListOwnerDetails = ({
  collaborators,
}: {
  collaborators: CollaboratorFragment[];
}) => {
  const owner = collaborators.find(
    collaborator => collaborator.role === 'owner',
  );

  const isOwnedByGD = owner?.user?.person?.email.includes(
    GLASSDOLLAR_EMAIL_DOMAIN,
  );

  if (owner) {
    return {
      isOwnedByGD,
      ownerName: owner?.user?.person?.full_name,
      owner: owner?.user?.person,
    };
  } else {
    return {
      isOwnedByGD,
      ownerName: undefined,
      owner: undefined,
    };
  }
};
