import { useAuth0 } from '@auth0/auth0-react';
import { Snackbar, Stack, Typography, Button } from '@mui/material';
import { captureMessage } from '@sentry/react';
import { useFindUserInvitationQuery } from 'apollo/generated/sdkShared';
import { OrganizationPortalHeader } from 'components/OrganizationPortal/shared/OrganizationPortalHeader';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

const ExternalBody = ({ children }: { children: React.ReactNode }) => (
  <>
    <Helmet>
      <style>{`body { padding-top: 0 !important; }`}</style>
    </Helmet>
    {children}
  </>
);

type InvitationQuery = {
  invitation: string;
  organization: string;
  organization_name: string;
};
export const SharedPagesLayout = () => {
  const { isSharedPage, setIsSharedPage } = useSharedPagesContext();
  const [pendingInvitation, setPendingInvitation] =
    useState<InvitationQuery | null>(null);
  const { loginWithRedirect } = useAuth0();

  const { data: userInvitationData } = useFindUserInvitationQuery();

  useEffect(() => {
    if (setIsSharedPage) {
      setIsSharedPage(true);
    } else {
      captureMessage('setIsSharedPage is not defined');
    }

    return () => {
      setIsSharedPage?.(false);
    };
  }, [setIsSharedPage]);

  useEffect(() => {
    const pendingInvitationUrl =
      userInvitationData?.find_user_invitation.data?.invitation_url;

    if (pendingInvitationUrl) {
      const url = new URL(pendingInvitationUrl);
      const queryParams = Array.from(url.searchParams.entries()).reduce<
        Record<string, string>
      >((params, [key, value]) => {
        params[key] = value;
        return params;
      }, {}) as InvitationQuery;
      setPendingInvitation(queryParams);
    }
  }, [
    userInvitationData?.find_user_invitation.data?.invitation_url,
    userInvitationData?.find_user_invitation.data?.user_metadata
      .invitee_full_name,
  ]);

  const acceptInvitation = async () => {
    if (!pendingInvitation) return;

    const pendingInvitationName =
      userInvitationData?.find_user_invitation.data?.user_metadata
        .invitee_full_name;

    await loginWithRedirect({
      authorizationParams: {
        invitation: pendingInvitation.invitation,
        fullName: pendingInvitationName
          ? pendingInvitationName.trim()
          : undefined,
      },
      appState: { returnTo: `/` },
    });
  };

  if (!isSharedPage) {
    return null;
  }

  return (
    <ExternalBody>
      <OrganizationPortalHeader />
      <Outlet />
      <Snackbar
        open={Boolean(pendingInvitation)}
        message={
          <Stack>
            <Typography variant='body1' fontWeight={800}>
              You&apos;re seeing a limited version.
            </Typography>
            <Typography alignItems={'center'}>
              Get full access by accepting your admin invite.{' '}
              <Button variant='text' onClick={acceptInvitation}>
                Accept Invitation
              </Button>
            </Typography>
          </Stack>
        }
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      />
    </ExternalBody>
  );
};

export const BuPageLayout = () => {
  return (
    <ExternalBody>
      <OrganizationPortalHeader />
      <Outlet />
    </ExternalBody>
  );
};

export const SharedPagesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSharedPage, setIsSharedPage] = useState(false);
  const contextValue = useMemo(() => {
    return {
      isSharedPage,
      setIsSharedPage,
    };
  }, [isSharedPage]);

  return (
    <SharedPagesContext.Provider value={contextValue}>
      {children}
    </SharedPagesContext.Provider>
  );
};

export const SharedPagesContext = createContext<{
  isSharedPage: boolean;
  setIsSharedPage: Dispatch<SetStateAction<boolean>> | null;
}>({
  isSharedPage: false,
  setIsSharedPage: null,
});

export const useSharedPagesContext = () => {
  const context = useContext(SharedPagesContext);

  return context;
};

export default SharedPagesLayout;
