import { useState, useRef } from 'react';

// Needed to handle the onBlur event and ensure that:
//   - input stays stable on every rerender and doesn't lose focus
//   - input is cleared when the focus(mouse click) is outside the Autocomplete component
export const useAutocompleteWithStableInput = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const autocompleteWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    console.log('event.relatedTarget', event.relatedTarget);
    if (
      autocompleteWrapperRef.current &&
      !autocompleteWrapperRef.current.contains(event.relatedTarget as Node)
    ) {
      console.log('clearing input value');
      setInputValue('');
    }
  };

  return [
    autocompleteWrapperRef,
    {
      inputValue,
      setInputValue,
      autocompleteWrapperRef,
      clearOnBlur: false,
      onBlur: handleBlur,
      onInputChange: (_: unknown, newInputValue: string) =>
        setInputValue(newInputValue),
    },
  ] as const;
};
