import { Box, Button, Card, Stack, Typography } from '@mui/material';

import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useGetSourcingOrdersQuery } from 'apollo/generated/sdkShared';
import EmptyContent from 'components/EmptyContent';
import { ListItemIconStyle, NAV_TEXT_GREY } from 'components/NavSection';
import Page from 'components/Page';
import { TypeSafeStyledDataGrid } from 'components/StyledDataGrid';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import BaseLabel from 'components/base/BaseLabel';
import { EmailPersonCell } from 'components/base/EmailPersonCell';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { LinkedEntityTitleTypography } from 'components/dashboard/StartupInfoSidePanel/LinkedToEntity';
import { OrderSourcingButton } from 'components/dashboard/sourcingOrder/OrderSourcingButton';
import { useSourcingOrderDrawer } from 'components/dashboard/sourcingOrder/SourcingOrderDrawer';
import { DecoratedSourcingOrderForOrdersIndexPage } from 'components/dashboard/sourcingOrder/types';
import { GDLogo } from 'components/shared/GDLogo';
import LoadingTable from 'components/shared/table/LoadingTable';
import { GLASSDOLLAR_EMAIL_DOMAIN, SEARCH_PARAMS } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { formatDistance, isToday, parseISO } from 'date-fns';
import useAlternativeNavigate from 'hooks/useAlternativeNavigate';
import { LibraryTabType } from 'layouts/LibraryLayout';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { VentureExpert } from '../../@types/innovationManager';
import PersonSearch from '../../layouts/dashboard/assets/person-search.svg?react';

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export default function SourcingOrdersPage() {
  const { data: sourcingsData, loading } = useGetSourcingOrdersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();
  const { setFilters } = useServerFiltersContext('listTableFilters');
  const {
    current_sourcing_credits_used,
    total_sourcing_credits,
    charge_credit_units_enabled,
  } = useCurrentOrganizationFromContext();

  const sourcings = useMemo(() => {
    return sourcingsData?.sourcing_orders || [];
  }, [sourcingsData]);

  const sourcingsCompleted = useMemo(() => {
    return sourcings.filter(so => so.status === 'scouting_completed');
  }, [sourcings]);

  const sourcingsInProgress = useMemo(() => {
    return sourcings.filter(
      so => so.status === 'in_progress' || so.status === 'draft',
    );
  }, [sourcings]);

  return (
    <Page
      title='Sourcings | GlassDollar'
      trackingTitle='Sourcings'
      sx={{ paddingBottom: 2, marginX: 0 }}
    >
      <Stack direction='row' spacing={2} marginBottom={2}>
        {charge_credit_units_enabled && (
          <>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 3,
                flexGrow: 1,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='subtitle2'>In progress</Typography>
                <Typography variant='h3'>
                  {sourcingsInProgress.length}
                </Typography>
              </Box>
            </Card>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 3,
                flexGrow: 1,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='subtitle2'>Available credits</Typography>
                <Typography variant='h3'>
                  {total_sourcing_credits >= 1000
                    ? 'Unlimited'
                    : total_sourcing_credits - current_sourcing_credits_used}
                </Typography>
              </Box>
            </Card>
          </>
        )}
      </Stack>
      {loading ? (
        <Card>
          <LoadingTable />
        </Card>
      ) : (
        <PageContent sourcingsInProgress={sourcingsInProgress} />
      )}
      <Box sx={{ marginTop: 2 }}>
        <Button
          onClick={() => {
            setFilters('sourcingsOnly', {
              sourcing_order: { id: { _is_null: false } },
            });

            navigate(
              `${PATH_ROOT.lists.root}?${SEARCH_PARAMS.tab}=${
                'lists' as LibraryTabType
              }`,
            );
          }}
          style={{ textDecoration: 'none' }}
          sx={({ palette, typography }) => ({
            fontWeight: typography.fontWeightBold,
            color: palette.secondary.main,
          })}
        >
          <Typography
            component='span'
            variant='body2'
            sx={{ fontWeight: 'bold' }}
          >
            View {sourcingsCompleted?.length} completed sourcings
          </Typography>
        </Button>
      </Box>
    </Page>
  );
}

function PageContent({
  sourcingsInProgress,
}: {
  sourcingsInProgress: DecoratedSourcingOrderForOrdersIndexPage[];
}) {
  const columns = useMemo(() => getColumns(), []);
  const rows = sourcingsInProgress;

  const { buildDrawerSearchPath } = useSourcingOrderDrawer();
  const navigate = useNavigate();

  return (
    <Card>
      <TypeSafeStyledDataGrid<DecoratedSourcingOrderForOrdersIndexPage>
        showCellVerticalBorder
        showColumnVerticalBorder
        getRowHeight={() => 'auto'}
        isRowClickable
        columns={columns}
        rows={rows}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        initialState={{
          sorting: {
            sortModel: [
              { field: 'status', sort: 'desc' },
              { field: 'created_at', sort: 'desc' },
            ],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        disableRowSelectionOnClick
        pagination={false}
        onCellClick={e => {
          const row: DecoratedSourcingOrderForOrdersIndexPage = e.row;

          navigate({
            search: buildDrawerSearchPath(row.id),
          });
        }}
        sx={rows.length === 0 ? { minHeight: 600 } : undefined}
      />
    </Card>
  );
}

function getColumns(): GridColDef<DecoratedSourcingOrderForOrdersIndexPage>[] {
  const formatDate = (date: string) =>
    isToday(new Date(date))
      ? 'Today'
      : formatDistance(new Date(date), new Date(), {
          addSuffix: true,
        });

  return [
    {
      field: 'title',
      headerName: 'Name',
      minWidth: 400,
      flex: 1,
      renderCell({
        row: { startup_list, delivery_type, status },
      }: GridRenderCellParams<
        DecoratedSourcingOrderForOrdersIndexPage,
        DecoratedSourcingOrderForOrdersIndexPage
      >) {
        if (!startup_list) return null;

        return (
          <Stack
            direction='row'
            alignItems='center'
            width='100%'
            marginY={1.5}
            gap={0.5}
          >
            <ListItemIconStyle
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& svg, & path': { fill: NAV_TEXT_GREY },
                alignSelf: 'flex-start',
              }}
            >
              <PersonSearch />
            </ListItemIconStyle>
            <LinkedEntityTitleTypography title={startup_list.title} />
            {delivery_type === 'express' && (
              <BaseLabel
                sx={({ spacing }) => ({ minWidth: spacing(5) })}
                color='primary'
              >
                Express
              </BaseLabel>
            )}
            {status === 'draft' && (
              <BaseLabel
                sx={({ spacing }) => ({ minWidth: spacing(5) })}
                color='warning'
              >
                Draft
              </BaseLabel>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'due_date',
      headerName: 'Deadline',
      flex: 1,
      minWidth: 200,
      renderCell(
        params: GridRenderCellParams<
          DecoratedSourcingOrderForOrdersIndexPage,
          DecoratedSourcingOrderForOrdersIndexPage
        >,
      ) {
        const deadline = params?.row.due_date;
        const status = params?.row.status;

        if (!deadline || status === 'draft')
          return <Typography variant='body2'>-</Typography>;

        // TODO: Add styles for overdue or close to deadline
        return (
          <Typography variant='body2'>{formatDate(`${deadline}`)}</Typography>
        );
      },
    },
    {
      field: 'venture_expert_json',
      headerName: 'Venture Expert',
      flex: 1,
      minWidth: 200,
      renderCell: (
        params: GridRenderCellParams<
          DecoratedSourcingOrderForOrdersIndexPage,
          VentureExpert[]
        >,
      ) => {
        const ventureExperts = params.value;

        if (!ventureExperts || !ventureExperts.length)
          return <Typography>-</Typography>;

        try {
          return ventureExperts.map(({ email, name }) => (
            <EmailPersonCell key={email} value={{ email, full_name: name }} />
          ));
        } catch (error) {
          console.error(error);
          return <Typography>-</Typography>;
        }
      },
    },
    {
      field: 'created_at',
      headerName: 'Created',
      type: 'dateTime',
      flex: 1,
      minWidth: 160,
      valueGetter: (value: GridCellParams) => {
        if (value instanceof Date) {
          return value;
        }
        if (typeof value === 'string') {
          return parseISO(value);
        }
        return null;
      },
      renderCell: ({
        value,
      }: GridRenderCellParams<
        DecoratedSourcingOrderForOrdersIndexPage,
        DecoratedSourcingOrderForOrdersIndexPage
      >) => {
        return (
          <Typography variant='body2'>{formatDate(`${value}`)}</Typography>
        );
      },
    },
    {
      field: 'created_by',
      headerName: 'Ordered by',
      flex: 1,
      minWidth: 200,
      renderCell({ value }: GridRenderCellParams) {
        return <CreatedByGridCell value={value} />;
      },
    },
    {
      field: 'requested_result_type',
      headerName: 'Format',
      minWidth: 160,
      flex: 1,
      renderCell({ value }: GridRenderCellParams) {
        if (!value) return <Typography variant='body2'>-</Typography>;

        return (
          <Typography variant='body2'>
            {capitalizeFirstLetter(value)}
          </Typography>
        );
      },
    },
  ];
}

export function CustomNoRowsOverlay() {
  return (
    <>
      <EmptyContent
        title={
          <Stack
            component='div'
            sx={{
              // This allow the content to be selectable/clickable
              zIndex: 5,
            }}
            spacing={2}
            direction='column'
            alignItems='center'
          >
            <Box component='span'>
              Let our Venture Experts curate a list for you
            </Box>
            <OrderSourcingButton orderOrigin='new' />
          </Stack>
        }
      />
    </>
  );
}

export const CreatedByGridCell = ({
  value,
}: {
  value: {
    person: {
      id: number;
      full_name?: string | null;
      email: string;
    };
  };
}) => {
  const { navigateTo } = useAlternativeNavigate();
  if (!value) return <Typography variant='body2'>-</Typography>;
  const person = value?.person;
  if (!person) return <EmailPersonCell value={person} />;

  const isOwnedByGD = person.email.includes(GLASSDOLLAR_EMAIL_DOMAIN);

  return (
    <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
      {isOwnedByGD && (
        <GDLogo
          size={20}
          sxProps={{
            display: 'inline-flex',
            marginTop: 0.1,
          }}
        />
      )}
      <BaseInitialsAvatar
        full_name={person.full_name || ''}
        sx={{
          height: '22px',
          width: '22px',
          fontSize: '10px',
        }}
      />
      <Typography
        onClick={e => {
          e.stopPropagation();
          navigateTo(PATH_ROOT.personProfile.details(person?.id));
        }}
        sx={{
          flexShrink: 1,
          minWidth: 0,
          display: 'block',
          paddingRight: '10px',
          lineHeight: '1.75',
          cursor: 'pointer',
          color: '#212B36',
          '&:hover': {
            textDecoration: 'underline',
            textUnderlineOffset: '3px',
            textDecorationColor: '#E5E8EB',
            textDecorationThickness: '1.25px',
          },
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant='body2'
        fontWeight={500}
      >
        {person.full_name ?? ''}
      </Typography>
    </Stack>
  );
};
