import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, TextField, Typography } from '@mui/material';
import { useUpdateOrganizationPortalConfigMutation } from 'apollo/generated/sdkInnovationManager';
import { BaseEmbeddedIframe } from 'components/base/BaseEmbeddedIframe';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useBreakpoints from 'hooks/useBreakpoints';
import useLocales, { ALL_LANGS, enCode } from 'hooks/useLocales';
import { useCallback, useMemo, useState } from 'react';
import { useOrganizationPortalContext } from '../contexts/OrganizationPortalContext';
import { useViewingOrganizationPortalAs } from '../contexts/ViewingPortalAsContext';

export const RequestVCUSectionViewMode = () => {
  const { isBelowMd } = useBreakpoints();
  const { getPortalConfigField } = useOrganizationPortalContext();
  const { currentLang, onChangeLang, translationEnabled } =
    useTranslateForOrganization();

  const vcuVideoUrl = getPortalConfigField('pitch_vcu_video_url');

  return (
    <Box width={isBelowMd ? '100%' : '550px'} flex={1} position='relative'>
      {vcuVideoUrl ? (
        <BaseEmbeddedIframe
          title='How Venture Clienting Works'
          src={vcuVideoUrl}
        />
      ) : (
        <Box
          component='img'
          borderRadius='10px'
          height='auto'
          width='auto'
          alt='lead form banner'
          src='/static/illustrations/lead-page-banner.png'
        />
      )}
      {translationEnabled && (
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{
            position: 'absolute',
            bottom: '-64px',
            alignSelf: 'flex-start',
            cursor: 'pointer',
            fontStyle: 'italic',
          }}
          onClick={() => {
            onChangeLang(currentLang.value === 'en' ? 'de' : 'en');
          }}
        >
          View in {currentLang.value === 'en' ? 'German' : 'English'}
        </Typography>
      )}
    </Box>
  );
};

export const RequestVCUSectionEditMode = () => {
  const { isBelowMd } = useBreakpoints();
  const { viewingOrganizationPortalAs } = useViewingOrganizationPortalAs();
  const { currentLang, onChangeLang, translationEnabled } =
    useTranslateForOrganization();
  const { getPortalConfigField, currentPortalConfig } =
    useOrganizationPortalContext();
  const [updateOrganizationPortalConfig] =
    useUpdateOrganizationPortalConfigMutation();

  const vcuVideoUrl = getPortalConfigField('pitch_vcu_video_url');
  const vcuCopy = getPortalConfigField('pitch_vcu_copy');

  const [vcuFieldsValue, setVcuFieldsValue] = useState<{
    copy?: string | null;
    videoUrl?: string | null;
  }>({ copy: vcuCopy, videoUrl: vcuVideoUrl });
  const [error, setError] = useState(false);

  const handleVcuFieldUpdate = useCallback(
    ({
      field,
      fieldValue,
    }: {
      field: 'pitch_vcu_video_url' | 'pitch_vcu_copy';
      fieldValue: string;
    }) => {
      updateOrganizationPortalConfig({
        variables: {
          configId: currentPortalConfig.id!,
          payload: {
            [field]: fieldValue,
          },
        },
        optimisticResponse: {
          updated_configs_response: {
            __typename: 'organization_portal_configs_mutation_response',
            returning: [
              {
                __typename: 'organization_portal_configs',
                ...currentPortalConfig,
                [field]: fieldValue,
              },
            ],
          },
        },
      });
    },
    [updateOrganizationPortalConfig, currentPortalConfig],
  );

  const handleUrlFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setVcuFieldsValue(prev => ({
      ...prev,
      videoUrl: url,
    }));

    const youtubeEmbedPattern = /^https:\/\/www\.youtube\.com\/embed\/.+$/;
    const vimeoEmbedPattern = /^https:\/\/player\.vimeo\.com\/video\/.+$/;
    const synthesiaEmbedPattern =
      /^https:\/\/share\.synthesia\.io\/embeds\/videos\/.+$/;

    const isValidEmbedUrl =
      youtubeEmbedPattern.test(url) ||
      vimeoEmbedPattern.test(url) ||
      synthesiaEmbedPattern.test(url);

    setError(!isValidEmbedUrl);
  };

  return (
    <Box width={isBelowMd ? '100%' : '550px'} flex={1} position='relative'>
      {/* <Typography
        gutterBottom
        variant='h3'
        sx={{ marginTop: 2, marginBottom: 2, fontWeight: 'normal' }}
      >
        {translate('requestForm.mainTitlePrefix')}{' '}
        <TextAnimate
          sx={{
            display: 'inline-block',
            fontWeight: typography.fontWeightBold,
          }}
          text={`${catchPhrase}?`}
        />
      </Typography>
      <ContentEditableText
        text={
          vcuCopy ??
          `
            ${translate('requestForm.mainDescriptionLine1')} 👉
            ${translate('requestForm.mainDescriptionLine2')}
          `
        }
        isEditable={viewingOrganizationPortalAs === 'admin'}
        sx={{
          color: 'text.secondary',
          marginBottom: spacing(3),
          fontWeight: 'normal',
        }}
        onBlur={e =>
          handleVcuFieldUpdate({
            field: 'pitch_vcu_copy',
            fieldValue: e.target.textContent!,
          })
        }
      /> */}
      {vcuVideoUrl && (
        <Card
          sx={{
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '56.25%',
          }}
        >
          <Box
            component='iframe'
            src={vcuVideoUrl}
            loading='lazy'
            title='How Venture Clienting Works'
            allow='encrypted-media; fullscreen;'
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              border: 'none',
              padding: 0,
              margin: 0,
              overflow: 'hidden',
            }}
          ></Box>
        </Card>
      )}
      {!vcuVideoUrl && (
        <Box
          sx={{
            width: '100%',
            borderRadius: '10px',
            margin: 0,
            height: 'fit-content',
          }}
        >
          <img
            src='/static/illustrations/lead-page-banner.png'
            alt='lead form banner'
            style={{
              height: '100%',
              borderRadius: '10px',
            }}
          />
        </Box>
      )}
      {viewingOrganizationPortalAs === 'admin' && (
        <Stack marginTop={3}>
          <Typography variant='body2' fontWeight={600}>
            Edit VCU pitch URL
          </Typography>
          <Stack direction='row' gap={2} alignItems='baseline'>
            <TextField
              margin='dense'
              variant='outlined'
              fullWidth
              placeholder='Embed URL'
              error={error}
              helperText={error ? 'Please enter a valid embed URL' : ''}
              value={vcuFieldsValue.videoUrl}
              onChange={handleUrlFieldChange}
              InputProps={{
                sx: {
                  '& .MuiInputBase-input': {
                    padding: '8px 10px',
                  },
                },
              }}
            />
            <LoadingButton
              variant='contained'
              component='label'
              onClick={() =>
                handleVcuFieldUpdate({
                  field: 'pitch_vcu_video_url',
                  fieldValue: vcuFieldsValue.videoUrl ?? '',
                })
              }
              disabled={error}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      )}
      {translationEnabled && (
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{
            position: 'absolute',
            bottom: '-64px',
            alignSelf: 'flex-start',
            cursor: 'pointer',
            fontStyle: 'italic',
          }}
          onClick={() => {
            onChangeLang(currentLang.value === 'en' ? 'de' : 'en');
          }}
        >
          View in {currentLang.value === 'en' ? 'German' : 'English'}
        </Typography>
      )}
    </Box>
  );
};

export const useTranslateForOrganization = () => {
  const { request_form_translate_enabled } =
    useCurrentOrganizationFromContext();
  const { translate, currentLang: _currentLang, onChangeLang } = useLocales();

  const decoratedTranslate = useMemo(
    () =>
      request_form_translate_enabled
        ? translate
        : (key: string) =>
            translate(key, {
              lng: enCode,
            }),
    [request_form_translate_enabled, translate],
  );

  const currentLang = request_form_translate_enabled
    ? _currentLang
    : ALL_LANGS[enCode];

  return {
    translate: decoratedTranslate,
    currentLang,
    onChangeLang,
    translationEnabled: request_form_translate_enabled,
  };
};
