import { Send } from '@mui/icons-material';
import {
  Autocomplete,
  TextareaAutosize as BaseTextareaAutosize,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
  Collapse,
} from '@mui/material';
import { captureMessage } from '@sentry/react';
import { useGetAllPeopleQuery } from 'apollo/generated/sdkInnovationManager';
import { useGenerateEmailMutation } from 'apollo/generated/sdkShared';
import { FALLBACK_SUPPLIER_LOGO_URL, LogoAvatar } from 'components/AvatarGroup';
import BaseInitialsAvatar, {
  avatarSizeSmall,
} from 'components/base/BaseInitialsAvatar';
import { useBaseStakeholderFilterOptions } from 'components/base/stakeholdersSelect/useBaseStakeholderFilterOptions';
import { useCreateLead } from 'components/projectLeads/useCreateLead';
import { RouterLink } from 'components/RouterLink';
import { EmailField } from 'components/shared/EmailField';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { usePersonContext } from 'contexts/PersonContext';
import useAuth from 'hooks/useAuth';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useEffect, useState } from 'react';
import { PATH_ROOT } from 'routes/paths';
import { Person } from '../../../@types/stakeholder';
import { MessageRecommendedStartup } from './ConversationMessage';

const ShareStartupModal = ({
  onClose,
  startup,
  threadId,
}: {
  onClose: () => void;
  startup: MessageRecommendedStartup;
  threadId: number;
}) => {
  const {
    user: { email: senderEmail },
  } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isCreateLeadChecked, setIsCreateLeadChecked] = useState(true);
  const [generateEmail, { loading: generatingEmail, called }] =
    useGenerateEmailMutation();
  const [email, setEmail] = useState<{
    subject: string;
    content: string;
  }>({ subject: '', content: '' });
  const { data, loading: loadingStakeholders } = useGetAllPeopleQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [selectedStakeholder, setSelectedStakeholder] = useState<Person | null>(
    null,
  );
  const { spacing } = useTheme();
  const { handleCreateLead } = useCreateLead(true);

  useEffect(() => {
    const startGeneratingEmail = async () => {
      if (selectedStakeholder) {
        captureAnalyticsEvent('Send Startup > Email Generated', {
          startupId: startup.id,
          stakeholderEmail: selectedStakeholder.email,
          senderEmail,
          source: 'ASSISTANT',
        });

        setEmail({ subject: '', content: '' });
        await generateEmail({
          variables: {
            threadId,
            stakeholderId: selectedStakeholder.id,
            supplierId: startup.id,
          },
          onCompleted: ({ generate_email }) => {
            if (generate_email?.emailContent && generate_email?.emailSubject) {
              setEmail({
                content: generate_email.emailContent,
                subject: generate_email.emailSubject,
              });
            }
          },
        });
      } else {
        setEmail({
          subject: '',
          content: '',
        });
      }
    };

    startGeneratingEmail();
  }, [generateEmail, selectedStakeholder, startup.id, threadId, senderEmail]);

  const { person } = usePersonContext();

  const filterOptions = useBaseStakeholderFilterOptions();

  if (!data?.people) return null;

  const uniquePeople = uniqBy(
    data.people,
    (person: Person) => person.id,
  ).filter(p => p.id !== person?.id);

  const onOpenEmailClient = async () => {
    captureAnalyticsEvent('Send Startup > Email Sent', {
      trackLead: isCreateLeadChecked,
      startupId: startup.id,
      stakeholderEmail: selectedStakeholder!.email,
      senderEmail,
      source: 'ASSISTANT',
    });

    if (isCreateLeadChecked) {
      const result = await handleCreateLead({
        title: `Pitch ${startup.name} to ${selectedStakeholder!.full_name}`,
        painPointOwnerId: selectedStakeholder!.id,
        source: 'Leads assistant',
      });
      const leadId = result?.project?.id;

      if (!leadId) {
        captureMessage('Lead creation failed');
        return;
      }

      const snackbarKey = enqueueSnackbar('Lead successfully created', {
        variant: 'success',
        autoHideDuration: 20000,
        action: (
          <Button variant='text' onClick={() => closeSnackbar(snackbarKey)}>
            <RouterLink to={PATH_ROOT.projectLeads._details(leadId)}>
              Navigate to Lead
            </RouterLink>
          </Button>
        ),
      });
    }
  };

  const startupLogo =
    startup.startup.logo_url ||
    `https://logo.clearbit.com/${startup.startup.domain}?size=34` ||
    FALLBACK_SUPPLIER_LOGO_URL;

  return (
    <Dialog fullWidth maxWidth='sm' open onClose={onClose}>
      <Stack>
        <Typography
          variant='h4'
          sx={{
            paddingX: 2,
            paddingY: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LogoAvatar
            src={startupLogo}
            size={34}
            sx={{ marginRight: spacing(1) }}
          />
          <span>Send &quot;{startup.name}&quot;</span>
        </Typography>
        <DialogContent sx={{ padding: spacing(2) }}>
          <Autocomplete
            sx={{ marginBottom: spacing(1) }}
            disabled={loadingStakeholders}
            fullWidth
            filterOptions={filterOptions}
            options={uniquePeople}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedStakeholder}
            getOptionLabel={option => option.full_name}
            onChange={async (_e, newValue: Person | null) => {
              setSelectedStakeholder(newValue);
            }}
            renderOption={(props, option) => {
              const text = option.department
                ? `${option.full_name} (${option.department})`
                : option.full_name;

              return (
                <Box component='li' {...props}>
                  <BaseInitialsAvatar
                    sx={avatarSizeSmall}
                    full_name={option.full_name}
                  />
                  <Stack>
                    <TypographyWithEllipsis>{text}</TypographyWithEllipsis>
                    <EmailField ignoreTooltip value={option.email} />
                  </Stack>
                </Box>
              );
            }}
            renderInput={({ inputProps, ...rest }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  sx={{ backgroundColor: 'white' }}
                  slotProps={{ htmlInput: inputProps }}
                  className='input-padding'
                  {...rest}
                  variant='outlined'
                  placeholder='Enter stakeholder'
                />
              </div>
            )}
          />

          <Box sx={{ position: 'relative' }}>
            {generatingEmail && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: spacing(5),
                  height: spacing(5),
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Collapse in={called}>
              <TextareaAutosize
                aria-label='draft email'
                value={email.content}
                disabled={generatingEmail}
                onChange={e =>
                  setEmail(prev => ({ ...prev, content: e.target.value }))
                }
                sx={{ resize: 'none' }}
                minRows={5}
                maxRows={15}
              />
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCreateLeadChecked}
                  onChange={e =>
                    setIsCreateLeadChecked(e.currentTarget.checked)
                  }
                />
              }
              label='Create a Lead to track outreach'
            />
          </FormGroup>
          <Button
            disabled={!selectedStakeholder || !email.content || generatingEmail}
            variant='contained'
            startIcon={<Send />}
            onClick={onOpenEmailClient}
            href={`mailto:${selectedStakeholder ? `${selectedStakeholder.full_name} <${selectedStakeholder.email}>` : ''}?subject=${encodeURIComponent(email.subject)}&body=${encodeURIComponent(email.content)}`}
          >
            Open email client
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: ${theme.spacing(1)};
  border: 1px solid ${theme.palette.grey['300']};
  width: 100%;
  padding: ${theme.spacing(1)};
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  &:hover {
    border-color: ${theme.palette.grey['700']}
  }
  &:focus {
    outline: none;
    border: 2px solid ${theme.palette.primary['main']}
  }
`,
);

export default ShareStartupModal;
