import {
  Delete as DeleteIcon,
  FiberDvr as DiscoverLetterIcon,
  DragIndicator,
  GridView as GridViewIcon,
  Input as InputIcon,
  LinkOff,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import MoreMenu from 'components/MoreMenu';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { buildAdminPlatformDomain } from 'utils/url';
import { StartupListForDetail } from '../../../../@types/startupList';
import { useCreateNewCategoryModal } from './CreateNewCategory';

import EnrichListMenuItem from '../enrichment/EnrichListMenuItem';
import { useUpdateStartupListActivities } from '../useUpdateStartupListActivities';
import DuplicateListMenuItem from './DuplicateListMenuItem';
import { CategorizedStartupForHeader, ConfirmArchiveListModal } from './Header';
import OrderSourcingOnStartupListDetails from './OrderSourcingOnStartupListDetails';
import { ExportExcelMenuItem } from './export/ExportExcelMenuItem';
import { ExportPDFMenuItem } from './export/ExportPDFMenuItem';
import { useBulkImportModal } from './fileImport/BulkImportStartupsModal';
import { DialogTitle } from '@mui/material';
import { Button } from '@mui/material';

export const ListActionsMenu = ({
  currentStartupList,
  categories,
  categorizedStartups,
  refetchMainQuery,
  scope = 'list',
  onUnlink,
}: {
  currentStartupList: StartupListForDetail;
  categories: { id: number }[];
  categorizedStartups: CategorizedStartupForHeader[];
  refetchMainQuery: () => void;
  scope: 'list' | 'project';
  onUnlink?: () => void;
}) => {
  const { isStaff, user: currentUser } = useAuth();
  const { subdomain: organizationSubdomain } =
    useCurrentOrganizationFromContext();

  const { palette } = useTheme();

  const [openArchiveListModal, setOpenArchiveListModal] =
    useState<boolean>(false);

  const [openConfirmUnlinkModal, setOpenConfirmUnlinkModal] =
    useState<boolean>(false);

  const isLandscape = currentStartupList?.result_type === 'landscape';
  const isDiscoveryAccount = organizationSubdomain === 'discovery';
  const [bulkImportModal, showBulkImportModal] = useBulkImportModal({
    startupListId: currentStartupList.id,
    categoryId: categories[0] ? categories[0].id : null,
    invalidateCache: refetchMainQuery,
  });

  const invalidateCache = useCallback(() => {
    refetchMainQuery();
  }, [refetchMainQuery]);

  const { showCreateNewCategoryModal } = useCreateNewCategoryModal({
    startupListId: currentStartupList.id,
    hasZeroCategories: categories.length === 0,
  });

  const { convertListToDiscoveryItem } = useUpdateStartupListActivities();

  const [startupSupplierIDs, setStartupSupplierIDs] = useState<number[]>(
    categorizedStartups.map(startup => startup.id),
  );

  useEffect(() => {
    setStartupSupplierIDs(categorizedStartups.map(startup => startup.id));
  }, [categorizedStartups]);

  const isListEmpty = !Boolean(startupSupplierIDs.length);

  const isDiscoveryItem = Boolean(
    currentStartupList.source === 'discovery_section',
  );

  const isLinkedLandscape = currentStartupList.project_categories.length > 1;
  const startupsSelectedForAssessment =
    currentStartupList.project_categories.flatMap(pj =>
      pj.categorized_suppliers.filter(
        cs => cs.is_shortlisted && !cs.is_archived,
      ),
    );

  const unlinkListModalConfirmationText = isLinkedLandscape
    ? `Are you sure you want to unlink the ${currentStartupList.title} landscape? This will unlink ${currentStartupList.project_categories.length} lists.`
    : startupsSelectedForAssessment.length > 0
      ? `Are you sure you want to unlink ${currentStartupList.title}? There is ${startupsSelectedForAssessment.length} startup(s) selected for Assessment in this list.`
      : '';

  return (
    <>
      <MoreMenu
        data-testid='list-actions-menu'
        icon={scope !== 'list' ? <DragIndicator fontSize='small' /> : undefined}
      >
        {!currentStartupList.sourcing_order && scope === 'list' && (
          <OrderSourcingOnStartupListDetails
            startupList={currentStartupList}
            invalidateCache={invalidateCache}
          />
        )}
        {!isLandscape && [
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => showCreateNewCategoryModal()}
            key='menu-item-turn-into-landscape'
          >
            <GridViewIcon fontSize='small' sx={{ marginRight: 0.5 }} />
            <Typography variant='body1'>Turn into Landscape</Typography>
          </MenuItem>,
        ]}
        <DuplicateListMenuItem listId={currentStartupList.id} />
        <ExportPDFMenuItem
          currentLandscapeCategoryId={false}
          currentStartupListId={currentStartupList.id}
        />
        <ExportExcelMenuItem
          currentLandscapeCategoryId={false}
          currentStartupListId={currentStartupList.id}
        />
        <Tooltip
          title={
            isLandscape
              ? 'Before you can import, please select a category below!'
              : ''
          }
          placement='right'
        >
          {/* Needed to get the tooltip working when the below component is disabled */}
          <span>
            <MenuItem
              sx={{ color: 'text.secondary' }}
              onClick={() => showBulkImportModal()}
              disabled={isLandscape}
            >
              <InputIcon fontSize='small' sx={{ marginRight: 0.5 }} />
              <Typography variant='body1'>Import from file</Typography>
            </MenuItem>
          </span>
        </Tooltip>
        {scope === 'project' && onUnlink && (
          <MenuItem
            sx={({ palette }) => ({
              color: palette.error.main,
            })}
            onClick={() => {
              if (
                isLinkedLandscape ||
                startupsSelectedForAssessment.length > 0
              ) {
                setOpenConfirmUnlinkModal(true);
              } else {
                onUnlink();
              }
            }}
          >
            <LinkOff fontSize='small' sx={{ marginRight: 0.5 }} />
            <Typography variant='body1'>Unlink</Typography>
          </MenuItem>
        )}
        <MenuItem
          sx={({ palette }) => ({
            color: palette.error.main,
          })}
          onClick={() => setOpenArchiveListModal(true)}
        >
          <DeleteIcon fontSize='small' sx={{ marginRight: 0.5 }} />
          <Typography variant='body1'>Delete</Typography>
        </MenuItem>
        {isStaff && (
          <Box>
            {isDiscoveryAccount && (
              <Tooltip
                title={
                  isDiscoveryItem
                    ? 'Discovery Items are not editable. Convert back to list.'
                    : ''
                }
                placement='right'
              >
                <span>
                  <MenuItem
                    sx={{ color: palette.secondary.main }}
                    onClick={() =>
                      convertListToDiscoveryItem(currentStartupList.id)
                    }
                  >
                    <DiscoverLetterIcon
                      fontSize='small'
                      sx={{ marginRight: 0.5 }}
                    />
                    <Typography variant='body1'>
                      Convert to Discovery Item
                    </Typography>
                  </MenuItem>
                </span>
              </Tooltip>
            )}
          </Box>
        )}
        {currentUser.isContentEditor && [
          <EnrichListMenuItem
            key='enrich-list-menu-item'
            categorizedStartups={categorizedStartups}
            title={currentStartupList.title}
            isListEmpty={isListEmpty}
          />,
          <MenuItem
            key='view-in-admin-menu-item'
            sx={{ color: palette.secondary.main }}
            component={Link}
            href={`${buildAdminPlatformDomain()}/startups?startupListId=${
              currentStartupList.id
            }`}
            target='_blank'
          >
            <OpenInNewIcon fontSize='small' sx={{ marginRight: 0.5 }} />
            <Typography variant='body1'>View in Admin</Typography>
          </MenuItem>,
        ]}
      </MoreMenu>
      {bulkImportModal}
      {openArchiveListModal && (
        <ConfirmArchiveListModal
          id={currentStartupList.id}
          archiveType='list'
          title={currentStartupList.title}
          onHide={() => setOpenArchiveListModal(false)}
          disableRedirect={scope === 'project'}
        />
      )}
      {openConfirmUnlinkModal && (
        <ConfirmUnlinkListModal
          listTitle={currentStartupList.title}
          textContent={unlinkListModalConfirmationText}
          onClick={() => {
            setOpenConfirmUnlinkModal(false);
            onUnlink && onUnlink();
          }}
          onHide={() => setOpenConfirmUnlinkModal(false)}
        />
      )}
    </>
  );
};

const ConfirmUnlinkListModal = ({
  listTitle,
  textContent,
  onHide,
  onClick,
}: {
  listTitle: string;
  textContent: string;
  onHide: () => void;
  onClick: () => void;
}) => {
  return (
    <Dialog open fullWidth>
      <DialogTitle>{listTitle}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography sx={{ marginTop: 2 }}>{textContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={onClick} variant='contained' color='error'>
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
  );
};
