import { Circle, North, South } from '@mui/icons-material';
import { Avatar, Stack, SxProps, Typography } from '@mui/material';
import {
  EnumTableChallengeStatusesEnum,
  EnumTableLeadMaturityEnum,
  EnumTableProjectHealthStatusesEnum,
  EnumTableProjectStagesEnum,
} from 'apollo/generated/sdkInnovationManager';
import { useFilesWithSignedUrlsQuery } from 'apollo/generated/sdkShared';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';
import { ChallengeStatusSelect } from 'components/engagement/challenges/details/ChallengeStatusSelect';
import { ListItemIconStyle, NAV_TEXT_GREY } from 'components/NavSection';
import { ProjectLeadMaturityLevelSelect } from 'components/projectLeadDetails/MaturityLevelSelect';
import ChallengesIcon from 'layouts/dashboard/assets/challenges.svg?react';
import ImplementationsIcon from 'layouts/dashboard/assets/implementations.svg?react';
import LeadsIcon from 'layouts/dashboard/assets/leads.svg?react';
import ListsIcon from 'layouts/dashboard/assets/lists.svg?react';
import ProjectsIcon from 'layouts/dashboard/assets/projects.svg?react';

import { ProjectStageSelect } from 'pages/dashboard/scoping/ProjectStageSelect';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { getProjectEntityTypeByStage, PATH_ROOT } from 'routes/paths';
import { LEAD_STAGES, POC_STAGES } from 'utils/projectStageEnum';
import { STATUS_COLOR_MAPPING } from '../startupList/constants';

type ProjectsType = {
  id: number;
  title: string;
  stage: EnumTableProjectStagesEnum;
  lead_maturity: EnumTableLeadMaturityEnum;
  health_status?: EnumTableProjectHealthStatusesEnum | null;
  teams?: {
    id: number;
    logo_file_id?: number | null;
    name: string;
  } | null;
}[];

export type LinkedEntitiesType = {
  startupLists: {
    id: number;
    title: string;
  }[];
  projects: ProjectsType;
  adoptions: ProjectsType;
  leads: ProjectsType;
  challenges: {
    id: number;
    title: string | null;
    status: EnumTableChallengeStatusesEnum;
  }[];
};

const POC_AND_LEAD_STAGES = [...POC_STAGES, ...LEAD_STAGES];

export type PocAndLeadStages = (typeof POC_AND_LEAD_STAGES)[number];

const isPocOrLead = (stage: string) =>
  POC_AND_LEAD_STAGES.includes(stage as PocAndLeadStages);

export const LinkedToEntity = ({
  hideHeader,
  showOnlyTitle,
  linkedEntitiesData,
  sxProps,
  suppressNavigation,
}: {
  hideHeader?: boolean;
  showOnlyTitle?: boolean;
  linkedEntitiesData: {
    projects: LinkedEntitiesType['projects'];
    startupLists: LinkedEntitiesType['startupLists'];
    challenges: LinkedEntitiesType['challenges'];
  };
  sxProps?: SxProps;
  suppressNavigation?: boolean;
}) => {
  const navigate = useNavigate();
  const teamAvatarFileIds = useMemo(() => {
    return (
      (linkedEntitiesData?.projects || [])
        .filter(project => project.teams?.logo_file_id)
        .map(project => project.teams!.logo_file_id!) || []
    );
  }, [linkedEntitiesData]);

  const { data: signedFileUrl } = useFilesWithSignedUrlsQuery({
    skip: teamAvatarFileIds.length === 0,
    variables: { files_ids: teamAvatarFileIds },
    fetchPolicy: 'cache-and-network',
  });

  const linkedEntities = useMemo(() => {
    if (!linkedEntitiesData)
      return {} as LinkedEntitiesType & {
        teamsAvatarUrlMap: Record<number, string>;
      };

    const {
      challenges,
      projects: allProjects,
      startupLists,
    } = linkedEntitiesData;

    const projectsMap: LinkedEntitiesType['projects'] = [];
    const leadsMap: LinkedEntitiesType['projects'] = [];
    const adoptionsMap: LinkedEntitiesType['projects'] = [];
    const teamsAvatarUrlMap: Record<number, string> = {};

    (allProjects || []).forEach(project => {
      const signedUrl = project.teams?.logo_file_id
        ? signedFileUrl?.files_with_signed_urls?.data.find(
            file => file.id === project.teams!.logo_file_id,
          )?.signed_url
        : null;

      const entityType = getProjectEntityTypeByStage(project.stage);

      if (signedUrl && project.teams?.logo_file_id) {
        teamsAvatarUrlMap[project.teams.logo_file_id] = signedUrl;
      }

      if (entityType === 'lead') {
        leadsMap.push(project);
      } else if (entityType === 'adoption') {
        adoptionsMap.push(project);
      } else {
        projectsMap.push(project);
      }
    });

    return {
      challenges: challenges || [],
      projects: projectsMap,
      adoptions: adoptionsMap,
      leads: leadsMap,
      startupLists: startupLists || [],
      teamsAvatarUrlMap,
    };
  }, [linkedEntitiesData, signedFileUrl?.files_with_signed_urls?.data]);

  const [showAll, setShowAll] = useState(false);

  const allItems: Array<
    | LinkedEntitiesType['projects'][number]
    | LinkedEntitiesType['challenges'][number]
    | LinkedEntitiesType['startupLists'][number]
  > = useMemo(
    () => [
      ...linkedEntities.adoptions,
      ...linkedEntities.projects,
      ...linkedEntities.leads,
      ...linkedEntities.challenges,
      ...linkedEntities.startupLists,
    ],
    [linkedEntities],
  );

  const displayedItems = showAll ? allItems : allItems.slice(0, 2);

  if (displayedItems.length === 0) {
    return null;
  }

  return (
    <Stack sx={{ ...sxProps }}>
      {!hideHeader && (
        <Typography variant='body2' color='grey.600' marginBottom={1}>
          Linked to
        </Typography>
      )}
      <Stack>
        {displayedItems.map(item => {
          const entityType =
            'stage' in item && getProjectEntityTypeByStage(item.stage);

          const icon =
            'stage' in item ? (
              entityType === 'lead' ? (
                <LeadsIcon />
              ) : entityType === 'adoption' ? (
                <ImplementationsIcon />
              ) : entityType === 'project' ? (
                <ProjectsIcon />
              ) : null
            ) : 'status' in item ? (
              <ChallengesIcon />
            ) : (
              <ListsIcon />
            );

          const navigateTo = () => {
            if ('stage' in item) {
              if (entityType === 'lead') {
                navigate(PATH_ROOT.projectLeads._details(item.id));
              } else if (entityType === 'adoption') {
                navigate(PATH_ROOT.projects._adoptionDetails(item.id));
              } else if (entityType === 'project') {
                navigate(PATH_ROOT.projects._pocDetails(item.id));
              }
            } else if ('status' in item) {
              navigate(PATH_ROOT.challenges._challengeDetails(item.id));
            } else {
              navigate(PATH_ROOT.lists.details(item.id));
            }
          };

          return (
            <Stack
              key={`item-${item.id}`}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              paddingX={0.5}
              paddingY={0.35}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'grey.50012',
                  borderRadius: 0.75,
                },
              }}
              onClick={() => !suppressNavigation && navigateTo()}
            >
              <Stack
                direction='row'
                gap={0.75}
                alignItems='center'
                flex='1'
                minWidth='0'
              >
                <ListItemIconStyle
                  sx={{
                    '& svg, & path': { fill: NAV_TEXT_GREY },
                    marginLeft: 0,
                    padding: 0,
                    alignSelf: 'flex-start',
                  }}
                >
                  {icon}
                </ListItemIconStyle>
                <Stack direction='row' gap={1} alignItems='flex-start'>
                  {'stage' in item &&
                    isPocOrLead(item.stage) &&
                    item.health_status && (
                      <Circle
                        fontSize='small'
                        sx={{
                          color:
                            STATUS_COLOR_MAPPING[
                              item.health_status as keyof typeof STATUS_COLOR_MAPPING
                            ],
                          marginTop: '2px',
                        }}
                      />
                    )}
                  <LinkedEntityTitleTypography title={item.title ?? ''} />
                </Stack>
              </Stack>
              {!showOnlyTitle && (
                <Stack gap={1} direction='row' alignItems='center'>
                  {'teams' in item && item.teams?.logo_file_id && (
                    <Avatar
                      src={
                        linkedEntities.teamsAvatarUrlMap[
                          item.teams.logo_file_id
                        ]
                      }
                      imgProps={{ sx: { objectFit: 'cover' } }}
                      sx={{ width: 28, height: 28 }}
                    >
                      <img
                        src={FALLBACK_SUPPLIER_LOGO_URL}
                        alt={item.teams.name}
                      />
                    </Avatar>
                  )}
                  {'teams' in item && (
                    <Typography variant='caption'>
                      {item.teams?.name}
                    </Typography>
                  )}
                  {'stage' in item && entityType !== 'lead' && (
                    <ProjectStageSelect
                      project={{
                        id: item.id,
                        stage: item.stage,
                      }}
                      readonly
                    />
                  )}
                  {'stage' in item && entityType === 'lead' && (
                    <ProjectLeadMaturityLevelSelect
                      project={{
                        id: item.id,
                        lead_maturity: item.lead_maturity,
                        stage: item.stage,
                      }}
                      readonly
                    />
                  )}
                  {'status' in item && (
                    <ChallengeStatusSelect
                      challengeId={item.id}
                      challengeStatus={item.status}
                      isReadonly
                    />
                  )}
                </Stack>
              )}
            </Stack>
          );
        })}
        {allItems.length > 2 && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
              paddingY: 0.5,
              paddingRight: 1.5,
              marginTop: 0.5,
              cursor: 'pointer',
              color: 'grey.600',
              width: 'fit-content',
              '&:hover': {
                backgroundColor: '#F2F3F5',
                borderRadius: 1,
              },
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? (
              <North
                fontSize='small'
                sx={{
                  color: NAV_TEXT_GREY,
                }}
              />
            ) : (
              <South
                fontSize='small'
                sx={{
                  color: NAV_TEXT_GREY,
                }}
              />
            )}
            <Typography variant='body2'>
              {showAll
                ? `Show ${allItems.length - 2} less`
                : `Show ${allItems.length - 2} more`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export const LinkedEntityTitleTypography = ({
  title,
  onClick,
  showEllipsis = false,
}: {
  title: string | React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showEllipsis?: boolean;
}) => {
  return (
    <Typography
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        color: '#212B36',
        textDecoration: 'underline',
        textDecorationColor: '#E5E8EB',
        textDecorationThickness: '1.25px',
        textUnderlineOffset: '3px',
        flexShrink: 1,
        minWidth: 0,
        display: 'block',
        paddingRight: '10px',
        lineHeight: '1.75',
        '&:hover': {
          textDecorationColor: '#E5E8EB',
          textDecorationThickness: '1.25px',
          // backgroundColor: 'grey.50012',
          borderRadius: 0.75,
        },
        ...(showEllipsis && {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }),
      }}
      variant='body2'
      fontWeight={500}
    >
      {title}
    </Typography>
  );
};
