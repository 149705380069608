import PageHeader from 'components/PageHeader';
import ChallengesTable from './ChallengesTable';
import { Button, Stack } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useState } from 'react';
import NewChallengeModal from './NewChallengeModal';
import { useGetChallengesQuery } from 'apollo/generated/sdkShared';

export const ChallengesPageContent = () => {
  const [newChallengeModalOpen, setNewChallengeModalOpen] = useState(false);

  const { data } = useGetChallengesQuery();
  const challengesData = data?.challenges || [];

  return (
    <>
      <Stack
        direction='row'
        alignItems='baseline'
        justifyContent='space-between'
      >
        <PageHeader
          heading='Initiatives'
          sx={{
            marginBottom: 2,
          }}
        />
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => {
            setNewChallengeModalOpen(true);
          }}
        >
          New Initiative
        </Button>
      </Stack>

      <ChallengesTable challengesData={challengesData} loading={false} />
      {newChallengeModalOpen && (
        <NewChallengeModal onClose={() => setNewChallengeModalOpen(false)} />
      )}
    </>
  );
};
