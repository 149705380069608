import { IconButton, IconButtonProps, Tooltip, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useCreateStartupListServiceMutation } from 'apollo/generated/sdkInnovationManager';
import { useSafeCallback } from 'components/base/useSafeCallback';
import { HIDE_FROM_LIBRARY_TAG } from 'components/dashboard/startupList/constants';
import { useInsertListTag } from 'components/dashboard/startupList/details/StartupListTagsSelect';
import useAddStartupToList from 'hooks/useAddStartupToList';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath } from 'routes/paths';
import LeadsIcon from '../../layouts/dashboard/assets/leads.svg?react';
import { CreateLeadSource, useCreateLead } from './useCreateLead';

export const CreateLeadButton = ({
  iconButtonProps = {},
  source,
  selectedStartup,
}: {
  iconButtonProps?: IconButtonProps;
  source: CreateLeadSource;
  selectedStartup: { domain: string; name: string; logo_url: string };
}) => {
  const { palette, spacing } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { addStartupToList, loading: addStartupToListLoading } =
    useAddStartupToList();
  const navigate = useNavigate();
  const { handleCreateLead, loading: createLeadLoading } = useCreateLead(true);
  const [createStartupList, { loading: createListLoading }] =
    useCreateStartupListServiceMutation();
  const loading =
    createLeadLoading || createListLoading || addStartupToListLoading;
  const onInsertTag = useInsertListTag({});
  const handleClick = useSafeCallback(async () => {
    enqueueSnackbar('Creating lead...', { variant: 'info' });

    const response = await handleCreateLead({
      source,
      leadMaturityLevel: 'contact_pending',
    });

    if (!response?.project) {
      console.error('Failed to create lead. Response:', response);
      captureException(new Error('Failed to create lead'));
      return;
    }

    const { project } = response;

    const listResponse = await createStartupList({
      variables: {
        object: {
          title: `List for ${project.title}`,
          link_project_id: project.id,
          source: 'self_created',
        },
      },
    });

    const list = listResponse.data?.create_startup_list?.startup_list;

    if (!list) {
      console.error('Failed to create list. Response:', list);
      captureException(new Error('Failed to create list'));
      return;
    }
    const listId = list.id;

    const projectCategoryId = list.project_categories?.[0]?.id;

    if (!projectCategoryId) {
      console.error('Failed to get project category id. Response:', list);
      captureException(new Error('Failed to get project category id'));
      return;
    }

    await onInsertTag(HIDE_FROM_LIBRARY_TAG, listId);

    await addStartupToList({
      listId,
      selectedStartup,
      categoryId: projectCategoryId,
      logActivity: true,
    });

    enqueueSnackbar('Lead created successfully', { variant: 'success' });

    navigate(buildProjectDetailsPath({ id: project.id, stage: project.stage }));
  }, [
    addStartupToList,
    createStartupList,
    enqueueSnackbar,
    handleCreateLead,
    navigate,
    onInsertTag,
    selectedStartup,
    source,
  ]);

  return (
    <Tooltip title={`Track ${selectedStartup.name} as Lead`}>
      <IconButton
        color='primary'
        disabled={loading}
        onClick={handleClick}
        sx={{ width: spacing(5), height: spacing(5) }}
        {...iconButtonProps}
      >
        <LeadsIcon fill={palette.primary.main} />
      </IconButton>
    </Tooltip>
  );
};
