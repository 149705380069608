import { Alert, AlertProps } from '@mui/material';
import React from 'react';

const BaseAlert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const { children, sx, ...other } = props;
  return (
    <Alert {...other} ref={ref} sx={{ borderRadius: 2, ...sx }} elevation={2}>
      {children}
    </Alert>
  );
});

BaseAlert.displayName = 'BaseAlert';

export { BaseAlert };
