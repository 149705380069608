import { createContext, useContext, ReactNode } from 'react';
import { useGetAllDiscoveryCorporationsDomainQuery } from 'apollo/generated/sdkInnovationManager';
import useAuth from 'hooks/useAuth';

type CorporationsDomainData = {
  startupsSupplierMapping: Record<string, number>;
};

type CorporationsDomainContextType = CorporationsDomainData;

type CorporationsDomainProviderProps = {
  children: ReactNode;
};
const StartupSupplierContext =
  createContext<CorporationsDomainContextType | null>(null);

export const CorporationsDomainProvider = ({
  children,
}: CorporationsDomainProviderProps) => {
  const { isAuthenticated } = useAuth();
  // TODO: skip the below query if there is not user session
  const { data } = useGetAllDiscoveryCorporationsDomainQuery({
    skip: !isAuthenticated,
  });

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  const startupsSupplierMapping =
    data?.startup_lists.reduce(
      (acc, { discovery_corporation_domain, id }) => ({
        ...acc,
        [discovery_corporation_domain as string]: id,
      }),
      {},
    ) || {};

  return (
    <StartupSupplierContext.Provider value={{ startupsSupplierMapping }}>
      {children}
    </StartupSupplierContext.Provider>
  );
};

export const useCorporationsDomainContext = (): CorporationsDomainData => {
  const context = useContext(StartupSupplierContext);

  if (!context) {
    throw new Error(
      'useCorporationsDomainContext must be used within a CorporationDomainProvider',
    );
  }

  return context;
};
