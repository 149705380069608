import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { ReactNode } from 'react';
import { requestSolutionsFormStateVar } from './state/requestSolutionsFormState';

const TOTAL_FIELDS = 7;
// const gradient =
//   'linear-gradient(to right, #E3FFE8 0%, #C8FACD 32%, #01AB55 69%, #027B55 100%)';

const ProgressBar = () => {
  const formDataSteps = useReactiveVar(requestSolutionsFormStateVar);
  const allFormData = {
    ...formDataSteps.step1,
    ...formDataSteps.step2,
  };

  const filledInputs = Object.entries(allFormData).reduce(
    (count, [key, value]) => {
      if (key === 'supportingDocuments') return count;
      if (Array.isArray(value)) {
        return count + (value.length > 0 ? 1 : 0);
      }
      return count + (String(value).trim() !== '' ? 1 : 0);
    },
    0,
  );

  const clampedFilled = Math.min(Math.max(filledInputs, 0), TOTAL_FIELDS);
  const clampedProgress = (clampedFilled / TOTAL_FIELDS) * 100;
  const gradient = `
    linear-gradient(
      to right,
      #E3FFE8 0%,
      #C8FACD ${clampedProgress / 1.75}%,
      #01AB55 ${clampedProgress}%,      
      #C8FACD 100%                     
    )
  `;

  return (
    <LinearProgress
      variant='determinate'
      value={clampedProgress}
      aria-label={`Form progress: ${clampedFilled} out of ${TOTAL_FIELDS} fields filled`}
      sx={{
        height: 10,
        borderRadius: 5,
        backgroundColor: '#E1E9EE',
        '& .MuiLinearProgress-bar': {
          backgroundImage: gradient,
          transition: 'background-image 0.3s ease, width 0.3s ease',
        },
        width: '100%',
        marginBottom: 4,
      }}
    />
  );
};

export const StepsWrapper = ({
  children,
  isInnovationManager,
}: {
  children: ReactNode;
  isInnovationManager?: boolean;
}) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) return null;

  if (isInnovationManager) {
    return <Stack margin={4}>{children}</Stack>;
  }

  return (
    <Stack alignItems='center' gap={1} paddingBottom={8}>
      <Typography textAlign='center'>
        The more detailed your scope, the better the expert sourcing and
        AI-recommended startups
      </Typography>
      <Box width='550px'>
        <ProgressBar />
        <Card sx={{ width: '100%', marginBottom: 4 }}>
          <CardContent>
            <Stack sx={{ alignItems: 'center' }}>{children}</Stack>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
};
