import { Save } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useUpdateChallengeMutation } from 'apollo/generated/sdkShared';
import { BaseShowMoreOrLess } from 'components/base/BaseShowMoreLess';
import SectionFieldLayout from 'components/dashboard/SectionFieldLayout';
import QuillEditor from 'components/editor/quill';
import Markdown from 'components/Markdown';
import { NAV_TEXT_GREY } from 'components/NavSection';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

const EditChallengeDescriptionOrImpactModal = ({
  challengeId,
  initialValue,
  fieldToEdit,
  open,
  onClose,
}: {
  challengeId: number;
  initialValue: string;
  fieldToEdit: 'description' | 'impact';
  open: boolean;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();

  const [updateChallenge] = useUpdateChallengeMutation();
  const [textField, setTextField] = useState(initialValue);

  useEffect(() => {
    setTextField(initialValue);
  }, [fieldToEdit, initialValue]);

  const handleSubmit = async () => {
    if (!challengeId) return;

    try {
      await updateChallenge({
        variables: {
          id: challengeId,
          payload: {
            [fieldToEdit]: textField,
          },
        },
      });

      onClose();
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Update failed', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>Edit initiative {fieldToEdit}</DialogTitle>
      <DialogContent sx={{ marginTop: '25px' }}>
        <SectionFieldLayout sx={{ fontSize: 14, paddingTop: 3 }}>
          <QuillEditor
            simple
            value={textField}
            onChange={newValue => setTextField(newValue)}
            sx={{ paddingBottom: 14 }}
          />
        </SectionFieldLayout>
      </DialogContent>
      <DialogActions>
        <Button
          size='small'
          sx={{ color: palette.grey[600] }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button startIcon={<Save />} size='small' onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ChallengeDescriptionSection = ({
  challengeId,
  challengeDescription,
  challengeImpact,
  isReadonly,
}: {
  challengeId: number;
  challengeDescription: string;
  challengeImpact: string;
  isReadonly?: boolean;
}) => {
  const [openEditChallengeFieldModal, setOpenEditChallengeFieldModal] =
    useState(false);

  const [fieldToEdit, setFieldToEdit] = useState<'description' | 'impact'>(
    'description',
  );

  return (
    <Stack direction='row' gap={1} marginY={2}>
      <Box flexBasis='50%'>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          marginBottom={1.5}
          marginRight={4}
        >
          <Typography variant='body1' fontWeight={500} fontSize={20}>
            About this Initiative
          </Typography>
          {!isReadonly && (
            <Button
              variant='text'
              size='small'
              onClick={() => {
                setFieldToEdit('description');
                setOpenEditChallengeFieldModal(true);
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Box marginRight={10}>
          <BaseShowMoreOrLess
            content={
              <Typography
                variant='body2'
                lineHeight={1.75}
                color={challengeDescription ? '' : NAV_TEXT_GREY}
              >
                <Markdown>
                  {challengeDescription || 'No description here yet'}
                </Markdown>
              </Typography>
            }
            collapsedSize='125px'
            allowEdit={false}
          />
        </Box>
      </Box>

      <Divider
        sx={{
          margin: '0 1.75rem',
        }}
        orientation='vertical'
        flexItem
      />
      <Box flexBasis='50%'>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          marginBottom={1.5}
          marginRight={4}
        >
          <Typography variant='body1' fontWeight={500} fontSize={20}>
            Impact
          </Typography>
          {!isReadonly && (
            <Button
              variant='text'
              size='small'
              onClick={() => {
                setFieldToEdit('impact');
                setOpenEditChallengeFieldModal(true);
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Box marginRight={10}>
          <BaseShowMoreOrLess
            content={
              <Typography
                variant='body2'
                lineHeight={1.75}
                color={challengeImpact ? '' : NAV_TEXT_GREY}
              >
                <Markdown>{challengeImpact || 'No impact here yet'}</Markdown>
              </Typography>
            }
            collapsedSize='125px'
            allowEdit={false}
          />
        </Box>
      </Box>

      <EditChallengeDescriptionOrImpactModal
        open={openEditChallengeFieldModal}
        onClose={() => setOpenEditChallengeFieldModal(false)}
        challengeId={challengeId}
        initialValue={
          fieldToEdit === 'description' ? challengeDescription : challengeImpact
        }
        fieldToEdit={fieldToEdit}
      />
    </Stack>
  );
};
