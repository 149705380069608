// Keep the imports relative, otherwise web worker build will fail
// ! Cannot be a hook, because it's used in web workers
import { produce } from 'immer';
import { EnumTableProjectStakeholderRolesEnum } from '../apollo/generated/sdkInnovationManager';
import { ORGANIZATION_SUBDOMAIN } from '../config';

export type ExtendedProjectRoles =
  | EnumTableProjectStakeholderRolesEnum
  | 'primary'
  | 'other'
  | 'others';

export type PROJECT_ROLES = {
  project_sponsor: 'Project Sponsor';
  project_team: 'Other Stakeholders (Business Unit)';
  primary: 'Primary Contact (Startup)';
  other: 'Other Stakeholders (Startup)';
};

const PROJECT_ROLES_MAP: Record<ExtendedProjectRoles, string> = {
  // Business Unit
  project_leader: 'Pain Point Owner',
  project_sponsor: 'Project Sponsor',
  project_team: 'Other Stakeholders (Business Unit)',
  // Startup
  primary: 'Primary Contact (Startup)',
  other: 'Other Stakeholders (Startup)',
  adoption_committee: 'Implementation Committee',
  adoption_owner: 'Implementation Owner',
  key_stakeholders_gatekeeper: 'Key Stakeholders/Gatekeepers',
  project_owner: 'Project Owner',
  technical_expert: 'Technical Expert',
  others: 'Others',
};

export const ALLOWED_PROJECT_ROLES: Partial<ExtendedProjectRoles>[] = [
  'project_leader',
  'project_sponsor',
  'project_team',
  'technical_expert',
];

export type OverrideType = Partial<
  Record<keyof typeof PROJECT_ROLES_MAP, string>
>;

export const getStakeholderRole = ({
  subdomain,
  override,
}: {
  // ! Cannot be taken from context, because it's used in web workers
  // ! Once you import useCurrentOrganizationFromContext, the build will start failing
  subdomain: string;
  override?: OverrideType;
}) => {
  if (subdomain === ORGANIZATION_SUBDOMAIN.siemens) {
    return {
      PROJECT_ROLES_MAP: produce(PROJECT_ROLES_MAP, draft => {
        draft.project_leader = 'Challenge Owner';

        if (draft.project_team) {
          // @ts-expect-error: ignore this
          delete draft.project_team;
        }

        Object.entries(override ?? {}).forEach(([key, value]) => {
          // @ts-expect-error: override is not typed
          draft[key] = value;
        });
      }),
    };
  }

  return {
    PROJECT_ROLES_MAP: {
      ...PROJECT_ROLES_MAP,
      ...override,
    },
  };
};
