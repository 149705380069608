import { OpenLinkHoverButton } from '../OpenLinkHoverButton';
import useAuth from 'hooks/useAuth';

export const OpenStartupInTab = ({
  name,
  website,
}: {
  name: string | null | undefined;
  website: string | null | undefined;
}) => {
  const { user } = useAuth();

  const showOpenLinkHoverButton = user.isContentEditor && website && name;

  return (
    <>{showOpenLinkHoverButton && <OpenLinkHoverButton website={website!} />}</>
  );
};
