// hooks
import useAuth from './useAuth';
// pages
import { Snackbar } from '@mui/material';
import { BaseAlert } from 'components/base/BaseAlert';
import { ORGANIZATION_SUBDOMAIN, SEARCH_PARAMS } from 'config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSearchParams } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { getOrganizationSubdomain } from 'utils/general';
// ----------------------------------------------------------------------

const DEBUG = false;
const MINUTE = 60 * 1000;

const useIdleTimeout = () => {
  const subdomain = getOrganizationSubdomain();
  const { logout: auth0Logout, isAuthenticated } = useAuth();
  const [searchParams, _setSearchParams] = useSearchParams();

  const isShortSession = searchParams.get('short_session') === 'true';

  // State to control the snackbar open/close and countdown text.
  const [open, setOpen] = useState(false);

  const [formattedTime, setFormattedTime] = useState('0:00');

  console.log('[DEBUG] AdminGuard: isAuthenticated', isAuthenticated);

  // 10 seconds for testing, 30 minutes for production
  const inactivityLimit = useMemo(
    () => (isShortSession ? 8 * 1000 : 30 * MINUTE),
    [isShortSession],
  );
  // 1 second or 1 minute
  const interval = useMemo(
    () => (isShortSession ? 1 * 1000 : MINUTE),
    [isShortSession],
  );
  // 5 seconds or 5 minutes
  const warningThreshold = useMemo(
    () => (isShortSession ? 5 * 1000 : 5 * MINUTE),
    [isShortSession],
  );

  const logoutPath = useMemo(
    () =>
      `${PATH_ROOT.auth.login}?${SEARCH_PARAMS.returnTo}=${encodeURIComponent(window.location.pathname)}&${SEARCH_PARAMS.loggedOutDueToInactivity}=true`,
    [],
  );

  const enableIdleTracker = useMemo(() => {
    const isVolkswagen =
      import.meta.env.VITE_ENV === 'production' &&
      subdomain === ORGANIZATION_SUBDOMAIN.volkswagen;
    const isNonProdDiscovery =
      import.meta.env.VITE_ENV !== 'production' &&
      subdomain === ORGANIZATION_SUBDOMAIN.discovery;

    return (
      isAuthenticated && (isVolkswagen || isNonProdDiscovery || isShortSession)
    );
  }, [isAuthenticated, subdomain, isShortSession]);

  const logout = useCallback(
    async (returnTo: string) => {
      localStorage.removeItem('lastActivity');
      await auth0Logout(returnTo);
    },
    [auth0Logout],
  );

  const shortenUserSession = useCallback(async () => {
    if (
      // Only trigger after the user is authenticated
      // In production for Volkswagen or in development for Discovery
      enableIdleTracker
    ) {
      console.log(
        '[DEBUG] AdminGuard.shortenUserSession: User session is too long',
      );
      await logout(logoutPath);
    }
  }, [enableIdleTracker, logout, logoutPath]);

  // Update the last activity timestamp in localStorage
  const updateLastActivity = useCallback(() => {
    const now = Date.now();
    localStorage.setItem('lastActivity', now.toString());
    console.log('Updated last activity:', now);
  }, []);

  // Initialize the idle timer to trigger after 10 seconds (10 * 1000 ms) of inactivity.
  const { getRemainingTime } = useIdleTimer({
    timeout: inactivityLimit,
    onIdle: shortenUserSession,
    disabled: !enableIdleTracker,
    onAction: updateLastActivity,
    debounce: 500,
  });

  // Helper function to format ms into "Minutes:Seconds"
  const formatTime = useCallback((ms: number) => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    // Ensure seconds are always two digits
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
  }, []);

  useEffect(() => {
    if (!enableIdleTracker) {
      return;
    }

    const timerInterval = setInterval(() => {
      if (DEBUG || isShortSession) {
        const remainingTime = `${Math.floor(getRemainingTime() / interval)} ${isShortSession ? 'seconds' : 'minutes'}`;
        console.log('Session Time remaining: ', remainingTime);
      }

      const remaining = getRemainingTime();
      // When the remaining time is 5 seconds or less, show the snackbar.
      if (remaining <= warningThreshold) {
        setFormattedTime(formatTime(remaining));
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 500);

    return () => {
      clearInterval(timerInterval);
    };
  }, [
    enableIdleTracker,
    formatTime,
    getRemainingTime,
    interval,
    isShortSession,
    warningThreshold,
  ]);

  useEffect(() => {
    if (!enableIdleTracker) return;

    const logoutUserDueToInactivity = async () => {
      const storedTimestamp = localStorage.getItem('lastActivity');
      if (storedTimestamp) {
        const inactivityTime = Date.now() - parseInt(storedTimestamp, 10);
        if (inactivityTime > inactivityLimit) {
          await logout(logoutPath);
        }
      } else {
        // Initialize the lastActivity timestamp if not present
        updateLastActivity();
      }
    };

    // On component mount, check if the user has already been inactive for too long
    logoutUserDueToInactivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const IdleTimeoutSnackbar = useMemo(() => {
    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <BaseAlert
          severity='warning'
          data-testid='idle-timeout-snackbar-alert'
          sx={{
            width: '100%',
            backgroundColor: 'yellow',
            color: 'black',
          }}
          elevation={6}
          variant='filled'
        >
          For organizational security, you will be automatically logged out in{' '}
          {formattedTime} min
        </BaseAlert>
      </Snackbar>
    );
  }, [formattedTime, open]);

  return {
    IdleTimeoutSnackbar,
  };
};

export default useIdleTimeout;
