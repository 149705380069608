import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import { useApolloClient } from '@apollo/client';
import SettingsIcon from '@mui/icons-material/Settings';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { captureException } from '@sentry/react';
import MenuPopover from 'components/MenuPopover';
import { usePersonContext } from 'contexts/PersonContext';
import { PATH_ROOT } from 'routes/paths';
import { useSettingsContext } from 'contexts/SettingsContext';
import PrivacyPolicyIcon from './assets/privacy_policy.svg?react';
import { showSecondLayer, useIsInitialized } from '@s-group/react-usercentrics';

// ----------------------------------------------------------------------
type LinkMenuOption = {
  label: string;
  icon: React.ElementType;
  openInNewTab: boolean;
  linkTo?: string;
  onClick?: () => void;
};

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { spacing, palette } = useTheme();
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const apolloClient = useApolloClient();
  const { person } = usePersonContext();

  const isInitialized = useIsInitialized();

  const {
    settings: { VENTURE_CLIENTING_ACADEMY_LINK },
  } = useSettingsContext();

  const menuOptions: LinkMenuOption[] = [
    {
      label: 'Settings',
      icon: SettingsIcon,
      linkTo: PATH_ROOT.settings.users,
      openInNewTab: false,
    },
    {
      label: 'Release Log',
      icon: NewspaperIcon,
      linkTo: VENTURE_CLIENTING_ACADEMY_LINK,
      openInNewTab: true,
    },
    ...(isInitialized
      ? [
          {
            label: 'Privacy Settings',
            icon: PrivacyPolicyIcon,
            onClick: () => {
              showSecondLayer();
            },
            openInNewTab: false,
          },
        ]
      : []),
  ];

  const name = useMemo(
    () => person?.fullName || user?.displayName,
    [person?.fullName, user?.displayName],
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await apolloClient.clearStore();
      logout(PATH_ROOT.auth.login);
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <Box onClick={handleOpen} data-testid='user-avatar'>
        <IconButton
          disabled
          ref={anchorRef}
          sx={{
            padding: 0,
            width: spacing(6),
            height: spacing(6),
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: alpha(palette.grey[900], 0.72),
              },
            }),
          }}
        >
          <Avatar src={user?.photoURL}></Avatar>
        </IconButton>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
            {name}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {menuOptions.map(option => {
          const Icon = option.icon;

          const ComponentType = option.onClick ? 'div' : RouterLink;
          return (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              onClick={() => {
                if (option.onClick) {
                  option.onClick();
                }
                handleClose();
              }}
              {...(!option.onClick && { to: option.linkTo })}
              {...(option.openInNewTab && {
                target: '_blank',
                rel: 'noopener noreferrer',
              })}
              component={ComponentType}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                sx={{
                  mr: 2,
                  width: spacing(3),
                  height: spacing(3),
                }}
              >
                <Icon />
              </Box>

              {option.label}
            </MenuItem>
          );
        })}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color='inherit'
            variant='outlined'
            onClick={handleLogout}
            data-testid='logout-button'
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
